/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from 'react';
import { getDisplayName } from './getDisplayName';

export const withTFDProps = <T extends object & { ['data-name']?: string }>(
  WrappedComponent: React.ComponentType<T>
) => {
  return (props: T): React.ReactElement<T> => {
    const dataName = props?.['data-name'] ?? getDisplayName(WrappedComponent);
    return <WrappedComponent {...props} data-name={dataName} />;
  };
};
