import {
  ButtonDesignProps,
  LinkDesignProps,
  getButtonDesign,
  getLinkDesign,
} from '../../designs';
import React, { FC } from 'react';

import { ActivityIndicator } from '../ActivityIndicator';
import { ButtonBase } from '../ButtonBase';
import { InterfaceUnion } from '../../types';
import classNames from 'classnames';
import styles from './Button.css';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];
type StyleProps = InterfaceUnion<ButtonDesignProps, LinkDesignProps>;

export interface ButtonProps extends StyleProps, IntrinsicButtonProps {
  /** Toggle loading status */
  loading?: boolean;
  /** Toggle link variant styles */
  asLink?: boolean;
  /** Optional class name */
  className?: string;
}

/**
 * Extends native button element
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/button | Button} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const Button: FC<ButtonProps> = ({
  loading = false,
  asLink = false,
  highContrast,
  variant,
  disabled,
  active,
  hover,
  asPill,
  asMini,
  mode,
  className,
  children,
  ...props
}) => {
  const elementClass = !asLink ? styles.button : styles.asLink;
  const designClass = !asLink
    ? getButtonDesign({
        variant: variant !== 'tertiary' ? variant : undefined,
        disabled: disabled && !loading,
        active,
        hover,
        asPill,
        asMini,
      })
    : getLinkDesign({
        variant,
        disabled,
        active,
        hover,
        mode,
        highContrast,
      });

  return (
    <ButtonBase
      aria-busy={loading}
      disabled={disabled || loading}
      {...props}
      className={classNames(elementClass, designClass, className, {
        [styles.loading]: loading && !asLink,
      })}
    >
      {children}
      {loading && !asLink && (
        <span className={styles.indicator}>
          <ActivityIndicator
            mode={variant !== 'secondary' ? 'light' : 'dark'}
          />
        </span>
      )}
    </ButtonBase>
  );
};
