// explicitly allowing the import of page from @farmersdog/analytics in this file
// eslint-disable-next-line no-restricted-imports
import { page as segmentPage } from '@farmersdog/analytics';
import { getMainSiteUserId } from './cookies';

interface PageProps {
  path: string;
  baseUrl: string | undefined;
  otherProps?: Record<string, unknown>;
}

export function page({ path, baseUrl, otherProps = {} }: PageProps) {
  const main_site_user_id = getMainSiteUserId();
  segmentPage({
    path,
    baseUrl,
    properties: {
      main_site_user_id,
      ...otherProps,
    },
  });
}
