import { NavLinkProps, Link } from 'react-router-dom';

/**
 * Determines if the props provided are for a react-router Link component
 */
export function isLink(props: unknown): props is Link {
  if (typeof props !== 'object' || !props) return false;
  const keys = Object.keys(props);
  return keys.includes('to') && Boolean((props as { to?: string }).to);
}

/**
 * Determines if the props provided are for a react-router NavLink component
 */
export function isNavLink(props: unknown): props is NavLinkProps {
  if (typeof props !== 'object' || !props) return false;
  const keys = Object.keys(props);
  return keys.includes('activeClassName') || keys.includes('activeStyle');
}

/**
 * Determines if the props provided are for an anchor element
 */
export function isAnchor(props: unknown): props is JSX.IntrinsicElements['a'] {
  if (typeof props !== 'object' || !props) return false;
  const keys = Object.keys(props);
  return keys.includes('href') && Boolean((props as { href?: string }).href);
}
