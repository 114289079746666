const general = 'general';
const research = 'research-and-safety-standards';

const generalTitle = 'General';
const researchTitle = 'Research and Safety Standards';

export interface MenuItem {
  name: string;
  label: string;
}

export const scrollAnchorNames = {
  general,
  research,
};

export const titles = {
  generalTitle,
  researchTitle,
};

export const menuItems: MenuItem[] = [
  {
    name: general,
    label: generalTitle,
  },
  {
    name: research,
    label: researchTitle,
  },
];
