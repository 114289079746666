import * as React from 'react';
import classNames from 'classnames';

import styles from './ContentWidth.css';

export const ContentWidthAlignment = ['start', 'center', 'end'] as const;
export interface ContentWidthProps extends React.HTMLAttributes<HTMLElement> {
  /** Number of thirds the column takes up */
  fr?: 1 | 2 | 3;
  /** Enable default column padding */
  withPadding?: boolean;
  /** Enable gutter to the right */
  withGutter?: boolean;
  /** Align content */
  alignSelf?: (typeof ContentWidthAlignment)[number];
}

/**
 * Limit your content into 1/3 chunks that adjust based on screen width.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/grid | Grid} from corgi-x to create grid layouts. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const ContentWidth: React.FC<ContentWidthProps> = ({
  fr = 1,
  className,
  children,
  withPadding,
  withGutter,
  alignSelf = 'start',
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.content,
        { [styles.withPadding]: withPadding, [styles.withGutter]: withGutter },
        styles[`fr-${fr}`],
        styles[alignSelf],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
