import { FoodBowls, ObesityPreventionLogo, WsavaLogo } from './components';

import { Grid, GridItem, PageWidth, Text } from '@farmersdog/corgi-x';

import styles from './HealthyDogsSection.module.css';

interface ResponsiveHealthyDogsSection {
  isMobile?: boolean;
}

export function ResponsiveHealthyDogsSection({
  isMobile,
}: ResponsiveHealthyDogsSection) {
  return (
    <Grid
      topSpacing={{ xs: 'xl', lg: '3xl' }}
      rowGap={{ xs: 'lg', lg: 'xl' }}
      justifyContent="center"
    >
      <PageWidth className={styles.pageWidth}>
        <GridItem justifyContent="center">
          <div className={styles.infoContainer}>
            <Text
              variant={isMobile ? 'article-16' : 'article-20'}
              color="charcoal-3"
            >
              It was a vet’s advice that helped launch our company, and we
              remain dedicated to helping vet professionals promote pet health
              with safe, balanced nutrition. We’ve taken the decades of
              veterinary nutrition science that went into making dry and canned
              pet food complete and balanced—and applied it to fresh, highly
              digestible food for dogs.{' '}
              <strong>
                10 years and nearly 1 billion meals later, and with thousands of
                vets recommending our food, we have raised the bar on pet food
                safety
              </strong>{' '}
              and continue to partner with leading institutions to build the
              body of scientific research on dog nutrition and health.
            </Text>
          </div>
        </GridItem>
      </PageWidth>
      <GridItem>
        <Grid gap="lg" alignItems="center" className={styles.logosContainer}>
          <GridItem lg={6}>
            <WsavaLogo />
          </GridItem>
          <GridItem lg={6}>
            <ObesityPreventionLogo />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem>
        <FoodBowls />
      </GridItem>
    </Grid>
  );
}

export function HealthyDogsSection() {
  return (
    <section>
      <div className={styles.mobile}>
        <ResponsiveHealthyDogsSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveHealthyDogsSection />
      </div>
    </section>
  );
}
