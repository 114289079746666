import classNames from 'classnames';
import * as React from 'react';
import { Prompt } from 'react-router-dom';

import { Lightbox, LightboxControlReturn } from '../Lightbox';

import { getModalDesign } from '../../designs/getModalDesign';
import { browserOnly } from '../../util';

import { AsyncModalContent } from './AsyncModalContent';
import styles from './AsyncModal.css';

const UNSET_WIDTH = '';
const MIN_HEIGHT = 0;

export interface AsyncModalProps extends LightboxControlReturn {
  /** Text shown in the blocking modal */
  modalText: string;
  /** Text shown in the react-router-dom prompt, and the browser alert in IE (not configurable in other browsers) */
  promptText: string;
  /** Any additional classes to apply */
  className?: string;
}
/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/activitymodal | ActivityModal} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const AsyncModal: React.FC<AsyncModalProps> = ({
  modalText,
  promptText,
  className,
  rootProps,
  ...props
}) => {
  const modalDesign = getModalDesign();

  React.useEffect(() => {
    if (!rootProps.isOpen) return;

    const warnBeforeUnload = (e: BeforeUnloadEvent): void => {
      // needs a dummy value even though message isn't customizable in all browsers
      e.returnValue = `You're in the middle of checking out. Are you sure you want to leave...?`;
    };

    browserOnly(window =>
      window.addEventListener('beforeunload', warnBeforeUnload)
    );

    return (): void =>
      browserOnly(window =>
        window.removeEventListener('beforeunload', warnBeforeUnload)
      );
  }, [rootProps.isOpen]);

  return (
    <Lightbox
      {...rootProps}
      {...props}
      width={UNSET_WIDTH}
      height={MIN_HEIGHT}
      disableInteractions={true}
      className={classNames(modalDesign, styles.lightbox, className)}
    >
      <AsyncModalContent modalText={modalText} />
      <Prompt when={rootProps.isOpen} message={promptText}></Prompt>
    </Lightbox>
  );
};
