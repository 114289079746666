import { track } from '../track';
import { landing_page_clicked_register_for_vet_team_portal_sticky_navbar } from './eventNames';

/**
 * Send a tracking event for when a user clicks on Register for Vet Team Portal in sticky Navbar.
 */

export function trackLandingPageClickedRegisterForVetTeamPortalStickyNavbar() {
  track({
    eventName: landing_page_clicked_register_for_vet_team_portal_sticky_navbar,
  });
}
