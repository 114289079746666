import { SVGProps, ReactElement, FunctionComponent } from 'react';
import { Text } from '@farmersdog/corgi-x';

import styles from './Benefit.module.css';
import { BenefitTitles } from '../../benefitData';

interface BenefitProps {
  image: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: BenefitTitles;
  ariaLabel?: string;
  content: ReactElement;
}

export function Benefit({ image, title, content, ariaLabel }: BenefitProps) {
  const Image = image;

  return (
    <div className={styles.container}>
      <div>
        <Image className={styles.icon} aria-label={ariaLabel} />
      </div>
      <div>
        <Text
          bold
          as="h3"
          color="blueberry-3"
          variant="heading-22"
          className={styles.title}
        >
          {title}
        </Text>
        <Text
          as="p"
          color="charcoal-3"
          variant="article-16"
          className={styles.subtitle}
        >
          {content}
        </Text>
      </div>
    </div>
  );
}
