import { FormikProps } from 'formik';
import { Button, Text } from '@farmersdog/corgi-x';

import { FormFieldArray, FormFields } from '../../../components/FormFields';
import { VeterinaryPracticeOrOrganizationInformationFormValues } from './constants';
import styles from './SignUpFormWithNoSelectedPractice.module.css';
import classNames from 'classnames';
import * as pagePaths from '../../../constants/pagePaths';
import { Chevron } from '@farmersdog/corgi-x/icons';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

interface SignUpFormWithNoSelectedPracticeProps<T extends FormFieldArray> {
  form: FormikProps<VeterinaryPracticeOrOrganizationInformationFormValues>;
  fieldData: T;
  title: string;
  children: JSX.Element | null;
  isConferenceModeOn?: boolean;
}

export function SignUpFormWithNoSelectedPractice<T extends FormFieldArray>({
  fieldData,
  form,
  title,
  children,
  isConferenceModeOn = false,
}: SignUpFormWithNoSelectedPracticeProps<T>) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <>
      {children}
      <div>
        <div className={styles.backSection}>
          <Button
            variant="plain-text"
            className={styles.button}
            color={showPortalUXOverhaul ? 'kale' : 'carrot'}
            to={{
              pathname: isConferenceModeOn
                ? pagePaths.PATH_SIGNUP_CONFERENCE
                : pagePaths.PATH_SIGNUP,
              search: 'practice=search',
            }}
            type="link"
            underline
          >
            <Chevron
              orientation="left"
              className={styles.chevron}
              baseColor={showPortalUXOverhaul ? 'Kale2' : 'Carrot2'}
            />

            <Text
              variant="heading-16"
              color={showPortalUXOverhaul ? 'kale-2' : 'carrot-2'}
            >
              Back to Search
            </Text>
          </Button>
        </div>
        <Text as="h3" variant="heading-22" bold className={styles.title}>
          {title}
        </Text>
        <form
          id="master-search-form"
          onSubmit={e => {
            e.preventDefault();
            form.handleSubmit(e);
          }}
        >
          <div
            className={classNames(styles.formContainer, styles.splitLastForms)}
          >
            {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
            <FormFields fieldData={fieldData} formik={form} />
          </div>
        </form>
      </div>
    </>
  );
}
