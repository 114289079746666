import { Component, ReactNode } from 'react';

import { ErrorPage } from '../ErrorPage';

import { onLocationChange } from '../onLocationChange';

interface ErrorBoundaryProps {
  error?: Error;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  private locationChangeInterval: NodeJS.Timeout | undefined;

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: !!props.error, error: props.error };
  }

  componentWillUnmount() {
    if (this.locationChangeInterval) {
      clearInterval(this.locationChangeInterval);
    }
  }

  componentDidCatch = (): void => {
    this.locationChangeInterval = onLocationChange(
      this.handleLocationChange
    ) as NodeJS.Timeout;
  };

  handleLocationChange = (): void => {
    this.setState({ hasError: false, error: undefined });
  };

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    if (!this.state.error) return null;

    return <ErrorPage error={this.state.error} />;
  }
}
