import * as React from 'react';

import { CardField, CardFieldProps } from './CardField';

import { elementTypes } from '../Stripe/elementTypes';

type CardCvcProps = Omit<CardFieldProps, 'elementType'>;

/**
 * Render a credit card cvc field. Must be inside an `ElementsProvider` and a
 * `StripeProvider`.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/creditcardinput | CreditCardInput} from corgi-x to create card fields. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const CardCvc: React.FC<CardCvcProps> = props => {
  return <CardField {...props} elementType={elementTypes.cardCvc} />;
};
