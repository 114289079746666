import { LazyQueryExecFunction } from '@apollo/client';
import { Logger } from '@farmersdog/logger';
import {
  VetDetailsQuery,
  VetDetailsQueryVariables,
} from '../../../graphql/types';

interface HandleCreateVetFailureArgs {
  deleteUser: (() => Promise<void>) | undefined;
  vetDetailsQuery: LazyQueryExecFunction<
    VetDetailsQuery,
    VetDetailsQueryVariables
  >;
}

export async function handleCreateVetFailure({
  deleteUser,
  vetDetailsQuery,
}: HandleCreateVetFailureArgs) {
  if (!deleteUser) {
    throw new Error('No deleteUser function provided');
  }

  const logger = new Logger('auth:handleCreateVetFailure');

  let vetCreated = false;
  try {
    // User is authenticated at this point to query for vet details
    const response = await vetDetailsQuery();
    vetCreated = Boolean(response.data?.vetDetails);
  } catch (error) {
    // Delete the auth record if we cannot determine whether the user was created, to be on the safe side. This might be an indicator that our backend service is down.
    await deleteUser();
    logger.warn(
      'Unable to determine if vet is created. Auth record has been deleted',
      { error }
    );
    return;
  }

  if (vetCreated) {
    return;
  }

  await deleteUser();
  logger.warn('Failed to create vet. Auth record has been deleted');
}
