import { Grid } from '@farmersdog/corgi-x';
import { TitleSection } from '../TitleSection';
import { ShareYourExperience } from './ShareYourExperience';
import { Testimonials } from './Testimonials';

export const TeamSupport = () => {
  return (
    <>
      <TitleSection title="See what other vets say" />
      <Testimonials />
      <Grid topSpacing="md">
        <ShareYourExperience />
      </Grid>
    </>
  );
};
