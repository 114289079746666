import { Amplify } from 'aws-amplify';
import { ConsoleLogger } from 'aws-amplify/utils';

import config from '../../../config';

export function setupAmplify() {
  // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#authentication-amazon-cognito
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.get('app.cognito.userPoolId'),
        userPoolClientId: config.get('app.cognito.clientId'),
      },
    },
  });

  ConsoleLogger.LOG_LEVEL = config.get('app.cognito.logLevel');
}
