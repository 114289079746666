import tfdAndWSAVAThumbnailPng from '../assets/TfdAndWsavaThumbnail.png';
import tfdAndWSAVAThumbnailJpg from '../assets/TfdAndWsavaThumbnail.jpg';
import tfdAndWSAVAThumbnailWebp from '../assets/TfdAndWsavaThumbnail.webp';
import tfdAndWSAVA from '../../../../../static/pdf/TFD_WSAVA_Onesheet.pdf';

import FoodAndSafetyThumbnailPng from '../assets/FoodSafetyThumbnail.png';
import FoodAndSafetyThumbnailJpg from '../assets/FoodSafetyThumbnail.jpg';
import FoodAndSafetyThumbnailWebp from '../assets/FoodSafetyThumbnail.webp';
import manufacturingandFoodSafety from '../../../../../static/pdf/ManufacturingandFoodSafety.pdf';

import ClinicalIndicationsThumbnailPng from '../assets/ClinicalIndicationsThumbnail.png';
import ClinicalIndicationsThumbnailJpg from '../assets/ClinicalIndicationsThumbnail.jpg';
import ClinicalIndicationsThumbnailWebp from '../assets/ClinicalIndicationsThumbnail.webp';
import clinicalIndicationsChart from '../../../../../static/pdf/ClinicalIndicationsChart.pdf';

import EliminationDietThumbnailPng from '../assets/EliminationDietThumbnail.png';
import EliminationDietThumbnailJpg from '../assets/EliminationDietThumbnail.jpg';
import EliminationDietThumbnailWebp from '../assets/EliminationDietThumbnail.webp';
import performEliminationDietTrialsFile from '../../../../../static/pdf/PerformEliminationDietTrials.pdf';

import FatConsumptionThumbnailPng from '../assets/FatConsumptionThumbnail.png';
import FatConsumptionThumbnailJpg from '../assets/FatConsumptionThumbnail.jpg';
import FatConsumptionThumbnailWebp from '../assets/FatConsumptionThumbnail.webp';
import understandingFatConsumptionFile from '../../../../../static/pdf/TFD_Understanding-Fat-Consumption.pdf';

import DcmThumbmnailPng from '../assets/DcmThumbnail.png';
import DcmThumbmnailJpg from '../assets/DcmThumbnail.jpg';
import DcmThumbmnailWebp from '../assets/DcmThumbnail.webp';
import decodingCanineDCMFile from '../../../../../static/pdf/DecodingCanineDCM.pdf';

import FeedingTrialThumbnailPng from '../assets/FeedingTrialThumbnail.png';
import FeedingTrialThumbnailJpg from '../assets/FeedingTrialThumbnail.jpg';
import FeedingTrialThumbnailWebp from '../assets/FeedingTrialThumbnail.webp';
import feedingTrialAffidavitsFile from '../../../../../static/pdf/Feeding-Trial-Affidavits.pdf';

import DigestibilityThumbnailPng from '../assets/DigestibilityThumbnail.png';
import DigestibilityThumbnailJpg from '../assets/DigestibilityThumbnail.jpg';
import DigestibilityThumbnailWebp from '../assets/DigestibilityThumbnail.webp';
import digestibilityOnesheetFile from '../../../../../static/pdf/DigestibilityOnesheet.pdf';

import UrinarySaturationThumbnailPng from '../assets/UrinarySaturationThumbnail.png';
import UrinarySaturationThumbnailJpg from '../assets/UrinarySaturationThumbnail.jpg';
import UrinarySaturationThumbnailWebp from '../assets/UrinarySaturationThumbnail.webp';
import urinaryHealthFile from '../../../../../static/pdf/TFD_Understanding-RSS.pdf';

export interface Pdf {
  title: string;
  description: string;
  link: string;
  thumbnailSources: string[];
  urlParam: string;
}
interface PdfData {
  defaultExpanded: boolean;
  sectionHeading: string;
  sectionDescription: string | null;
  pdfs: Pdf[];
}
export const pdfSectionData: PdfData[] = [
  {
    defaultExpanded: true,
    sectionHeading: 'Getting to Know Us',
    sectionDescription:
      'Our food is complete and balanced according to AAFCO standards and made in FDA-compliant facilities, to the same quality-control standards as human food.',
    pdfs: [
      {
        title: 'The Farmer’s Dog and WSAVA',
        description:
          'Learn how we meet the World Small Animal Veterinary Association’s guidelines for pet food.',
        link: tfdAndWSAVA,
        thumbnailSources: [
          tfdAndWSAVAThumbnailPng,
          tfdAndWSAVAThumbnailJpg,
          tfdAndWSAVAThumbnailWebp,
        ],
        urlParam: 'tfd-and-wsava',
      },
      {
        title: 'The Farmer’s Dog Manufacturing and Food Safety',
        description:
          'The Farmer’s Dog is raising the bar on every aspect of pet food, with a particular focus on manufacturing and food safety. Learn more about our production and safety methods methods.',
        link: manufacturingandFoodSafety,
        thumbnailSources: [
          FoodAndSafetyThumbnailPng,
          FoodAndSafetyThumbnailJpg,
          FoodAndSafetyThumbnailWebp,
        ],
        urlParam: 'manufacturing-and-food-safety',
      },
    ],
  },
  {
    defaultExpanded: false,
    sectionHeading: 'Patient Care',
    sectionDescription: null,
    pdfs: [
      {
        title: 'Clinical Indications',
        description:
          "Learn more about how The Farmer's Dog can be used therapeutically to support your patients with medical conditions.",
        link: clinicalIndicationsChart,
        thumbnailSources: [
          ClinicalIndicationsThumbnailPng,
          ClinicalIndicationsThumbnailJpg,
          ClinicalIndicationsThumbnailWebp,
        ],
        urlParam: 'clinical-indications',
      },
      {
        title:
          "How to Perform Elimination Diet Trials Using The Farmer's Dog Recipes",
        description:
          'Our human-grade manufacturing process avoid the risk of cross-contamination and introduction of unwanted allergens, making our recipes great options for dogs with food sensitivities.',
        link: performEliminationDietTrialsFile,
        thumbnailSources: [
          EliminationDietThumbnailPng,
          EliminationDietThumbnailJpg,
          EliminationDietThumbnailWebp,
        ],
        urlParam: 'elimination-diet',
      },
    ],
  },
  {
    defaultExpanded: false,
    sectionHeading: 'Research',
    sectionDescription: null,
    pdfs: [
      {
        title: 'Feeding Study Affidavits',
        description:
          'Affidavits from veterinarians who oversaw our feeding studies.',
        link: feedingTrialAffidavitsFile,
        thumbnailSources: [
          FeedingTrialThumbnailPng,
          FeedingTrialThumbnailJpg,
          FeedingTrialThumbnailWebp,
        ],
        urlParam: 'feeding-study-affidavits',
      },
      {
        title: 'Digestibility: A Core Nutritional Metric',
        description:
          'A key measure of a pet food’s quality, affecting not just how much stool is produced each day but a dog’s long-term well-being.',
        link: digestibilityOnesheetFile,
        thumbnailSources: [
          DigestibilityThumbnailPng,
          DigestibilityThumbnailJpg,
          DigestibilityThumbnailWebp,
        ],
        urlParam: 'digestibility',
      },
      {
        title:
          'Urinary Health: Understanding Urinary Relative Supersaturation (RSS)',
        description:
          'Relative supersaturation (RSS) is considered the gold standard for risk assessment of crystal formation (struvite and oxalate) in urine for dogs.',
        link: urinaryHealthFile,
        thumbnailSources: [
          UrinarySaturationThumbnailPng,
          UrinarySaturationThumbnailJpg,
          UrinarySaturationThumbnailWebp,
        ],
        urlParam: 'urinary-health',
      },
      {
        title:
          'Understanding Fat Consumption by Calculating Grams of Fat Consumed',
        description:
          'See how we are able to use more meat and non-rendered fats while keeping fat consumption at or below a typical veterinarian-recommended kibble.',
        link: understandingFatConsumptionFile,
        thumbnailSources: [
          FatConsumptionThumbnailPng,
          FatConsumptionThumbnailJpg,
          FatConsumptionThumbnailWebp,
        ],
        urlParam: 'fat-consumption',
      },
      {
        title: 'Decoding Canine DCM: The Truth Behind the Headlines',
        description:
          'In order to address the most persistent misunderstandings, we’ve compiled statements from the FDA along with the latest peer-reviewed research, and commentary from board-certified nutritionists.',
        link: decodingCanineDCMFile,
        thumbnailSources: [
          DcmThumbmnailPng,
          DcmThumbmnailJpg,
          DcmThumbmnailWebp,
        ],
        urlParam: 'decoding-canine-dcm',
      },
    ],
  },
];
