import { ReviewStars } from './ReviewStars';
import { Card } from '../../../../components';
import { Divider, Grid, Text } from '@farmersdog/corgi-x';
import VETS_DATA from './vetsData';
import { Testimonial } from './Testimonial/Testimonial';

export const Testimonials = () => {
  const notLastItem = (index: number) => index < VETS_DATA.length - 1;

  return (
    <>
      <Card>
        <Grid
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="md"
        >
          <ReviewStars starCount={5} />
          <Text variant="heading-22" bold color="Blueberry3">
            Veterinarian Testimonials
          </Text>
          {VETS_DATA.map((vet, index) => (
            <div key={index}>
              <Testimonial {...vet} />
              {notLastItem(index) && (
                <Divider width={1} color="Blueberry1" spacing={24} />
              )}{' '}
            </div>
          ))}
        </Grid>
      </Card>
    </>
  );
};
