import { Grid, Link, Text } from '@farmersdog/corgi-x';
import { emails } from '@farmersdog/constants';

import EnvelopeIcon from '../assets/envelope.svg?react';
import styles from './FAQsContent.module.css';

export const EmailSectionLegacy = () => {
  return (
    <>
      <Text variant="heading-22" as="h4" bold color="blueberry-3">
        Can’t find what you are looking for? Reach out:
      </Text>

      <Grid bottomSpacing="lg" alignItems="center" columnGap="xs">
        <EnvelopeIcon className={styles.contactIcon} />
        <Link to={`mailto:${emails.EMAIL_VET}`}>
          <Text variant="heading-16" color="kale-3">
            {emails.EMAIL_VET}
          </Text>
        </Link>
      </Grid>
    </>
  );
};
