import { CustomSelectorProps } from '../Selector';
import { LabeledSelector } from '../LabeledSelector';
import React from 'react';
import * as typography from '../../typography';

import styles from './Radio.css';

export interface RadioProps extends CustomSelectorProps {
  label: React.ReactNode;
  /** Color of the label text */
  color?:
    | (typeof typography.ArticleColors)[number]
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | (typeof typography.BodyColors)[number]
    | (typeof typography.HeadingColors)[number];
}

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/radiobutton | RadioButton} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Radio: React.FC<RadioProps> = ({ label, color, ...props }) => {
  return (
    <LabeledSelector
      className={styles.container}
      {...props}
      type="radio"
      color={color}
    >
      {label}
    </LabeledSelector>
  );
};
