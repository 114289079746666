import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import styles from './InterceptedSection.module.css';

import { useIntersection } from '@farmersdog/corgi-x';

export interface InterceptedSectionProps {
  // id used to create the hash for the section
  id: string;
  // section to be intercepted
  children: React.ReactNode;
  // active hash in the URL
  activeHash: string;
  // block intersections when the user is scrolling or clicking on the navigation
  blockIntersections: boolean;
}

/**
 * This component is used to intercept the section passed down as a children and once it is intercepted
 * it pushes the id passed as a prop to the history object.
 * The intercepction is done by using the useIntersection hook.
 **/
export const InterceptedSection = ({
  id,
  children,
  activeHash,
  blockIntersections,
}: InterceptedSectionProps) => {
  const topRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const hash = `#${id}`;

  const topEntry = useIntersection(topRef.current, {
    // a threashold of 1 means that the point of intersection is when the section is fully visible at that rootMargin
    threshold: 1,
    // point of intersection is -90% from the bottom from the top of the viewport for the top reference (when user scrolling to the bottom)
    rootMargin: '0% 0px -90% 0px',
  });
  const bottomEntry = useIntersection(bottomRef.current, {
    // a threashold of 1 means that the point of intersection is when the section is fully visible at that rootMargin
    threshold: 1,
    // point of intersection is between -10% and -80% from the top of the viewport for the bottom reference (when user scrolling to the top)
    rootMargin: '-10% 0px -80% 0px',
  });

  useEffect(() => {
    // if the user is scrolling, do not push the active tag to the history
    if (blockIntersections) {
      return;
    }

    if (topEntry && topEntry.isIntersecting) {
      // if the section is already active, do not push the active tag to the history
      if (activeHash === hash) return;
      history.push(hash);
      return;
    }
    if (bottomEntry && bottomEntry.isIntersecting) {
      // if the section is already active, do not push the active tag to the history(avoid re-rendering)
      if (activeHash === hash) return;
      history.push(hash);
    }
  }, [
    topEntry,
    topEntry?.isIntersecting,
    blockIntersections,
    id,
    activeHash,
    history,
    bottomEntry,
    hash,
  ]);

  return (
    <>
      {/*the topRef will help us to detect whether the section is coming up when scrolling down  */}
      <div ref={topRef} className={styles.interceptedSection} />
      <div className={styles.content}>{children}</div>
      {/*the bottomRef will help us to detect whether the section is coming up when scrolling up  */}
      <div ref={bottomRef} className={styles.interceptedSection} />
    </>
  );
};
