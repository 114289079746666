import * as React from 'react';

import { ActivityIndicator } from '../ActivityIndicator';
import { Text } from '../Text';
import { getNonWaveDesign } from '../../designs/getModalDesign';
import styles from './AsyncModal.css';

export interface AsyncModalContentProps {
  modalText: string;
}

export const AsyncModalContent: React.FC<AsyncModalContentProps> = ({
  modalText,
}) => {
  const nonWaveDesign = getNonWaveDesign();

  return (
    <div className={nonWaveDesign}>
      <Text
        as="div"
        variant="heading-21"
        color="kale-3"
        className={styles.message}
      >
        {modalText}
      </Text>
      <div className={styles.activityIndicator}>
        <ActivityIndicator mode="dark" />
      </div>
    </div>
  );
};
