/**
 * Return a boolean indicating if the slide should be marked as the currently
 * active slide.
 */
export function getIsActiveSlide(
  slideRect: DOMRect,
  scrollableRect: DOMRect
): boolean {
  const scrollableCenter = scrollableRect.left + scrollableRect.width / 2;

  return (
    scrollableCenter > slideRect.left && scrollableCenter < slideRect.right
  );
}
