import * as React from 'react';

import { Arrow } from '../../icons';
import { Link, LinkProps } from '../Link';
import { Text } from '../Text';
import { splitPath } from './splitPath';
import { stringifyUrl } from './stringifyUrl';
import styles from './Breadcrumbs.css';
import { useLocation } from 'react-router-dom';

export interface BreadcrumbsProps extends LinkProps {
  stem: string;
  /** A function that will stringify the url to a display string */
  stringify?: (url: string) => string;
  /** @ignore Current pathname for demo purposes only */
  pathname?: string;
  /** A class name for the breadcrumb container */
  containerClassName?: string;
}
/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/breadcrumbs | BreadCrumbs} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  stringify = stringifyUrl,
  pathname: pathnameProp,
  stem = '',
  containerClassName,
  ...props
}) => {
  const location = useLocation();
  const pathname = pathnameProp || location.pathname;
  const breadcrumbs = splitPath(pathname);
  const stems = splitPath(stem);

  return (
    <Text as="div" variant="heading-16" className={containerClassName}>
      {breadcrumbs.map((breadcrumb, index) => {
        const previousBreadcrumbs = breadcrumbs.slice(0, index + 1);
        const isLast = index === breadcrumbs.length - 1;

        if (stems.includes(breadcrumb)) {
          return null;
        }

        const path = `/${previousBreadcrumbs.join('/')}`;

        return (
          <React.Fragment key={path}>
            <Link
              to={path}
              mode="dark"
              variant="secondary"
              activeClassName={styles.active}
              disabled={isLast}
              {...props}
            >
              {stringify(breadcrumb)}
            </Link>
            {!isLast && <Arrow className={styles.caret} />}
          </React.Fragment>
        );
      })}
    </Text>
  );
};
