import { Close } from '../../icons';
import React from 'react';
import classNames from 'classnames';
import styles from './CloseButton.css';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];

/**
 * Default modal close button.
 */
export const CloseButton: React.FC<IntrinsicButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      aria-label="close"
      className={classNames(styles.button, className)}
      {...props}
    >
      <Close />
    </button>
  );
};
