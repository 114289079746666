import * as React from 'react';
import round from 'lodash/round';
import * as animations from './animations';
import buttonStyles from '../../styles/button.css';
import classNames from 'classnames';
import { getScale } from './getScale';
import styles from './Slide.css';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];
export interface SlideProps extends IntrinsicButtonProps {
  /** Whether to toggle the wide view */
  isWide: boolean;
  /** The index of the slide */
  index: number;
  /** Whether to toggle the debug mode for this component */
  isDebug: boolean;
  /** The amount the slide is intersecting the parent element */
  intersectionRatio: number;
  /** Whether this is the active slide */
  active: boolean;
}

export const Slide: React.FC<SlideProps> = ({
  children,
  className,
  isWide = false,
  index,
  intersectionRatio,
  isDebug = false,
  active,
  ...props
}) => {
  const responsiveAnimation = !isWide ? animations.notWide : animations.wide;

  const style = React.useMemo(() => {
    const { minScale, maxScale, curve } = responsiveAnimation;
    const progress = curve(intersectionRatio);
    const scale = getScale(progress, minScale, maxScale);

    return { transform: `scale(${scale})` };
  }, [intersectionRatio, responsiveAnimation]);

  const showDebug = index === 2 && isDebug;
  const activeStyle = active ? styles.active : styles.inactive;

  return (
    <li
      className={classNames(styles.slide, className, {
        [styles.isWide]: isWide,
        [styles.isNotWide]: !isWide,
      })}
    >
      <div
        className={classNames(
          styles.animationReference,
          showDebug && styles.animationDebug
        )}
      >
        {showDebug &&
          `Animation reference intersection ${round(intersectionRatio, 2)}`}
      </div>
      <button
        className={classNames(styles.button, buttonStyles.button, activeStyle)}
        style={style}
        {...props}
      >
        {children}
      </button>
    </li>
  );
};
