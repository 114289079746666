import { Text } from '@farmersdog/corgi-x';
import { QuestionDropdown } from '../QuestionDropdown';
import { researchAndSafetyStandardQuestions } from '../data';

export const ResearchSection = ({
  menuItemRef,
  showPortalUXOverhaul,
}: {
  menuItemRef: string;
  showPortalUXOverhaul?: boolean;
}) => {
  return (
    <div id={menuItemRef}>
      <Text
        bold
        as="h3"
        topSpacing={showPortalUXOverhaul ? 'none' : 'xl'}
        color="blueberry-3"
        variant="heading-28"
      >
        Research and Safety Standards
      </Text>
      {researchAndSafetyStandardQuestions.map(({ question, answer }) => (
        <QuestionDropdown key={question} question={question} answer={answer} />
      ))}
    </div>
  );
};
