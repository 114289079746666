import { Grid, Text, Button } from '@farmersdog/corgi-x';
import { useDeepLink } from '../../hooks';
import * as pagePaths from '../../constants/pagePaths';
import styles from './LoginSection.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const LoginSection = () => {
  const { redirectTo } = useDeepLink();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <Grid
      flexDirection="column"
      className={showPortalUXOverhaul ? styles.login : styles.loginLegacy}
      gap="md"
    >
      <Text bold align="center">
        Already have an account?
      </Text>
      <Text align="center">
        <Button
          to={{
            pathname: pagePaths.PATH_LOGIN,
            search: redirectTo,
          }}
          type="link"
          variant={
            showPortalUXOverhaul
              ? 'secondary-mini-kale-experimental'
              : 'secondary'
          }
          className={styles.loginButton}
        >
          Log In
        </Button>
      </Text>
    </Grid>
  );
};
