import { createMachine } from 'xstate';
import {
  DiscountRequestEvent,
  DiscountRequestMachineEvent,
} from './discountRequestEvent';
import { DiscountRequestState } from './discountRequestState';

export type DiscountRequestMachineContext = {
  isLegacyHospital: boolean;
  steps: DiscountRequestState[];
};

const INITIAL_STATE = DiscountRequestState.AreYouSubscribed;

export const discountRequestMachine = createMachine<
  DiscountRequestMachineContext,
  DiscountRequestMachineEvent
>({
  id: 'discount-request',
  initial: INITIAL_STATE,
  predictableActionArguments: true,
  context: {
    isLegacyHospital: true,
    steps: [INITIAL_STATE],
  },
  states: {
    [DiscountRequestState.AreYouSubscribed]: {
      on: {
        [DiscountRequestEvent.AlreadySubscribed]: {
          target: DiscountRequestState.EnterEmail,
        },
        [DiscountRequestEvent.NotYetSubscribed]: {
          target: DiscountRequestState.HowToGetDiscount,
        },
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.HowToGetDiscount]: {
      on: {
        [DiscountRequestEvent.HowToGetDiscountContinue]: {
          target: DiscountRequestState.EnterEmail,
        },
        [DiscountRequestEvent.Previous]: {
          target: DiscountRequestState.AreYouSubscribed,
        },
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.EnterEmail]: {
      on: {
        [DiscountRequestEvent.EnterEmailContinue]: [
          {
            cond: context => context.isLegacyHospital,
            target: DiscountRequestState.PracticeSearch,
          },
          {
            target: DiscountRequestState.ReviewInfo,
          },
        ],
        [DiscountRequestEvent.Previous]: [
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.HowToGetDiscount,
            target: DiscountRequestState.HowToGetDiscount,
          },
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.AreYouSubscribed,
            target: DiscountRequestState.AreYouSubscribed,
          },
        ],
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.PracticeSearch]: {
      on: {
        [DiscountRequestEvent.SelectPractice]: {
          target: DiscountRequestState.ReviewInfoWithUpdatedPractice,
        },
        [DiscountRequestEvent.AddPractice]: {
          target: DiscountRequestState.AddPractice,
        },
        [DiscountRequestEvent.Previous]: [
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.ReviewInfo,
            target: DiscountRequestState.ReviewInfo,
          },
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.EnterEmail,
            target: DiscountRequestState.EnterEmail,
          },
        ],
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.AddPractice]: {
      on: {
        [DiscountRequestEvent.SelectPractice]: {
          target: DiscountRequestState.ReviewInfo,
        },
        [DiscountRequestEvent.Previous]: {
          target: DiscountRequestState.PracticeSearch,
        },
        [DiscountRequestEvent.SubmitRequest]: {
          target: DiscountRequestState.RequestSuccess,
        },
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.ReviewInfo]: {
      on: {
        [DiscountRequestEvent.UpdatePractice]: {
          target: DiscountRequestState.PracticeSearch,
        },
        [DiscountRequestEvent.SubmitRequest]: {
          target: DiscountRequestState.RequestSuccess,
        },
        [DiscountRequestEvent.Previous]: [
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.EnterEmail,
            target: DiscountRequestState.EnterEmail,
          },
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.PracticeSearch,
            target: DiscountRequestState.PracticeSearch,
          },
        ],
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.ReviewInfoWithUpdatedPractice]: {
      on: {
        [DiscountRequestEvent.UpdatePractice]: {
          target: DiscountRequestState.PracticeSearch,
        },
        [DiscountRequestEvent.SubmitRequest]: {
          target: DiscountRequestState.RequestSuccess,
        },
        [DiscountRequestEvent.Previous]: [
          {
            cond: (_, event) =>
              event.prevStep === DiscountRequestState.PracticeSearch,
            target: DiscountRequestState.PracticeSearch,
          },
        ],
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    [DiscountRequestState.RequestSuccess]: {
      on: {
        [DiscountRequestEvent.Close]: {
          target: DiscountRequestState.Close,
        },
      },
    },
    /**
     * We want to properly close the machine when exiting the workflow
     * The current designs do not having a closing ui
     */
    [DiscountRequestEvent.Close]: {
      type: 'final',
    },
  },
});
