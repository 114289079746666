import { StatusMessage, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import styles from './FormSuccess.module.css';
import { CheckCircle } from '@farmersdog/corgi-x/icons';

export interface FormSuccessProps {
  message: string;
}

export const FormSuccess = ({ message }: FormSuccessProps) => {
  return (
    <StatusMessage
      politeness="polite"
      visuallyHidden={!message}
      className={classNames({ [styles.container]: message })}
    >
      <CheckCircle size={24} baseColor="Kale2" />
      <Text color="kale-2" bold>
        {message}
      </Text>
    </StatusMessage>
  );
};
