import { Position } from '../../../../../graphql/types';
import { AddVetpracticeFormFields } from '../constants';
import * as yup from 'yup';
import { states } from '../../../../../constants';
import { fieldRequiredErrorMessage } from '../../../../../utils';

export function generateAddVetPracticeValidationSchema() {
  return yup.object({
    [AddVetpracticeFormFields.name]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddVetpracticeFormFields.phone]: yup.string(),

    [AddVetpracticeFormFields.streetAddress1]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddVetpracticeFormFields.streetAddress2]: yup.string(),
    [AddVetpracticeFormFields.city]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddVetpracticeFormFields.state]: yup
      .string()
      .required(fieldRequiredErrorMessage)
      .oneOf(Object.values(states)),
    [AddVetpracticeFormFields.zip]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddVetpracticeFormFields.position]: yup
      .string()
      .required(fieldRequiredErrorMessage)
      .oneOf(Object.values(Position)),
    [AddVetpracticeFormFields.acceptedTerms]: yup
      .boolean()
      .required(fieldRequiredErrorMessage)
      .oneOf([true], 'You must accept the terms and conditions.'),
  });
}
