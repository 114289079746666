import { track } from '../track';
import { faq_navbar_clicked } from './eventNames';

/**
 * Send a tracking event for when a user clicks "FAQ" in the navbar
 *
 */

export function trackClickedFAQNavbar() {
  track({
    eventName: faq_navbar_clicked,
  });
}
