import { forgot_password_requested_reset } from './eventNames';
import { track } from '../track';

/**
 * Send a tracking event for when a user uses forgot password functionality
 *
 * @param email - User email
 */

export interface TrackRequestedResetPasswordValues {
  email: string;
  result: boolean;
}

export function trackRequestedResetPassword({
  email,
  result,
}: TrackRequestedResetPasswordValues) {
  track({
    eventName: forgot_password_requested_reset,
    eventProps: {
      email,
      result,
    },
  });
}
