import React, { useId } from 'react';

import { ANIMATION_DURATION } from './constants';
import { Button } from '../Button';
import { CSSTransition } from 'react-transition-group';
import { Text } from '../Text';
import { ToastContext } from './ToastContext';
import classNames from 'classnames';
import dialogStyles from './ToastDialog.css';
import toastStyles from './Toast.css';
import transitionStyles from './Toast.transitions.css';

export const VARIANTS = ['negative', 'neutral', 'positive'] as const;
export interface ToastDialogProps {
  variant: (typeof VARIANTS)[number];
  message: React.ReactNode;
  buttonLabel: React.ReactNode;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  close: () => void;
  id?: string;
}

/**
 * A toast that includes a single interaction.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/toast | Toast} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const ToastDialog: React.FC<ToastDialogProps> = ({
  /** Style of the toast */
  variant,
  /** Toast content */
  message,
  /** Text in the button */
  buttonLabel,
  /** Button click handler */
  onButtonClick,
  ...transitionProps
}) => {
  const { closeToastDialog } = React.useContext(ToastContext);
  const labelId = useId();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    closeToastDialog?.();
    onButtonClick?.(e);
  };

  return (
    <CSSTransition
      {...transitionProps}
      timeout={ANIMATION_DURATION}
      classNames={transitionStyles}
      unmountOnExit
      appear
    >
      <div
        role="alertdialog"
        className={classNames(toastStyles.toast, toastStyles[variant])}
        aria-label={labelId}
      >
        <div role="document" className={dialogStyles.document}>
          <Text
            variant="heading-16"
            color="kale-3"
            id={labelId}
            className={dialogStyles.message}
          >
            {message}
          </Text>
          <Button
            asLink
            variant="primary"
            onClick={handleClick}
            className={dialogStyles.button}
          >
            <Text variant="heading-16" bold>
              {buttonLabel}
            </Text>
          </Button>
        </div>
      </div>
    </CSSTransition>
  );
};
