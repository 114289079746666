/**
 * Return if an input has a value. Inputs can have values of number type which
 * can make naive `Boolean` coercion incorrect.
 *
 * @example
 *
 *   hasValue(null) // false
 *   hasValue('') // false
 *   hasValue(0) // true
 *   hasValue(undefined) // false
 *   hasValue('some string') // true
 *
 */
export const getHasValue = (
  value?: string | number | readonly string[]
): boolean => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'number') {
    return Number.isFinite(value);
  }

  return Boolean(value);
};
