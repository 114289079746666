import { Button, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../../components';
import { emails } from '@farmersdog/constants';

const vetEmail = emails.EMAIL_VET;

export const ShareYourExperience = () => {
  return (
    <Card>
      <Text
        variant="heading-22"
        bold
        color="Blueberry3"
        as="h3"
        topSpacing="none"
      >
        Share Your Experience
      </Text>
      <Text variant="article-16" topSpacing="none" bottomSpacing="md" as="p">
        We love sharing stories and insights from veterinary professionals. If
        you or a client had a positive experience with our food, tell us about
        it!
      </Text>
      <Button
        variant="plain-text"
        href={`mailto:${vetEmail}`}
        type="anchor"
        color="kale"
        underline
      >
        Email Us
      </Button>
    </Card>
  );
};
