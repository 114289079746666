import classNames from 'classnames';

import { Link, LinkProps } from '@farmersdog/corgi';
import { PageWidth, Text } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';
import { getAbsoluteLink } from '../../utils/get-absolute-link';

import styles from './LegalDetails.module.css';
import { LINK_PRIVACY_OPTOUT } from '@farmersdog/constants/links';

interface Link {
  label: string;
  props: LinkProps;
}

export const links: Link[] = [
  {
    label: 'Privacy',
    props: {
      href: getAbsoluteLink(paths.PATH_PRIVACY),
    },
  },
  {
    label: 'Terms',
    props: {
      href: getAbsoluteLink(paths.PATH_TERMS),
    },
  },
  {
    label: 'Accessibility',
    props: {
      href: getAbsoluteLink(paths.PATH_ACCESSIBILITY),
    },
  },
  {
    label: 'Do Not Sell My Personal Information',
    props: {
      href: LINK_PRIVACY_OPTOUT,
    },
  },
];

interface LegalDetailsProps {
  mode?: LinkProps['mode'];
}

export function LegalDetails(props: LegalDetailsProps) {
  const mode = props?.mode ?? 'dark';
  const color = mode === 'light' ? 'white' : 'charcoal-3';

  const containerClassName = classNames(mode, styles.container);

  return (
    <PageWidth>
      <div className={containerClassName}>
        <div className={styles.copyright}>
          <Text
            className={styles.copyrightText}
            variant="heading-16"
            color={color}
            as="span"
          >
            © {new Date().getFullYear()} The Farmer’s Dog, Inc.
          </Text>
          <Text
            className={styles.longLiveDogs}
            variant="heading-16"
            color={color}
            as="span"
          >
            Long Live Dogs™
          </Text>
        </div>
        <ul aria-label="Legal details" className={styles.legalDetails}>
          {links.map(link => (
            <Text
              key={link.label}
              className={styles.link}
              variant="heading-16"
              as="li"
            >
              <Link mode={mode} {...link.props} variant="secondary">
                {link.label}
              </Link>
            </Text>
          ))}
        </ul>
      </div>
    </PageWidth>
  );
}
