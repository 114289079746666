export enum FeatureName {
  HEALTH_VET_PORTAL_BANNER = 'HEALTH_VET_PORTAL_BANNER',
  HEALTH_VET_PORTAL_REGISTRATION_BANNER = 'HEALTH_VET_PORTAL_REGISTRATION_BANNER',
  terms_changes_banner = 'terms_changes_banner',
  HEALTH_HOMEPAGE_LOGGED_OUT_PORTAL_NAVIGATION = 'HEALTH_HOMEPAGE_LOGGED_OUT_PORTAL_NAVIGATION',
  HEALTH_PORTAL_UX_OVERHAUL = 'HEALTH_PORTAL_UX_OVERHAUL',
  HEALTH_PORTAL_PDF_REDIRECTS = 'HEALTH_PORTAL_PDF_REDIRECTS',
  HEALTH_VET_PORTAL_CONFERENCE_SIGNUP_PASSWORD_REVAMP = 'HEALTH_VET_PORTAL_CONFERENCE_SIGNUP_PASSWORD_REVAMP',
}

export type AnonymousFeatures = typeof anonymousFeatures;
export type UserFeatures = typeof userFeatures;

export enum OnOffTreatments {
  off = 'off',
  on = 'on',
}

export enum BannerTreatments {
  hide_banner = 'hide_banner',
  control = 'control',
  show_banner = 'show_banner',
}

export interface BannerConfig {
  link: string;
  buttonVerbiage: string;
  textVerbiage: string;
}

export const anonymousFeatures = {
  [FeatureName.terms_changes_banner]: {
    treatments: Object.values(OnOffTreatments),
    track: true,
    mock: { treatment: 'on', config: null },
  },
  [FeatureName.HEALTH_VET_PORTAL_BANNER]: {
    treatments: Object.values(BannerTreatments),
    track: false,
    mock: {
      treatment: 'show_banner',
      config: {
        link: 'https://thefarmersdog.zoom.us/webinar/register/1817137954070/WN_YkN2KoKSR6euO80x82dkuQ',
        buttonVerbiage: 'REGISTER NOW',
        textVerbiage:
          'for a free CE webinar (RACE-approval pending) on May 7th!',
      },
    },
  },
  [FeatureName.HEALTH_VET_PORTAL_REGISTRATION_BANNER]: {
    treatments: Object.values(BannerTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_HOMEPAGE_LOGGED_OUT_PORTAL_NAVIGATION]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_PORTAL_UX_OVERHAUL]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_VET_PORTAL_CONFERENCE_SIGNUP_PASSWORD_REVAMP]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
};

export const userFeatures = {
  [FeatureName.HEALTH_PORTAL_PDF_REDIRECTS]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
};
