import { useEffect } from 'react';
import * as pagePaths from '../constants/pagePaths';
import { useRedirection } from './useRedirection';

/**
 * Custom hook for sign up page that will redirect authenticated users.
 */

interface useSignUpAuthenticatedRedirectProps {
  isAuthenticated?: boolean;
  isConferenceModeOn: boolean;
  logout: (() => Promise<void>) | undefined;
}
export const useSignUpAuthenticatedRedirect = ({
  isAuthenticated,
  isConferenceModeOn,
  logout,
}: useSignUpAuthenticatedRedirectProps) => {
  const push = useRedirection();
  useEffect(() => {
    if (isAuthenticated) {
      if (isConferenceModeOn && logout) {
        void logout();
      } else {
        push({
          noRedirectPathname: pagePaths.PATH_DASHBOARD,
        });
      }
    }
  }, [isAuthenticated, isConferenceModeOn, logout, push]);
};
