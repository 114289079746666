import { Text, PageWidth } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import { LegalDetails } from './LegalDetails';
import {
  ConnectLinksList,
  JoinUsLinksList,
  NavigationLinksList,
  SupportLinksList,
} from './LinkItems';

import styles from './FullFooter.module.css';

interface FullFooterProps {
  isAccountPage?: boolean;
}

export function FullFooter({ isAccountPage }: FullFooterProps) {
  return (
    <footer className={classNames(styles.container)}>
      <PageWidth>
        <div className={classNames(styles.content, styles.sortContent)}>
          {!isAccountPage && (
            <span className={styles.siteMap}>
              <Text as="h4" variant="heading-16" color="white" bold>
                Site Map
              </Text>
              <NavigationLinksList />
            </span>
          )}
          {isAccountPage && (
            <span className={styles.siteMap}>
              <Text as="h4" variant="heading-16" color="white" bold>
                Vet Team Portal
              </Text>
              <NavigationLinksList isAccountPage />
            </span>
          )}

          <span className={styles.contact}>
            <Text as="h4" variant="heading-16" bold>
              Get Support{' '}
              {!isAccountPage && (
                <Text
                  variant="article-12"
                  color="Kale1"
                  fontStyle="italic"
                  className={styles.availability}
                >
                  Available 24/7
                </Text>
              )}
            </Text>
            <SupportLinksList isAccountPage={isAccountPage} />
            <Text
              as="h4"
              variant="heading-16"
              color="white"
              bold
              topSpacing="sm"
            >
              Connect
            </Text>
            <ConnectLinksList />
          </span>
          {!isAccountPage && (
            <span className={styles.joinUs}>
              <Text as="h4" variant="heading-16" color="white" bold>
                Access Vet Team Portal
              </Text>
              <JoinUsLinksList />
            </span>
          )}
        </div>
        <div className={styles.ancillary}>
          <Text as="span" variant="heading-16" color="white">
            Our food is made while playing doo-wop tunes, all with ❤ in the US
          </Text>
        </div>
      </PageWidth>
      <LegalDetails mode="light" />
    </footer>
  );
}
