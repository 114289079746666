import { CustomSelectorProps } from '../Selector';
import { LabeledSelector } from '../LabeledSelector';
import React from 'react';
import classNames from 'classnames';
import * as typography from '../../typography';
import { WithRefProps, withRef } from '../../util';
import styles from './Checkbox.css';

type RefProps = WithRefProps<HTMLInputElement>;
export interface CheckboxProps extends CustomSelectorProps, RefProps {
  label: React.ReactNode;
  /** If the label should wrap or not  */
  labelWrap: boolean;
  /** Color of the label text */
  color?:
    | (typeof typography.ArticleColors)[number]
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | (typeof typography.BodyColors)[number]
    | (typeof typography.HeadingColors)[number];
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/checkbox | Checkbox} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Checkbox = withRef<HTMLInputElement, CheckboxProps>(
  ({ label, labelWrap = false, forwardedRef, ...props }) => {
    return (
      <LabeledSelector
        className={classNames(styles.container, {
          [styles.wrap]: labelWrap,
        })}
        {...props}
        type="checkbox"
        ref={forwardedRef}
      >
        {label}
      </LabeledSelector>
    );
  }
);
