import * as React from 'react';
import classNames from 'classnames';

import WaveIcon from './assets/wave.svg';
import styles from './Wave.css';

export const WaveFills = [
  'kale-3',
  'white',
  'charcoal-1',
  'charcoal-3',
  'oyster-0',
  'charcoal-0',
  'kale-1',
  'carrot-1',
  'chickpea-2',
  'chickpea-1',
] as const;

export const WaveMargins = [
  'margin-xxs',
  'margin-xs',
  'margin-sm',
  'margin-md',
  'margin-lg',
  'margin-xl',
  'margin-xxl',
  'margin-3xl',
  'margin-4xl',
] as const;

type IntrinsicDivProps = JSX.IntrinsicElements['div'];
export interface WaveProps extends IntrinsicDivProps {
  /** Optional class name */
  bottomClassName?: string;
  /** Optional class name */
  topClassName?: string;
  /** Optional class name */
  className?: string;
  /** Enable top wave */
  withTop?: boolean;
  /** Enable bottom wave */
  withBottom?: boolean;
  /** A transform to apply to the top wave */
  topTransform?: string;
  /** A transform to apply to the bottom wave */
  bottomTransform?: string;
  /** A color to fill with */
  fill: (typeof WaveFills)[number];
  /** The height of the wave */
  waveHeight?: string | number;
  /** The vertical padding of the wave content */
  padding?: (typeof WaveMargins)[number];
}

/**
 * Render an interface
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/wave | Wave} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const Wave: React.FC<WaveProps> = ({
  className,
  children,
  withTop,
  withBottom,
  topClassName,
  bottomClassName,
  fill = 'chickpea-1',
  padding,
  waveHeight = 24,
  topTransform = 'scaleX(-1)',
  bottomTransform = 'scaleX(-1) rotate(180deg)',
  ...props
}) => {
  return (
    <div className={classNames(styles.container, className, styles[fill])}>
      {withTop && (
        <WaveIcon
          className={classNames(styles.topWave, topClassName)}
          style={{
            height: waveHeight,
            transform: topTransform,
          }}
        />
      )}
      <div
        className={classNames(
          styles.background,
          padding ? styles[padding] : null
        )}
        {...props}
      >
        {children}
      </div>
      {withBottom && (
        <WaveIcon
          className={classNames(styles.bottomWave, bottomClassName)}
          style={{
            height: waveHeight,
            transform: bottomTransform,
          }}
        />
      )}
    </div>
  );
};
