import { VeterinaryPracticeOrOrganizationFormFields } from './signUpFormWithNoSelectedPracticeFieldsData';

export interface VeterinaryPracticeOrOrganizationInformationFormValues {
  [VeterinaryPracticeOrOrganizationFormFields.name]: string;
  [VeterinaryPracticeOrOrganizationFormFields.phone]?: string;
  [VeterinaryPracticeOrOrganizationFormFields.streetAddress1]: string;
  [VeterinaryPracticeOrOrganizationFormFields.streetAddress2]?: string;
  [VeterinaryPracticeOrOrganizationFormFields.city]: string;
  [VeterinaryPracticeOrOrganizationFormFields.state]: string | undefined;
  [VeterinaryPracticeOrOrganizationFormFields.zip]: string;
}

export const veterinaryPracticeOrOrganizationInformationFormValues: VeterinaryPracticeOrOrganizationInformationFormValues =
  {
    [VeterinaryPracticeOrOrganizationFormFields.name]: '',
    [VeterinaryPracticeOrOrganizationFormFields.phone]: '',
    [VeterinaryPracticeOrOrganizationFormFields.streetAddress1]: '',
    [VeterinaryPracticeOrOrganizationFormFields.streetAddress2]: '',
    [VeterinaryPracticeOrOrganizationFormFields.city]: '',
    [VeterinaryPracticeOrOrganizationFormFields.state]: undefined,
    [VeterinaryPracticeOrOrganizationFormFields.zip]: '',
  };
