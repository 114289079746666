import { Button, Text } from '@farmersdog/corgi-x';
import styles from './EducationItem.module.css';
import { getEducationItems } from '../educationData';
import { Card } from '../../../../components';

type EducationItemProps = ReturnType<typeof getEducationItems>[number];

export const EducationItem = ({
  title,
  description,
  linkUrl,
  linkText,
  externalLink,
  onClick,
}: EducationItemProps) => {
  return (
    <Card className={styles.container}>
      <div>
        <Text
          variant="heading-22"
          bold
          color="Blueberry3"
          as="h3"
          topSpacing="none"
          bottomSpacing="xs"
        >
          {title}
        </Text>
        <Text as="p" variant="article-16" topSpacing="none">
          {description}
        </Text>
      </div>
      {externalLink ? (
        <Button
          variant="plain-text"
          underline
          color="kale"
          type="anchor"
          href={linkUrl}
          target="_blank"
          onClick={onClick}
        >
          {linkText}
        </Button>
      ) : (
        <Button
          variant="plain-text"
          underline
          color="kale"
          type="link"
          to={linkUrl}
          onClick={onClick}
        >
          {linkText}
        </Button>
      )}
    </Card>
  );
};
