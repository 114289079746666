import { FunctionComponent, ReactElement, SVGProps } from 'react';

import { Text } from '@farmersdog/corgi-x';

import styles from './Solution.module.css';
interface SolutionProps {
  image: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string | ReactElement;
  arialLabel?: string;
  content: ReactElement;
}

export function Solution({ image, title, content, arialLabel }: SolutionProps) {
  const Image = image;
  return (
    <div className={styles.solution}>
      <div>
        <Image className={styles.icon} aria-label={arialLabel} />
      </div>
      <Text
        bold
        as="h4"
        color="blueberry-3"
        variant="heading-22"
        className={styles.title}
      >
        {title}
      </Text>
      <Text as="p" color="charcoal-3" variant="article-16">
        {content}
      </Text>
    </div>
  );
}
