import { useEffect, useState } from 'react';

import { Breakpoint } from '@farmersdog/corgi-x';
import fastdom from 'fastdom';
import { browserOnly } from '@farmersdog/utils';

export interface ScreenWidth {
  isExtraSmallScreen: boolean;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
  isExtraLargeScreen: boolean;
}

// todo: remove this hook and use the useScreenSize hook which uses the corgi-x useViewport hook
export function useScreenWidth(): ScreenWidth {
  const [screenWidth, setScreenWidth] = useState(
    browserOnly(
      window => window.innerWidth,
      () => 0
    )
  );

  useEffect(() => {
    const handleResize = () => {
      fastdom.measure(() => {
        setScreenWidth(window.innerWidth);
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isExtraSmallScreen =
    screenWidth > Breakpoint.XS && screenWidth < Breakpoint.SM;
  const isSmallScreen =
    screenWidth >= Breakpoint.SM && screenWidth < Breakpoint.MD;
  const isMediumScreen =
    screenWidth >= Breakpoint.MD && screenWidth < Breakpoint.LG;
  const isLargeScreen =
    screenWidth >= Breakpoint.LG && screenWidth < Breakpoint.XL;
  const isExtraLargeScreen = screenWidth >= Breakpoint.XL;

  return {
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  };
}
