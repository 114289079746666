import { v4 } from 'uuid';
import {
  PersonalInformationFormValues,
  PersonalInformationConferenceFormValues,
} from '../PersonalInformationForm';

interface GetPasswordArgs {
  personalInformationValues:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues;
  isConferenceModeOn: boolean;
}

export const getPassword = ({
  personalInformationValues,
  isConferenceModeOn,
}: GetPasswordArgs) => {
  let password = '';
  if (
    !isConferenceModeOn &&
    formsValuesContainPassword(personalInformationValues)
  ) {
    password = personalInformationValues.password;
  } else {
    password = v4();
  }
  return password;
};

const formsValuesContainPassword = (
  formValues:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues
): formValues is PersonalInformationFormValues => {
  return Object.prototype.hasOwnProperty.call(formValues, 'password');
};
