import * as yup from 'yup';
import { VeterinaryPracticeOrOrganizationFormFields } from '../constants';
import { states } from '../../../../constants';
import { fieldRequiredErrorMessage } from '../../../../utils';

export const practiceOrOrganizationValidationSchema = yup.object({
  [VeterinaryPracticeOrOrganizationFormFields.name]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [VeterinaryPracticeOrOrganizationFormFields.phone]: yup.string(),

  [VeterinaryPracticeOrOrganizationFormFields.streetAddress1]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [VeterinaryPracticeOrOrganizationFormFields.streetAddress2]: yup.string(),
  [VeterinaryPracticeOrOrganizationFormFields.city]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [VeterinaryPracticeOrOrganizationFormFields.state]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(Object.values(states)),
  [VeterinaryPracticeOrOrganizationFormFields.zip]: yup
    .string()
    .required(fieldRequiredErrorMessage),
});
