import noop from 'lodash/noop';

import { hasPassiveTouchSupport } from './device';

interface BlockBodyScrollReturn {
  initialize: () => void;
  cleanup: () => void;
}

/**
 * Imperative effect that blocks scrollling on the body element. The bounds for
 * scrolling are determined by the the height of the content element rectangle
 * compared to the height of container element rectangle. When the content has
 * been scrolled all the way up or down then this effect will block any further
 * scrolling action. Usually used to correct iOS behavior that does not respect
 * body overflow css.
 */
export const blockBodyScroll = (
  containerElement: HTMLElement,
  contentElement: HTMLElement
): BlockBodyScrollReturn => {
  if (!containerElement || !contentElement || typeof window === 'undefined') {
    return { initialize: noop, cleanup: noop };
  }
  const BODY: HTMLElement = window.document.body;
  let touchStartClientY = 0;

  const handleTouchMove = (e: TouchEvent): void => {
    if (e.targetTouches.length !== 1) {
      return;
    }

    if (!e.cancelable || e.defaultPrevented) {
      return;
    }

    const scrollUp = touchStartClientY - e.targetTouches[0].clientY < 0;
    const { scrollTop, clientHeight } = containerElement;

    /** is the lightbox scrolled up? */
    if (scrollTop <= 0 && scrollUp) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const { clientHeight: contentHeight } = contentElement;
    /** is the lightbox scrolled down? */
    if (scrollTop + clientHeight >= contentHeight && !scrollUp) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleTouchStart = (e: TouchEvent): void => {
    if (e.targetTouches.length === 1) {
      touchStartClientY = e.targetTouches[0].clientY;
    }
  };

  const initialize = (): void => {
    const passiveSupported = hasPassiveTouchSupport();

    BODY.addEventListener(
      'touchstart',
      handleTouchStart,
      passiveSupported ? { passive: false } : false
    );

    BODY.addEventListener(
      'touchmove',
      handleTouchMove,
      passiveSupported ? { passive: false } : false
    );
  };

  const cleanup = (): void => {
    BODY.removeEventListener('touchstart', handleTouchStart);
    BODY.removeEventListener('touchmove', handleTouchMove);
  };

  return { initialize, cleanup };
};
