import { Text, Link, Grid, GridItem } from '@farmersdog/corgi-x';

import { AccountPage } from '../../components/AccountPage';
import { RecommendToClients } from './RecommendToClients';
import { NutrientMix } from './NutrientMix';
import { Recipes } from './Recipes';

import ClinicalIndicationsChart from '../../../static/pdf/ClinicalIndicationsChart.pdf';
import NutritionGuide from '../../../static/pdf/TFD_Vet_Nutrition_Guide_2024.pdf';

import { trackDownloadableInfo } from '../../analytics/events';

import styles from './ClinicalRecipeRecommendations.module.css';

const DownloadLink = ({
  title,
  href,
  ariaLabel,
}: {
  title: string;
  href: string;
  ariaLabel: string;
}) => (
  <Link
    href={href}
    target="_blank"
    baseColor="kale"
    className={styles.downloadButton}
    aria-label={ariaLabel}
    onClick={() =>
      trackDownloadableInfo({
        title,
      })
    }
  >
    <Text variant="heading-16">{title}</Text>
  </Link>
);

const ResponsiveClinicalRecipeRecommendations = ({
  isMobile,
}: {
  isMobile: boolean;
}) => {
  return (
    <AccountPage>
      <Grid>
        <GridItem>
          <Text
            bold
            as="h2"
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="none"
            variant={isMobile ? 'heading-28' : 'heading-40'}
          >
            Clinical Recipe Recommendations
          </Text>
          <Text
            as="p"
            variant="article-16"
            color="charcoal-3"
            topSpacing="sm"
            bottomSpacing="xs"
          >
            See details about our ready-to-eat recipes (including indications
            and contraindications) and our Do-It-Yourself Nutrient Mix.
          </Text>
          <div className={styles.downloadSection}>
            <DownloadLink
              title="Download Clinical Indications Chart"
              href={ClinicalIndicationsChart}
              ariaLabel='View "Clinical Indications Chart" PDF'
            />
            <DownloadLink
              title="Download 2024 Nutrition Guide"
              href={NutritionGuide}
              ariaLabel='View "2024 Nutrition Guide" PDF'
            />
          </div>
        </GridItem>
        <Grid rowGap="md">
          <GridItem className={styles.recommendedToClientsSection}>
            <RecommendToClients />
          </GridItem>
          <GridItem>
            <Recipes isMobile={isMobile} />
          </GridItem>
          <GridItem>
            <NutrientMix />
          </GridItem>
        </Grid>
      </Grid>
    </AccountPage>
  );
};

export const ClinicalRecipeRecommendations = () => {
  return (
    <>
      <div className={styles.desktop}>
        <ResponsiveClinicalRecipeRecommendations isMobile={false} />
      </div>
      <div className={styles.mobile}>
        <ResponsiveClinicalRecipeRecommendations isMobile={true} />
      </div>
    </>
  );
};
