import { track } from '../track';
import { landing_page_clicked_register_for_vet_team_portal_hero } from './eventNames';

/**
 * Send a tracking event for when a user clicks on Register for Vet Team Portal button in hero section.
 */

export function trackLandingPageClickedRegisterForVetTeamPortalHero() {
  track({ eventName: landing_page_clicked_register_for_vet_team_portal_hero });
}
