import debounce from 'lodash/debounce';

interface FocusTrapReturn {
  initialize: () => void;
  cleanup: () => void;
}

/**
 * Return two functions that will initialize and cleanup trapping focus inside a
 * dom node. If focus goes outside the hierarchy of the dom node then
 * programmatically refocus the dom node.
 */
export function trapFocus(modalRoot: HTMLElement): FocusTrapReturn {
  const lastActiveElement = document.activeElement;

  const handleFocus = (e: FocusEvent): void => {
    if (e.target instanceof Node && !modalRoot.contains(e.target)) {
      debounce(function () {
        modalRoot.focus();
      }, 100);
    }
  };

  const initialize = (): void => {
    if (!modalRoot) {
      return;
    }

    debounce(function () {
      modalRoot.focus();
    }, 100);
    document.addEventListener('focusin', handleFocus);
  };

  const cleanup = (): void => {
    document.removeEventListener('focusin', handleFocus);

    if (lastActiveElement && lastActiveElement instanceof HTMLElement) {
      debounce(function () {
        lastActiveElement.focus();
      }, 100);
    }
  };

  return {
    initialize,
    cleanup,
  };
}
