import React, { FC } from 'react';

import { LinkModes } from '../../designs/getLinkDesign';
import classNames from 'classnames';
import styles from './HamburgerButton.css';

export interface HamburgerButtonProps {
  onClick(): void;
  isOpen: boolean;
  mode: (typeof LinkModes)[number];
}

export const HamburgerButton: FC<HamburgerButtonProps> = ({
  onClick,
  isOpen,
  mode,
}) => {
  const barClassName = classNames(styles.bar, {
    [styles.darkTheme]: mode === LinkModes[0],
  });

  return (
    <button
      type="button"
      role="button"
      aria-label={`${isOpen ? 'close' : 'open'} menu`}
      aria-expanded={isOpen}
      className={classNames(styles.container, { [styles.x]: isOpen })}
      onClick={onClick}
    >
      <div className={barClassName} />
      <div className={barClassName} />
      <div className={barClassName} />
    </button>
  );
};
