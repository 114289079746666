import { ComplexValue } from './types';

/**
 * Get the initial state value according to the input props. Initial state
 * depends on whether or not multiple selections are available as well as
 * whether or not a `value` prop is passed in.
 */
function getInitialValue(value: ComplexValue, multi: boolean): ComplexValue {
  const isControlled = typeof value !== 'undefined';

  if (isControlled) {
    return value;
  }

  if (multi) {
    return [];
  }

  return undefined;
}

export default getInitialValue;
