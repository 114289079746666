import * as React from 'react';

import { ANIMATION_DURATION } from './constants';
import { CSSTransition } from 'react-transition-group';
import { Text } from '../Text';
import classNames from 'classnames';
import styles from './Toast.css';
import transitionStyles from './Toast.transitions.css';

export const VARIANTS = ['negative', 'neutral', 'positive'] as const;
export interface ToastProps {
  variant: (typeof VARIANTS)[number];
  children: React.ReactNode;
  id?: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/toast | Toast} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Toast: React.FC<ToastProps> = ({
  variant,
  children,
  ...transitionProps
}) => {
  return (
    <CSSTransition
      {...transitionProps}
      timeout={ANIMATION_DURATION}
      classNames={transitionStyles}
      unmountOnExit
      appear
    >
      <div role="alert" className={classNames(styles.toast, styles[variant])}>
        <Text variant="heading-16" color="kale-3">
          {children}
        </Text>
      </div>
    </CSSTransition>
  );
};
