import type stripeJs from '@stripe/stripe-js';
import { elementTypes } from './elementTypes';

/** The type of elements supported by this component */
export type ValidElement =
  | stripeJs.StripeCardNumberElement
  | stripeJs.StripeCardCvcElement
  | stripeJs.StripeCardExpiryElement;

/** The type of valid element options */
export type ElementOptions = {
  [key: string]: unknown;
};

/**
 * Safely get an element by only allowing the valid element types allowed by
 * this module
 */
export function getElement(
  elements: stripeJs.StripeElements,
  elementType: elementTypes
): ValidElement | null {
  switch (elementType) {
    case elementTypes.cardNumber: {
      return elements.getElement(elementType);
    }

    case elementTypes.cardCvc: {
      return elements.getElement(elementType);
    }

    case elementTypes.cardExpiry: {
      return elements.getElement(elementType);
    }

    default: {
      return null;
    }
  }
}

/**
 * Safely create an element by only allowing the valid element types allowed by
 * this module
 */
export function create(
  elements: stripeJs.StripeElements,
  elementType: elementTypes,
  options: ElementOptions
): ValidElement | null {
  switch (elementType) {
    case elementTypes.cardNumber: {
      return elements.create(elementType, options);
    }

    case elementTypes.cardCvc: {
      return elements.create(elementType, options);
    }

    case elementTypes.cardExpiry: {
      return elements.create(elementType, options);
    }

    default: {
      return null;
    }
  }
}
