import { AppBarItemProps, AppBarProps } from './AppBar';
import { Lightbox, useLightboxControl } from '../Lightbox';
import React, { FC, ReactElement, useRef } from 'react';

import { CallToAction } from './CallToAction';
import { Divider } from '../Divider';
import { HamburgerButton } from './HamburgerButton';
import { LOGO_SIZE } from './constants';
import { Link, isAnchorLink } from '../Link';
import { Logo } from './Logo';
import { MaxWidth } from './MaxWidth';
import { Text } from '../Text';
import classNames from 'classnames';
import styles from './Mobile.css';
import { useCallToActionObserver } from './useCallToActionObserver';
import { useScrollingStyles } from './useScrollingStyles';
import { OYSTER_0 } from '../../styles/colors';
import { interceptEvent } from '../../util';
import { HorizontalNavigation, HorizontalNavigationItem } from 'components';

interface MobileProps extends AppBarProps {
  onToggle: (isOpen: boolean) => void;
  content: Element | null;
}

export const Mobile: FC<MobileProps> = ({
  overlay,
  darkTheme,
  content,
  leftItems,
  rightItems,
  persistentItem,
  callToAction,
  subNavItems,
  showCTA,
  onCallToActionClick,
  onToggle,
  logoTo,
  logoHref,
  hasContentAbove,
}) => {
  const { rootProps, toggle, isOpen } = useLightboxControl({
    initialIsOpen: false,
    id: 'appbar-lightbox',
    onToggle,
  });
  const nav = useRef<HTMLElement>(null);
  const { backgroundColor, boxShadow, logoTranslate, linkTheme } =
    useScrollingStyles({
      overlay,
      darkTheme,
      content,
      hasContentAbove,
      isOpen,
    });
  const showCallToAction = useCallToActionObserver(showCTA, callToAction);

  const logoLinkProps = logoTo ? { to: logoTo } : { href: logoHref };

  return (
    <div
      style={{
        backgroundColor,
        boxShadow,
      }}
    >
      <nav role="navigation" className={styles.nav} ref={nav}>
        <MaxWidth>
          <div className={styles.navItem}>
            <HamburgerButton
              onClick={toggle}
              isOpen={isOpen}
              mode={linkTheme}
            />
          </div>
          {(!showCallToAction || isOpen) && (
            <>
              <Logo
                {...logoLinkProps}
                translate={logoTranslate}
                className={classNames(styles.logo, styles.navItem)}
              />
              <div
                className={classNames(styles.persistentLink, styles.navItem)}
              >
                {persistentItem && (
                  <Link
                    {...persistentItem}
                    className={classNames(
                      styles.link,
                      styles.inNav,
                      persistentItem.className as string
                    )}
                    style={{ opacity: isOpen ? 0 : 1 }}
                    variant="secondary"
                    mode={linkTheme}
                  >
                    <Text variant="heading-16" bold>
                      {persistentItem.title}
                    </Text>
                  </Link>
                )}
              </div>
            </>
          )}
          {showCallToAction && callToAction && !isOpen && (
            <>
              <div className={classNames(styles.navItem, styles.callToAction)}>
                <CallToAction
                  {...callToAction}
                  onClick={onCallToActionClick}
                  asPill
                />
              </div>
              <div className={styles.navItem} />
            </>
          )}
        </MaxWidth>
      </nav>
      {subNavItems && (
        <div
          className={styles.horizontalNavContainer}
          aria-label="subnavigation"
          style={{ display: isOpen ? 'none' : 'block' }}
        >
          <MaxWidth className={styles.horizontalNavContentRow}>
            <HorizontalNavigation withButtons={false}>
              {subNavItems.map(item => {
                const linkProps = isAnchorLink(item)
                  ? { href: item.href }
                  : { to: item.to };

                return (
                  <HorizontalNavigationItem
                    className={styles.horizontalNavLink}
                    key={item.title}
                    {...linkProps}
                  >
                    {item.title}
                  </HorizontalNavigationItem>
                );
              })}
            </HorizontalNavigation>
          </MaxWidth>
        </div>
      )}
      <Lightbox
        offsetTop={LOGO_SIZE}
        background="white"
        className={styles.menu}
        {...rootProps}
      >
        <Divider orientation="horizontal" margin={0} color={OYSTER_0} />
        <ul className={styles.items}>
          {rightItems && (
            <MaxWidth>
              {rightItems.map(
                (item: AppBarItemProps): ReactElement => (
                  <li key={item.title}>
                    <Link
                      {...item}
                      activeClassName={styles.active}
                      className={classNames(
                        styles.link,
                        item.className as string
                      )}
                      onClick={interceptEvent(toggle, item.onClick)}
                      variant="secondary"
                      mode="dark"
                    >
                      <Text variant="heading-21" bold>
                        {item.title}
                      </Text>
                    </Link>
                    <Divider
                      orientation="horizontal"
                      margin={0}
                      color={OYSTER_0}
                      className={styles.shortDivider}
                    />
                  </li>
                )
              )}
            </MaxWidth>
          )}
          <MaxWidth>
            {leftItems.map(
              (item: AppBarItemProps, index: number): ReactElement => (
                <li key={item.title}>
                  <Link
                    {...item}
                    className={classNames(
                      styles.link,
                      item.className as string
                    )}
                    activeClassName={styles.active}
                    onClick={interceptEvent(toggle, item.onClick)}
                    variant="secondary"
                    mode="dark"
                  >
                    <Text variant="heading-21" bold>
                      {item.title}
                    </Text>
                  </Link>
                  {(leftItems.length !== index + 1 || persistentItem) && (
                    <Divider
                      orientation="horizontal"
                      margin={0}
                      color={OYSTER_0}
                      className={styles.shortDivider}
                    />
                  )}
                </li>
              )
            )}
          </MaxWidth>
          {persistentItem && (
            <MaxWidth>
              <Link
                {...persistentItem}
                className={classNames(
                  styles.link,
                  persistentItem.className as string
                )}
                onClick={interceptEvent(toggle, persistentItem.onClick)}
                variant="secondary"
                mode="dark"
              >
                <Text variant="heading-21" bold>
                  {persistentItem.title}
                </Text>
              </Link>
            </MaxWidth>
          )}
        </ul>
        <div className={styles.ctaContainer}>
          <MaxWidth>
            {isOpen && callToAction && (
              <CallToAction
                isMobile={true}
                {...callToAction}
                withPadding={false}
                onClick={interceptEvent(toggle, callToAction.onClick)}
              />
            )}
          </MaxWidth>
        </div>
      </Lightbox>
    </div>
  );
};
