import {
  Accordion,
  AccordionPanel,
  AccordionVariant,
  Text,
} from '@farmersdog/corgi-x';

import { listItems } from '../../../../../pages/Account/VetDiscount/EligibleModal/EligibleModal';

import styles from './EligibleAccordion.module.css';

export function EligibleAccordion() {
  return (
    <Accordion variant={AccordionVariant.Kale}>
      <AccordionPanel id="seeWhosEligible" heading="See Who’s Eligible">
        <Text as="p" variant="article-12" color="charcoal-3" vSpacing="none">
          The following veterinary professions are eligible:
        </Text>
        <ul className={styles.list}>
          {listItems.map(item => (
            <Text
              className={styles.listItem}
              key={item}
              as="li"
              variant="article-12"
              color="charcoal-3"
              vSpacing="none"
            >
              {item}
            </Text>
          ))}
        </ul>
        <Text variant="article-12" color="charcoal-3">
          Professionals may be retired.
        </Text>
      </AccordionPanel>
    </Accordion>
  );
}
