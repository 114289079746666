import * as Apollo from '@apollo/client';
import { setOperationHeaders } from './setOperationHeaders';

export interface RequestContext {
  headers?: Record<string, string>;
}

export interface SetOperationTokenArg {
  operation: Apollo.Operation;
  token?: string | null | undefined;
}

export function setOperationToken({ operation, token }: SetOperationTokenArg) {
  const headers = {
    Authorization: token ? `Bearer ${token}` : '',
  };
  setOperationHeaders({
    operation,
    headers,
  });
}
