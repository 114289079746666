import classNames from 'classnames';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import { addDOMElementById, cleanupDOM, initializeDOM } from './dom';

// root styles need to be imported before the transition styles for CSS specificity
import styles from './Lightbox.css';
import backgroundTransition from './transitions/background.css';
import contentTransition from './transitions/content.css';

import { LightboxRootProps } from './useLightboxControl';
import { blockBodyScroll } from './blockBodyScroll';
import { browserOnly } from '../../util';
import { isIos } from './device';
import { trapFocus } from './trapFocus';
import { wait } from '../../util/wait';

export const ANIMATION_DURATION = 300;
export const ESCAPE = 27;
const rootEl = browserOnly(() => addDOMElementById(styles.tfdLightbox));

export interface LightboxProps {
  className?: string;
  /** A color string  */
  background?: string;
  /** A valid css string indicating the height of the lightbox  */
  height?: string | number;
  /** Distance from top of view port the light box appears */
  offsetTop?: number;
  /** A valid css string indicating the width of the lightbox  */
  width?: string | number;
  /** Toggle the open and close animations */
  withAnimation?: boolean;
  /** Toggle if clicking background closes the lightbox */
  disableInteractions?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/lightbox | Lightbox} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Lightbox: React.FC<LightboxProps & LightboxRootProps> = ({
  background,
  width = '100%',
  height = '100vh',
  offsetTop,
  children,
  isOpen,
  className,
  close,
  withAnimation = true,
  disableInteractions = false,
  ...props
}) => {
  const contentEl = React.useRef<HTMLDivElement>(null);
  const containerEl = React.useRef<HTMLDivElement>(null);
  const calculatedHeight = offsetTop
    ? `calc(${height} - ${offsetTop}px)`
    : height;
  const animationDuration = withAnimation ? ANIMATION_DURATION : 0;

  React.useEffect(() => {
    if (!isOpen) {
      return noop;
    }

    initializeDOM();
    return (): Promise<void> => wait(animationDuration, cleanupDOM);
  }, [animationDuration, isOpen]);

  React.useEffect(() => {
    if (!isIos() || !isOpen || !containerEl.current || !contentEl.current) {
      return noop;
    }

    const { initialize, cleanup } = blockBodyScroll(
      containerEl.current,
      contentEl.current
    );

    initialize();
    return cleanup;
  }, [contentEl, containerEl, isOpen]);

  // trapModalFocus
  React.useEffect(() => {
    if (!isOpen || !containerEl.current) {
      return noop;
    }

    const { initialize, cleanup } = trapFocus(containerEl.current);
    initialize();
    return cleanup;
  }, [isOpen, containerEl]);

  // addEscKeyListener
  React.useEffect(() => {
    if (!isOpen) {
      return noop;
    }

    const handleKeyUp = (e: KeyboardEvent): void => {
      if (e.code === 'Escape') {
        close();
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return (): void => window.removeEventListener('keyup', handleKeyUp);
  }, [isOpen, close, disableInteractions]);

  if (!rootEl) {
    return null;
  }

  return (
    <Transition
      in={isOpen}
      component={null}
      timeout={animationDuration}
      mountOnEnter
      unmountOnExit
      appear
    >
      {(state): React.ReactPortal =>
        ReactDOM.createPortal(
          <aside
            className={classNames(
              styles.container,
              !withAnimation && styles.disableAnimation
            )}
            style={{ marginTop: offsetTop }}
            ref={containerEl}
            // Remove some deprecated props. This can be removed in v4
            {...omit(props, [
              'overflow',
              'open',
              'labelProps',
              'descriptionProps',
            ])}
          >
            <button
              name="background"
              aria-label="close"
              onClick={close}
              disabled={disableInteractions}
              className={classNames(
                styles.background,
                backgroundTransition[state]
              )}
              style={{ background }}
            />
            <div className={styles.autoBox}>
              <div
                ref={contentEl}
                className={classNames(
                  styles.content,
                  contentTransition[state],
                  className
                )}
                style={{ width, minHeight: calculatedHeight }}
              >
                {children}
              </div>
            </div>
          </aside>,
          rootEl
        )
      }
    </Transition>
  );
};
