import { useHistory, useLocation } from 'react-router-dom';
import { DiscountRequestState } from './discountRequestState';
import { useEffect } from 'react';

interface UseSearchParamArgs {
  isOnFirstStep?: boolean;
}

const FIRST_STEP = DiscountRequestState.AreYouSubscribed;

/**
 * The search param is needed for analytics purposes only.
 * The flow does not depend on the search param specified by users in the url to decide which screen to land on. The search param can therefore be dictated by the flow state instead of the other way around
 *
 */
export function useSearchParam(input?: UseSearchParamArgs) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (input?.isOnFirstStep) {
      history.replace(`${location.pathname}?step=${FIRST_STEP}`);
    }
  }, [input?.isOnFirstStep, history, location.pathname, location.search]);

  const updateSearchParam = (nextStep: DiscountRequestState) => {
    const nextSearchParams = new URLSearchParams(location.search);
    nextSearchParams.set('step', nextStep as string);
    const nextLink = `${location.pathname}?${nextSearchParams.toString()}`;
    history.replace(nextLink);
  };

  return {
    updateSearchParam,
  };
}
