export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Contact = {
  __typename?: 'Contact';
  contactReason: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  contactReason: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type DiscountHospital = {
  __typename?: 'DiscountHospital';
  city: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  state: Maybe<Scalars['String']['output']>;
  streetAddress1: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type DiscountHospitalInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DiscountRequest = {
  __typename?: 'DiscountRequest';
  acceptedTerms: Scalars['Boolean']['output'];
  discountHospital: Maybe<DiscountHospital>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
};

export type DiscountRequestInput = {
  acceptedTerms: Scalars['Boolean']['input'];
  discountHospital?: InputMaybe<DiscountHospitalInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type FoundPractice = {
  __typename?: 'FoundPractice';
  formattedAddress: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Hospital = {
  __typename?: 'Hospital';
  city: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type HospitalInput = {
  city: Scalars['String']['input'];
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContact: VetView;
  addDiscountRequest: VetView;
  createVet: VetView;
  createVetWithOrganization: VetDetails;
  updateVet: VetDetails;
};

export type MutationAddContactArgs = {
  contact: ContactInput;
};

export type MutationAddDiscountRequestArgs = {
  discountRequest: DiscountRequestInput;
};

export type MutationCreateVetArgs = {
  vet: VetInput;
};

export type MutationCreateVetWithOrganizationArgs = {
  organization: OrganizationInput;
  vet: VetInput;
};

export type MutationUpdateVetArgs = {
  organization: InputMaybe<OrganizationInput>;
  vet: VetInput;
};

export enum OrganizationDataSource {
  Google = 'Google',
  User = 'User',
}

export type OrganizationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  source: OrganizationDataSource;
  state: Scalars['String']['input'];
  streetAddress1: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type OrganizationView = {
  __typename?: 'OrganizationView';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  source: OrganizationDataSource;
  state: Scalars['String']['output'];
  streetAddress1: Scalars['String']['output'];
  streetAddress2: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export enum Position {
  Other = 'Other',
  PracticeManagerOrOfficeManager = 'PracticeManagerOrOfficeManager',
  Veterinarian = 'Veterinarian',
  VeterinaryStudent = 'VeterinaryStudent',
  VeterinaryTechnicianOrNurse = 'VeterinaryTechnicianOrNurse',
  VeterinaryTechnicianOrNurseStudent = 'VeterinaryTechnicianOrNurseStudent',
}

export type Query = {
  __typename?: 'Query';
  contact: Maybe<Contact>;
  discountRequest: Maybe<DiscountRequest>;
  findPractice: Maybe<Array<FoundPractice>>;
  validatePractice: Maybe<ValidatedPractice>;
  version: Scalars['String']['output'];
  vet: Maybe<VetView>;
  vetDetails: Maybe<VetDetails>;
};

export type QueryFindPracticeArgs = {
  searchTerm: Scalars['String']['input'];
};

export type QueryValidatePracticeArgs = {
  practice: ValidatePracticeInput;
};

export type ValidatePracticeInput = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ValidatedPractice = {
  __typename?: 'ValidatedPractice';
  addressComponents: Maybe<ValidatedPracticeAddressComponent>;
  formattedAddress: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
};

export type ValidatedPracticeAddressComponent = {
  __typename?: 'ValidatedPracticeAddressComponent';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetAddress1: Scalars['String']['output'];
  streetAddress2: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type VetDetails = {
  __typename?: 'VetDetails';
  organization: Maybe<OrganizationView>;
  vet: Maybe<VetView>;
};

export type VetInput = {
  acceptedPrivacyPolicy: Scalars['Boolean']['input'];
  attestedAtSignup?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hospital?: InputMaybe<HospitalInput>;
  howDidYouHearAboutUs?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  needsPassword?: InputMaybe<Scalars['Boolean']['input']>;
  position: Position;
};

export type VetView = {
  __typename?: 'VetView';
  acceptedPrivacyPolicy: Scalars['Boolean']['output'];
  attestedAtSignup: Maybe<Scalars['Boolean']['output']>;
  contacts: Maybe<Array<Contact>>;
  discountRequests: Maybe<Array<DiscountRequest>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hospital: Maybe<Hospital>;
  howDidYouHearAboutUs: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Maybe<Scalars['String']['output']>;
  position: Position;
};

export type FullAddContactViewFragment = {
  __typename?: 'Contact';
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  contactReason: string;
  message: string;
};

export type FullAddDiscountRequestViewFragment = {
  __typename?: 'DiscountRequest';
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  acceptedTerms: boolean;
  discountHospital: {
    __typename?: 'DiscountHospital';
    name: string;
    streetAddress1: string | null;
    streetAddress2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  } | null;
};

export type FullDiscountHospitalViewFragment = {
  __typename?: 'DiscountHospital';
  name: string;
  streetAddress1: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
};

export type FullHospitalViewFragment = {
  __typename?: 'Hospital';
  name: string;
  city: string | null;
  state: string;
};

export type FullOrganizationViewFragment = {
  __typename?: 'OrganizationView';
  id: string | null;
  type: string;
  name: string;
  streetAddress1: string;
  streetAddress2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string | null;
  source: OrganizationDataSource;
};

export type FullVetViewFragment = {
  __typename?: 'VetView';
  id: string;
  firstName: string;
  lastName: string;
  position: Position;
  email: string;
  howDidYouHearAboutUs: string | null;
  acceptedPrivacyPolicy: boolean;
  attestedAtSignup: boolean | null;
  hospital: {
    __typename?: 'Hospital';
    name: string;
    city: string | null;
    state: string;
  } | null;
  discountRequests: Array<{
    __typename?: 'DiscountRequest';
    acceptedTerms: boolean;
    email: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    discountHospital: {
      __typename?: 'DiscountHospital';
      city: string | null;
      name: string;
      state: string | null;
      streetAddress1: string | null;
      streetAddress2: string | null;
      zip: string | null;
    } | null;
  }> | null;
};

export type AddContactMutationVariables = Exact<{
  input: ContactInput;
}>;

export type AddContactMutation = {
  __typename?: 'Mutation';
  addContact: {
    __typename?: 'VetView';
    id: string;
    contacts: Array<{
      __typename?: 'Contact';
      firstName: string;
      lastName: string;
      email: string;
      phone: string | null;
      contactReason: string;
      message: string;
    }> | null;
  };
};

export type AddDiscountRequestMutationVariables = Exact<{
  input: DiscountRequestInput;
}>;

export type AddDiscountRequestMutation = {
  __typename?: 'Mutation';
  addDiscountRequest: {
    __typename?: 'VetView';
    id: string;
    discountRequests: Array<{
      __typename?: 'DiscountRequest';
      firstName: string;
      lastName: string;
      email: string;
      phone: string | null;
      acceptedTerms: boolean;
      discountHospital: {
        __typename?: 'DiscountHospital';
        name: string;
        streetAddress1: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
      } | null;
    }> | null;
  };
};

export type CreateVetWithOrganizationMutationVariables = Exact<{
  vetInput: VetInput;
  organizationInput: OrganizationInput;
}>;

export type CreateVetWithOrganizationMutation = {
  __typename?: 'Mutation';
  createVetWithOrganization: {
    __typename?: 'VetDetails';
    vet: {
      __typename?: 'VetView';
      id: string;
      firstName: string;
      lastName: string;
      position: Position;
      email: string;
      howDidYouHearAboutUs: string | null;
      acceptedPrivacyPolicy: boolean;
      attestedAtSignup: boolean | null;
      hospital: {
        __typename?: 'Hospital';
        name: string;
        city: string | null;
        state: string;
      } | null;
      discountRequests: Array<{
        __typename?: 'DiscountRequest';
        acceptedTerms: boolean;
        email: string;
        firstName: string;
        lastName: string;
        phone: string | null;
        discountHospital: {
          __typename?: 'DiscountHospital';
          city: string | null;
          name: string;
          state: string | null;
          streetAddress1: string | null;
          streetAddress2: string | null;
          zip: string | null;
        } | null;
      }> | null;
    } | null;
    organization: {
      __typename?: 'OrganizationView';
      id: string | null;
      type: string;
      name: string;
      streetAddress1: string;
      streetAddress2: string | null;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string | null;
      source: OrganizationDataSource;
    } | null;
  };
};

export type CreateVetMutationVariables = Exact<{
  input: VetInput;
}>;

export type CreateVetMutation = {
  __typename?: 'Mutation';
  createVet: {
    __typename?: 'VetView';
    id: string;
    firstName: string;
    lastName: string;
    position: Position;
    email: string;
    howDidYouHearAboutUs: string | null;
    acceptedPrivacyPolicy: boolean;
    attestedAtSignup: boolean | null;
    hospital: {
      __typename?: 'Hospital';
      name: string;
      city: string | null;
      state: string;
    } | null;
    discountRequests: Array<{
      __typename?: 'DiscountRequest';
      acceptedTerms: boolean;
      email: string;
      firstName: string;
      lastName: string;
      phone: string | null;
      discountHospital: {
        __typename?: 'DiscountHospital';
        city: string | null;
        name: string;
        state: string | null;
        streetAddress1: string | null;
        streetAddress2: string | null;
        zip: string | null;
      } | null;
    }> | null;
  };
};

export type UpdateVetMutationVariables = Exact<{
  vetInput: VetInput;
  organizationInput: InputMaybe<OrganizationInput>;
}>;

export type UpdateVetMutation = {
  __typename?: 'Mutation';
  updateVet: {
    __typename?: 'VetDetails';
    vet: {
      __typename?: 'VetView';
      id: string;
      firstName: string;
      lastName: string;
      position: Position;
      email: string;
      howDidYouHearAboutUs: string | null;
      acceptedPrivacyPolicy: boolean;
      attestedAtSignup: boolean | null;
      hospital: {
        __typename?: 'Hospital';
        name: string;
        city: string | null;
        state: string;
      } | null;
      discountRequests: Array<{
        __typename?: 'DiscountRequest';
        acceptedTerms: boolean;
        email: string;
        firstName: string;
        lastName: string;
        phone: string | null;
        discountHospital: {
          __typename?: 'DiscountHospital';
          city: string | null;
          name: string;
          state: string | null;
          streetAddress1: string | null;
          streetAddress2: string | null;
          zip: string | null;
        } | null;
      }> | null;
    } | null;
    organization: {
      __typename?: 'OrganizationView';
      id: string | null;
      type: string;
      name: string;
      streetAddress1: string;
      streetAddress2: string | null;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string | null;
      source: OrganizationDataSource;
    } | null;
  };
};

export type FindPracticeQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;

export type FindPracticeQuery = {
  __typename?: 'Query';
  findPractice: Array<{
    __typename?: 'FoundPractice';
    id: string;
    name: string;
    formattedAddress: string;
  }> | null;
};

export type ValidatePracticeQueryVariables = Exact<{
  practice: ValidatePracticeInput;
}>;

export type ValidatePracticeQuery = {
  __typename?: 'Query';
  validatePractice: {
    __typename?: 'ValidatedPractice';
    id: string | null;
    formattedAddress: string | null;
    addressComponents: {
      __typename?: 'ValidatedPracticeAddressComponent';
      city: string;
      country: string;
      name: string;
      state: string;
      streetAddress1: string;
      streetAddress2: string;
      zip: string;
    } | null;
  } | null;
};

export type VetDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type VetDetailsQuery = {
  __typename?: 'Query';
  vetDetails: {
    __typename?: 'VetDetails';
    vet: {
      __typename?: 'VetView';
      id: string;
      firstName: string;
      lastName: string;
      position: Position;
      email: string;
      howDidYouHearAboutUs: string | null;
      acceptedPrivacyPolicy: boolean;
      attestedAtSignup: boolean | null;
      hospital: {
        __typename?: 'Hospital';
        name: string;
        city: string | null;
        state: string;
      } | null;
      discountRequests: Array<{
        __typename?: 'DiscountRequest';
        acceptedTerms: boolean;
        email: string;
        firstName: string;
        lastName: string;
        phone: string | null;
        discountHospital: {
          __typename?: 'DiscountHospital';
          city: string | null;
          name: string;
          state: string | null;
          streetAddress1: string | null;
          streetAddress2: string | null;
          zip: string | null;
        } | null;
      }> | null;
    } | null;
    organization: {
      __typename?: 'OrganizationView';
      id: string | null;
      type: string;
      name: string;
      streetAddress1: string;
      streetAddress2: string | null;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string | null;
      source: OrganizationDataSource;
    } | null;
  } | null;
};
