import { browserOnly } from '@farmersdog/utils';

export function onLocationChange(
  callback: () => void
): NodeJS.Timeout | undefined {
  return browserOnly((window: Window) => {
    const currentPage = window.location.href;
    const interval = setInterval(() => {
      if (currentPage !== window.location.href) {
        clearInterval(interval);
        callback();
      }
    }, 500);
    return interval;
  });
}
