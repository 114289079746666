import { FC } from 'react';
import { PageTitle, PublicPage, ForgotPasswordForm } from '../../components';

import styles from './ForgotPassword.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const ForgotPassword: FC = () => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <PublicPage blueberryBackground={showPortalUXOverhaul}>
      <section className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle
            title={'Enter Email Address'}
            subtitle={
              'Trouble logging in? Enter your email to receive your code.'
            }
            titleColor={showPortalUXOverhaul ? 'blueberry-3' : undefined}
          />
        </div>
        <ForgotPasswordForm />
      </section>
    </PublicPage>
  );
};
