import classNames from 'classnames';

import styles from './getModalDesign.css';

/**
 * Return a class name the applies the modal design.
 */
export function getModalDesign(): string {
  return classNames(styles.modal);
}

/**
 * Return a class name that applies the non-wave design to the modal content.
 */
export function getNonWaveDesign(): string {
  return classNames(styles.nonWave);
}

export interface GetWaveDesignReturn {
  waveSectionDesign: string;
  belowWaveSectionDesign: string;
}

/**
 * Return object with class names for the sections of the wave design modal.
 */
export function getWaveDesign(): GetWaveDesignReturn {
  return {
    waveSectionDesign: classNames(styles.waveSection),
    belowWaveSectionDesign: classNames(styles.belowWaveSection),
  };
}
