import React from 'react';
import styles from '../../styles/accessibility.css';

interface AccessibleContentProps {
  /** Content that is visually visible but ignored in the accessibility tree */
  visualContent: React.ReactNode;
  /** Text that is not visually visible but is included in the accessibility tree */
  accessibleText: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/accessiblecontent | AccessibleContent} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export function AccessibleContent({
  visualContent,
  accessibleText,
}: AccessibleContentProps): JSX.Element {
  return (
    <>
      <div aria-hidden="true">{visualContent}</div>
      <span className={styles.hidden}>{accessibleText}</span>
    </>
  );
}
