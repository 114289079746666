import {
  OperationVariables,
  QueryHookOptions,
  useQuery as apolloUseQuery,
} from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { DocumentNode } from 'graphql';

import { useClient } from './useClient';

export function useQuery<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const clientOverride = useClient();

  return apolloUseQuery<TData, TVariables>(mutation, {
    ...options,
    client: clientOverride,
  });
}
