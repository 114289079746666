import { ResetPasswordFormFields } from './resetPasswordFieldsData';

export interface ResetPasswordFormValues {
  [ResetPasswordFormFields.code]: string;
  [ResetPasswordFormFields.email]: string;
  [ResetPasswordFormFields.password]: string;
  [ResetPasswordFormFields.confirmPassword]: string;
}

export const initialFormValues: ResetPasswordFormValues = {
  [ResetPasswordFormFields.code]: '',
  [ResetPasswordFormFields.email]: '',
  [ResetPasswordFormFields.password]: '',
  [ResetPasswordFormFields.confirmPassword]: '',
};
