import isError from 'lodash/isError';
import * as React from 'react';

import { loadStripe } from '@stripe/stripe-js/pure';

import { StripeContext } from './StripeContext';

import type stripeJs from '@stripe/stripe-js';

export interface StripeProviderProps {
  /** Stripe public key */
  publishableKey: string;
  /** A callback to handle errors while loading stripe */
  onLoadError?: (error: Error) => void;
}

/**
 * Initializes Stripe API and create a context to access the global instance.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/StripeProvider | StripeProvider} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const StripeProvider: React.FC<
  React.PropsWithChildren<StripeProviderProps>
> = ({ publishableKey, onLoadError, children }) => {
  const [error, setError] = React.useState<Error>();
  const [instance, setInstance] = React.useState<stripeJs.Stripe>();

  /** Load stripe API */
  React.useEffect(() => {
    if (instance) {
      // Stripe is initialized nothing to do
      return;
    }

    if (error) {
      // Could not load stripe
      return;
    }

    const load = async (): Promise<void> => {
      try {
        const stripeInstance = await loadStripe(publishableKey);
        setInstance(stripeInstance ? stripeInstance : undefined);
      } catch (error) {
        if (!isError(error)) return;

        setError(error);

        if (onLoadError) {
          onLoadError(error);
        }
      }
    };

    void load();
  }, [publishableKey, onLoadError, error, instance]);

  const stripeContextValue = React.useMemo(
    () => ({
      stripe: instance,
      stripeLoadError: error,
    }),
    [instance, error]
  );

  return (
    <StripeContext.Provider value={stripeContextValue}>
      {children}
    </StripeContext.Provider>
  );
};
