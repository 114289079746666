import cookie from 'js-cookie';

const CUSTOMER_ACCOUNT_LOG_IN_NOTICE_KEY =
  'customer-account-log-in-notice-dismissed';

export function getCustomerAccountLogInNoticeDismissed(): string | undefined {
  return cookie.get(CUSTOMER_ACCOUNT_LOG_IN_NOTICE_KEY);
}

export function setCustomerAccountLogInNoticeDismissed(): void {
  cookie.set(CUSTOMER_ACCOUNT_LOG_IN_NOTICE_KEY, 'true');
}
