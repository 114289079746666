import { SingleValue, MultiValue } from './types';

/**
 * Returns a boolean indicating whether or not the array contains an item that
 * matches the stringified argument.
 */
function includesCastValue(value: MultiValue, child: SingleValue): boolean {
  if (!Array.isArray(value)) {
    return false;
  }

  return (
    typeof value.find(value => String(value) === String(child)) !== 'undefined'
  );
}

export default includesCastValue;
