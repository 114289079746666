import { arrayToOptions } from '../../../../utils';
import { FieldType, FormFieldArray } from '../../../FormFields';
import { states } from '../../../../constants';

export enum VeterinaryPracticeOrOrganizationFormFields {
  name = 'name',
  phone = 'phone',
  streetAddress1 = 'streetAddress1',
  streetAddress2 = 'streetAddress2',
  city = 'city',
  state = 'state',
  zip = 'zip',
}

interface SignUpWithNoSelectedPracticeFieldsDataArgs {
  isPractice: boolean;
}

export const signUpWithNoSelectedPracticeFieldsData = ({
  isPractice = false,
}: SignUpWithNoSelectedPracticeFieldsDataArgs): FormFieldArray<VeterinaryPracticeOrOrganizationFormFields> => [
  {
    name: VeterinaryPracticeOrOrganizationFormFields.name,
    type: FieldType.Text,
    label: isPractice ? 'Practice Name' : 'Organization Name',
  },
  {
    name: VeterinaryPracticeOrOrganizationFormFields.phone,
    type: FieldType.Text,
    label: isPractice
      ? 'Practice Phone Number (Optional)'
      : 'Phone Number (Optional)',
    inputMode: 'tel',
  },
  {
    name: VeterinaryPracticeOrOrganizationFormFields.streetAddress1,
    type: FieldType.Text,
    label: 'Street Address',
  },
  {
    name: VeterinaryPracticeOrOrganizationFormFields.streetAddress2,
    type: FieldType.Text,
    label: 'Apt., Floor, Unit, etc (Optional)',
  },
  {
    name: VeterinaryPracticeOrOrganizationFormFields.city,
    type: FieldType.Text,
    label: 'City',
  },
  {
    name: VeterinaryPracticeOrOrganizationFormFields.state,
    type: FieldType.Select,
    label: 'State',
    options: arrayToOptions(states),
  },

  {
    name: VeterinaryPracticeOrOrganizationFormFields.zip,
    type: FieldType.Text,
    label: 'Zip Code',
    inputMode: 'numeric',
  },
];
