import * as React from 'react';

import accessibilityStyles from '../../styles/accessibility.css';
import classNames from 'classnames';
import {
  interceptEvent,
  WithRefProps,
  withRef,
  IntrinsicElementOmitRef,
} from '../../util';
import { ElementState, useElementState } from '../BorderLabel';

import styles from './Selector.css';

/** Valid input types that can be a selector */
export const Types = ['radio', 'checkbox'] as const;

type RefProps = WithRefProps<HTMLInputElement>;

export interface SelectorProps
  extends Omit<IntrinsicElementOmitRef<'input'>, 'children'>,
    RefProps {
  /** Every child element will be provided the state prop from useElementState */
  children: React.ReactNode | ((state: ElementState) => React.ReactNode);
  /**
   * The input type of the selector<br />
   * radio: Allows a single value to be selected out of multiple choices<br />
   * checkbox: Allows single values to be selected/deselected
   */
  type?: (typeof Types)[number];
  /** Can be a string or a number */
  value?: number | string;
  /** Props to pass to the containing label element */
  labelProps?: JSX.IntrinsicElements['label'];
}

export type CustomSelectorProps = Omit<SelectorProps, 'children'>;

/**
 * Component used to create a custom radio or checkbox element following a11y rules.
 * Children can be any react element and will be passed the prop "state" which holds a value from
 * useElementState.
 *
 * All props not defined will be passed into the `input` element except for the className which is passed to
 * the containing `label` element.
 *
 * @deprecated Do not use this component. Use Checkbox or RadioButton from corgi-x, or implement your own state.
 */
export const Selector = withRef<HTMLInputElement, SelectorProps>(
  ({
    className,
    onFocus,
    onBlur,
    children,
    labelProps,
    forwardedRef,
    ...props
  }) => {
    const [state, handlers] = useElementState(props);

    return (
      <label
        className={classNames(styles.container, className)}
        {...labelProps}
      >
        <input
          ref={forwardedRef}
          className={classNames(accessibilityStyles.hidden)}
          onFocus={interceptEvent(handlers.onFocus, onFocus)}
          onBlur={interceptEvent(handlers.onBlur, onBlur)}
          data-checked={props.checked}
          {...props}
        />
        {typeof children === 'function'
          ? children(state)
          : React.Children.map(children, child => {
              if (!React.isValidElement(child)) return child;

              return React.cloneElement(child, {
                // @ts-ignore
                state,
              });
            })}
      </label>
    );
  }
);
