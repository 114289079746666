export enum SignupMode {
  Search = 'search',
  Add = 'add',
  None = 'none',
}

export enum SignupSearchStep {
  Search = 'search',
  Selected = 'selected',
}
