import ArrowSvg from './svg/arrow.svg';
import BackSvg from './svg/back.svg';
import BlobSvg from './svg/blob.svg';
import BoltSvg from './svg/bolt.svg';
import BowlSteamingSvg from './svg/bowl-steaming.svg';
import CalendarSvg from './svg/calendar.svg';
import CaretSvg from './svg/caret.svg';
import CarrotSvg from './svg/carrot.svg';
import CheckCircleSvg from './svg/check-circle.svg';
import CheckSvg from './svg/check.svg';
import ChevronSvg from './svg/chevron.svg';
import CircleAlertSvg from './svg/circle-alert.svg';
import CircleCrossSvg from './svg/circle-cross.svg';
import CircleFillSvg from './svg/circle-fill.svg';
import CircleMinusSvg from './svg/circle-minus.svg';
import CirclePlusSvg from './svg/circle-plus.svg';
import CircleSvg from './svg/circle.svg';
import ClipboardSvg from './svg/clipboard.svg';
import ClockSvg from './svg/clock.svg';
import CloseSvg from './svg/close.svg';
import DoctorSvg from './svg/doctor.svg';
import DogFoodSvg from './svg/dog-food.svg';
import FacebookSvg from './svg/facebook.svg';
import ForkSpoonSvg from './svg/fork-spoon.svg';
import HeartOutlineSvg from './svg/heart-outline.svg';
import HeartSvg from './svg/heart.svg';
import HouseSvg from './svg/house.svg';
import InformationSvg from './svg/information.svg';
import LeafSvg from './svg/leaf.svg';
import LockSvg from './svg/lock.svg';
import MailSvg from './svg/mail.svg';
import MinusSvg from './svg/minus.svg';
import NavigationSvg from './svg/navigation.svg';
import ParcelSvg from './svg/parcel.svg';
import PlaySvg from './svg/play.svg';
import PlusSvg from './svg/plus.svg';
import RefundSvg from './svg/refund.svg';
import ScaleSvg from './svg/scale.svg';
import SquareFillSvg from './svg/square-fill.svg';
import SquareSvg from './svg/square.svg';
import StandOnScaleSvg from './svg/stand-on-scale.svg';
import StarSvg from './svg/star.svg';
import TruckSvg from './svg/truck.svg';
import TwitterSvg from './svg/twitter.svg';
import VegetableSvg from './svg/vegetable.svg';
import { withIconProps } from './withIconProps';

export * from './PropTypes';

/** @deprecated Import `Arrow` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Arrow = withIconProps(ArrowSvg);
/** @deprecated Import `Back` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Back = withIconProps(BackSvg);
/** @deprecated Import `Blob` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Blob = withIconProps(BlobSvg);
/** @deprecated Import `Bolt` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Bolt = withIconProps(BoltSvg);
/** @deprecated Import `BowlSteaming` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const BowlSteaming = withIconProps(BowlSteamingSvg);
/** @deprecated Import `Calendar` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Calendar = withIconProps(CalendarSvg);
/** @deprecated Import `Caret` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Caret = withIconProps(CaretSvg);
/** @deprecated Import `Carrot` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Carrot = withIconProps(CarrotSvg);
/** @deprecated Import `Check` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Check = withIconProps(CheckSvg);
/** @deprecated Import `CheckCircle` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CheckCircle = withIconProps(CheckCircleSvg);
/** @deprecated Import `Chevron` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Chevron = withIconProps(ChevronSvg);
/** @deprecated Import `Circle` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Circle = withIconProps(CircleSvg);
/** @deprecated Import `CircleAlert` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CircleAlert = withIconProps(CircleAlertSvg);
/** @deprecated Import `CircleCross` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CircleCross = withIconProps(CircleCrossSvg);
/** @deprecated Import `CircleFill` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CircleFill = withIconProps(CircleFillSvg);
/** @deprecated Import `CircleMinus` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CircleMinus = withIconProps(CircleMinusSvg);
/** @deprecated Import `CirclePlus` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const CirclePlus = withIconProps(CirclePlusSvg);
/** @deprecated Import `Clipboard` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Clipboard = withIconProps(ClipboardSvg);
/** @deprecated Import `Clock` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Clock = withIconProps(ClockSvg);
/** @deprecated Import `Close` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Close = withIconProps(CloseSvg);
/** @deprecated Import `Doctor` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Doctor = withIconProps(DoctorSvg);
/** @deprecated Import `DogFood` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const DogFood = withIconProps(DogFoodSvg);
/** @deprecated Import `Facebook` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Facebook = withIconProps(FacebookSvg);
/** @deprecated Import `ForkSpoon` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const ForkSpoon = withIconProps(ForkSpoonSvg);
/** @deprecated Import `Heart` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Heart = withIconProps(HeartSvg);
/** @deprecated Import `HeartOutline` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const HeartOutline = withIconProps(HeartOutlineSvg);
/** @deprecated Import `House` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const HoImport = withIconProps(HouseSvg);
/** @deprecated Import `Information` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Information = withIconProps(InformationSvg);
/** @deprecated Import `Leaf` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Leaf = withIconProps(LeafSvg);
/** @deprecated Import `Lock` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Lock = withIconProps(LockSvg);
/** @deprecated Import `Mail` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Mail = withIconProps(MailSvg);
/** @deprecated Import `Minus` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Minus = withIconProps(MinusSvg);
/** @deprecated Import `Navigation` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Navigation = withIconProps(NavigationSvg);
/** @deprecated Import `Parcel` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Parcel = withIconProps(ParcelSvg);
/** @deprecated Import `Play` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Play = withIconProps(PlaySvg);
/** @deprecated Import `Plus` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Plus = withIconProps(PlusSvg);
/** @deprecated Import `Refund` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Refund = withIconProps(RefundSvg);
/** @deprecated Import `Scale` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Scale = withIconProps(ScaleSvg);
/** @deprecated Import `Square` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Square = withIconProps(SquareSvg);
/** @deprecated Import `SquareFill` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const SquareFill = withIconProps(SquareFillSvg);
/** @deprecated Import `StandOnScale` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const StandOnScale = withIconProps(StandOnScaleSvg);
/** @deprecated Import `Star` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Star = withIconProps(StarSvg);
/** @deprecated Import `Truck` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Truck = withIconProps(TruckSvg);
/** @deprecated Import `Twitter` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Twitter = withIconProps(TwitterSvg);
/** @deprecated Import `Vegetable` from the corgi-x {@link https://corgi-x.tfd.engineering/system/icons | Icons Library}. */
export const Vegetable = withIconProps(VegetableSvg);
