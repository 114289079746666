import { Card } from '../../../components';
import { TitleSection } from '../TitleSection';
import { Divider, Grid, Link, Text } from '@farmersdog/corgi-x';
import { Handout } from './Handout/Handout';
import PageIconSvg from './assets/page.svg?react';
import { useScreenSizeContext } from '../../../context/ScreenSizeContext';
import ReferralHandoutOnePager from '../../../../static/pdf/ReferralHandout.pdf';
import ReferralHandoutTrifoldBrochure from '../../../../static/pdf/TFD_Referral-Handout-Trifold-Brochure.pdf';
import styles from './Referrals.module.css';
import * as externalLinks from '../../../constants/externalLinks';
import { trackDownloadableInfo } from '../../../analytics/events';

export const Referrals = () => {
  const { isMobile } = useScreenSizeContext();
  return (
    <>
      <TitleSection title="Refer your clients" />
      <Card className={styles.card}>
        <Text variant="heading-22" bold color="Blueberry3" bottomSpacing="xs">
          Referral Handouts
        </Text>
        <Text as="p" variant="article-16" topSpacing="none" bottomSpacing="md">
          We have referral handouts available for your team to use. You can make
          them client-specific by adding patient details and instructions.
        </Text>

        <Grid>
          <Handout
            icon={PageIconSvg}
            title="One-Pager"
            description="Great for printing or digital sharing."
            linkText="Download One-Pager"
            onClick={() =>
              trackDownloadableInfo({ title: 'Referral Handout One-Pager' })
            }
            linkUrl={ReferralHandoutOnePager}
          />
          <Divider
            width={1}
            spacing={isMobile ? 24 : 36}
            color="Blueberry1"
            orientation={isMobile ? 'horizontal' : 'vertical'}
          />
          <Handout
            icon={PageIconSvg}
            title="Trifold Brochure"
            description={
              <span>
                Use our quick and easy form to request mailed copies of our
                trifold brochure. To preview it,{' '}
                <Link
                  href={ReferralHandoutTrifoldBrochure}
                  target="_blank"
                  baseColor="kale"
                  onClick={() =>
                    trackDownloadableInfo({
                      title: 'Referral Handout Trifold Brochure',
                    })
                  }
                >
                  {' '}
                  <u>
                    {' '}
                    <strong> click here.</strong>
                  </u>
                </Link>
              </span>
            }
            linkText="Request Brochures"
            linkUrl={externalLinks.PATH_REQUEST_BROCHURE}
          />
        </Grid>
      </Card>
    </>
  );
};
