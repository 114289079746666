import { Reason } from './components';
import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import { desktopReasons, mobileReasons } from './reasonsData';

import styles from './WhatFreshFoodIsNotSection.module.css';

interface ResponsiveWhatFreshFoodIsNotSectionProps {
  isMobile?: boolean;
}

export function ResponsiveWhatFreshFoodIsNotSection({
  isMobile,
}: ResponsiveWhatFreshFoodIsNotSectionProps) {
  const reasons = isMobile ? mobileReasons : desktopReasons;
  return (
    <Grid
      topSpacing={{ xs: 'xl', lg: '4xl' }}
      bottomSpacing={{ xs: 'xl', lg: '4xl' }}
      rowGap={{ xs: 'md', lg: 'lg' }}
      justifyContent="space-around"
    >
      <GridItem justifyContent="center">
        <Text
          className={styles.title}
          variant={isMobile ? 'heading-28' : 'heading-40'}
          color="white"
          bold
        >
          What The Farmer’s Dog Fresh Food is Not
        </Text>
      </GridItem>
      <GridItem>
        <Grid columnGap="xl" rowGap="lg" className={styles.reasonsContainer}>
          {reasons.map(({ title, description }) => (
            <GridItem key={title} justifyContent="center">
              <Reason
                title={title}
                description={description}
                ariaLabel={`${title} Icon`}
              />
            </GridItem>
          ))}
        </Grid>
      </GridItem>
    </Grid>
  );
}

export function WhatFreshFoodIsNotSection() {
  return (
    <section className={styles.section}>
      <div className={styles.mobile}>
        <ResponsiveWhatFreshFoodIsNotSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveWhatFreshFoodIsNotSection />
      </div>
    </section>
  );
}
