import { Text } from '@farmersdog/corgi-x';

import { browserOnly } from '@farmersdog/utils';
import { MenuItem, menuItems } from '../menuItems';

import styles from './FAQsMenu.module.css';

const ENTER = 13;

interface FAQsMenuProps {
  menuItemRefs: Record<string, string>;
  menuItems: MenuItem[];
}

export const FAQsMenuLegacy = ({ menuItemRefs }: FAQsMenuProps) => {
  // Function to handle key up event for accessibility purposes
  function handleKeyUp(event: React.KeyboardEvent<HTMLElement>, id: string) {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === ENTER) {
      scrollTo(id);
    }
  }

  function scrollTo(id: string) {
    const element = document.querySelector(`#${id}`);

    browserOnly(() => {
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  return (
    <ul className={styles.list}>
      {menuItems.map(({ name, label }) => (
        <li
          tabIndex={0}
          key={name}
          onKeyUp={event => handleKeyUp(event, menuItemRefs[name])}
          onClick={() => scrollTo(menuItemRefs[name])}
        >
          <a className={styles.link}>
            <Text variant="heading-16" bold color="charcoal-3">
              {label}
            </Text>
          </a>
        </li>
      ))}
    </ul>
  );
};
