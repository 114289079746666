import { track } from '../track';
import { landing_page_clicked_university_visit_request } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Request University Visit" button on the landing page
 */

export function trackLandingPageClickedUniversityVisitRequest() {
  track({ eventName: landing_page_clicked_university_visit_request });
}
