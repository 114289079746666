import {
  ChangeEventHandler,
  FocusEventHandler,
  isValidElement,
  ReactElement,
} from 'react';

import { FormControl } from '@farmersdog/corgi';
import { Checkbox, Text, TextVariants } from '@farmersdog/corgi-x';

import styles from './CheckboxWithText.module.css';

interface CheckboxWithTextProps {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  error: string | undefined;
  isTouched: boolean | undefined;
  fieldName: string;
  labelContent: string | ReactElement;
  textVariant?: TextVariants;
  bold?: boolean;
  maxWidth?: boolean;
}

export function CheckboxWithText({
  error,
  checked,
  onChange,
  onBlur,
  isTouched,
  fieldName,
  labelContent,
  bold = false,
  textVariant = 'heading-12',
  maxWidth = false,
}: CheckboxWithTextProps) {
  const fieldHasErrorAndIsTouched = Boolean(error && isTouched);
  const isInvalid = fieldHasErrorAndIsTouched
    ? ('true' as unknown as boolean)
    : undefined;

  const label = isValidElement(labelContent) ? (
    labelContent
  ) : (
    <Text
      bold={bold}
      className={
        maxWidth ? styles.checkboxWithTextFullWidth : styles.checkboxWithText
      }
      variant={textVariant}
      color="charcoal-3"
      as="p"
    >
      {labelContent}
    </Text>
  );

  return (
    <div className={styles.container}>
      <FormControl
        id={`${fieldName}-message`}
        aria-live="polite"
        message={fieldHasErrorAndIsTouched ? error : undefined}
        invalid={isInvalid}
      >
        <Checkbox
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          name={fieldName}
          label={label}
        />
      </FormControl>
    </div>
  );
}
