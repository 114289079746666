import { useEffect } from 'react';

interface useOnScrollEndProps {
  onScrollEnd: () => void;
  // The time in milliseconds to wait before calling the onScrollEnd function
  timeout?: number;
}

/**
 * Hook that listens for the end of a scroll event, then calls the onScrollEnd function
 *
 * @param useOnScrollEndProps - The props for the hook
 **/
export const useOnScrollEnd = ({
  onScrollEnd,
  timeout = 200,
}: useOnScrollEndProps) => {
  useEffect(() => {
    let isScrolling: ReturnType<typeof setTimeout>;

    const handleScroll = () => {
      window.clearTimeout(isScrolling);

      isScrolling = setTimeout(() => {
        onScrollEnd();
      }, timeout);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
