import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Card.css';
import elevationStyle from './elevation.css';
import { AsProp } from '../../types';

export const CardHTMLElements = ['figure', 'div'] as const;

export interface CardDesignProps {
  /** Elevation level of the card. Use `0` for no shadow. */
  elevation?: 0 | 1;
  /** Content of the card */
  children: ReactNode;
  className?: string;
}

type CardHTMLElement = (typeof CardHTMLElements)[number];

export type CardProps<T extends CardHTMLElement = 'figure'> = AsProp<T> &
  CardDesignProps;

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/card | Card} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export function Card<T extends CardHTMLElement>(
  props: CardProps<T>
): JSX.Element;

export function Card({
  children,
  elevation = 0,
  className,
  as: HTMLElement = 'figure',
  ...props
}: CardProps): JSX.Element {
  return (
    <HTMLElement
      className={classNames(
        styles.container,
        className,
        elevationStyle[`level${elevation}`]
      )}
      {...props}
    >
      {children}
    </HTMLElement>
  );
}
