import { QueryHookOptions } from '@apollo/client';
import { VetDetailsQuery, VetDetailsQueryVariables } from '../types';
import vetDetailsQuery from '../queries/vet-details.graphql';
import { useLazyQuery } from '../../services/apollo';

export function useVetDetailsLazy(
  options?: QueryHookOptions<VetDetailsQuery, VetDetailsQueryVariables>
) {
  const [query, { loading }] = useLazyQuery<
    VetDetailsQuery,
    VetDetailsQueryVariables
  >(vetDetailsQuery, options);

  return {
    vetDetailsQuery: query,
    loading,
  };
}
