import * as React from 'react';
import omit from 'lodash/omit';

import {
  ButtonDesignProps,
  LinkDesignProps,
  getButtonDesign,
  getLinkDesign,
} from '../../designs';
import {
  NavLink,
  NavLinkProps,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import { InterfaceUnion } from '../../types';
import classNames from 'classnames';
import styles from './Link.css';
import { isNavLink, isAnchor } from './linkType';

export type LinkStyleProps = InterfaceUnion<LinkDesignProps, ButtonDesignProps>;

export function isAnchorLink(
  item: LinkAsAnchorProps | LinkAsRouteProps
): item is LinkAsAnchorProps {
  return Boolean(!item.to && item.href);
}

export interface LinkAsRouteProps {
  to: string;
  href?: never;
}

export interface LinkAsAnchorProps {
  to?: never;
  href: string;
}

export type LinkLocationProps = LinkAsAnchorProps | LinkAsRouteProps;

export interface LinkProps
  extends Partial<LinkStyleProps>,
    Partial<NavLinkProps> {
  /** Toggles to button variant styles */
  asButton?: boolean;
  /** Optional class name */
  className?: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/link | Link} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Link: React.FC<LinkProps> = ({
  asButton = false,
  variant,
  mode,
  highContrast,
  className,
  children,
  hover,
  active,
  asPill,
  asMini,
  ...props
}) => {
  const designClass = asButton
    ? getButtonDesign({
        variant: variant !== 'tertiary' ? variant : undefined,
        active,
        hover,
        asPill,
        asMini,
        disabled: props.disabled,
      })
    : getLinkDesign({
        variant,
        mode,
        active,
        hover,
        disabled: props.disabled,
        highContrast,
      });

  const linkProps = {
    ...props,
    className: classNames(
      className,
      styles.link,
      designClass,
      asButton && styles.asButton
    ),
  };

  if (props.disabled) {
    linkProps['aria-disabled'] = true;
  }

  if (isAnchor(linkProps)) {
    const { className, ...restProps } = omit(linkProps, ['activeClassName']);

    return (
      <a {...restProps} className={classNames(className)}>
        {children}
      </a>
    );
  }

  if (isNavLink(linkProps)) {
    return <NavLink {...linkProps}>{children}</NavLink>;
  }

  return (
    <RouterLink {...(linkProps as RouterLinkProps)}>{children}</RouterLink>
  );
};
