import type stripeJs from '@stripe/stripe-js';
import noop from 'lodash/noop';

import { useStripe } from './useStripe';
import { useElements } from './useElements';
import { elementTypes } from './elementTypes';

type CreateToken = stripeJs.Stripe['createToken'];
type TokenData = stripeJs.CreateTokenCardData;
type Token = ReturnType<CreateToken>;

/**
 * Return a single function to create a stripe token. Automatically subscribes
 * to the current elements context and passes a valid element.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/useStripeCreateToken | useStripeCreateToken} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export function useCreateToken(): typeof noop | ((data: TokenData) => Token) {
  const { elements } = useElements();
  const { stripe } = useStripe();

  return (data: TokenData): Token => {
    if (!stripe) {
      throw new Error('Stripe cannot be found');
    }

    if (!elements) {
      throw new Error('Stripe elements cannot be found');
    }

    const element = elements.getElement(elementTypes.cardNumber);

    if (!element) {
      throw new Error('Card element cannot found');
    }

    return stripe.createToken(element, data);
  };
}
