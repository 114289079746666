export const howDidYouHearAboutUsOptions = [
  'Colleague',
  'Social Media',
  'Client',
  'In-Person Event',
  'Employee',
  'Webinar',
  'Email',
  'Other',
];
