import { Position } from 'src/graphql/types';
import { ReviewInfoFormFields } from './reviewInfoFormFieldsData';

export interface ReviewInfoFormValues {
  [ReviewInfoFormFields.position]?: string;
  [ReviewInfoFormFields.acceptedPrivacyPolicy]: boolean;
}
export const initialFormValues: ReviewInfoFormValues = {
  [ReviewInfoFormFields.position]: undefined,
  [ReviewInfoFormFields.acceptedPrivacyPolicy]: false,
};

export const getInitialFormValues = ({
  position,
}: {
  position: Position | undefined;
}): ReviewInfoFormValues => {
  if (!position) {
    return initialFormValues;
  }
  return {
    ...initialFormValues,
    [ReviewInfoFormFields.position]: position,
  };
};
