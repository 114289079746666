import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { AccountPage } from '../../components/AccountPage';
import { FAQsMenu, FAQsMenuLegacy } from './FAQsMenu';
import FAQIcon from './assets/faqIcon.svg?react';

import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import * as pagePaths from '../../constants/pagePaths';
import { trackClickedFAQActivateDiscountButton } from '../../analytics/events';

import styles from './FAQs.module.css';
import { menuItems, scrollAnchorNames } from './menuItems';
import {
  GeneralSection,
  ResearchSection,
  EmailSection,
  EmailSectionLegacy,
} from './FAQContent';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { Card } from '../../components';
import { VetDiscount } from '../Account/VetDiscount';

export const FAQs = () => {
  const { data } = useVetDetails();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const discountRequests = data?.vetDetails?.vet?.discountRequests || [];

  const noDiscountsRequested = discountRequests.length === 0;

  const generalId = scrollAnchorNames.general;
  const researchId = scrollAnchorNames.research;

  const menuItemRefs = {
    [scrollAnchorNames.general]: generalId,
    [scrollAnchorNames.research]: researchId,
  };

  return (
    <AccountPage fullWidth>
      <Grid justifyContent="center" columnGap="md" className={styles.container}>
        <GridItem lg={4} className={styles.nav}>
          <Text
            bold
            as="h2"
            topSpacing="none"
            bottomSpacing="lg"
            color="blueberry-3"
            variant="heading-40"
          >
            Frequently Asked Questions for Vet Professionals
          </Text>
          {showPortalUXOverhaul ? (
            <FAQsMenu menuItems={menuItems} menuItemRefs={menuItemRefs} />
          ) : (
            <FAQsMenuLegacy menuItems={menuItems} menuItemRefs={menuItemRefs} />
          )}
          <FAQIcon className={styles.icon} />
        </GridItem>
        <GridItem className={styles.questionsContainer} lg={8}>
          {showPortalUXOverhaul ? (
            <div className={styles.card}>
              <Card>
                <GeneralSection
                  menuItemRef={menuItemRefs[scrollAnchorNames.general]}
                />
              </Card>
            </div>
          ) : (
            <GeneralSection
              menuItemRef={menuItemRefs[scrollAnchorNames.general]}
            />
          )}
          {showPortalUXOverhaul ? (
            <Card>
              <ResearchSection
                menuItemRef={menuItemRefs[scrollAnchorNames.research]}
                showPortalUXOverhaul={showPortalUXOverhaul}
              />
            </Card>
          ) : (
            <ResearchSection
              menuItemRef={menuItemRefs[scrollAnchorNames.research]}
              showPortalUXOverhaul={showPortalUXOverhaul}
            />
          )}

          {!showPortalUXOverhaul && <Divider color="Oyster1" spacing={48} />}

          {showPortalUXOverhaul ? <EmailSection /> : <EmailSectionLegacy />}

          {noDiscountsRequested &&
            (showPortalUXOverhaul ? (
              <div className={styles.card}>
                <GridItem>
                  <VetDiscount hasDiscountRequests={!noDiscountsRequested} />
                </GridItem>
              </div>
            ) : (
              <Button
                onClick={() => trackClickedFAQActivateDiscountButton()}
                type="link"
                to={pagePaths.PATH_TRIAL_DISCOUNTS}
              >
                Activate Vet Discount
              </Button>
            ))}
        </GridItem>
      </Grid>
    </AccountPage>
  );
};
