/// <reference types="vite-plugin-svgr/client" />
import { SVGProps, ReactElement, FunctionComponent } from 'react';

import FoodSVG from '../assets/food.svg?react';
import StethoscopeSVG from '../assets/stethoscope.svg?react';
import InspectSVG from '../assets/inspect.svg?react';
import FoodPacksSVG from '../assets/food-packs.svg?react';
import { Nowrap } from '@farmersdog/corgi-x';

export enum BenefitTitles {
  portions = 'Raising the Bar on Safety Standards',
  safety = 'Cooked For Safety and Digestibility',
  science = 'Committed to Veterinary Science and Vet Professionals',
  food = 'Complete and Balanced, Meets AAFCO Standards and WSAVA Guidelines',
}

interface Benefits {
  image: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: BenefitTitles;
  ariaLabel?: string;
  content: ReactElement;
}

export const benefitData: Benefits[] = [
  {
    image: InspectSVG,
    title: BenefitTitles.portions,
    ariaLabel: BenefitTitles.portions,
    content: (
      <>
        All of our recipes are made and tested in FDA-compliant facilities, to
        the same quality-control standards as human food. Very few pet foods are
        made in this uncompromising way.
      </>
    ),
  },
  {
    image: FoodPacksSVG,
    title: BenefitTitles.safety,
    ariaLabel: BenefitTitles.safety,
    content: (
      <>
        Our food is cooked (not raw) so it’s safe for humans to handle and dogs
        to eat—and it’s more digestible than typical varieties of heavily{' '}
        <Nowrap>processed kibble.</Nowrap>
      </>
    ),
  },
  {
    image: StethoscopeSVG,
    title: BenefitTitles.science,
    ariaLabel: BenefitTitles.science,
    content: (
      <>
        Veterinary professionals shape every aspect of our product. All our
        recipes are developed by board-certified nutritionists based on decades
        of research and clinical practices.
      </>
    ),
  },
  {
    image: FoodSVG,
    title: BenefitTitles.food,
    ariaLabel: BenefitTitles.food,
    content: (
      <>
        All of our complete and balanced recipes comply with AAFCO nutrient
        profiles and meet or exceed WSAVA’s guidelines for selecting pet food.
        We are not driven by fads, marketing, or ingredient trends; we are
        driven to make the best, safest and healthiest food for dogs.
      </>
    ),
  },
];
