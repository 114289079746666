import { Route, Switch } from 'react-router';
import { AuthorizedRoute } from './AuthorizedRoute';

import { usePageTracker } from '../../analytics/hooks';
import { useScrollRestore } from '../../hooks';

import { refreshSession } from '@farmersdog/sessions';
import { useUserEngagementHandler } from '@farmersdog/utils';

import { routes } from '../../constants/routes';
import { Page } from '../Page';
import { useMountGa4PageView } from '../../vendors/ga4';
import { ScreenSizeContextProvider } from '../../context/ScreenSizeContext';

// eslint-disable-next-line no-restricted-imports
import { useScreenSize } from '../../hooks/useScreenSize';

export const Router = () => {
  useUserEngagementHandler({ onUserEngagement: refreshSession });
  usePageTracker();
  useScrollRestore();
  useMountGa4PageView();

  const screenSize = useScreenSize();

  return (
    <Switch>
      {Object.values(routes).map(
        ({
          path,
          component: Component,
          isPrivate,
          exact,
          title,
          description,
        }) =>
          isPrivate ? (
            <AuthorizedRoute key={path} path={path} exact={exact}>
              <ScreenSizeContextProvider screenSize={screenSize}>
                <Page title={title} description={description}>
                  <Component />
                </Page>
              </ScreenSizeContextProvider>
            </AuthorizedRoute>
          ) : (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={() => (
                <Page title={title} description={description}>
                  <Component />
                </Page>
              )}
            />
          )
      )}
    </Switch>
  );
};
