import chickenAndGrainPng from './chickenAndGrain.png';
import chickenAndGrainWebp from './chickenAndGrain.webp';

import turkeyPng from './turkey.png';
import turkeyWebp from './turkey.webp';

import beefPng from './beef.png';
import beefWebp from './beef.webp';

import chickenPng from './chicken.png';
import chickenWebp from './chicken.webp';

import porkPng from './pork.png';
import porkWebp from './pork.webp';

import porkAndGrainPng from './porkAndGrain.png';
import porkAndGrainWebp from './porkAndGrain.webp';

export const chickenAndGrain = [chickenAndGrainPng, chickenAndGrainWebp];
export const turkey = [turkeyPng, turkeyWebp];
export const beef = [beefPng, beefWebp];
export const chicken = [chickenPng, chickenWebp];
export const pork = [porkPng, porkWebp];
export const porkAndGrain = [porkAndGrainPng, porkAndGrainWebp];
