import { Button, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import ReferralHandoutSVG from '../assets/vetReferralHandout.svg?react';
import referralHandout from '../../../../static/pdf/ReferralHandout.pdf';

import styles from './ReferralHandout.module.css';
import { trackDownloadableVetReferralHandout } from '../../../analytics/events/trackDownloadableVetReferralHandout';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

export const ReferralHandout = () => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <>
      <Card>
        <div className={styles.container}>
          <ReferralHandoutSVG className={styles.desktopDog} />
          <div className={styles.texts}>
            <Text
              bold
              as="h2"
              topSpacing="none"
              bottomSpacing="xs"
              color="blueberry-3"
              variant="heading-28"
            >
              Refer Your Clients
            </Text>
            <Text
              as="p"
              color="kale-3"
              topSpacing="none"
              bottomSpacing="md"
              variant="article-16"
            >
              We have a handout available for you and your team to use. You can
              make it specific for your client by adding patient details and
              instructions before printing or emailing.
            </Text>
            <div className={styles.bottom}>
              <div className={styles.buttons}>
                <Button
                  target="_blank"
                  variant={
                    showPortalUXOverhaul
                      ? 'primary-mini-kale-experimental'
                      : 'primary-mini'
                  }
                  type="anchor"
                  href={referralHandout}
                  onClick={trackDownloadableVetReferralHandout}
                  className={styles.downloadLink}
                  aria-label="View Vet Pro Client Referral Handout PDF"
                >
                  Download Handout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
