import { Text } from '@farmersdog/corgi-x';

export const TitleSection = ({ title }: { title: string }) => {
  return (
    <>
      <Text
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="lg"
        color="blueberry-3"
        variant="heading-28"
      >
        {title}
      </Text>
    </>
  );
};
