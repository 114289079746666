import * as yup from 'yup';
import { LoginFormFields } from './loginFormFieldsData';
import { EMAIL_REGEXP } from '../../../constants/validation';
import { noTrailingWhitespace } from '../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../../utils';

export const loginFormValidationSchema = yup.object({
  [LoginFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),
  [LoginFormFields.password]: yup.string().required(fieldRequiredErrorMessage),
});
