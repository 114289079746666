import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { SVGProps, FunctionComponent } from 'react';
import styles from './Handout.module.css';

interface HandoutProps {
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  description: string | React.ReactElement;
  linkText: string;
  linkUrl: string;
  onClick?: () => void;
}

export const Handout = ({
  icon: Icon,
  title,
  description,
  linkText,
  linkUrl,
  onClick,
}: HandoutProps) => {
  return (
    <GridItem className={styles.container}>
      <Grid flexDirection="column">
        <Icon className={styles.svg} />
        <Text bold topSpacing="sm">
          {title}
        </Text>
        <Text variant="article-12" topSpacing="xs" color="Kale3">
          {description}
        </Text>
      </Grid>
      <Button
        target="_blank"
        variant="plain-text"
        type="anchor"
        href={linkUrl}
        color="kale"
        underline
        className={styles.link}
        onClick={onClick}
      >
        {linkText}
      </Button>
    </GridItem>
  );
};
