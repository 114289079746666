import * as React from 'react';
import { ElementsContext, ElementsContextValue } from './ElementsContext';

/**
 * Return the elements context value
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/useStripeElements | useStripeElements} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export function useElements(): ElementsContextValue {
  return React.useContext(ElementsContext);
}
