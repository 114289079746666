import React, { useId } from 'react';

import { AriaLiveAttributes } from '../LiveMessage/LiveMessage';
import { LiveMessage } from '../LiveMessage';
import classNames from 'classnames';
import { heading } from '../../typography';
import styles from './FormControl.css';

const typography = heading.create({ size: 16, color: 'charcoal-1' });

export interface FormControlProps extends React.AriaAttributes {
  /** Optional class name */
  className?: string;
  /** The interactive element to provide messages for */
  children?: React.ReactNode;
  /** The message to provide to the user */
  message?: React.ReactNode;
  /** Whether to toggle the invalid state */
  invalid?: boolean;
  /** An optional id. WCAG compliant ids are automatically provided. */
  id?: string;
  /**
   * Indicates that an element will be updated, and describes the types of
   * updates the user agents, assistive technologies, and user can expect from
   * the live region.
   */
  'aria-live': (typeof AriaLiveAttributes)[number];
  /** Disable passing of attributes to child component */
  disableAriaProps?: boolean;
}

/**
 * This component is used for including inline error messages along with form
 * elements.
 *
 * https://www.w3.org/WAI/tutorials/forms/notifications/#listing-errors
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/formcontrol | FormControl} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const FormControl: React.FC<FormControlProps> = ({
  children,
  message,
  invalid,
  className,
  disableAriaProps = false,
  ...props
}) => {
  const reactId = useId();
  const id = props.id || reactId;

  const hidden = !message;

  return (
    <div className={classNames(styles.container, className)}>
      {React.isValidElement(children) && !disableAriaProps
        ? React.cloneElement(children, {
            // @ts-ignore
            invalid,
            ['aria-describedby']: id,
            ['aria-invalid']: invalid,
          })
        : children}
      <LiveMessage
        id={id}
        aria-live={props['aria-live']}
        hidden={hidden}
        invalid={invalid}
        className={classNames(typography.className, styles.message)}
      >
        {message}
      </LiveMessage>
    </div>
  );
};

FormControl.defaultProps = {
  ['aria-live']: 'polite',
};
