import * as React from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Return an object that updates based on a deep equality reference rather then
 * a shallow one.
 *
 * This is useful when you need to pass parameters into 3rd party objects and
 * you want to avoid needlessly destroying and creating those objects inside of
 * a useEffect hook.
 *
 * @param currentOptions - Any object that you want to update based on a deep equality comparison
 */
export function useSafeOptions<T>(currentOptions: T): T {
  const [safeOptions, setSafeOptions] = React.useState<T>(currentOptions);

  React.useEffect(() => {
    setSafeOptions(prevOptions => {
      if (!isEqual(prevOptions, currentOptions)) {
        return currentOptions;
      }

      return prevOptions;
    });
  }, [currentOptions]);

  return safeOptions;
}
