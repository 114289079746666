import { clicked_contact_support } from './eventNames';
import { track } from '../track';

/**
 * Send a tracking event for when a user clicks on the Contact Support button in the error page.
 */

export function trackClickedSupport() {
  track({
    eventName: clicked_contact_support,
    eventProps: {
      supportChannel: 'email',
    },
  });
}
