import { Text, Link } from '@farmersdog/corgi-x';

export const EmailSectionLegacy = () => {
  return (
    <>
      <Text
        as="h3"
        color="Blueberry3"
        variant="heading-28"
        bold
        bottomSpacing="sm"
        topSpacing="lg"
      >
        Prefer email?
      </Text>

      <Text color="charcoal-3" variant="article-16">
        You can always send us a message at{' '}
        <Link href="mailto:vetpros@thefarmersdog.com">
          vetpros@thefarmersdog.com
        </Link>
        .
      </Text>
    </>
  );
};
