import * as React from 'react';

import { KALE_3, WHITE, TFD_LOGO } from '../../styles/colors';

import LogoBackground from './assets/background.svg';
import LogoText from './assets/text.svg';
import classNames from 'classnames';
import styles from './Logo.css';

export const LogoModes = ['light', 'dark', 'product'] as const;
// The width/height aspect ratio of our logo
export interface LogoProps {
  mode: (typeof LogoModes)[number];
  size: number;
  style?: React.CSSProperties;
  className?: string;
}

function getTextFill(mode: (typeof LogoModes)[number]): string {
  switch (mode) {
    case 'light':
    case 'product':
    default:
      return WHITE;
    case 'dark':
      return KALE_3;
  }
}

function getBackgroundFill(mode: (typeof LogoModes)[number]): string {
  switch (mode) {
    case 'light':
    default:
      return KALE_3;
    case 'product':
      return TFD_LOGO;
    case 'dark':
      return WHITE;
  }
}

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/logo | Logo} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Logo: React.FC<LogoProps> = ({
  size = 100,
  mode = 'light',
  className,
  style,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 463.097 421.318"
      className={classNames(styles.logo, className)}
      style={{ height: size, ...style }}
      {...props}
      aria-label="Logo"
    >
      <g fillRule="nonzero" fill="none">
        <LogoBackground
          className={styles.path}
          style={{ fill: getBackgroundFill(mode) }}
        />
        <LogoText className={styles.path} style={{ fill: getTextFill(mode) }} />
      </g>
    </svg>
  );
};
