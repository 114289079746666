import { Position } from '../graphql/types';
import { OptionType } from '../components/FormFields';

export const positionOptions: Array<OptionType> = [
  {
    label: 'Practice Manager/Office Manager',
    value: Position.PracticeManagerOrOfficeManager,
  },
  {
    label: 'Veterinary Technician/Nurse',
    value: Position.VeterinaryTechnicianOrNurse,
  },
  {
    label: 'Veterinary Technician/Nurse Student',
    value: Position.VeterinaryTechnicianOrNurseStudent,
  },
  {
    label: 'Veterinarian',
    value: Position.Veterinarian,
  },
  {
    label: 'Veterinary Student',
    value: Position.VeterinaryStudent,
  },
  {
    label: 'Other Healthcare Team Member',
    value: Position.Other,
  },
];
