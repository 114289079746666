import { Typography, TypographyProps } from '../Typography';
import { headingStyles } from './styles';

/** The available heading font sizes */
export const HeadingSizes = [72, 52, 40, 28, 22, 21, 16, 12] as const;
/** The available heading font weights */
export const HeadingWeights = ['normal', 'bold'] as const;
/** The available heading font styles */
export const HeadingStyles = ['normal'] as const;
/** The available heading font colors */
export const HeadingColors = [
  'blueberry-3',
  'carrot-2',
  'carrot-3',
  'charcoal-1',
  'charcoal-2',
  'charcoal-3',
  'kale-2',
  'kale-3',
  'white',
] as const;
/** The available heading font size variant for responsive text */
export const HeadingSizeVariants = ['1', '2', '3', '4', '5'] as const;
export const HeadingFontWeights = ['black'] as const;

/**
 * The allowable property names and values for heading text styles.
 */
export interface HeadingProps extends TypographyProps {
  /** Set the heading font color */
  color: (typeof HeadingColors)[number];
  /** Set the heading font size */
  size: (typeof HeadingSizes)[number];
  /** Set the heading font weight */
  weight: (typeof HeadingWeights)[number];
  /** Set the heading font style */
  fontStyle: (typeof HeadingStyles)[number];
}

export interface HeadingResponsiveProps
  extends Omit<HeadingProps, 'size' | 'weight'> {
  size: (typeof HeadingSizeVariants)[number];
  weight: (typeof HeadingFontWeights)[number];
}

export const heading = new Typography<HeadingProps | HeadingResponsiveProps>(
  headingStyles,
  {
    weight: 'normal',
    fontStyle: 'normal',
  }
);
