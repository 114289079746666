import { Banner, Button, Text } from '@farmersdog/corgi-x';
import styles from './SignUpBanner.module.css';
import { SectionId } from '../../pages/Home/Hero/nav-items';
import { paths } from '@farmersdog/constants';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export function SignUpBanner() {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <>
      <Banner
        className={showPortalUXOverhaul ? styles.banner : styles.bannerLegacy}
        variant={showPortalUXOverhaul ? 'chickpea-0' : 'default'}
      >
        <Text color="Blueberry3" variant="article-16">
          The Farmer’s Dog has <b> on-staff board-certified nutritionists </b>
          who are committed to veterinary science and safety.{' '}
        </Text>{' '}
        <Button
          variant="plain-text"
          type="link"
          to={{
            pathname: paths.PATH_HOME,
            hash: SectionId.ScienceAndSafety,
          }}
          color={showPortalUXOverhaul ? 'kale' : 'carrot'}
          id="signup-learn-more-btn"
          underline
        >
          <Text variant="heading-16" bold>
            Learn More
          </Text>
        </Button>
      </Banner>
    </>
  );
}
