import { browserOnly } from '@farmersdog/utils';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useEffect, useState } from 'react';

/**
 * Custom hook that focus the first field that contains an error.
 * @param values - the values to be passed to a regular useFormik hook.
 * @returns values returned by the useFormik hook.
 */
export function useFormikWithFocusOnError<
  Values extends FormikValues = FormikValues,
>(formikValues: FormikConfig<Values>) {
  const [submitCount, setSubmitCount] = useState(0);
  const formik = useFormik({
    ...formikValues,
  });
  useEffect(() => {
    if (
      Object.entries(formik.errors).length > 0 &&
      formik.submitCount != submitCount
    ) {
      setSubmitCount(formik.submitCount);

      const firstElement = Object.keys(formik.errors)[0];
      browserOnly((_, document) => {
        const inputElements = document.querySelectorAll(`#${firstElement}`);
        inputElements.forEach((element: Element) => {
          const inputElement = element as HTMLElement;
          // check if the input is visible
          if (inputElement.style.display !== 'none') {
            inputElement.focus();
          }
        });
      });
    }
  }, [formik.errors, formik.isSubmitting, formik.submitCount, submitCount]);

  return formik;
}
