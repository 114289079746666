import { LOGO_TRANSLATE, SHADOW_OPACITY } from './constants';

import { LinkModes } from '../../designs/getLinkDesign';
import { getProgressOfRange } from './getProgressOfRange';
import { useMemo } from 'react';
import { useScrollProgress } from './useScrollProgress';

interface UseScrollingStyles {
  overlay?: boolean;
  darkTheme?: boolean;
  content: Element | null;
  isOpen?: boolean;
  hasContentAbove?: boolean;
}
interface ScrollingStyles {
  linkTheme: (typeof LinkModes)[number];
  backgroundColor: string;
  boxShadow: string;
  logoTranslate: number;
}

const calculateOpacity = (
  isOpen: boolean,
  overlay: boolean,
  scrollProgress: number
): number => {
  return isOpen || !overlay ? 1 : scrollProgress;
};

const calculateTranslate = (
  isOpen: boolean,
  scrollProgress: number,
  hasContentAbove: boolean
): number => {
  if (isOpen) return LOGO_TRANSLATE;
  const logoDown = !hasContentAbove || scrollProgress >= 0.7;

  return logoDown ? LOGO_TRANSLATE : LOGO_TRANSLATE * -1;
};

const calculateShadowOpacity = (
  isOpen: boolean,
  scrollProgress: number
): number => {
  if (isOpen) return SHADOW_OPACITY;
  return getProgressOfRange(scrollProgress, 0, SHADOW_OPACITY);
};

const calculateLinkTheme = (
  isOpen: boolean,
  scrollProgress: number,
  overlay: boolean,
  darkTheme: boolean
): (typeof LinkModes)[number] => {
  const canBeDark = overlay && darkTheme;
  if (isOpen || !canBeDark) return LinkModes[1];
  return scrollProgress > 0.7 ? LinkModes[1] : LinkModes[0];
};

export const useScrollingStyles = ({
  overlay = false,
  darkTheme = false,
  content,
  isOpen = false,
  hasContentAbove = false,
}: UseScrollingStyles): ScrollingStyles => {
  const scrollProgress = useScrollProgress(content);

  return useMemo(() => {
    const opacity = calculateOpacity(isOpen, overlay, scrollProgress);
    const shadowOpacity = calculateShadowOpacity(isOpen, scrollProgress);

    const logoTranslate = calculateTranslate(
      isOpen,
      scrollProgress,
      hasContentAbove
    );

    const linkTheme = calculateLinkTheme(
      isOpen,
      scrollProgress,
      overlay,
      darkTheme
    );

    return {
      linkTheme,
      backgroundColor: `rgba(255, 255, 255, ${opacity})`,
      boxShadow: `0 2px 12px 0 rgba(0, 0, 0, ${shadowOpacity})`,
      logoTranslate,
    };
  }, [darkTheme, hasContentAbove, isOpen, overlay, scrollProgress]);
};
