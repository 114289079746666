import { track } from '../track';
import { faq_review_clicked } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Review FAQs" button
 */

export function trackClickedReviewFAQs() {
  track({ eventName: faq_review_clicked });
}
