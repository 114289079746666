import { Text, Divider } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import styles from './RecipeDisplay.module.css';

interface RecipeDisplayProps {
  title: string;
  children: React.ReactNode;
}

export const RecipeDisplay = ({ title, children }: RecipeDisplayProps) => {
  return (
    <Card>
      <Text
        bold
        as="h3"
        topSpacing="none"
        color="blueberry-3"
        variant="heading-28"
      >
        {title}
      </Text>
      <Divider width={1} color="Kale1" spacing={36} />
      <div className={styles.grid}>{children}</div>
    </Card>
  );
};
