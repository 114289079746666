import { ReactNode } from 'react';
import { Text } from '@farmersdog/corgi-x';

import { Details } from '../Details';

interface QuestionDropdownProps {
  question: string;
  answer: string | ReactNode;
}

export function QuestionDropdown({ question, answer }: QuestionDropdownProps) {
  const isChildrenString = typeof answer === 'string';

  return (
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <Details summary={question} itemProp="name">
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            {isChildrenString ? (
              <Text
                as="p"
                topSpacing="sm"
                bottomSpacing="lg"
                variant="article-16"
                color="charcoal-3"
              >
                {answer}
              </Text>
            ) : (
              answer
            )}
          </div>
        </div>
      </Details>
    </div>
  );
}
