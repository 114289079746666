export const HowToGetDiscountSteps = [
  {
    id: 1,
    content: (
      <>
        <strong> Sign up for fresh food subscription</strong>
        <br />
        <span> using the button below to receive a free trial box.</span>
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        <strong> Return to this screen</strong>
        <br />
        after completing sign up for your fresh food subscription.
      </>
    ),
  },
  {
    id: 3,
    content: (
      <>
        <strong>Complete verification</strong>
        <br />
        to receive your ongoing discount. We’ll send you an email to confirm!
      </>
    ),
  },
];
