import { arrayToOptions } from '../../../../../utils';
import { positionOptions, states } from '../../../../../constants';

import { FieldType, FormFieldArray } from '../../../../FormFields';

export enum AddVetpracticeFormFields {
  name = 'name',
  phone = 'phone',
  streetAddress1 = 'streetAddress1',
  streetAddress2 = 'streetAddress2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  position = 'position',
  acceptedTerms = 'acceptedTerms',
}

export const addVetpracticeFieldsData: FormFieldArray<AddVetpracticeFormFields> =
  [
    {
      name: AddVetpracticeFormFields.name,
      type: FieldType.Text,
      label: 'Practice Name',
    },
    {
      name: AddVetpracticeFormFields.phone,
      type: FieldType.Text,
      label: 'Practice Phone Number (Optional)',
      inputMode: 'tel',
    },
    {
      name: AddVetpracticeFormFields.city,
      type: FieldType.Text,
      label: 'City',
    },
    {
      name: AddVetpracticeFormFields.streetAddress1,
      type: FieldType.Text,
      label: 'Street Address',
    },
    {
      name: AddVetpracticeFormFields.streetAddress2,
      type: FieldType.Text,
      label: 'Apt., Floor, Unit, etc (Optional)',
    },
    {
      name: AddVetpracticeFormFields.state,
      type: FieldType.Select,
      label: 'State',
      options: arrayToOptions(states),
    },
    {
      name: AddVetpracticeFormFields.zip,
      type: FieldType.Text,
      label: 'Zip Code',
      inputMode: 'numeric',
    },
    {
      name: AddVetpracticeFormFields.position,
      type: FieldType.Select,
      label: 'Role',
      options: positionOptions,
    },
  ];
