/* CH#49502: Used by RET and CVR */
import { useState, useEffect, useMemo } from 'react';
import mime from 'mime/lite';
import { useIntersection, useHtmlElement } from '@farmersdog/corgi';

const FALLBACK_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/gif',
];

/** All of the available loading types. */
export enum LoadingOption {
  /** Defer loading of image until it is in the viewport */
  lazy = 'lazy',
  /** Loading image immediately when the component is mounted */
  eager = 'eager',
}

export interface PictureProps {
  /** Explicit fallback src */
  src?: string;
  /** An ordered array of sources, most likely in different formats */
  sources: string[];
  /** Required alt image tag */
  alt: string;
  /**
   * An enum of loading types. Use `lazy` to defer loading of images outside of
   * the viewport and `eager` to load them immediately. For more information see
   * this resource.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-loading
   */
  loading?: LoadingOption;
  /** rootMargin to use for the intersection observer if you're lazy loading the image */
  rootMargin?: string;
  className?: string;
  /** Image width */
  width?: string;
  /** Image height */
  height?: string;
}

export function Picture({
  sources,
  src,
  alt,
  loading = LoadingOption.eager,
  rootMargin = '33%',
  ...props
}: PictureProps) {
  const [loaded, setLoaded] = useState(loading !== 'lazy');
  const [imageEl, imageElRef] = useHtmlElement();
  const intersection = useIntersection(imageEl, {
    rootMargin,
  });

  useEffect(() => {
    if (loaded || !intersection) {
      return;
    }

    if (intersection.isIntersecting) {
      setLoaded(true);
    }
  }, [loaded, intersection]);

  const fallbackSrc = useMemo(() => {
    if (src) {
      return src;
    }

    return Array.isArray(sources)
      ? sources.find(srcSet => {
          const type = mime.getType(srcSet);
          return type && FALLBACK_TYPES.includes(type);
        })
      : '';
  }, [src, sources]);

  if (!Array.isArray(sources)) {
    return (
      <img
        ref={imageElRef}
        src={loaded ? fallbackSrc : undefined}
        alt={alt}
        {...props}
      />
    );
  }

  return (
    <picture>
      {sources.map(srcSet => {
        const type = mime.getType(srcSet) || undefined;

        return (
          <source
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`${alt}-${type}`}
            type={type}
            srcSet={loaded ? srcSet : undefined}
          />
        );
      })}
      {/* important not to give img tag a src or safari double downloads */}
      <img ref={imageElRef} alt={alt} {...props} />
    </picture>
  );
}
