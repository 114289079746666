/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from 'react';
import * as typography from '../../typography';

import classNames from 'classnames';
import { AsProp } from '../../types';

/** The html element that the component can be rendered as */
type TypographyHTMLElement =
  | 'span'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'li';

/** The available variants of article.
 * Article has a different font family than heading and body.
 * article-3 should only be used for labels
 */
export const ArticleTextVariants = [
  'article-1',
  'article-2',
  'article-3',
] as const;

/** The available variants of body.
 * body-3 should only be used for labels
 */
export const BodyTextVariants = ['body-1', 'body-2', 'body-3'] as const;

/** The available variants of heading.
 * Heading always has a bold font styles
 */
export const HeadingTextVariants = [
  'heading-1',
  'heading-2',
  'heading-3',
  'heading-4',
  'heading-5',
] as const;

export const ResponsiveTextVariants = [
  ...ArticleTextVariants,
  ...BodyTextVariants,
  ...HeadingTextVariants,
] as const;

/** The props that can be applied to the article variants. */
interface TextArticleProps {
  /** The text variant to be applied to article (required) */
  variant: (typeof ArticleTextVariants)[number];
  /** The font style to use for article */
  fontStyle?: (typeof typography.ArticleStyles)[number];
  /** The font weight to use for article */
  fontWeight?: (typeof typography.ArticleFontWeights)[number];
  /** The color to use for article */
  color?: (typeof typography.ArticleColors)[number];
}

/** The props that can be applied to the body variants */
interface TextBodyProps {
  /** The text variant to be applied to body (required) */
  variant: (typeof BodyTextVariants)[number];
  /** The font style to use for body */
  fontStyle?: (typeof typography.BodyStyles)[number];
  /** The font weight to use for body */
  fontWeight?: (typeof typography.BodyFontWeights)[number];
  /** The color to use for body */
  color?: (typeof typography.BodyColors)[number];
}

/** The props that can be applied to the heading variants */
interface TextHeadingProps {
  /** The text variant to be applied to heading (required) */
  variant: (typeof HeadingTextVariants)[number];
  /** The color to use for heading */
  fontStyle?: (typeof typography.HeadingStyles)[number];
  /** The color to use for heading */
  fontWeight?: (typeof typography.HeadingFontWeights)[number];
  /** The color to use for heading */
  color?: (typeof typography.HeadingColors)[number];
}

/** The props that can be applied to the component */
type ResponsiveTextProps<T extends TypographyHTMLElement = 'span'> = AsProp<T> &
  (TextArticleProps | TextBodyProps | TextHeadingProps);

/**
 * ## ResponsiveText
 *
 * The `ResponsiveText` component renders textual content following the CORGI styles.
 * It detects current viewport size and changes the heading font size accordingly, in adherence to the design system.
 *
 *
 * ### Usage
 *
 * - Use this component to render block of texts.
 * - Follow the designer guidelines to choose the right `variant`.
 * - Make sure you use the proper semantic HTML tags for the `as` property.
 *
 * ### Example
 *
 * ```jsx
 * import { ResponsiveText } from '@farmersdog/corgi';
 *
 * function () {
 *  return (
 *    <>
 *      <ResponsiveText
 *        variant="heading-2"
 *        color="kale-3"
 *      >
 *        Heading 2 is 48px on desktop but 36px on mobile!
 *      </ResponsiveText>
 *      <ResponsiveText
 *        variant="body-1"
 *        color="charcoal-3"
 *      >
 *        Body 1 is 18px on both desktop and mobile!
 *      </ResponsiveText>
 *    </>
 *  )
 * }
 *
 * ```
 *
 */
export function ResponsiveText<T extends TypographyHTMLElement>(
  props: ResponsiveTextProps<T>
): JSX.Element;

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/text | Text} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export function ResponsiveText({
  as: TypographyHTMLElementTag = 'span',
  variant,
  fontWeight = 'roman',
  fontStyle,
  color,
  className,
  ...props
}: ResponsiveTextProps): JSX.Element {
  const [type, size] = variant.split('-');

  let weight = fontWeight;
  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(TypographyHTMLElementTag)) {
    weight = 'black';
  }

  // @ts-expect-error not sure why this works at runtime, but typescript isn't happy
  const typographyStyles = typography[type as keyof typeof typography].create({
    size,
    weight,
    fontStyle,
    color,
  });

  return (
    <TypographyHTMLElementTag
      className={classNames(className, typographyStyles.className as string)}
      {...props}
    />
  );
}
