import { ReactSVGElement } from 'react';
import StarSVG from '../../assets/starLegacy.svg?react';
import styles from './ReviewStars.module.css';

interface ReviewStarsProps {
  starCount: number;
}
export function ReviewStars({ starCount }: ReviewStarsProps) {
  return (
    <div aria-label={`${starCount} star rating`} className={styles.container}>
      {[...Array<ReactSVGElement>(starCount)].map((_, i) => {
        return <StarSVG key={`star-${i}`} className={styles.star} />;
      })}
    </div>
  );
}
