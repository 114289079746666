import { AddOrganizationFormFields } from './AddOrganizationFieldsData';

export interface AddOrganizationFormValues {
  [AddOrganizationFormFields.name]: string;
  [AddOrganizationFormFields.phone]: string;
  [AddOrganizationFormFields.streetAddress1]: string;
  [AddOrganizationFormFields.streetAddress2]?: string;
  [AddOrganizationFormFields.state]: string | undefined;
  [AddOrganizationFormFields.zip]: string;
  [AddOrganizationFormFields.city]: string;
}

export const addOrganizationInitialFormValues: AddOrganizationFormValues = {
  [AddOrganizationFormFields.name]: '',
  [AddOrganizationFormFields.phone]: '',
  [AddOrganizationFormFields.streetAddress1]: '',
  [AddOrganizationFormFields.streetAddress2]: '',
  [AddOrganizationFormFields.state]: undefined,
  [AddOrganizationFormFields.zip]: '',
  [AddOrganizationFormFields.city]: '',
};
