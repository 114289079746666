import { track } from '../track';
import { landing_page_clicked_logging_in_to_our_portal } from './eventNames';

/**
 * Send a tracking event for when a user clicks on logging in to our portal link.
 */

export function trackLandingPageClickedLoggingInToOurPortal() {
  track({ eventName: landing_page_clicked_logging_in_to_our_portal });
}
