import React, {
  Children,
  FC,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import isFunction from 'lodash/isFunction';

import { ArrowButton } from '../ArrowButton';
import CarouselItem from './CarouselItem';
import { HTMLElementOf } from '../../types';
import { ScrollSnap } from '../ScrollSnap';
import classNames from 'classnames';
import fastdom from 'fastdom';
import isOverflowingLeft from './isOverflowingLeft';
import isOverflowingRight from './isOverflowingRight';
import styles from './Carousel.css';

export interface CarouselChildProps {
  onMouseUp(ref: HTMLLIElement): void;
  fullWidth?: boolean;
}

export interface CarouselProps {
  /** A collection of equal width rich elements */
  children: ReactNode | ((props: CarouselChildProps) => React.ReactNode);
  /** Toggle next/previous buttons */
  withButtons?: boolean;
  /** Toggle margins to center first and last items when in view */
  centerFirstAndLast?: boolean;
  /** Toggle looping from last to first and first to last */
  loop?: boolean;
  /** Toggle behavior to only show one child at a time */
  asSlides?: boolean;
  className?: string;
}

/**
 * A horizontal scrolling carousel to display a collection of rich elements.
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/carousel | Carousel} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const Carousel: FC<CarouselProps> = ({
  children,
  withButtons = false,
  centerFirstAndLast = false,
  loop = false,
  asSlides = false,
  className,
}) => {
  const scrollSnap = useRef<HTMLElementOf<HTMLElement>>(null);
  const [blankWidth, setBlankWidth] = useState('');
  const id = useId();

  const handlePreviousClick = (): void => {
    let nextChild: HTMLElement | null | undefined;
    fastdom.measure(() => {
      if (!scrollSnap.current) {
        return;
      }

      if (loop && scrollSnap.current.scrollLeft === 0) {
        nextChild = centerFirstAndLast
          ? scrollSnap.current.children.item(
              scrollSnap.current.children.length - 2
            )
          : scrollSnap.current.lastChild;
      } else if (scrollSnap.current) {
        nextChild = Array.from(scrollSnap.current.children)
          .reverse()
          .filter((child): child is HTMLElement => Boolean(child))
          .find(child => isOverflowingLeft(child, scrollSnap.current));

        if (!nextChild) {
          nextChild = centerFirstAndLast
            ? scrollSnap.current.children.item(1)
            : scrollSnap.current.firstChild;
        }
      }
    });

    fastdom.mutate(() => {
      if (nextChild) {
        nextChild.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    });
  };

  const handleNextClick = (): void => {
    let nextChild: HTMLElement | null | undefined;
    fastdom.measure(() => {
      if (!scrollSnap.current) {
        return;
      }

      const maxScroll =
        scrollSnap.current.scrollWidth - scrollSnap.current.clientWidth;
      if (loop && scrollSnap.current.scrollLeft === maxScroll) {
        nextChild = centerFirstAndLast
          ? scrollSnap.current.children.item(1)
          : scrollSnap.current.firstChild;
      } else {
        nextChild = Array.from(scrollSnap.current.children)
          .filter((child): child is HTMLElement => Boolean(child))
          .find(child => isOverflowingRight(child, scrollSnap.current));

        if (!nextChild) {
          nextChild = centerFirstAndLast
            ? scrollSnap.current.children.item(
                scrollSnap.current.children.length - 2
              )
            : scrollSnap.current.lastChild;
        }
      }
    });

    fastdom.mutate(() => {
      if (nextChild) {
        nextChild.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    });
  };

  const handleChildMouseUp = (element: HTMLLIElement): void => {
    let isOverflow: boolean;

    fastdom.measure(() => {
      isOverflow =
        isOverflowingRight(element, scrollSnap.current) ||
        isOverflowingLeft(element, scrollSnap.current);
    });

    fastdom.mutate(() => {
      if (isOverflow) {
        element.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    });
  };

  useEffect(() => {
    if (!scrollSnap.current) return;
    const firstRealChild = scrollSnap.current.children.item(1);

    if (!firstRealChild) return;

    const childWidth = firstRealChild.getBoundingClientRect().width;
    setBlankWidth(`calc(50% - ${childWidth / 2}px)`);
  }, []);

  const childProps = {
    onMouseUp: handleChildMouseUp,
    fullWidth: asSlides,
  };

  return (
    <div className={classNames(styles.container, className)}>
      {withButtons && (
        <ArrowButton
          onClick={handlePreviousClick}
          direction="left"
          aria-label="previous"
          aria-controls={id}
          className={styles.previous}
        />
      )}
      <ScrollSnap
        as="ul"
        id={id}
        ref={scrollSnap}
        className={styles.horizontalSnap}
      >
        {centerFirstAndLast && (
          <li
            className={classNames(styles.blank, styles.left)}
            style={{ marginRight: blankWidth }}
          />
        )}
        {isFunction(children)
          ? children(childProps)
          : Children.map(children, child => (
              <CarouselItem {...childProps}>{child}</CarouselItem>
            ))}
        {centerFirstAndLast && (
          <li
            className={classNames(styles.blank, styles.right)}
            style={{ marginLeft: blankWidth }}
          />
        )}
      </ScrollSnap>
      {withButtons && (
        <ArrowButton
          onClick={handleNextClick}
          direction="right"
          aria-label="next"
          aria-controls={id}
          className={styles.next}
        />
      )}
    </div>
  );
};
