import { useCallback, useMemo, useState } from 'react';
import { AccountPage } from '../../components/AccountPage';
import {
  Page,
  Text,
  Toast,
  ToastProps,
  ToastSentiment,
  Grid,
  GridItem,
} from '@farmersdog/corgi-x';
import styles from './Profile.module.css';
import { useUpdateVet, useVetDetails } from '../../graphql/hooks';
import { PersonalDetails } from './PersonalDetails/PersonalDetails';
import { UpdatePasswordLegacy } from './UpdatePassword/UpdatePasswordLegacy';
import { Organization, OrganizationLegacy } from './Organization';
import { useAuthContext } from '../../context';
import { ResetPasswordSucessMessage } from './ResetPasswordSucessMessage/ResetPasswordSucessMessage';
import { getErrorMessage } from '../../services/auth/utils/errors';
import { trackRequestedResetPassword } from '../../analytics/events';
import { useQueryParameters } from '../../hooks';
import { UpdatePersonalDetails } from './UpdatePersonalDetails/UpdatePersonalDetails';
import { useHistory } from 'react-router';
import * as pagePaths from '../../constants/pagePaths';
import omit from 'lodash/omit';
import {
  HospitalInput,
  InputMaybe,
  OrganizationInput,
  OrganizationView,
  Position,
  VetDetailsQuery,
  VetInput,
  VetView,
} from '../../graphql/types';
import { UpdateRole } from './UpdateRole/UpdateRole';
import { UpdateOrganization } from './UpdateOrganization/UpdateOrganization';
import { trackProfileUpdated } from '../../analytics/events/trackProfileUpdated';
import { ResponsiveProps } from '../../constants';
import { usePortalUXOverhaul } from '../../abTesting/PublicPageFeatures/featureHooks';
import { UpdatePassword } from './UpdatePassword/UpdatePassword';

export const SUCCESS_MESSAGE = 'Your profile has been updated';
export const ERROR_MESSAGE =
  'We were unable to update your profile. Please try again';

const ProfileResponsive = ({ isMobile = false }: ResponsiveProps) => {
  const query = useQueryParameters();
  const history = useHistory();
  const { data, called, refetch } = useVetDetails();
  const { updatePassword, isUpdatingPassword } = useAuthContext();
  const [updateVet, { loading }] = useUpdateVet();
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] =
    useState(false);
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const vet = data?.vetDetails?.vet;
  const organization = useMemo(
    () => data?.vetDetails?.organization,
    [data?.vetDetails?.organization]
  );
  const hospital = vet?.hospital;

  const { showPortalUXOverhaul } = usePortalUXOverhaul();

  if (!vet || (!vet && called)) {
    throw new Error('Vet not found');
  }

  const address = useMemo(() => {
    if (organization) {
      return [
        organization?.streetAddress1,
        organization?.streetAddress2,
        organization?.city,
        organization?.state,
        organization?.zip,
      ]
        .filter(item => Boolean(item))
        .join(', ');
    }
    if (hospital) {
      return [hospital?.city, hospital?.state]
        .filter(item => Boolean(item))
        .join(', ');
    }
    return '';
  }, [hospital, organization]);

  const name = [vet.firstName, vet.lastName].join(' ');
  const organizationName = organization?.name || hospital?.name;

  const updatePasswordHandler = async () => {
    if (!updatePassword) {
      throw new Error('No forgotPassword function provided');
    }
    const { email } = vet;
    try {
      await updatePassword({ email });
      trackRequestedResetPassword({ email, result: true });
      setShowResetPasswordSuccess(true);
    } catch (error) {
      trackRequestedResetPassword({ email, result: false });
      const message = getErrorMessage(error);
      setToast({
        message,
        sentiment: ToastSentiment.Negative,
        isOpen: true,
      });
    }
  };

  const goToProfile = () => {
    history.push(pagePaths.PATH_PROFILE);
  };

  const updateVetInfo = useCallback(
    async ({
      updatedVet,
      updatedOrganization,
    }: {
      updatedVet: VetView;
      updatedOrganization: OrganizationView;
    }) => {
      try {
        await updateVet({
          refetchQueries: ['vetDetails'],
          variables: convertVetDetailsToVetInput({
            vetDetails: {
              vet: updatedVet,
              organization: updatedOrganization ?? null,
            },
          }),
        });
        await refetch();
        setToast({
          message: SUCCESS_MESSAGE,
          sentiment: ToastSentiment.Positive,
          isOpen: true,
        });
        history.replace(pagePaths.PATH_PROFILE);
      } catch {
        setToast({
          message: ERROR_MESSAGE,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [history, refetch, updateVet]
  );

  const updatePersonalDetails = useCallback(
    async (firstName: string, lastName: string) => {
      await updateVetInfo({
        updatedVet: {
          ...(vet as VetView),
          firstName,
          lastName,
        },
        updatedOrganization: organization as OrganizationView,
      });
      if (vet.firstName !== firstName && vet.lastName !== lastName) {
        trackProfileUpdated({ id: vet.id, firstName: true, lastName: true });
        return;
      }
      if (vet.firstName !== firstName) {
        trackProfileUpdated({ id: vet.id, firstName: true });
      }
      if (vet.lastName !== lastName) {
        trackProfileUpdated({ id: vet.id, lastName: true });
      }
    },
    [organization, updateVetInfo, vet]
  );

  const updatePosition = useCallback(
    async (position: string) => {
      await updateVetInfo({
        updatedVet: {
          ...(vet as VetView),
          position: position as Position,
        },
        updatedOrganization: organization as OrganizationView,
      });
      trackProfileUpdated({ id: vet.id, position: true });
    },
    [organization, updateVetInfo, vet]
  );

  const updateOrganization = useCallback(
    async (organizationInput: OrganizationInput) => {
      await updateVetInfo({
        updatedVet: vet as VetView,
        updatedOrganization: organizationInput as OrganizationView,
      });
      trackProfileUpdated({ id: vet.id, practice: true });
    },
    [updateVetInfo, vet]
  );

  const updateView = useMemo(() => {
    if (query.get('update') === 'personal') {
      return (
        <UpdatePersonalDetails
          firstName={vet.firstName}
          lastName={vet.lastName}
          onSubmit={updatePersonalDetails}
          disableSubmit={loading}
          email={vet.email}
        />
      );
    }
    if (query.get('update') === 'role') {
      return (
        <UpdateRole
          position={vet.position}
          onSubmit={updatePosition}
          disableSubmit={loading}
        />
      );
    }

    return null;
  }, [
    loading,
    query,
    updatePersonalDetails,
    updatePosition,
    vet.email,
    vet.firstName,
    vet.lastName,
    vet.position,
  ]);

  if (showResetPasswordSuccess) {
    return (
      <ResetPasswordSucessMessage
        isMobile={isMobile}
        onReturnProfile={() => {
          setShowResetPasswordSuccess(false);
        }}
      />
    );
  }
  if (query.has('update') && query.get('update') === 'practice') {
    return (
      <>
        <UpdateOrganization
          onUpdateOrganization={updateOrganization}
          disableSubmit={loading}
        />
        <Toast
          {...toast}
          autoClose
          onClose={() => setToast({ isOpen: false })}
          timeout={1000}
        />
      </>
    );
  }

  if (query.has('update')) {
    return (
      <Page onBackClick={goToProfile} onCloseClick={goToProfile}>
        {updateView}
        <Toast
          {...toast}
          autoClose
          onClose={() => setToast({ isOpen: false })}
          timeout={1000}
        />
      </Page>
    );
  }

  return (
    <>
      <Toast
        {...toast}
        autoClose
        onClose={() => setToast({ isOpen: false })}
        timeout={1000}
      />
      <AccountPage>
        <Grid>
          <GridItem>
            <Text
              as="h2"
              variant={isMobile ? 'heading-28' : 'heading-40'}
              bold
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="none"
            >
              My Professional Profile
            </Text>
            <Text
              as="p"
              variant="article-16"
              color="charcoal-3"
              topSpacing="sm"
              bottomSpacing="none"
            >
              Keep your profile up to date to receive relevant information and
              resources from The Farmer’s Dog.
            </Text>
          </GridItem>
          <Grid className={styles.container}>
            <PersonalDetails
              email={vet.email}
              name={name}
              showPortalUXOverhaul={showPortalUXOverhaul}
            />

            {showPortalUXOverhaul ? (
              <>
                <Organization
                  address={address}
                  position={vet.position}
                  name={organizationName}
                  type={organization?.type}
                />
                <UpdatePassword
                  onUpdatePassword={updatePasswordHandler}
                  disableUpdatePassword={isUpdatingPassword}
                />
              </>
            ) : (
              <>
                <UpdatePasswordLegacy
                  onUpdatePassword={updatePasswordHandler}
                  disableUpdatePassword={isUpdatingPassword}
                />
                <OrganizationLegacy
                  address={address}
                  position={vet.position}
                  isMobile={isMobile}
                  name={organizationName}
                  type={organization?.type}
                />
              </>
            )}
          </Grid>
        </Grid>
      </AccountPage>
    </>
  );
};

export const Profile = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ProfileResponsive isMobile />
      </div>
      <div className={styles.desktop}>
        <ProfileResponsive />
      </div>
    </>
  );
};

export const convertVetDetailsToVetInput = ({
  vetDetails,
}: VetDetailsQuery): {
  vetInput: VetInput;
  organizationInput: InputMaybe<OrganizationInput>;
} => {
  const vet = vetDetails?.vet;
  const organization = vetDetails?.organization;
  const hospital = vet?.hospital;

  return {
    vetInput: {
      ...(omit(
        {
          ...vet,
        },
        '__typename',
        'id',
        'discountRequests'
      ) as VetInput),
      hospital: hospital ? omit(hospital as HospitalInput, '__typename') : null,
    },
    organizationInput: organization ? omit(organization, '__typename') : null,
  };
};
