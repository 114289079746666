import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Icon.css';

import { WithIconProps } from './PropTypes';
type IntrinsicSVGProps = JSX.IntrinsicElements['svg'];

export function withIconProps(
  IconComponent: React.ComponentType<IntrinsicSVGProps>
): React.FC<WithIconProps> {
  const Wrapped: FC<WithIconProps> = props => {
    const WithFillProp: FC<WithIconProps> = ({
      fill,
      size = 24,
      className,
      ...otherProps
    }) => {
      const fillClass = fill ? styles[fill] : '';
      return (
        <IconComponent
          className={classNames(fillClass, className)}
          fill={fillClass ? undefined : fill}
          height={size}
          {...otherProps}
        />
      );
    };

    return <WithFillProp {...props} />;
  };

  return Wrapped;
}
