/**
 * Return a promise that resolves when certain amount of milliseconds has
 * elapsed. This is used so we can wait the same duration as our css animation.
 * Will also optionally call a callback function at the same point.
 *
 * TODO: See if we can listen to `transitionend` event in a better way.
 * http://reactcommunity.org/react-transition-group/transition#Transition-prop-addEndListener
 */
export const wait = (duration: number, callback?: () => void): Promise<void> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
      if (callback) {
        callback();
      }
    }, duration);
  });
