import { Typography, TypographyProps } from '../Typography';
import { bodyStyles } from './styles';

/** the available body font sizes */
export const BodySizes = [21, 16, 12] as const;
/** The available body font weights */
export const BodyWeights = ['normal', 'bold'] as const;
/** The available body font styles */
export const BodyStyles = ['normal', 'italic'] as const;
/** The available body font colors */
export const BodyColors = [
  'blueberry-2',
  'carrot-2',
  'carrot-3',
  'charcoal-1',
  'charcoal-2',
  'charcoal-3',
  'kale-2',
  'white',
] as const;
/** The available body font size variant for responsive text */
export const BodySizeVariants = ['1', '2', '3'] as const;
/** The available body font weights for responsive text */
export const BodyFontWeights = ['roman', 'bold'] as const;

/**
 * The allowable property names and values for body text styles.
 */
export interface BodyProps extends TypographyProps {
  /** Set the body font color */
  color: (typeof BodyColors)[number];
  /** Set the body font size */
  size: (typeof BodySizes)[number];
  /** Set the body font weight */
  weight: (typeof BodyWeights)[number];
  /** Set the body font style */
  fontStyle: (typeof BodyStyles)[number];
}

export interface BodyResponsiveProps
  extends Omit<BodyProps, 'size' | 'weight'> {
  size: (typeof BodySizeVariants)[number];
  weight: (typeof BodyFontWeights)[number];
}

export const body = new Typography<BodyProps | BodyResponsiveProps>(
  bodyStyles,
  {
    weight: 'normal',
    fontStyle: 'normal',
    size: 21,
  }
);
