import classNames from 'classnames';

import styles from './getLineThroughDesign.css';

/** The properties of the line through design */
export interface LineThroughDesignProps {
  /** Is first in group */
  first?: boolean;
  /** Is last in group */
  last?: boolean;
}

/**
 * Return a class name the applies the line through design based on the line through design
 * props interface.
 */
export function getLineThroughDesign({
  first = false,
  last = false,
}: LineThroughDesignProps = {}): string {
  return classNames(styles.line, {
    [styles.first]: first,
    [styles.last]: last,
  });
}
