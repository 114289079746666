import { jwtDecode } from 'jwt-decode';

export const getTokenExpiration = (token: string) => {
  if (!token) {
    return;
  }
  const decoded = jwtDecode(token);
  let expires: Date = new Date();
  if (decoded.exp) {
    expires = new Date(decoded.exp * 1000);
  }
  return expires;
};
