import { track } from '../track';
import { landing_page_clicked_explore_studies_and_resources } from './eventNames';

/**
 * Send a tracking event for when a user clicks on explore studies & resources.
 */

export function trackLandingPageClickedExploreStudiesAndResources() {
  track({ eventName: landing_page_clicked_explore_studies_and_resources });
}
