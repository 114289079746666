import { Button } from '@farmersdog/corgi';
import { Text, Logo, PageWidth } from '@farmersdog/corgi-x';

import { EmailSupportButton } from '../EmailSupportButton';
import { browserOnly } from '@farmersdog/utils';

import styles from './ErrorPage.module.css';
import { ErrorMessage } from '../ErrorMessage';

interface ErrorPageProps {
  error: Error;
}

export function reloadLocation() {
  browserOnly(window => {
    window.location.reload();
  });
}

const ERROR_PAGE_TITLE = 'Our website is being a bad boy';
const ERROR_PAGE_MESSAGE =
  'We couldn’t process your request. While we are cleaning the mess, try to reload this page or contact our customer support.';

export function ErrorPage({ error }: ErrorPageProps) {
  return (
    <PageWidth className={styles.pageContainer}>
      <div className={styles.center}>
        <Logo />
      </div>
      <Text variant="heading-40" color="kale-3" className={styles.center} bold>
        {ERROR_PAGE_TITLE}
      </Text>
      <ErrorMessage error={error} errorMessage={ERROR_PAGE_MESSAGE} />
      <div className={styles.center}>
        <EmailSupportButton subject="Vet Team Portal Support">
          <Text variant="heading-16" bold>
            Contact Support
          </Text>
        </EmailSupportButton>
        <Button className={styles.button} onClick={reloadLocation}>
          Try Again
        </Button>
      </div>
    </PageWidth>
  );
}
