import {
  brandonMemberProfile,
  josephMemberProfile,
  maggieMemberProfile,
  raeMemberProfile,
  ryanMemberProfile,
} from '../../OurTeam/TeamMembers';

export const teamMembers = [
  brandonMemberProfile,
  ryanMemberProfile,
  josephMemberProfile,
  raeMemberProfile,
  maggieMemberProfile,
];
