import * as React from 'react';

import { ContentReveal } from '../ContentReveal';
import accessibilityStyles from '../../styles/accessibility.css';
import classNames from 'classnames';
import styles from './LiveMessage.css';

/**
 * The valid string values for the aria-live attribute. This is used to indicate
 * to screen readers how frequently they should announce new message content.
 */
export const AriaLiveAttributes = ['off', 'assertive', 'polite'] as const;

export interface LiveMessageProps {
  /** A required id for compliance with WCAG accessibility guidelines */
  id?: string;
  /** Whether to toggle the invalid message state */
  invalid?: boolean;
  /** Whether to visually show the message */
  hidden?: boolean;
  /**
   * Indicates that an element will be updated, and describes the types of
   * updates the user agents, assistive technologies, and user can expect from
   * the live region.
   */
  'aria-live': (typeof AriaLiveAttributes)[number];
  /** The message content */
  children?: React.ReactNode;
  /** Optional class name */
  className?: string;
}

/**
 * An WCAG compliant component for rendering messages whose content is dynamic.
 * It's content can be visually show or visually hidden. Commonly used for
 * inline error messages.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/statusmessage | StatusMessage} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const LiveMessage: React.FC<LiveMessageProps> = ({
  id,
  invalid,
  hidden = false,
  className,
  children,
  ...props
}) => {
  return (
    <ContentReveal inAnimation="fade" outAnimation="fade" in={!hidden}>
      <div
        id={id}
        role="status"
        className={classNames(className, styles.message, {
          [styles.invalid]: invalid,
          [accessibilityStyles.hidden]: hidden,
        })}
        {...props}
      >
        {children}
      </div>
    </ContentReveal>
  );
};
