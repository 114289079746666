import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Logo as LogoSVG } from '../Logo';
import { LinkLocationProps } from '../Link';
import { LOGO_SIZE } from './constants';

import styles from './Logo.css';

export type LogoProps = {
  translate: number;
  className?: string;
} & Partial<LinkLocationProps>;

export const Logo: FC<LogoProps> = ({
  to = '/',
  href,
  translate,
  className,
}) => {
  return (
    <div
      className={classNames(styles.logo, className)}
      style={{
        transform: `translateY(${translate}px)`,
      }}
    >
      {href ? (
        <a
          href={href}
          className={styles.link}
          aria-label="The Farmer's Dog Logo"
        >
          <LogoSVG size={LOGO_SIZE} mode="product" />
        </a>
      ) : (
        <Link
          to={to}
          className={styles.link}
          aria-label="The Farmer's Dog Logo"
        >
          <LogoSVG size={LOGO_SIZE} mode="product" />
        </Link>
      )}
    </div>
  );
};
