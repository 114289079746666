import * as React from 'react';

import { BorderLabel, useElementState } from '../BorderLabel';
import { WithRefProps, getHasValue, interceptEvent, withRef } from '../../util';

import classNames from 'classnames';
import { heading } from '../../typography';
import inputStyles from './Input.css';

const typography = heading.create({ size: 16 });

type IntrinsicInputProps = JSX.IntrinsicElements['input'];
type RefProps = WithRefProps<HTMLInputElement>;
export interface InputProps extends IntrinsicInputProps, RefProps {
  /** Label and placeholder of input */
  label?: string;
  /** Display invalid state */
  invalid?: boolean;
  /** Display highlighted state */
  highlighted?: boolean;
  /** The value of the input */
  value?: string | number | string[];
  /** Hide BorderLabel but keep it in the accessibility tree */
  hideLabel?: boolean;
}

export const InputComponent: React.FC<InputProps> = ({
  label,
  className,
  invalid,
  highlighted,
  onChange,
  onBlur,
  onFocus,
  forwardedRef,
  hideLabel = false,
  ...props
}) => {
  // autoFocus and disabled should be passed on to native element.
  const [state, handlers] = useElementState({
    autoFocus: props.autoFocus,
    disabled: props.disabled,
    invalid,
    highlighted,
  });

  const [value, setValue] = React.useState(props.value ?? '');

  React.useEffect(() => {
    if (props.value !== undefined && props.value !== null) {
      setValue(props.value);
    }
  }, [props.value, value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setValue(event.target.value);
  };

  return (
    <BorderLabel
      in={
        props.type === 'date' ||
        state === 'focus' ||
        state === 'invalid' ||
        getHasValue(value)
      }
      text={label || props.name || ''}
      state={state}
      className={className}
      hideLabel={hideLabel}
    >
      <input
        ref={forwardedRef}
        className={classNames(inputStyles.input, typography.className)}
        onChange={interceptEvent(handleChange, onChange)}
        onFocus={interceptEvent(handlers.onFocus, onFocus)}
        onBlur={interceptEvent(handlers.onBlur, onBlur)}
        aria-invalid={invalid}
        {...props}
        // Order is important, we want to overwrite value
        value={value}
        placeholder={undefined}
      />
    </BorderLabel>
  );
};

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/input | Input} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Input = withRef(InputComponent);
