import { Modal } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { LightboxId, useGlobalLightbox } from '../../../../hooks';

import styles from './TermsModal.module.css';

export const TermsModal = () => {
  const { rootProps, labelProps } = useGlobalLightbox({
    id: LightboxId.AreYouSubscribedTerms,
  });

  return (
    //@ts-expect-error types are wrong
    <Modal {...rootProps}>
      <Text
        bold
        as="h3"
        {...labelProps}
        topSpacing="none"
        bottomSpacing="md"
        color="blueberry-3"
        variant="heading-28"
        className={styles.title}
      >
        Terms and Conditions
      </Text>
      <Text variant="article-16" as="p">
        The discount cannot be used in conjunction with any other promotions,
        offers or discounts. The discount may be rescinded at any time, for any
        reason, in TFD’s sole discretion.
      </Text>
      <Text variant="article-16" as="p">
        The discount available for eligible veterinary professionals and support
        staff under this program can only be applied to purchases of The
        Farmer’s Dog, Inc. products. The discount cannot be redeemed for cash,
        cash equivalents, or any other monetary sum.
      </Text>
    </Modal>
  );
};
