import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { OrganizationDataSource } from '../../../graphql/types';
import { OrganizationType, SignupMode } from '../../../constants';

export function useSignupMode() {
  const location = useLocation();

  const signupMode = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return new URLSearchParams(params).get('practice');
  }, [location.search]);

  const organizationSource =
    signupMode === SignupMode.Search
      ? OrganizationDataSource.Google
      : OrganizationDataSource.User;

  const organizationType =
    signupMode !== SignupMode.None
      ? OrganizationType.Practice
      : OrganizationType.Other;

  return { signupMode, organizationSource, organizationType };
}
