import { createContext, useContext } from 'react';

interface ScreenSizeContextProps {
  isMobile: boolean;
  isDesktop: boolean;
}

export const ScreenSizeContext = createContext<ScreenSizeContextProps>({
  isMobile: false,
  isDesktop: false,
});

interface ScreenSizeContextProviderProps {
  children: React.ReactNode;
  screenSize: ScreenSizeContextProps;
}

export const ScreenSizeContextProvider = ({
  children,
  screenSize,
}: ScreenSizeContextProviderProps) => {
  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export const useScreenSizeContext = () => {
  const context = useContext(ScreenSizeContext);

  if (context === undefined) {
    throw new Error(
      'useScreenSizeContext must be used within a ScreenSizeContextProvider'
    );
  } else {
    return context;
  }
};
