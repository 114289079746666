import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Picture } from '../../../../components';

import { meals } from './assets';

import styles from './RequestSuccess.module.css';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';

interface RequestSuccessProps {
  onReturnToVetPortal: () => void;
}

export function RequestSuccess({ onReturnToVetPortal }: RequestSuccessProps) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <Grid className={styles.container} rowGap={{ xs: 'md', lg: 'lg' }}>
      <GridItem justifyContent="center">
        <Picture className={styles.picture} sources={meals} alt="pet meals" />
      </GridItem>
      <GridItem
        alignItems="center"
        className={styles.textsContainer}
        flexDirection="column"
      >
        <Text
          className={styles.title}
          variant="heading-28"
          color="blueberry-3"
          bold
        >
          50% discount requested!
        </Text>
        <Text
          className={styles.subtitle}
          variant="article-16"
          color="charcoal-3"
        >
          Thank you for signing up and all you do for pets! Our veterinary
          relations team will complete your verification process and send you an
          email in about a week when they’ve activated your professional
          discount.
        </Text>
      </GridItem>
      <GridItem justifyContent="center">
        <Button
          className={styles.button}
          onClick={onReturnToVetPortal}
          variant={
            showPortalUXOverhaul ? 'primary-mini-kale-experimental' : 'primary'
          }
        >
          Return to Vet Portal
        </Button>
      </GridItem>
    </Grid>
  );
}
