import { signup } from './eventNames';
import { track } from '../track';
import { trackGa4SignupSuccessEvent } from '../../vendors/ga4';

export interface TrackSignupValues {
  email: string;
  origin: string;
}

export function trackSignup({ email, origin }: TrackSignupValues) {
  track({
    eventName: signup,
    eventProps: {
      email,
      origin,
    },
  });

  trackGa4SignupSuccessEvent();
}
