import { useCallback, useId, useMemo, useState } from 'react';

import { ANIMATION_DURATION } from './Lightbox';
import { wait } from '../../util/wait';

export interface LightboxRootProps {
  id?: string;
  isOpen: boolean;
  role: string;
  'aria-modal': boolean;
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  tabIndex: number;
  close: () => void;
}
export interface LightboxControlReturn {
  isOpen: boolean;
  labelProps: { id?: string };
  descriptionProps: { id?: string };
  rootProps: LightboxRootProps;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface UseLightboxControlProps {
  /* The intial isOpen state of the control */
  initialIsOpen?: boolean;
  /* A callback function that is called when the lightbox isOpens */
  onOpen?: () => void;
  /* A callback function that is called when the lightbox closes */
  onClose?: () => void;
  /* A callback function that is called when the lightbox toggles */
  onToggle?: (isOpen: boolean) => void;
  /* An optional id for the lightbox element */
  id?: string;
}

/**
 * Hook for managing lightbox dialog state
 */
export const useLightboxControl = ({
  initialIsOpen = false,
  onClose,
  onOpen,
  onToggle,
  id: idProp,
}: UseLightboxControlProps = {}): LightboxControlReturn => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const reactId = useId();
  const id = idProp || reactId;

  const labelId = useId();
  const descriptionId = useId();

  const open = useCallback(() => {
    if (isOpen) return;

    setIsOpen(true);
    onToggle ? onToggle(true) : undefined;

    return wait(ANIMATION_DURATION, onOpen ? (): void => onOpen() : undefined);
  }, [onOpen, onToggle, isOpen]);

  const close = useCallback(() => {
    if (!isOpen) return;

    setIsOpen(false);
    onToggle ? onToggle(false) : undefined;

    return wait(
      ANIMATION_DURATION,
      onClose ? (): void => onClose() : undefined
    );
  }, [onClose, onToggle, isOpen]);

  const toggle = (): Promise<void> | void => {
    setIsOpen(!isOpen);
    onToggle ? onToggle(!isOpen) : undefined;

    if (!isOpen) {
      return wait(
        ANIMATION_DURATION,
        onOpen ? (): void => onOpen() : undefined
      );
    }

    if (isOpen) {
      return wait(
        ANIMATION_DURATION,
        onClose ? (): void => onClose() : undefined
      );
    }
  };

  const labelProps = useMemo(
    () => ({
      id: labelId,
    }),
    [labelId]
  );

  const descriptionProps = useMemo(
    () => ({
      id: descriptionId,
    }),
    [descriptionId]
  );

  const rootProps = useMemo(
    () => ({
      id,
      isOpen,
      role: 'dialog',
      'aria-modal': true,
      'aria-labelledby': labelId,
      'aria-describedby': descriptionId,
      tabIndex: -1,
      close,
    }),
    [id, isOpen, labelId, descriptionId, close]
  );

  return {
    isOpen,
    labelProps,
    descriptionProps,
    rootProps,
    // these return promises but don't declare that in the type
    // it's a large change to fix that so we'll just ignore it

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    open,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    close,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    toggle,
  };
};
