import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getMasterPracticeUrl } from '../utils';
import * as paths from '../constants/pagePaths';

export function useSignupPracticeSearchMode() {
  const history = useHistory();

  useEffect(() => {
    const { pathname, search } = history.location;
    if (!pathname.includes(paths.PATH_SIGNUP)) return;
    history.replace(
      getMasterPracticeUrl({ pathname, search, signupMode: 'search' })
    );
  }, [history]);

  return history;
}
