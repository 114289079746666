import { Typography, TypographyProps } from '../Typography';
import { articleStyles } from './styles';

/** The available article font sizes */
export const ArticleSizes = [21, 20, 16, 12] as const;
/** The available article font weights */
export const ArticleWeights = ['normal', 'bold'] as const;
/** The available article font styles */
export const ArticleStyles = ['normal', 'italic'] as const;
/** The available article font colors */
export const ArticleColors = [
  'blueberry-2',
  'carrot-2',
  'carrot-3',
  'charcoal-1',
  'charcoal-2',
  'charcoal-3',
  'kale-2',
  'white',
] as const;
/** The available article font size variant for responsive text */
export const ArticleSizeVariants = ['1', '2', '3'] as const;
/** The available article font weights for responsive text */
export const ArticleFontWeights = ['roman', 'bold'] as const;

/**
 * The allowable property names and values for article text styles.
 */
export interface ArticleProps extends TypographyProps {
  /** Set the article font color */
  color: (typeof ArticleColors)[number];
  /** Set the article font size */
  size: (typeof ArticleSizes)[number];
  /** Set the article font weight */
  weight: (typeof ArticleWeights)[number];
  /** Set the article font style */
  fontStyle: (typeof ArticleStyles)[number];
}

export interface ArticleResponsiveProps
  extends Omit<ArticleProps, 'size' | 'weight'> {
  size: (typeof ArticleSizeVariants)[number];
  weight: (typeof ArticleFontWeights)[number];
}

export const article = new Typography<ArticleProps | ArticleResponsiveProps>(
  articleStyles,
  {
    weight: 'normal',
    fontStyle: 'normal',
    size: 16,
  }
);
