import * as React from 'react';
import type stripeJs from '@stripe/stripe-js';

/** An interface representing the value of `ElementsContext` */
export interface ElementsContextValue {
  elements?: stripeJs.StripeElements;
}

const INITIAL_VALUE: ElementsContextValue = {};
export const ElementsContext = React.createContext(INITIAL_VALUE);
