import { useCallback, useState } from 'react';
import { PracticeSelector } from '../PracticeSelector/PracticeSelector';
import styles from './SignupForm.module.css';
import { PageTitle } from '../PageTitle';
import {
  Button,
  Toast,
  ActivityIndicator,
  Logo,
  Text,
} from '@farmersdog/corgi-x';
import {
  PersonalInformationConferenceFormValues,
  PersonalInformationFormValues,
} from './PersonalInformationForm/constants';
import { signUpWithNoSelectedPracticeFieldsData } from './SignUpFormWithNoSelectedPractice/constants';
import * as pagePaths from '../../constants/pagePaths';

import {
  CreateVetWithOrganizationMutation,
  FoundPractice,
  OrganizationInput,
  Position,
  ValidatePracticeQuery,
} from '../../graphql/types';
import { useValidatePractice } from '../../graphql/hooks/useValidatePractice';
import { getMasterPracticeUrl } from '../../utils';
import { useHistory } from 'react-router';
import {
  useQueryParameters,
  useScrollRestore,
  useSignUpAuthenticatedRedirect,
  useRestoreScrollOnModeChange,
  useRedirection,
} from '../../hooks';
import { useCreateVetWithOrganization } from '../../graphql/hooks/useCreateVetWithOrganization';
import { useAuthContext } from '../../context';
import { getCurrentEmailSession } from '../../services/auth';
import { handleCreateVetFailure } from './utils';
import { trackSignup } from '../../analytics/events';
import {
  defaultErrorMessage,
  getErrorMessage,
} from '../../services/auth/utils/errors';
import { handleUnexpectedAuthError } from '../../services/auth/utils/handleUnexpectedAuthError';
import omit from 'lodash/omit';
import { SignupMode, SignupSearchStep } from '../../constants';
import { SignUpFormWithNoSelectedPractice } from './SignUpFormWithNoSelectedPractice';
import { PersonalInformationForm } from './PersonalInformationForm';
import { PracticeInformation } from '../PracticeInformation';
import WillEatAnythingSVG from './assets/WillEatAnything.svg?react';
import classNames from 'classnames';
import { Logger } from '@farmersdog/logger';
import { FetchResult } from '@apollo/client';
import { useVetDetailsLazy } from '../../graphql/hooks';
import {
  useSignupMode,
  useSignupToast,
  usePersonalInfoForm,
  useOrganizationOrPracticeManualForm,
} from './hooks';
import { LoginSection } from './LoginSection';
import { TermsAndConditionsSection } from './TermsAndConditionsSection';
import { Card } from '../Card';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

const CANNOT_RETRIEVE_PRACTICE_ERROR =
  'We were unable to retrieve your practice. Please try again.';
const pageTitle = 'Vet Team Portal Registration';
const pageTitleConference = 'Vet Portal Registration';
const subtitle =
  'Register yourself to get access to useful info on canine nutrition, downloadable resources for your practice, and discounts on fresh food.';

export const DONE_SCREEN_TIMEOUT = 3000;

export interface CreateVetWithOrganizationArgs {
  personalInformation:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues;
  organization: OrganizationInput;
  password: string;
}

interface SignupFormProps {
  isConferenceModeOn?: boolean;
}

export function SignupForm({ isConferenceModeOn = false }: SignupFormProps) {
  const { vetDetailsQuery } = useVetDetailsLazy({ fetchPolicy: 'no-cache' });
  const history = useHistory();
  const push = useRedirection();
  const query = useQueryParameters();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const { restoreScroll } = useScrollRestore();
  useRestoreScrollOnModeChange();

  const { assertAuth, login, signup, logout, deleteUser, isAuthenticated } =
    useAuthContext();

  // will redirect users when successfully authenticated
  useSignUpAuthenticatedRedirect({
    isAuthenticated,
    isConferenceModeOn,
    logout,
  });

  const [step, setStep] = useState<SignupSearchStep>(SignupSearchStep.Search);
  const [selectedPractice, setSelectedPractice] = useState<FoundPractice>();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedPractice, setValidatedPractice] =
    useState<ValidatePracticeQuery['validatePractice']>();
  const { toast, showErrorToast, clearToast } = useSignupToast();

  const [searchResults, setSearchResults] = useState<FoundPractice[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { createVetWithOrganizationMutation } = useCreateVetWithOrganization();
  const { validatePractice, loading: validatePracticeLoading } =
    useValidatePractice();

  const { signupMode, organizationSource, organizationType } = useSignupMode();

  const onSelectedPracticeChange = useCallback(
    async (practice: FoundPractice, searchString: string) => {
      setSelectedPractice(practice);
      setSearchTerm(searchString);
      try {
        const { data } = await validatePractice({
          variables: {
            practice: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        if (!data?.validatePractice) {
          showErrorToast(CANNOT_RETRIEVE_PRACTICE_ERROR);
          return;
        }

        setValidatedPractice(data.validatePractice);
        setStep(SignupSearchStep.Selected);
      } catch {
        showErrorToast(CANNOT_RETRIEVE_PRACTICE_ERROR);
      }
    },
    [validatePractice, showErrorToast]
  );

  const signupFormNewPracticeFieldsData =
    signUpWithNoSelectedPracticeFieldsData({ isPractice: true });

  const signupFormNoPracticeFieldsData = signUpWithNoSelectedPracticeFieldsData(
    { isPractice: false }
  );

  const resetForms = () => {
    organizationOrPracticeManualForm.resetForm();
    personalInfoForm.resetForm();
    setSearchTerm('');
    setSearchResults([]);
    setSelectedPractice(undefined);
    setValidatedPractice(undefined);
    setStep(SignupSearchStep.Search);
  };

  const createVetWithOrganization = async ({
    personalInformation,
    organization,
    password,
  }: CreateVetWithOrganizationArgs) => {
    if (!signup) {
      throw new Error('No signup function provided');
    }
    if (!login) {
      throw new Error('No login function provided');
    }
    if (!logout) {
      throw new Error('No logout function provided');
    }
    if (!isConferenceModeOn) {
      if (!assertAuth) {
        throw new Error('No assertAuth function provided');
      }
    }

    setSubmitting(true);
    const { email } = personalInformation;
    const originParam = query.get('origin');
    const authPayload = { email, password };

    try {
      const currentEmailSession = await getCurrentEmailSession();

      if (!currentEmailSession) {
        await signup(authPayload);
        await login(authPayload);
      }

      const vetInput = {
        ...omit(personalInformation, 'password'),
        position: personalInformation.position as Position,
        needsPassword: isConferenceModeOn,
      };

      let data: FetchResult<CreateVetWithOrganizationMutation>['data'];

      try {
        const result = await createVetWithOrganizationMutation({
          variables: {
            vetInput,
            organizationInput: organization,
          },
        });
        data = result.data;
      } catch (error) {
        // if this also fails, the error will be caught by the outer catch block and log to our monitoring tool
        await handleCreateVetFailure({
          deleteUser,
          vetDetailsQuery,
        });
        throw error;
      }

      if (isConferenceModeOn) {
        await logout();
      }

      if (!data?.createVetWithOrganization) {
        setSubmitting(false);
        showErrorToast(defaultErrorMessage);
        return;
      }

      trackSignup({
        email: email,
        origin: originParam ?? '',
      });
    } catch (e) {
      setSubmitting(false);
      const message = getErrorMessage(e);
      showErrorToast(message);

      // default error message indicates an unexpected error
      if (message === defaultErrorMessage) {
        const logger = new Logger('auth:signup');
        handleUnexpectedAuthError({ error: e, logger, authPayload });
      }
      return;
    }
    if (isConferenceModeOn) {
      resetForms();
      setSubmitted(true);
      history.replace(
        getMasterPracticeUrl({
          pathname: history.location.pathname,
          search: history.location.search,
          signupMode: SignupMode.Search,
        })
      );
      restoreScroll();
      setSubmitting(false);
      setTimeout(() => {
        setSubmitted(false);
      }, DONE_SCREEN_TIMEOUT);
      clearToast();
    } else {
      // push registered user to logged in account
      if (assertAuth) {
        void assertAuth();
      }

      /**
       * TODO: this could be removed in the future
       *
       * This is explicitly redirecting users at the end of their
       * sign up flow but useSignUpAuthenticatedRedirect will redirect
       * authenticated users any time that isAuthenticated updates on AuthContext.
       *
       * So in theory this redirect is redundant and needs to be cleanup up,
       * but we will hold off on this change until a larger authenticated redirect refactor.
       *
       */
      push({
        noRedirectPathname: pagePaths.PATH_DASHBOARD,
      });
    }
  };

  const { personalInfoForm, personalInfoFormFields } = usePersonalInfoForm({
    isConferenceModeOn,
    dataToSubmit: {
      source: organizationSource,
      type: organizationType,
      validatedPractice,
    },
    submit: createVetWithOrganization,
  });

  const { organizationOrPracticeManualForm } =
    useOrganizationOrPracticeManualForm({
      personalInfoFormValues: personalInfoForm.values,
      isConferenceModeOn,
      dataToSubmit: { source: organizationSource, type: organizationType },
      submit: createVetWithOrganization,
    });

  const renderSignupForm = () => {
    if (signupMode === SignupMode.Add) {
      return (
        <SignUpFormWithNoSelectedPractice
          fieldData={signupFormNewPracticeFieldsData}
          form={organizationOrPracticeManualForm}
          isConferenceModeOn={isConferenceModeOn}
          title="Add Veterinary Practice"
        >
          <PersonalInformationForm
            fieldData={personalInfoFormFields}
            form={personalInfoForm}
            isConferenceModeOn={isConferenceModeOn}
            withForm={false}
          />
        </SignUpFormWithNoSelectedPractice>
      );
    }
    if (signupMode === SignupMode.None) {
      return (
        <SignUpFormWithNoSelectedPractice
          title="Add Organization"
          fieldData={signupFormNoPracticeFieldsData}
          form={organizationOrPracticeManualForm}
          isConferenceModeOn={isConferenceModeOn}
        >
          <PersonalInformationForm
            fieldData={personalInfoFormFields}
            form={personalInfoForm}
            withForm={false}
            isConferenceModeOn={isConferenceModeOn}
          />
        </SignUpFormWithNoSelectedPractice>
      );
    }
    if (step === SignupSearchStep.Search)
      return (
        <PersonalInformationForm
          isConferenceModeOn={isConferenceModeOn}
          fieldData={personalInfoFormFields}
          form={personalInfoForm}
        >
          {validatePracticeLoading ? (
            <div className={styles.loadingPractice}>
              <ActivityIndicator mode="dark" />
            </div>
          ) : (
            <PracticeSelector
              selectedPractice={selectedPractice}
              onPracticeSelected={onSelectedPracticeChange}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              withNotAssociated
            />
          )}
        </PersonalInformationForm>
      );

    if (step === SignupSearchStep.Selected && validatedPractice) {
      return (
        <PersonalInformationForm
          fieldData={personalInfoFormFields}
          form={personalInfoForm}
          isConferenceModeOn={isConferenceModeOn}
        >
          <PracticeInformation
            name={validatedPractice?.addressComponents?.name}
            address={validatedPractice.formattedAddress ?? ''}
            onUpdate={() => {
              setStep(SignupSearchStep.Search);
            }}
          />
        </PersonalInformationForm>
      );
    }

    return null;
  };

  if (submitting) {
    return (
      <div className={styles.loading}>
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}
        <div className={styles.activityIndicator}>
          <ActivityIndicator mode="dark" />
        </div>
      </div>
    );
  }
  if (submitted) {
    return (
      <div className={styles.submitted}>
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}

        <div className={styles.logoContainer}>
          <div className={styles.willEatAnything}>
            <WillEatAnythingSVG />
          </div>
          <Text as="h4" variant="heading-40" bold>
            Thanks for registering!
          </Text>
          <Button
            variant="secondary"
            onClick={() => {
              setSubmitted(false);
            }}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }

  const personalFormIsNotComplete =
    !personalInfoForm.dirty || !personalInfoForm.isValid;

  const manualFormIsNotComplete =
    !organizationOrPracticeManualForm.dirty ||
    !organizationOrPracticeManualForm.isValid;

  const hasNotSelectedPractice = SignupSearchStep.Selected !== step;

  const innerForm = (
    <>
      <div className={styles.formsContainer}>
        {renderSignupForm()}
        <div className={styles.termsAndConditions}>
          <TermsAndConditionsSection personalInfoForm={personalInfoForm} />
        </div>
      </div>
      <div className={styles.registerButtonContainer}>
        <Button
          variant={
            showPortalUXOverhaul ? 'primary-mini-kale-experimental' : 'primary'
          }
          type="submit"
          form="master-search-form"
          className={styles.submitButton}
          aria-disabled={
            ((hasNotSelectedPractice || personalFormIsNotComplete) &&
              (!hasNotSelectedPractice ||
                manualFormIsNotComplete ||
                personalFormIsNotComplete)) ||
            submitting
          }
        >
          Register
        </Button>
      </div>
    </>
  );

  const renderSignupFormWithDynamicCard = showPortalUXOverhaul ? (
    <Card className={styles.signupFormCard}>{innerForm}</Card>
  ) : (
    <>{innerForm}</>
  );
  return (
    <>
      <Toast {...toast} autoClose onClose={clearToast} />
      <div
        className={
          showPortalUXOverhaul ? styles.container : styles.containerLegacy
        }
      >
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}
        <div
          className={classNames(styles.titleContainer, {
            [styles.addSpacingLegacy]: !isConferenceModeOn,
            [styles.addSpacing]: showPortalUXOverhaul,
          })}
        >
          <PageTitle
            title={isConferenceModeOn ? pageTitleConference : pageTitle}
            subtitle={isConferenceModeOn ? '' : subtitle}
            titleColor={showPortalUXOverhaul ? 'blueberry-3' : undefined}
          />
        </div>

        {renderSignupFormWithDynamicCard}
      </div>
      {!isConferenceModeOn && <LoginSection />}
    </>
  );
}
