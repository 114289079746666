import { track } from '../track';
import { landing_page_clicked_register_for_vet_team_portal_have_more_questions } from './eventNames';

/**
 * Send a tracking event for when a user clicks on Register for Vet Team Portal in the have more questions section.
 */

export function trackLandingPageClickedRegisterForVetTeamPortalHaveMoreQuestions() {
  track({
    eventName:
      landing_page_clicked_register_for_vet_team_portal_have_more_questions,
  });
}
