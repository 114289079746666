import { Text, Button, PageWidth } from '@farmersdog/corgi-x';
import gif from '../assets/dog.gif';
import * as pagePaths from '../../../constants/pagePaths';

import styles from './FAQSection.module.css';
import { trackLandingPageClickedRegisterForVetTeamPortalHaveMoreQuestions } from '../../../analytics/events';

interface ResponsiveFAQSectionProps {
  isMobile?: boolean;
}

export function ResponsiveFAQSection({ isMobile }: ResponsiveFAQSectionProps) {
  return (
    <PageWidth>
      <Text
        bold
        as="h2"
        topSpacing="none"
        color="blueberry-3"
        variant={isMobile ? 'heading-28' : 'heading-52'}
        className={styles.title}
      >
        <img className={styles.dog} src={gif} alt="animated dog" />
        Review our FAQs
      </Text>
      <Text
        as="p"
        topSpacing="none"
        bottomSpacing="lg"
        color="charcoal-3"
        variant={isMobile ? 'article-16' : 'article-20'}
        className={styles.description}
      >
        We’d love to tell you more. Register for frequently asked questions from
        fellow vet professionals, quick responses to all your nutrition
        questions, and other useful resources for your practice.
      </Text>
      <Button
        type="link"
        to={pagePaths.PATH_FAQ}
        className={styles.button}
        onClick={() => {
          trackLandingPageClickedRegisterForVetTeamPortalHaveMoreQuestions();
        }}
      >
        Access Vet Team Portal
      </Button>
    </PageWidth>
  );
}

export function FAQSection() {
  return (
    <section className={styles.section}>
      <div className={styles.mobile}>
        <ResponsiveFAQSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveFAQSection />
      </div>
    </section>
  );
}
