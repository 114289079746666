import colors from './colors.css';
import fontStyles from './fontStyles.css';
import sizes from './sizes.css';
import base from './base.css';
import weights from './weights.css';

export const articleStyles = {
  colors,
  fontStyles,
  sizes,
  base,
  weights,
};
