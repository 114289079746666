import * as Apollo from '@apollo/client';

import {
  AddDiscountRequestMutation,
  AddDiscountRequestMutationVariables,
} from '../types';
import addDiscountRequest from '../mutations/add-discount-request.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  AddDiscountRequestMutation,
  AddDiscountRequestMutationVariables
>;

export function useAddDiscountRequest(options?: Options) {
  return useMutation<
    AddDiscountRequestMutation,
    AddDiscountRequestMutationVariables
  >(addDiscountRequest, options);
}
