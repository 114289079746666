export enum DiscountRequestState {
  AreYouSubscribed = 'AreYouSubscribed',
  HowToGetDiscount = 'HowToGetDiscount',
  EnterEmail = 'EnterEmail',
  ReviewInfo = 'ReviewInfo',
  ReviewInfoWithUpdatedPractice = 'ReviewInfoWithUpdatedPractice',
  PracticeSearch = 'PracticeSearch',
  AddPractice = 'AddPractice',
  RequestSuccess = 'RequestSuccess',
  Close = 'Close',
}
