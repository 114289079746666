import React, { useId } from 'react';
import type stripeJs from '@stripe/stripe-js';

import pick from 'lodash/pick';
import { useSafeOptions } from '../../hooks/useSafeOptions';

import { useElements } from './useElements';
import { getElement, create, ValidElement } from './utils';
import { elementTypes } from './elementTypes';

/**
 * Allowed options differ based on element type. These are the options that are
 * valid based on the element types supported in this module
 *
 * {@link https://stripe.com/docs/stripe-js/reference#element-options}
 */
const ValidElementOptions = [
  'classes',
  'style',
  'disabled',
  'placeholder',
] as const;

export type ChangeEvent =
  | stripeJs.StripeCardNumberElementChangeEvent
  | stripeJs.StripeCardCvcElementChangeEvent
  | stripeJs.StripeCardExpiryElementChangeEvent;

/**
 * An interface based partially on the options available to stripe elements.
 * This component only supports a subset of elements so the only a corresponding
 * subset of options are supported. For more details please refer the the
 * Stripe.js documentation
 *
 * {@link https://stripe.com/docs/stripe-js/reference#element-options}
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/api/functions/CreditCardInput | CreditCardInput} from corgi-x to create card fields. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export interface StripeElementProps {
  /** Name of the element type to create from Stripe.js documentation */
  elementType: elementTypes;
  /** An optional id for the stripe element container */
  id?: string;
  /** An optional className for the stripe element container */
  className?: string;
  /** Handler function to call when the element changes */
  onChange?: (event: ChangeEvent, element: ValidElement) => void;
  /** Handler function to call when the element blurs */
  onBlur?: (element: ValidElement) => void;
  /** Handler function to call when the element receives focus */
  onFocus?: (element: ValidElement) => void;
  /** Handler function to call when the element is ready */
  onReady?: (element: ValidElement) => void;
  /** Stripe.js option passed to interior element as css classes */
  classes?: stripeJs.StripeElementClasses;
  /** Stripe.js option passed to interior element as inline style */
  style?: stripeJs.StripeElementStyle;
  /** Stripe.js option passed to interior element as disabled attribute */
  disabled?: boolean;
  /** Stripe.js option passed to interior element as placeholder attribute */
  placeholder?: string;
}

/**
 * Create a stripe element. Must be used inside the context of a
 * `StripeProvider` and an `ElementsProvider`
 */
export const StripeElement: React.FC<StripeElementProps> = ({
  className,
  elementType,
  onChange,
  onBlur,
  onFocus,
  onReady,
  ...props
}) => {
  const { elements } = useElements();
  const [element, setElement] = React.useState<ValidElement | null>();

  const reactId = useId();
  const id = props.id || reactId;

  const elementId = id ? `${elementType}-${id}` : undefined;
  const safeOptions = useSafeOptions(pick(props, ValidElementOptions));

  /**
   * Create a stripe element or update an existing stripe element. If the
   * element already exists we can update the options. Each element must belong
   * to an elements set that is created by the StripeContext.
   */
  React.useEffect(() => {
    if (!elements) {
      return;
    }

    const cachedElement = getElement(elements, elementType);

    if (!element && cachedElement) {
      cachedElement.clear();
      setElement(cachedElement);
      return;
    }

    if (!element) {
      setElement(create(elements, elementType, safeOptions));
      return;
    }

    element.update(safeOptions);
  }, [elements, element, elementType, safeOptions]);

  /*
   * After an element is created in-memory it must be mounted to the dom. This
   * is where the actual iframe is added to the dom.
   */
  React.useEffect(() => {
    if (!elementId || !element) {
      return;
    }

    // Note: using `#${elementId}` as a selector here throws an error because
    // the element id contains characters that cannot be used in a selector
    const domElement = document.getElementById(elementId);
    if (domElement) {
      element.mount(domElement);
    }

    return (): void => element.unmount();
  }, [element, elementId]);

  /**
   * To support react style handler functions they must be stored inside a
   * mutable ref. This is so we only attach a handler once inside each iframe
   * element.
   */
  const handlers = React.useRef({
    onChange,
    onBlur,
    onFocus,
    onReady,
  });

  React.useEffect(() => {
    handlers.current = {
      onChange,
      onBlur,
      onFocus,
      onReady,
    };
  });

  /**
   * These handlers element.on('event') get attached inside the iframe. In order
   * to use react style syntax here we must attach a single handler for each
   * event and then call the current available handler that is passed in as a
   * prop. This works because we do not need to re-render or update anything
   * when these handlers change. Stripe doesn't even allow us to update them and
   * this is much more performative.
   */
  React.useEffect(() => {
    if (!element) {
      return;
    }

    /**
     * The type definitions for element are very strange as they can one of many
     * types. We enforce our types at runtime so we can safely ignore this
     * error.
     */
    // @ts-ignore
    element.on('change', (event: ChangeEvent): void => {
      if (handlers.current.onChange) {
        handlers.current.onChange(event, element);
      }
    });

    // @ts-ignore
    element.on('ready', () => {
      if (handlers.current.onReady) {
        handlers.current.onReady(element);
      }
    });

    // @ts-ignore
    element.on('blur', () => {
      if (handlers.current.onBlur) {
        handlers.current.onBlur(element);
      }
    });

    // @ts-ignore
    element.on('focus', () => {
      if (handlers.current.onFocus) {
        handlers.current.onFocus(element);
      }
    });
  }, [element, handlers]);

  return <div id={elementId} data-name={elementType} className={className} />;
};
