import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import {
  useConnectLinks,
  useContactLinks,
  useSupportLinks,
} from './useContactLinks';
import {
  joinUsLinks,
  navigationLinks,
  navigationVetPortalLinks,
} from './navigation-links';

import styles from './LinkItems.module.css';
import { useLocation } from 'react-router-dom';
import { PATH_APP } from '../../../constants';
import classNames from 'classnames';

export function JoinUsLinksList() {
  return (
    <ul className={styles.list} aria-label="Join Us">
      {joinUsLinks.map((link, index) => {
        const { label, to } = link;

        return (
          <li className={styles.item} key={index}>
            <Text as="span" variant="heading-16" color="white">
              <Link to={to} variant="secondary" mode="light">
                {label}
              </Link>
            </Text>
          </li>
        );
      })}
    </ul>
  );
}

interface NavigationLinksListProps {
  isAccountPage?: boolean;
}

export function NavigationLinksList({
  isAccountPage = false,
}: NavigationLinksListProps) {
  return (
    <ul className={styles.list} aria-label="The Farmer's Dog">
      {(isAccountPage ? navigationVetPortalLinks : navigationLinks).map(
        (link, index) => {
          const { label, href, to, ...linkProps } = link;
          return (
            <li className={styles.item} key={index}>
              <Text as="span" variant="heading-16" color="white">
                <Link
                  variant="secondary"
                  mode="light"
                  rel="noopener noreferrer"
                  {...linkProps}
                  {...(isAccountPage ? { to } : { href, target: '_blank' })}
                >
                  {label}
                </Link>
              </Text>
            </li>
          );
        }
      )}
    </ul>
  );
}

export function ContactLinksList() {
  const location = useLocation();
  const isAccountPage = location.pathname.startsWith(PATH_APP);
  const contactLinks = useContactLinks({ isAccountPage });

  return (
    <ul className={styles.list} aria-label="Contact and Support">
      {contactLinks.map((link, index) => {
        if (!link) {
          return null;
        }

        const { label, icon: Icon, ...linkProps } = link;

        return (
          <li className={styles.item} key={index}>
            <Text as="span" variant="heading-16" color="white">
              <Link
                variant="secondary"
                mode="light"
                {...linkProps}
                className={styles.contactLink}
              >
                {Icon && <Icon className={styles.icon} />} {label}
              </Link>
            </Text>
          </li>
        );
      })}
    </ul>
  );
}
interface SupportLinksListProps {
  isAccountPage?: boolean;
}

export function SupportLinksList({
  isAccountPage = false,
}: SupportLinksListProps) {
  const contactLinks = useSupportLinks({ isAccountPage });

  return (
    <ul className={styles.list} aria-label="Contact and Support">
      {contactLinks.map((link, index) => {
        if (!link) {
          return null;
        }

        const { label, icon: Icon, ...linkProps } = link;

        return (
          <li className={styles.item} key={index}>
            <Text as="span" variant="heading-16" color="white">
              <Link
                variant="secondary"
                mode="light"
                {...linkProps}
                className={styles.contactLink}
              >
                {Icon && <Icon className={styles.icon} />} {label}
              </Link>
            </Text>
          </li>
        );
      })}
    </ul>
  );
}
export function ConnectLinksList() {
  const contactLinks = useConnectLinks();

  return (
    <>
      <ul className={styles.list} aria-label="Inquiries">
        {contactLinks?.inquiries.map((link, index) => {
          if (!link) {
            return null;
          }

          const { label, icon: Icon, ...linkProps } = link;
          return (
            <li className={styles.item} key={index}>
              <Text as="span" variant="heading-16" color="white">
                <Link
                  variant="secondary"
                  mode="light"
                  {...linkProps}
                  className={styles.contactLink}
                >
                  {Icon && <Icon className={styles.icon} />} {label}
                </Link>
              </Text>
            </li>
          );
        })}
      </ul>
      <ul
        className={classNames(styles.list, styles.socialList)}
        aria-label="Social"
      >
        {contactLinks?.social.map((link, index) => {
          if (!link) {
            return null;
          }

          const { label, icon: Icon, ...linkProps } = link;
          return (
            <li className={styles.item} key={index}>
              <Link
                variant="secondary"
                mode="light"
                {...linkProps}
                className={styles.contactLink}
              >
                {Icon && <Icon className={styles.icon} />} {label}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
