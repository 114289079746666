// explicitly allowing the import of track from @farmersdog/analytics in this file
// eslint-disable-next-line no-restricted-imports
import { track as segmentTrack } from '@farmersdog/analytics';
import { getMainSiteUserId } from './cookies';

interface TrackProps {
  eventName: string;
  eventProps?: Record<string, unknown>;
}

export function track({ eventName, eventProps = {} }: TrackProps) {
  const main_site_user_id = getMainSiteUserId();
  segmentTrack(eventName, {
    main_site_user_id,
    ...eventProps,
  });
}
