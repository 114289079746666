import styles from './ContactUs.module.css';

import { ContactUsResponsive } from './ContactUsResponsive';

export const ContactUs = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ContactUsResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <ContactUsResponsive isMobile={false} />
      </div>
    </>
  );
};
