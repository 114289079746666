import * as Apollo from '@apollo/client';

import {
  CreateVetWithOrganizationMutationVariables,
  CreateVetWithOrganizationMutation,
} from '../types';
import createVetWithOrganization from '../mutations/create-vet-with-organization.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  CreateVetWithOrganizationMutation,
  CreateVetWithOrganizationMutationVariables
>;

export function useCreateVetWithOrganization(options?: Options) {
  const [createVetWithOrganizationMutation, { loading, error }] = useMutation<
    CreateVetWithOrganizationMutation,
    CreateVetWithOrganizationMutationVariables
  >(createVetWithOrganization, options);

  return { createVetWithOrganizationMutation, loading, error };
}
