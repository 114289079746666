import * as Apollo from '@apollo/client';

import { UpdateVetMutation, UpdateVetMutationVariables } from '../types';
import updateVet from '../mutations/update-vet.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  UpdateVetMutation,
  UpdateVetMutationVariables
>;

export function useUpdateVet(options?: Options) {
  return useMutation<UpdateVetMutation, UpdateVetMutationVariables>(
    updateVet,
    options
  );
}
