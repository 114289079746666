import {
  beef,
  chicken,
  chickenAndGrain,
  pork,
  porkAndGrain,
  turkey,
} from './images/sources';

import BeefRecipe from '../../../../static/pdf/BeefRecipe.pdf';
import PorkRecipe from '../../../../static/pdf/PorkRecipe.pdf';
import TurkeyRecipe from '../../../../static/pdf/TurkeyRecipe.pdf';
import ChickenRecipe from '../../../../static/pdf/ChickenRecipe.pdf';
import ChickenAndGrainRecipe from '../../../../static/pdf/ChickenAndGrainRecipe.pdf';
import PorkAndGrainRecipe from '../../../../static/pdf/PorkAndGrainRecipe.pdf';

export interface Recipe {
  alt: string;
  title: string;
  pdf: string;
  availableDate?: string;
  dietIndications: string[];
  contraIndications: string[];
  images: string[];
}

export const grains: Recipe[] = [
  {
    alt: 'Chicken & Grain Recipe',
    title: 'Chicken & Grain Recipe',
    pdf: ChickenAndGrainRecipe,
    images: chickenAndGrain,
    dietIndications: [
      'Cardiac support',
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Urolithiasis (struvite & calcium oxalate)',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
  {
    alt: 'Pork & Grain Recipe',
    title: 'Pork & Grain Recipe',
    pdf: PorkAndGrainRecipe,
    images: porkAndGrain,
    availableDate: 'Summer 2024',
    dietIndications: [
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Urolithiasis (struvite & calcium oxalate)',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
];

export const farmersDogMeals: Recipe[] = [
  {
    alt: 'Beef Recipe',
    title: 'Beef Recipe',
    pdf: BeefRecipe,
    images: beef,
    dietIndications: [
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Urolithiasis (struvite & calcium oxalate)',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
  {
    alt: 'Turkey Recipe',
    title: 'Turkey Recipe',
    pdf: TurkeyRecipe,
    images: turkey,
    dietIndications: [
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Aid in prevention of calcium oxalate urolithiasis',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs predisposed to struvite urolithiasis',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
  {
    alt: 'Pork Recipe',
    title: 'Pork Recipe',
    pdf: PorkRecipe,
    images: pork,
    dietIndications: [
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Urolithiasis (struvite & calcium oxalate)',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
  {
    alt: 'Chicken Recipe',
    title: 'Chicken Recipe',
    pdf: ChickenRecipe,
    images: chicken,
    dietIndications: [
      'Healthy adult dogs or puppies',
      'Weight management (loss & maintenance)',
      'Allergies (limited ingredient base and controlled allergen manufacturing conditions)',
      'Urolithiasis (struvite & calcium oxalate)',
      'Gastrointestinal disorders (highly digestible)',
      'Primary/secondary hyporexia.',
    ],
    contraIndications: [
      'Dogs who require low-fat diets (pancreatitis, hyperlipidemia, and lymphangiectasia)',
      'Dogs with kidney disease',
      'Dogs with protein intolerance (hepatic encephalopathy or urate urolithiasis)',
    ],
  },
];
