import { useState } from 'react';
import { Banner, Button, Text, IconButton } from '@farmersdog/corgi-x';
import { Close } from '@farmersdog/corgi-x/icons';
import { paths } from '@farmersdog/constants';
import { getAbsoluteLink } from '../../../utils';
import {
  getCustomerAccountLogInNoticeDismissed,
  setCustomerAccountLogInNoticeDismissed,
} from '../../../utils/cookies/customerAccountLogInNotice';
import {
  trackDismissedCustomerAccountLogInNotice,
  trackClickedCustomerAccountLogIn,
} from '../../../analytics/events';
import styles from './AccountBanner.module.css';

export const AccountBanner = () => {
  const hasDismissedCustomerAccountLogInNotice =
    getCustomerAccountLogInNoticeDismissed() === 'true';
  const [dismissed, setDismissed] = useState(
    hasDismissedCustomerAccountLogInNotice
  );
  const onDismiss = () => {
    setDismissed(true);
    setCustomerAccountLogInNoticeDismissed();
    trackDismissedCustomerAccountLogInNotice();
  };

  if (dismissed) {
    return null;
  }

  return (
    <Banner variant="chickpea-0" className={styles.banner}>
      <div>
        <Text variant="heading-16" color="Blueberry3">
          Looking to manage your <strong>customer account</strong> plan and
          preferences?{' '}
          <Button
            variant="plain-text"
            type="anchor"
            href={getAbsoluteLink(paths.PATH_LOGIN)}
            target="_blank"
            color="kale"
            id="go-to-customer-login-btn"
            underline
            onClick={trackClickedCustomerAccountLogIn}
          >
            <Text variant="heading-16" bold>
              Log in here
            </Text>
          </Button>
        </Text>
      </div>
      <div className={styles.closeButton}>
        <CloseButton onDismiss={onDismiss} />
      </div>
    </Banner>
  );
};

interface CloseButtonProps {
  onDismiss: () => void;
}

function CloseButton({ onDismiss }: CloseButtonProps) {
  return (
    <IconButton
      icon={<Close baseColor="Charcoal3" size={28} />}
      aria-label="Close"
      onClick={onDismiss}
    />
  );
}
