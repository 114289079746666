import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function useSignUpBanner() {
  const signUpBanner = useFeature<AnonymousFeatures, UserFeatures, FeatureName>(
    FeatureName.HEALTH_VET_PORTAL_REGISTRATION_BANNER,
    {}
  );

  const showSignUpBanner = signUpBanner.treatment === OnOffTreatments.on;

  return {
    showSignUpBanner,
  };
}
