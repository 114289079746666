import { useHistory } from 'react-router';

import { Button, ResponsiveText } from '@farmersdog/corgi';
import { PublicPage, Picture } from '../../components';

import { diggingDogSources } from './assests';

import { PATH_HOME } from '../../constants';

import styles from './NotFound.module.css';

export const NotFound = () => {
  const history = useHistory();
  return (
    <PublicPage>
      <section className={styles.section}>
        <Picture sources={diggingDogSources} alt="digging dog" />
        <ResponsiveText
          className={styles.heading}
          variant="heading-1"
          color="kale-3"
          as="h1"
        >
          This page can’t be found.
        </ResponsiveText>
        <ResponsiveText
          className={styles.text}
          variant="heading-1"
          color="charcoal-3"
          as="h3"
        >
          We’ll dig into it, but try going back for now.
        </ResponsiveText>
        <div className={styles.cta}>
          <Button
            variant="primary"
            className={styles.button}
            onClick={() => history.push(PATH_HOME)}
          >
            Back to Homepage
          </Button>
        </div>
      </section>
    </PublicPage>
  );
};
