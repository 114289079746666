import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import { AccountPage } from '../../components/AccountPage';
import { VetDiscount } from './VetDiscount';
import styles from './Account.module.css';
import { AttestationPrompt } from './AttestationPrompt';
import { ResponsiveProps } from '../../constants';
import { AccountLegacy } from './AccountLegacy';
import { Education } from './Education';
import { Referrals } from './Referrals';
import { Support } from './Support';
import { TeamSupport } from './TeamSupport/TeamSupport';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { ResourcePreview } from '../Resources/ResourcesContent/ResourcePreview/ResourcePreview';
import { usePdfRedirects } from '../../abTesting/PublicPageFeatures/featureHooks/usePdfRedirects';
import { AccountBanner } from './AccountBanner';

export const AccountResponsive = ({ isMobile }: ResponsiveProps) => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  const { isPdfRedirectsActive } = usePdfRedirects();
  const { data, loading } = useVetDetails();

  const vetData = data?.vetDetails?.vet;

  const firstName = vetData?.firstName || '';
  const discountRequests = vetData?.discountRequests || [];
  const hasDiscountRequests = discountRequests.length > 0;

  if (!showPortalUXOverhaul) {
    return <AccountLegacy />;
  }
  if (loading) {
    return null;
  }

  if (vetData && !vetData.attestedAtSignup) {
    return <AttestationPrompt vetDetails={data.vetDetails} />;
  }

  return (
    <>
      <AccountPage accountBanner={<AccountBanner />}>
        <Grid>
          <GridItem>
            <Text
              as="p"
              variant={isMobile ? 'article-16' : 'article-20'}
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="xs"
            >
              Welcome{firstName ? `, ${firstName}!` : '!'}
            </Text>
            <Text
              as="h2"
              variant={isMobile ? 'heading-28' : 'heading-40'}
              bold
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="none"
            >
              The Farmer’s Dog Vet Team Portal
            </Text>
          </GridItem>
          <Grid className={styles.container} rowGap="xl" topSpacing="xl">
            {!hasDiscountRequests && (
              <GridItem>
                <VetDiscount hasDiscountRequests={hasDiscountRequests} />
              </GridItem>
            )}
            {isPdfRedirectsActive && <ResourcePreview isMobile={isMobile} />}
            <GridItem>
              <Education />
            </GridItem>
            <GridItem>
              <Referrals />
            </GridItem>
            <GridItem>
              <Support />
            </GridItem>
            <GridItem>
              <TeamSupport />
            </GridItem>
          </Grid>
        </Grid>
      </AccountPage>
    </>
  );
};

export const Account = () => {
  return (
    <>
      <div className={styles.mobile}>
        <AccountResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <AccountResponsive isMobile={false} />
      </div>
    </>
  );
};
