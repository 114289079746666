import { Text } from '@farmersdog/corgi-x';

import CheckIcon from '../../assets/Check.svg?react';

import styles from './Reason.module.css';

interface ReasonProps {
  title: string;
  description: string;
  ariaLabel?: string;
}

interface ResponsiveReasonProps extends ReasonProps {
  isMobile?: boolean;
}

export function ResponsiveReason({
  isMobile,
  title,
  ariaLabel,
  description,
}: ResponsiveReasonProps) {
  return (
    <div className={styles.container}>
      <Text variant={isMobile ? 'heading-16' : 'heading-22'} color="white" bold>
        <CheckIcon className={styles.icon} aria-label={ariaLabel} />
        {title}
      </Text>

      <Text variant="article-16" color="white">
        {description}
      </Text>
    </div>
  );
}

export function Reason({ title, description, ariaLabel }: ReasonProps) {
  return (
    <>
      <div className={styles.mobile}>
        <ResponsiveReason
          isMobile
          title={title}
          description={description}
          ariaLabel={ariaLabel}
        />
      </div>
      <div className={styles.desktop}>
        <ResponsiveReason
          title={title}
          description={description}
          ariaLabel={ariaLabel}
        />
      </div>
    </>
  );
}
