import { track } from '../track';
import { contact_us_clicked_lunch_and_learn_request } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Request Lunch and Learn" button on the events & recordings page
 */

export function trackContactUsClickedLunchAndLearnRequest() {
  track({
    eventName: contact_us_clicked_lunch_and_learn_request,
  });
}
