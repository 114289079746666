import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  BannerTreatments,
  BannerConfig,
} from '../../features';

export function useVetPortalBanner() {
  const vetPortalBanner = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_VET_PORTAL_BANNER, {});

  const showPortalBanner =
    vetPortalBanner.treatment === BannerTreatments.show_banner;
  const bannerConfig = vetPortalBanner.config as BannerConfig;

  return {
    showPortalBanner,
    bannerConfig,
  };
}
