import { Button, Picture, Text } from '@farmersdog/corgi-x';

import { Card } from '../../../components';

import config from '../../../config';

import nutrientBackgroundDesktopJpg from '../assets/images/nutrientsBackgroundDesktop.jpg';
import nutrientBackgroundDesktopWebp from '../assets/images/nutrientsBackgroundDesktop.webp';
import nutrientBackgroundMobileWebp from '../assets/images/nutrientBackgroundMobile.webp';
import nutrientBackgroundMobileJpg from '../assets/images/nutrientBackgroundMobile.jpg';

import styles from './NutrientMix.module.css';

const BUTTON_HREF = `${config.get('app.website.url')}/diy`;

interface ResponsiveNutrientMixProps {
  isMobile: boolean;
}

export function ResponsiveNutrientMix({
  isMobile,
}: ResponsiveNutrientMixProps) {
  const responsiveImage = isMobile
    ? [nutrientBackgroundMobileJpg, nutrientBackgroundMobileWebp]
    : [nutrientBackgroundDesktopJpg, nutrientBackgroundDesktopWebp];

  return (
    <Card className={styles.card}>
      <Picture
        className={styles.image}
        sources={responsiveImage}
        alt="DIY Nutrient Mix"
      />
      <Text
        bold
        as="h2"
        color="white"
        topSpacing="lg"
        bottomSpacing="sm"
        variant="heading-28"
      >
        Home Cooking with the Do-It-Yourself Nutrient Mix{' '}
      </Text>
      <Text
        as="p"
        color="white"
        topSpacing="none"
        bottomSpacing="md"
        variant="article-16"
      >
        The Farmer’s Dog makes it simple and safe for any dog owner to give
        their dog the benefits of a fresh diet. The Do-It-Yourself Nutrient Mix
        can be added to recipes developed by our on-staff, Board-Certified
        Veterinary Nutritionists to make home-cooked dog food complete and
        balanced.
      </Text>
      <Button
        type="anchor"
        target="_blank"
        href={BUTTON_HREF}
        variant="primary-mini"
        className={styles.button}
      >
        Learn About DIY Nutrient Mix
      </Button>
    </Card>
  );
}

export const NutrientMix = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ResponsiveNutrientMix isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveNutrientMix isMobile={false} />
      </div>
    </>
  );
};
