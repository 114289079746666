import { Button, Divider, Section, Text } from '@farmersdog/corgi-x';
import styles from './Organization.module.css';
import { Position } from '../../../graphql/types';
import { OrganizationType, positionOptions } from '../../../constants';
import { useId } from 'react';
import * as pagePaths from '../../../constants/pagePaths';

interface OrganizationProps {
  address?: string | null;
  name?: string;
  type?: string;
  position: Position;
  isMobile?: boolean;
}

export const OrganizationLegacy = ({
  address,
  position,
  name,
  type,
  isMobile,
}: OrganizationProps) => {
  const isPractice = type === OrganizationType.Practice;
  const headingId = useId();
  return (
    <Section aria-labelledby={headingId} variant="card">
      <Text
        id={headingId}
        as="h2"
        variant="heading-28"
        bold
        color="blueberry-3"
      >
        {isPractice ? 'My Veterinary Practice' : 'My Organization'}
      </Text>
      <div className={styles.organization}>
        <div>
          <Text as="span" variant="heading-16" color="charcoal-3">
            <strong>
              {isPractice ? 'Practice Name' : 'Organization Name'}
            </strong>
            <br />
            {name}
          </Text>
          <Text as="p" variant="heading-16" color="charcoal-3">
            <strong>Address</strong>
            <br />
            {address}
          </Text>
          <Button
            variant="plain-text"
            underline
            weight="normal"
            type="link"
            data-testid="update-practice"
            to={{
              pathname: pagePaths.PATH_PROFILE,
              search: 'update=practice&practice=search',
            }}
          >
            Update Practice
          </Button>
        </div>
        <Divider
          color="Kale1"
          width={1}
          spacing={0}
          orientation={isMobile ? 'horizontal' : 'vertical'}
        />
        <div>
          <Text as="p" variant="heading-16" color="charcoal-3">
            <strong>Role</strong>
            <br />
            {position
              ? positionOptions.find(option => option.value === position)?.label
              : ''}
          </Text>

          <Button
            variant="plain-text"
            underline
            weight="normal"
            type="link"
            to={{
              pathname: pagePaths.PATH_PROFILE,
              search: 'update=role',
            }}
          >
            Update Role
          </Button>
        </div>
      </div>
    </Section>
  );
};
