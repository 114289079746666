import { useHistory } from 'react-router';
import { useQueryParameters } from '.';

export interface pushArgs {
  noRedirectPathname: string;
}

/**
 * Custom hook that returns a function which redirects to the deeplink contained in the 'next' query parameter if it exists, otherwise it redirects to the noRedirectPathname.
 * Usually is called after a login or signup is made.
 */
export function useRedirection() {
  const history = useHistory();
  const query = useQueryParameters();
  const push = ({ noRedirectPathname }: pushArgs) => {
    const next = query.get('next');
    if (next) {
      let path = next;
      let hash;
      const splittedHash = next.split('#');
      if (splittedHash.length > 1) {
        path = splittedHash[0];
        hash = splittedHash[1];
      }
      const resource = query.get('resource');

      let search;
      if (resource) {
        search = `?resource=${resource}`;
      }
      history.push({
        pathname: path,
        hash: hash,
        search: search,
      });
      return;
    }
    history.push({
      pathname: noRedirectPathname,
    });
  };

  return push;
}
