import { useState } from 'react';

import { Button, Text, Link } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import { textToBeCopied } from './textToBeCopied';
import { trackClickedCopyButton } from '../../../analytics/events';
import { useVetDetails } from '../../../graphql/hooks/useVetDetails';

import config from '../../../config';

import styles from './RecommendedToClients.module.css';

const SIGNUP_URL = `${config.get('app.website.url')}/signup`;

const listItems = [
  <>
    Sign up for The Farmer’s Dog (
    <Link target="_blank" className={styles.link} href={SIGNUP_URL}>
      thefarmersdog.com/signup
    </Link>
    ) to create your profile.
  </>,
  <>
    On the last step, click “Select Your Own Plan” to choose the recipes
    recommended by your vet.
  </>,
  <>
    Don’t see the vet recommended recipes? Call The Farmer’s Dog at{' '}
    <Link target="_blank" className={styles.link} href="tel:+16467807957">
      (646) 780-7957.
    </Link>
  </>,
];

export const RecommendToClients = () => {
  const [isCopied, setIsCopied] = useState(false);

  const { data } = useVetDetails();

  const email = data?.vetDetails?.vet?.email || '';

  const handleClick = async () => {
    await navigator?.clipboard?.writeText(textToBeCopied);
    setIsCopied(true);
    trackClickedCopyButton({
      email,
    });
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Card>
      <Text
        bold
        as="h3"
        bottomSpacing="sm"
        topSpacing="none"
        color="blueberry-3"
        variant="heading-28"
      >
        How to Recommend to Your Clients
      </Text>
      <Text topSpacing="none" bottomSpacing="md" as="p" variant="article-16">
        Copy the instructions below and share with your clients:
      </Text>
      <div className={styles.listContainer}>
        <ol className={styles.list}>
          {listItems.map((item, index) => (
            <Text
              topSpacing="none"
              bottomSpacing="xs"
              as="li"
              variant="article-16"
              key={index}
            >
              {item}
            </Text>
          ))}
        </ol>
        <Button
          onClick={handleClick}
          variant="primary-mini"
          className={styles.button}
          aria-controls="copy-instructions-button"
        >
          <span aria-live="polite" role="status" id="copy-instructions-button">
            {isCopied ? 'Copied!' : 'Copy Instructions'}
          </span>
        </Button>
      </div>
    </Card>
  );
};
