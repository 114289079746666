import { Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';

import styles from './TrialDiscountsContainer.module.css';
import classNames from 'classnames';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';

export interface TrialDiscountsContainerProps {
  children?: React.ReactNode;
  className?: string;
  withSubtitle?: boolean;
}

interface ResponsiveTrialDiscountsContainerProps
  extends TrialDiscountsContainerProps {
  isMobile?: boolean;
}

export function ResponsiveTrialDiscountsContainer({
  children,
  isMobile,
  className,
  withSubtitle,
}: ResponsiveTrialDiscountsContainerProps) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <Grid
      className={classNames([className, styles.container])}
      topSpacing={{ xs: 'none', lg: 'sm' }}
    >
      <GridItem
        className={styles.textsContainer}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          as="h2"
          variant={isMobile ? 'heading-28' : 'heading-40'}
          color="blueberry-3"
          vSpacing="none"
          bold
        >
          Request Veterinary Discount
        </Text>
        {withSubtitle && (
          <Text as="p" variant="article-16" color="charcoal-3" vSpacing="none">
            We’re offering a <b>free trial box</b> and ongoing{' '}
            <b>50% discount.</b>
          </Text>
        )}
      </GridItem>
      <GridItem>
        <Divider
          color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
          spacing={isMobile ? 36 : 48}
          width={1}
        />
      </GridItem>
      <GridItem xs={12} justifyContent="center">
        {children}
      </GridItem>
    </Grid>
  );
}

export function TrialDiscountsContainer(props: TrialDiscountsContainerProps) {
  return (
    <>
      <div className={styles.mobile}>
        <ResponsiveTrialDiscountsContainer {...props} isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveTrialDiscountsContainer {...props} />
      </div>
    </>
  );
}
