import React, { PropsWithChildren, useId } from 'react';
import fastdom from 'fastdom';
import classNames from 'classnames';

import { useHtmlElement, useSlideShow } from '../../hooks';

import { IndexButton } from '../IndexButton';
import { LiveMessage } from '../LiveMessage';
import { ScrollSnap } from '../ScrollSnap';
import { Slide } from './Slide';
import { Text } from '../Text';
import { Chevron } from '../../icons';
import { getIsActiveSlide } from './getIsActiveSlide';
import styles from './Gallery.css';

/** Which contrast mode to use */
export const GalleryModes = ['light', 'dark'] as const;

export interface GalleryProps {
  /** The label used for this gallery. Required for accessibility. */
  label: string;
  /** Boolean value to toggle the display of index dots indicators */
  withDots?: boolean;
  /** Callback when the currently active slide is clicked */
  onActiveSlideClick?: React.MouseEventHandler;
  /** Callback when the current slide changes from a touch gestures */
  onSlideChange?: (nextIndex: number) => void;
  /** The currently active index when used in a controlled state pattern */
  activeIndex?: number;
  /** Content to render beneath the active slide */
  footnote?: React.ReactNode;
  /** Which contrast mode to use */
  mode?: (typeof GalleryModes)[number];
  /** Whether to toggle the wide view */
  isWide?: boolean;
  /** Whether to toggle the debug code to visualize the intersections */
  isDebug?: boolean;
  /** Optional class name */
  className?: string;
}

const initialRatios: number[] = [];

/**
 * Render a gallery component. This component accepts any number of children
 * that will each be rendered as a slide for the gallery.
 *
 * !Requires `polyfillSmoothScroll()` on the client for full browser support.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/gallery | Gallery} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const Gallery: React.FC<PropsWithChildren<GalleryProps>> = ({
  mode = 'dark',
  children,
  label,
  onSlideChange,
  withDots = false,
  onActiveSlideClick,
  footnote,
  activeIndex: activeIndexProp,
  isWide = false,
  className,
  isDebug = false,
}) => {
  const id = useId();
  const messageId = useId();
  const numberOfSlides = React.Children.count(children);
  const [scrollableEl, scrollableRef] = useHtmlElement();
  const [intersectionRatios, setIntersectionRatios] =
    React.useState(initialRatios);

  const { activeIndex, goToSlide } = useSlideShow({
    scrollableEl,
    scrollBehavior: 'smooth',
    getIsActiveSlide,
    onSlideChange,
    activeIndex: activeIndexProp,
  });

  const handleScroll = React.useCallback((): void => {
    let nextIntersectionRatios: number[];

    fastdom.measure(() => {
      if (!scrollableEl) {
        return;
      }

      const scrollableRect = scrollableEl.getBoundingClientRect();
      const scrollableLeft = scrollableRect.left;
      const scrollableRight = scrollableRect.right;
      const scrollableWidth = scrollableRect.width;
      const slides = Array.from(scrollableEl.children);

      nextIntersectionRatios = slides.map(slide => {
        const slideRect = slide.getBoundingClientRect();
        const slideCenter = slideRect.width / 2 + slideRect.left;
        const animationReferenceRight = slideCenter + scrollableRect.width / 2;
        const animationReferenceLeft = slideCenter - scrollableRect.width / 2;

        const isIntersectingLeft =
          animationReferenceLeft <= scrollableLeft &&
          animationReferenceRight > scrollableLeft;

        const isIntersectingRight =
          animationReferenceRight >= scrollableRight &&
          animationReferenceLeft < scrollableRight;

        if (isIntersectingRight) {
          return (
            (scrollableWidth - (animationReferenceRight - scrollableRight)) /
            scrollableWidth
          );
        }

        if (isIntersectingLeft) {
          return (animationReferenceRight - scrollableLeft) / scrollableWidth;
        }

        return 0;
      });

      fastdom.mutate(() => {
        setIntersectionRatios(nextIntersectionRatios);
      });
    });
  }, [scrollableEl]);

  // Set up initial positions when element is rendered
  React.useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return (
    <section
      aria-label={label}
      className={classNames(className, {
        [styles.isWide]: isWide,
        [styles.isNotWide]: !isWide,
      })}
      data-active-slide={activeIndex}
    >
      <div className={styles.container}>
        {isWide && (
          <>
            <button
              onClick={(): void => goToSlide(activeIndex - 1)}
              className={classNames(styles.arrowButton)}
              disabled={activeIndex === 0}
              aria-label="Previous Slide"
            >
              <Chevron
                fill={mode === 'light' ? 'white' : 'kale-3'}
                size={72}
                className={classNames(styles.buttonIcon, styles.buttonIconBack)}
              />
            </button>
            <button
              onClick={(): void => goToSlide(activeIndex + 1)}
              className={classNames(styles.arrowButton, styles.forwardButton)}
              disabled={activeIndex === numberOfSlides - 1}
              aria-label="Next Slide"
            >
              <Chevron
                fill={mode === 'light' ? 'white' : 'kale-3'}
                size={72}
                className={styles.buttonIcon}
              />
            </button>
          </>
        )}
        <ScrollSnap
          as="ul"
          id={id}
          ref={scrollableRef}
          className={styles.horizontalSnap}
          onScroll={handleScroll}
        >
          {React.Children.map(children, (child, index) => {
            const handleClick =
              index === activeIndex
                ? onActiveSlideClick
                : (): void => goToSlide(index);

            return (
              <Slide
                aria-label={`${index + 1} of ${numberOfSlides}`}
                aria-describedby={messageId}
                onClick={handleClick}
                isDebug={isDebug}
                isWide={isWide}
                index={index}
                intersectionRatio={intersectionRatios[index]}
                active={index === activeIndex}
              >
                {child}
              </Slide>
            );
          })}
        </ScrollSnap>
      </div>
      <LiveMessage aria-live="polite" id={messageId} className={styles.message}>
        <Text variant="heading-16" color={mode === 'dark' ? 'kale-3' : 'white'}>
          {footnote}
        </Text>
      </LiveMessage>
      {withDots && (
        <div className={styles.dots}>
          {React.Children.map(children, (_, childIndex) => {
            return (
              <IndexButton
                mode={mode}
                aria-label={`Go to slide ${childIndex}`}
                aria-controls={id}
                active={childIndex === activeIndex}
                onClick={(): void => goToSlide(childIndex)}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};
