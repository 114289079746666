import { track } from '../track';
import { webinars_page_clicked_copy_link_button } from './eventNames';

interface TrackWebinarsPageClickedCopyLinkButtonArgs {
  title: string;
}

export function trackWebinarsPageClickedCopyLinkButton({
  title,
}: TrackWebinarsPageClickedCopyLinkButtonArgs) {
  track({
    eventName: webinars_page_clicked_copy_link_button,
    eventProps: {
      title,
    },
  });
}
