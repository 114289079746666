import { track } from '../track';
import { downloadable_vet_referral_handout_on_dowload_link } from './eventNames';

/**
 * Send a tracking event for when a user downloads a the vet referral handout pdf from the dashboard page
 */

export function trackDownloadableVetReferralHandout() {
  track({
    eventName: downloadable_vet_referral_handout_on_dowload_link,
  });
}
