import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './ActivityIndicator.css';

export const ActivityIndicatorModes = ['light', 'dark'] as const;

export interface ActivityIndicatorProps {
  /** Use `dark` when placing the indicator on lighter backgrounds */
  mode: (typeof ActivityIndicatorModes)[number];
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/activityindicator | ActivityIndicator} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const ActivityIndicator: FC<ActivityIndicatorProps> = ({
  mode = 'light',
}) => {
  const cssClass = classNames(styles.container, styles[mode]);
  return (
    <span className={cssClass}>
      <span />
      <span />
      <span />
    </span>
  );
};
