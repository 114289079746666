import { ApolloLink } from '@apollo/client';

import { setOperationToken } from '../utils/setOperationToken';
import cognitoToken from '../../../utils/cognito-token-storage';

/**
 * Authorization middleware
 *
 * Automatically handles setting authorization tokens in outgoing network
 * requests and setting authorization tokens in incoming responses.
 */
export function createAuthMiddleware(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    setOperationToken({
      operation,
      token: cognitoToken.get(),
    });

    return forward(operation);
  });
}
