import { FormFieldArray, FieldType } from '../../../../components/FormFields';

export enum PersonalDetailsFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
}

export const personalDetailsFormFieldsData: FormFieldArray<PersonalDetailsFormFields> =
  [
    {
      name: PersonalDetailsFormFields.firstName,
      type: FieldType.Text,
      label: 'First Name',
    },
    {
      name: PersonalDetailsFormFields.lastName,
      type: FieldType.Text,
      label: 'Last Name',
    },
  ];
