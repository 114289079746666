/**
 * Given a percent, max, and min.  Determine the number that is some percent between
 * max and min.
 *
 * @example
 * getProgressOfRange(.5, 0, 10) // 5
 */
export const getProgressOfRange = (
  percent: number,
  min: number,
  max: number
): number => {
  return (percent * 100 * (max - min)) / 100 + min;
};
