import { ContactUsFormFields } from './contactUsFormFieldsData';

export interface ContactUsFormValues {
  [ContactUsFormFields.firstName]: string;
  [ContactUsFormFields.lastName]: string;
  [ContactUsFormFields.email]: string;
  [ContactUsFormFields.phone]?: string;
  [ContactUsFormFields.message]: string;
}
export const initialFormValues: ContactUsFormValues = {
  [ContactUsFormFields.firstName]: '',
  [ContactUsFormFields.lastName]: '',
  [ContactUsFormFields.email]: '',
  [ContactUsFormFields.phone]: '',
  [ContactUsFormFields.message]: '',
};
