import { Button, Grid, GridItem, Section, Text } from '@farmersdog/corgi-x';
import { useId } from 'react';
import styles from '../Profile.module.css';
interface UpdatePasswordProps {
  disableUpdatePassword?: boolean;
  onUpdatePassword: () => void;
}

export const UpdatePassword = ({
  disableUpdatePassword,
  onUpdatePassword,
}: UpdatePasswordProps) => {
  const headingId = useId();
  return (
    <Section
      aria-labelledby={headingId}
      variant="card"
      as="div"
      className={styles.card}
    >
      <Grid>
        <GridItem md={9}>
          <Text variant="heading-28" bold color="blueberry-3">
            Password
          </Text>
        </GridItem>
        <GridItem md={3}>
          <Button
            variant="secondary-mini-kale-experimental"
            onClick={
              !disableUpdatePassword ? () => onUpdatePassword() : undefined
            }
            aria-disabled={disableUpdatePassword}
            align-self="flex-end"
          >
            Update Password
          </Button>
        </GridItem>
      </Grid>
    </Section>
  );
};
