import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './MaxWidth.css';

type IntrinsicDivProps = JSX.IntrinsicElements['div'];

export const MaxWidth: FC<IntrinsicDivProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      {children}
    </div>
  );
};
