import { FC, useMemo } from 'react';

import { PageTitle, LoginForm, PublicPage, Card } from '../../components';
import styles from './Login.module.css';

import * as pagePaths from '../../constants/pagePaths';
import { Button, Text } from '@farmersdog/corgi-x';
import { useQueryParameters } from '../../hooks';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import StethoscopeSvg from '../../pages/Home/assets/stethoscope.svg?react';

export const Login: FC = () => {
  const query = useQueryParameters();
  const pageTitle = 'Welcome Back!';
  const pageSubtitle = 'We’re glad you’re here. Please log in to continue. ';

  const redirectTo = useMemo(() => {
    return query.toString();
  }, [query]);

  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const loginForm = showPortalUXOverhaul ? (
    <Card className={styles.loginFormCard}>
      <LoginForm />
    </Card>
  ) : (
    <LoginForm />
  );

  return (
    <PublicPage blueberryBackground={showPortalUXOverhaul}>
      <div
        className={
          showPortalUXOverhaul ? styles.container : styles.containerLegacy
        }
      >
        {showPortalUXOverhaul && (
          <StethoscopeSvg className={styles.stethoscope} />
        )}
        <div className={styles.titleContainer}>
          <PageTitle
            title={pageTitle}
            subtitle={pageSubtitle}
            titleColor={showPortalUXOverhaul ? 'blueberry-3' : undefined}
          />
        </div>
        {loginForm}
        <Text
          as="h3"
          variant="heading-16"
          bold
          topSpacing="md"
          bottomSpacing="md"
          color="kale-3"
        >
          Don’t have an account?
        </Text>
        <Button
          variant={
            showPortalUXOverhaul
              ? 'secondary-mini-kale-experimental'
              : 'secondary'
          }
          type="link"
          className={styles.registerButton}
          to={{
            pathname: pagePaths.PATH_SIGNUP,
            search: redirectTo,
          }}
        >
          Register
        </Button>
      </div>
    </PublicPage>
  );
};
