import { track } from '../track';
import { faq_activate_discount_clicked } from './eventNames';

/**
 * Send a tracking event for when a user clicks "Activate Vet Discount" button.
 *
 */

export function trackClickedFAQActivateDiscountButton() {
  track({
    eventName: faq_activate_discount_clicked,
  });
}
