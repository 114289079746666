import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Slide.css';

export type SlideProps = JSX.IntrinsicElements['li'];

const Slide: FC<SlideProps> = ({ children, className, ...props }) => {
  return (
    <li className={classNames(styles.slide, className)} {...props}>
      {children}
    </li>
  );
};

export default Slide;
