import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { useClient } from './useClient';

export function useMutation<
  TData = unknown,
  TVariables = Apollo.OperationVariables,
  TContext = Apollo.DefaultContext,
  TCache extends Apollo.ApolloCache<unknown> = Apollo.ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Apollo.MutationHookOptions<TData, TVariables, TContext>
) {
  const clientOverride = useClient();

  return Apollo.useMutation<TData, TVariables, TContext, TCache>(mutation, {
    ...options,
    client: clientOverride,
  });
}
