import { local } from '@farmersdog/utils';

export const STORAGE_KEY = 'vet-portal-cognito-token';

function get() {
  return local.getItem(STORAGE_KEY);
}

function set(token: string) {
  local.setItem(STORAGE_KEY, token);
}

function remove() {
  local.removeItem(STORAGE_KEY);
}

export default {
  get,
  set,
  remove,
};
