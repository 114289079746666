import { PracticeMode } from '../constants';

interface GetMasterPracticeUrlArgs {
  pathname: string;
  search: string;
  signupMode: PracticeMode;
}

export const getMasterPracticeUrl = ({
  pathname,
  search,
  signupMode,
}: GetMasterPracticeUrlArgs) => {
  const practiceSearchParam = new URLSearchParams([['practice', signupMode]]);
  if (!search) {
    return `${pathname}?${practiceSearchParam.toString()}`;
  }

  const newSearchParams = new URLSearchParams(search);
  newSearchParams.set('practice', signupMode);
  return `${pathname}?${newSearchParams.toString()}`;
};
