import { CloseButton, Lightbox, LightboxControlReturn } from '../Lightbox';
import React, { FC, PropsWithChildren } from 'react';
import { Wave } from '../Wave';

import classNames from 'classnames';
import {
  getModalDesign,
  getNonWaveDesign,
  getWaveDesign,
  // getWaveSectionDesign,
  // getBelowWaveSectionDesign,
} from '../../designs/getModalDesign';

import { MARGIN_XL } from '../../styles/margins';

import styles from './Modal.css';

const UNSET_WIDTH = '';
const MIN_HEIGHT = 0;

export interface ModalProps extends LightboxControlReturn {
  /** Toggle if clicking background closes the modal */
  disableInteractions?: boolean;
  /** Content to show in a section at the top with a coloured background and wave below */
  waveSectionContent?: React.ReactNode;
  className?: string;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  rootProps,
  disableInteractions = false,
  waveSectionContent,
  className,
  ...props
}) => {
  const isWaveDesign = Boolean(waveSectionContent);

  const modalDesign = getModalDesign();
  const nonWaveDesign = getNonWaveDesign();
  const { waveSectionDesign, belowWaveSectionDesign } = getWaveDesign();

  return (
    <Lightbox
      width={UNSET_WIDTH}
      height={MIN_HEIGHT}
      className={classNames(modalDesign, className, {
        [nonWaveDesign]: !isWaveDesign,
      })}
      disableInteractions={disableInteractions}
      {...rootProps}
      {...props}
    >
      {!disableInteractions && (
        <CloseButton onClick={props.close} className={styles.closeButton} />
      )}
      {isWaveDesign && (
        <>
          <Wave fill="chickpea-1" waveHeight={MARGIN_XL} withBottom>
            <div className={waveSectionDesign}>{waveSectionContent}</div>
          </Wave>
          <div className={belowWaveSectionDesign}>{children}</div>
        </>
      )}
      {!isWaveDesign && children}
    </Lightbox>
  );
};
