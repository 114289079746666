import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function useConferenceSignUpPasswordRevamp() {
  const conferenceSignUpPasswordRevamp = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_VET_PORTAL_CONFERENCE_SIGNUP_PASSWORD_REVAMP, {});

  const showConferenceSignUpPasswordRevamp =
    conferenceSignUpPasswordRevamp.treatment === OnOffTreatments.on;

  return {
    showConferenceSignUpPasswordRevamp,
  };
}
