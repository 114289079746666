import React, { FC } from 'react';

type IntrinsicSVGProps = JSX.IntrinsicElements['svg'];
export interface WithIconProps extends IntrinsicSVGProps {
  /** Height of the icon */
  size?: number;
  /** Fill color of the icon, can be a css color variable */
  fill?: string;
}

export const IconPropTypes: FC<WithIconProps> = ({ children }) => {
  return <div>{children}</div>;
};
