import React, { FC } from 'react';
import classNames from 'classnames';

import { Carousel } from '../Carousel';
import styles from './HorizontalNavigation.css';

export interface HorizontalNavigationProps {
  withButtons: boolean;
  children: React.ReactNode;
  className?: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/navbar | NavBar} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const HorizontalNavigation: FC<HorizontalNavigationProps> = ({
  withButtons,
  children,
  className,
}) => {
  return (
    <Carousel
      withButtons={withButtons}
      className={classNames(styles.carousel, className)}
    >
      {children}
    </Carousel>
  );
};
