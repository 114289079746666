import * as React from 'react';

import buttonStyles from '../../styles/button.css';
import classNames from 'classnames';
import styles from './IndexButton.css';

export const Modes = ['light', 'dark', 'green'] as const;

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];
export interface IndexButtonProps extends IntrinsicButtonProps {
  /** Required aria-label for buttons with no textual content */
  ['aria-label']: string;
  /** Indicate the current index is active */
  active: boolean;
  /** Optional class name */
  className?: string;
  /** Which contrast mode to use */
  mode?: (typeof Modes)[number];
}

/**
 * Render a small button that can be used to indicate the active index of a list
 * of items.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/indexbutton | IndexButton} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const IndexButton: React.FC<IndexButtonProps> = ({
  active,
  mode = 'dark',
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(
        buttonStyles.button,
        styles.button,
        className,
        styles[mode],
        {
          [styles.active]: active,
        }
      )}
      {...props}
    />
  );
};
