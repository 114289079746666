import { RefObject, useMemo } from 'react';

import { useIntersection } from '../../hooks/useIntersection';

import { AppBarItemProps } from './AppBar';

export const useCallToActionObserver = (
  showCTA?: RefObject<HTMLElement>,
  callToAction?: AppBarItemProps
): boolean => {
  const intersection = useIntersection(showCTA?.current ?? undefined, {
    rootMargin: `0px 0px -95% 0px`,
  });

  return useMemo(() => {
    if (!intersection || !callToAction || !showCTA?.current) {
      return false;
    }

    const bottom = intersection.boundingClientRect.bottom;
    return intersection.isIntersecting || bottom < 0;
  }, [callToAction, intersection, showCTA]);
};
