import {
  buildThresholdList,
  useIntersection,
} from '../../hooks/useIntersection';
import { useEffect, useState } from 'react';

import { LOGO_SIZE } from './constants';
import { browserOnly } from '../../util';
import fastdom from 'fastdom';

const threshold = buildThresholdList(LOGO_SIZE * 5);

function getScrollY(): number {
  return (
    browserOnly((window: Window) => {
      return typeof window.scrollY === 'number'
        ? window.scrollY
        : window.pageYOffset;
    }) ?? 0
  );
}

function getScrollProgress(): number {
  const scrollY = getScrollY();
  const scrollProgress = scrollY / LOGO_SIZE;
  return Math.min(scrollProgress, 1);
}

export function useScrollProgress(content: Element | null): number {
  const [scrollProgress, setScrollProgress] = useState(getScrollProgress());

  const intersection = useIntersection(content, {
    threshold,
  });

  useEffect(() => {
    return browserOnly((window: Window) => {
      if (!intersection || !content) return;
      const intersectionTop = intersection.intersectionRect.top;
      const boundingTop = intersection.boundingClientRect.top;
      let attachListener = false;
      if (boundingTop > 0) {
        // Content above the AppBar
        attachListener = intersectionTop > 0;
      } else {
        // Nothing above the AppBar
        attachListener = Math.abs(boundingTop) <= LOGO_SIZE;
      }

      const scrollListener = (): void => {
        fastdom.measure(() => {
          setScrollProgress(getScrollProgress());
        });
      };

      if (attachListener) {
        window.addEventListener('scroll', scrollListener);
      } else if (!attachListener) {
        // Set to 1 on detach to ensure we don't get stuck in a smaller progress value
        // This also fixes scroll position restore after a refresh
        setScrollProgress(1);
        window.removeEventListener('scroll', scrollListener);
      }

      return (): void => window.removeEventListener('scroll', scrollListener);
    });
  }, [content, intersection]);

  return scrollProgress;
}
