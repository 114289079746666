import * as stripeJs from '@stripe/stripe-js';

/** The possible brand string values as defined by the Stripe.js library */
export type Brand = stripeJs.StripeCardNumberElementChangeEvent['brand'];

/** A convenient enum of the Stripe.js brand types. */
export enum brandTypes {
  amex = 'amex',
  diners = 'diners',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unknown = 'unknown',
  visa = 'visa',
}
