import * as yup from 'yup';
import { PersonalDetailsFormFields } from '../constants';
import { fieldRequiredErrorMessage } from '../../../../utils';

export const personalInformationValidationSchema = yup.object({
  [PersonalDetailsFormFields.firstName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [PersonalDetailsFormFields.lastName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
});
