import * as React from 'react';
import { getRadioDesign } from '../../designs/getRadioDesign';
import { ElementState } from '../BorderLabel';

type IntrinsicSpanProps = JSX.IntrinsicElements['span'];

export interface RadioViewProps extends IntrinsicSpanProps {
  state: ElementState;
  checked?: boolean;
}

export const RadioView: React.FC<RadioViewProps> = ({ state, checked }) => {
  const inputDesign = getRadioDesign({ state, checked });
  return <span className={inputDesign}></span>;
};
