import { ElementStates } from '../../components/BorderLabel';
import classNames from 'classnames';
import styles from './getRadioDesign.css';

/** The properties of the radio design */
export interface RadioDesignProps {
  /** Toggle checked style */
  checked?: boolean;
  /** State of input from useElementState */
  state: (typeof ElementStates)[number];
}

/**
 * Return a class name the applies the radio design based on the radio design
 * props interface.
 */
export function getRadioDesign({ state, checked }: RadioDesignProps): string {
  return classNames(styles.radio, styles[state], {
    [styles.checked]: checked,
  });
}
