import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import styles from './IsCompleteVerificationCta.module.css';

import classNames from 'classnames';

interface IsCompleteVerificationCtaProps {
  children?: React.ReactNode;
  className?: string;
}

export function IsCompleteVerificationCta({
  children,
  className,
}: IsCompleteVerificationCtaProps) {
  return (
    <Grid
      className={classNames([styles.container, className])}
      rowGap={{ xs: 'md' }}
      flexDirection="column"
      alignItems="center"
    >
      <GridItem className={styles.textsContainer} flexDirection="column">
        <Text as="h4" variant="heading-22" color="kale-3" bold vSpacing="none">
          Next, Complete Verification
        </Text>
        <Text as="p" variant="article-16" color="kale-3" vSpacing="none">
          We need some information to verify your status as a veterinary
          professional and activate your ongoing discount.
        </Text>
      </GridItem>
      <GridItem justifyContent="center">{children}</GridItem>
    </Grid>
  );
}
