import { ElementStates } from '../../components/BorderLabel';
import classNames from 'classnames';
import styles from './getCheckboxDesign.css';

/** The properties of the checkbox design */
export interface CheckboxDesignProps {
  /** Toggle checked style */
  checked?: boolean;
  /** State of input from useElementState */
  state: (typeof ElementStates)[number];
}

/**
 * Return a class name the applies the checkbox design based on the checkbox design
 * props interface.
 */
export function getCheckboxDesign({
  checked = false,
  state,
}: CheckboxDesignProps): string {
  return classNames(styles.checkbox, styles[state], {
    [styles.checked]: checked,
  });
}
