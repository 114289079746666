import { VetEvent } from '../../../data/eventsAndRecordingsData';
import { Card } from '../../../components';
import { Divider, Grid, Text } from '@farmersdog/corgi-x';
import { EventType } from './Events';
import { Event } from './Event';

interface EventsLegacyProps {
  isMobile?: boolean;
  eventType: EventType;
  events: VetEvent[];
  getShowLeftBorder: (index: number) => boolean;
  gridClassnames: string;
}

export const EventsLegacy = ({
  isMobile,
  eventType,
  events,
  getShowLeftBorder,
  gridClassnames,
}: EventsLegacyProps) => {
  return (
    <Card>
      <Text
        as="h3"
        variant={isMobile ? 'heading-22' : 'heading-28'}
        bold
        color="blueberry-3"
        topSpacing="none"
        bottomSpacing="none"
      >
        {eventType}
      </Text>
      <Grid className={gridClassnames}>
        {events.map((event, index) => (
          <Grid
            key={`${event.type}-${event.name}-${event.hourLocation}`}
            flexDirection="column"
          >
            <Divider width={1} color="Kale1" spacing={isMobile ? 24 : 36} />
            <Event vetEvent={event} leftBorder={getShowLeftBorder(index)} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
