import { IconButton, Text, Banner, Link } from '@farmersdog/corgi-x';
import { Close } from '@farmersdog/corgi-x/icons';
import { paths } from '@farmersdog/constants';
import { getAbsoluteLink } from '../../utils';
import { setTermsChangesNoticeDismissed } from '../../utils/cookies/termsChangesNotice';
import { trackTermsChangesNoticeDismissed } from '../../analytics/events';
import styles from './TermsChangesBanner.module.css';

interface TermsChangesBannerProps {
  dismissed: boolean;
  dismissNotice: () => void;
}

export function TermsChangesBanner({
  dismissed,
  dismissNotice,
}: TermsChangesBannerProps) {
  const onDismiss = () => {
    dismissNotice();
    setTermsChangesNoticeDismissed();
    trackTermsChangesNoticeDismissed();
  };

  if (dismissed) {
    return null;
  }
  return (
    <Banner variant="oyster-0" className={styles.banner}>
      <div className={styles.bannerContent}>
        <Text
          variant="article-16"
          color="charcoal-3"
          className={styles.bannerText}
        >
          Please see our updated{' '}
          <Link href={getAbsoluteLink(paths.PATH_PRIVACY)} target="_blank">
            <Text variant="article-16" underline>
              Privacy Policy
            </Text>{' '}
          </Link>
          and{' '}
          <Link href={getAbsoluteLink(paths.PATH_TERMS)} target="_blank">
            <Text variant="article-16" underline>
              Terms of Use
            </Text>
          </Link>
          .
        </Text>
        <CloseButton onDismiss={onDismiss} />
      </div>
    </Banner>
  );
}

interface CloseButtonProps {
  onDismiss: () => void;
}

function CloseButton({ onDismiss }: CloseButtonProps) {
  return (
    <IconButton
      icon={<Close baseColor="Charcoal3" size={28} />}
      aria-label="Close"
      onClick={onDismiss}
    />
  );
}
