import { LoginFormFields } from './loginFormFieldsData';

export interface LoginFormValues {
  [LoginFormFields.email]: string;
  [LoginFormFields.password]: string;
}

export const initialFormValues: LoginFormValues = {
  [LoginFormFields.email]: '',
  [LoginFormFields.password]: '',
};
