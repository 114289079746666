import { AddOrganizationFormFields } from '../constants';
import * as yup from 'yup';
import { states } from '../../../../../constants';
import { fieldRequiredErrorMessage } from '../../../../../utils';

export function addOrganizationValidationSchema() {
  return yup.object({
    [AddOrganizationFormFields.name]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddOrganizationFormFields.phone]: yup.string(),

    [AddOrganizationFormFields.streetAddress1]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddOrganizationFormFields.streetAddress2]: yup.string(),
    [AddOrganizationFormFields.city]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [AddOrganizationFormFields.state]: yup
      .string()
      .required(fieldRequiredErrorMessage)
      .oneOf(Object.values(states)),
    [AddOrganizationFormFields.zip]: yup
      .string()
      .required(fieldRequiredErrorMessage),
  });
}
