import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Divider.css';
import { StandardShorthandProperties } from 'csstype';

const { horizontal, vertical, container } = styles;

export const DividerOrientations = ['horizontal', 'vertical'] as const;
export const DividerColors = [
  'kale-3',
  'white',
  'charcoal-3',
  'charcoal-1',
  'oyster-0',
  'charcoal-0',
  'kale-1',
  'carrot-1',
  'chickpea-2',
  'carrot-2',
  'carrot-3',
] as const;

export interface DividerProps {
  /** The divider direction. */
  orientation: (typeof DividerOrientations)[number];
  /** Style of the divider.  Can be any valid border-style */
  style?: StandardShorthandProperties['borderStyle'];
  /** Margins of divider.  Top and bottom for horizontal, left and right for vertical. */
  margin?: number | string;
  /** Color of the divider. */
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  color?: (typeof DividerColors)[number] | string;
  /** Width of the divider line */
  width?: number;
  className?: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/divider | Divider} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Divider: FC<DividerProps> = ({
  orientation,
  style = 'solid',
  margin = 13,
  color,
  width = 2,
  className,
  ...props
}) => {
  const isHorizontal = orientation === 'horizontal';
  const margins = isHorizontal
    ? { marginTop: margin, marginBottom: margin }
    : { marginLeft: margin, marginRight: margin };

  const colorClass = color ? styles[color] : undefined;

  return (
    <div
      role="separator"
      aria-orientation={orientation}
      className={classNames(
        container,
        { [horizontal]: isHorizontal, [vertical]: !isHorizontal },
        colorClass,
        className
      )}
      style={{
        borderColor: !colorClass ? color : undefined,
        borderStyle: style,
        borderTopWidth: isHorizontal ? width : 0,
        borderLeftWidth: isHorizontal ? 0 : width,
        ...margins,
      }}
      {...props}
    />
  );
};
