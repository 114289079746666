import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function useTermsChangesBanner() {
  const termsChangesBanner = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.terms_changes_banner, {});

  const showTermsChangesBanner =
    termsChangesBanner.treatment === OnOffTreatments.on;

  return {
    showTermsChangesBanner,
  };
}
