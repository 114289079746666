import * as pagePaths from '../../../constants/pagePaths';

export const getSupportItems = () => [
  {
    title: 'Frequently Asked Questions',
    description:
      'Check out what your fellow vet professionals commonly want to know about us.',
    linkUrl: pagePaths.PATH_FAQ,
    linkText: 'Review FAQs',
  },
  {
    title: 'Contact Us',
    description: 'Have questions or need more info?',
    linkUrl: pagePaths.PATH_CONTACT_US,
    linkText: 'Get In Touch',
  },
  {
    title: 'Meet Our Team',
    description:
      'Our experts are here to answer any question you have about our product or pet nutrition.',
    linkUrl: pagePaths.PATH_OUR_TEAM,
    linkText: 'Learn More',
  },
];
