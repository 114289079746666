import styles from './Lightbox.css';

let DOCUMENT_ELEMENT: HTMLElement | undefined;
let DOCUMENT: Document | undefined;

if (typeof window !== 'undefined') {
  DOCUMENT_ELEMENT = window.document.documentElement;
  DOCUMENT = window.document;
}

let lightboxes = 0;
let previousPadding: string | null = null;

/**
 * Initialize the body element by setting a padding right of the same width as
 * any active body scrollbars.
 */
function setScrollBarGap(): void {
  if (!(DOCUMENT && DOCUMENT_ELEMENT && typeof window !== 'undefined')) {
    return;
  }

  const gap = window.innerWidth - DOCUMENT_ELEMENT.clientWidth;
  previousPadding = DOCUMENT.body.style.paddingRight;
  DOCUMENT.body.style.paddingRight = `${gap}px`;
}
/**
 * Cleanup the body element by reseting the padding right to the initial stored
 * value.
 */
function unsetScrollBarGap(): void {
  if (!DOCUMENT || previousPadding === null) {
    return;
  }

  DOCUMENT.body.style.paddingRight = previousPadding;
}

/**
 * Initialize the root dom element by adding a css class. This class is used to
 * add specific overflow and scrolling related css.
 */
export const initializeDOM = (): void => {
  if (lightboxes < 1 && DOCUMENT_ELEMENT) {
    setScrollBarGap();
    DOCUMENT_ELEMENT.classList.add(styles.hasLightbox);
  }
  lightboxes += 1;
};

/**
 * Cleanup the root dom element by removing a css class. This class is used to
 * add specific overflow and scrolling related css. Removal of the class will
 * restore normal scrolling behavior.
 */
export const cleanupDOM = (): void => {
  lightboxes -= 1;
  if (lightboxes < 1 && DOCUMENT_ELEMENT) {
    DOCUMENT_ELEMENT.classList.remove(styles.hasLightbox);
    unsetScrollBarGap();
  }
};

/**
 * Add a new Element with the specified id. If the element already exists, it
 * returns it.
 */
export const addDOMElementById = (
  id: string,
  tagName = 'div'
): HTMLElement | undefined => {
  if (!DOCUMENT) return;

  let el = DOCUMENT.getElementById(id);
  if (!el) {
    el = DOCUMENT.createElement(tagName);
    el.id = id;
    DOCUMENT.body.appendChild(el);
  }
  return el;
};
