import { parse } from 'bowser';

let hasSupport: boolean | undefined;

export const hasPassiveTouchSupport = (): boolean => {
  if (hasSupport !== undefined) return hasSupport;

  try {
    const options = {
      get passive(): undefined {
        // This function will be called when the browser attempts to access
        // the passive property.
        hasSupport = true;
        return undefined;
      },
    };

    // Ignore the next lines to set a "fake" event listener
    // @ts-ignore
    window.addEventListener('test', options, options);
    // @ts-ignore
    window.removeEventListener('test', options, options);
  } catch (err) {
    hasSupport = false;
  }

  hasSupport = Boolean(hasSupport);

  return hasSupport;
};

let isIosDevice: boolean | undefined;

export const isIos = (): boolean => {
  if (typeof navigator === 'undefined') return false;
  if (isIosDevice !== undefined) return isIosDevice;

  const userAgent = navigator.userAgent;
  const deviceInfo = parse(userAgent);

  const osName = deviceInfo.os.name || '';
  isIosDevice = osName.toLowerCase() === 'ios';

  return isIosDevice;
};
