import React, { FC } from 'react';
import classNames from 'classnames';

import { Link, LinkLocationProps } from '../Link';
import { TextV2 } from '../Text';

import styles from './HorizontalNavigationItem.css';

export type HorizontalNavigationItemProps = {
  active?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
} & LinkLocationProps;

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/navbar | NavBar} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const HorizontalNavigationItem: FC<HorizontalNavigationItemProps> = ({
  to,
  href,
  disabled,
  active,
  children,
  onClick,
  className,
}) => {
  return (
    <Link
      variant="secondary"
      mode="dark"
      to={to}
      href={href}
      disabled={disabled}
      onClick={onClick}
      activeClassName={styles.active}
      className={classNames(styles.navItem, className, {
        [styles.active]: active,
      })}
    >
      <TextV2 bold variant="heading-16">
        {children}
      </TextV2>
    </Link>
  );
};
