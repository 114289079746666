import React, { FC, RefObject, useRef, useState } from 'react';
import { NavLinkProps } from 'react-router-dom';
import classNames from 'classnames';

import { HTMLElementOf } from '../../types';
import { LOGO_SIZE } from './constants';
import { Large } from './Large';
import { Mobile } from './Mobile';
import { LinkLocationProps } from '../Link';

import styles from './AppBar.css';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';

export type AppBarItemProps = { title: string } & LinkLocationProps &
  Partial<NavLinkProps>;

export interface AppBarProps {
  /** Toggle AppBar to overlay on top of page content */
  overlay?: boolean;
  /** Toggle style to large, defaults to mobile */
  large?: boolean;
  /** Items to appear on the left side of the logo */
  leftItems: AppBarItemProps[];
  /** Items to appear on the right side of the logo */
  rightItems?: AppBarItemProps[];
  /** Item to remain on the right side of the logo when `large: false` */
  persistentItem?: AppBarItemProps;
  /** Items to appear in horizontal subnav*/
  subNavItems?: AppBarItemProps[];
  /** Item to be styled as a call to action button */
  callToAction?: AppBarItemProps;
  /** Ref to trigger the Call To Action item to appear */
  showCTA?: RefObject<HTMLElement>;
  /** Function to call when CTA is clicked */
  onCallToActionClick?: () => void;
  /** Toggle theme to work with a dark theme */
  darkTheme?: boolean;
  /** The route that the logo button navigates to */
  logoTo?: string;
  /** The href that the logo button links to */
  logoHref?: string;
  /** Enable ui changes to move elements above the AppBar */
  hasContentAbove?: boolean;
  /** Not clickable label next to persistant item with smaller, not bold text - only available in Large */
  smallRightLabel?: string;
  /** Toggle AppBar to stay at the top of the page when scrolled */
  static?: boolean;
}

/**
 * The App Bar displays information and actions relating to the current screen.
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/layout | Layout} from corgi-x to build layouts with an app bar. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const AppBar: FC<AppBarProps> = props => {
  const appBarRef = useRef<HTMLElementOf<HTMLElement, HTMLDivElement>>(null);
  const [content, setContent] = useState<HTMLElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (appBarRef.current) {
      const nextElementSibling = appBarRef.current.nextElementSibling;
      setContent(nextElementSibling);
      if (nextElementSibling && props.overlay) {
        const currentMarginTop = nextElementSibling.style.marginTop || '0px';
        nextElementSibling.style.marginTop = `calc(${currentMarginTop} - ${LOGO_SIZE}px)`;
      }
    }
  }, []);

  return (
    <div
      ref={appBarRef}
      data-name="AppBar"
      className={classNames(styles.container, {
        [styles.isOpen]: isOpen,
        [styles.static]: props.static,
      })}
    >
      {props.large && <Large content={content} {...props} />}
      {!props.large && (
        <Mobile onToggle={setIsOpen} content={content} {...props} />
      )}
    </div>
  );
};
