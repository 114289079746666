import { createContext } from 'react';
import noop from 'lodash/noop';

import { ToastProps } from './Toast';
import { ToastDialogProps } from './ToastDialog';

export type DispatchToast = (toastProps: ToastProps) => void;
export type DispatchToastDialog = (toastProps: ToastDialogProps) => void;
export interface ToastContextValue {
  activeToasts?: ToastProps[];
  dispatchToast: DispatchToast;
  activeToastDialogs?: ToastDialogProps[];
  dispatchToastDialog: DispatchToastDialog;
  closeToastDialog: () => void;
}

export const ToastContext = createContext<ToastContextValue>({
  dispatchToast: noop,
  dispatchToastDialog: noop,
  closeToastDialog: noop,
});
