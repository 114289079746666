import { useEffect } from 'react';
import { Text } from '@farmersdog/corgi-x';

import { Picture } from '../../components/Picture';
import { errorDogSources } from '../assets';

import styles from './ErrorMessage.module.css';
import { Logger } from '@farmersdog/logger';
import { track } from '../../analytics/track';
import { show_error_message } from '../../analytics/events/eventNames';

const log = new Logger('app:errorBoundary');
interface ErrorMessageProps {
  error: Error;
  errorMessage: string;
}

export function ErrorMessage({ error, errorMessage }: ErrorMessageProps) {
  useEffect(() => {
    track({
      eventName: show_error_message,
      eventProps: {
        name: error.name,
        message: error.message,
        error,
      },
    });

    log.error(show_error_message, {
      name: error.name,
      message: error.message,
      error,
    });
  }, [error]);

  return (
    <>
      <div className={styles.imageContainer}>
        <Picture width="200" sources={errorDogSources} alt="Bad Boy" />
      </div>
      <Text as="p" variant="heading-16">
        {errorMessage}
      </Text>
    </>
  );
}
