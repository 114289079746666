import { useIntersection } from '@farmersdog/corgi-x';
import { useEffect, useRef, useState } from 'react';

interface UseTopAndBottomIntersectionProps {
  // margin for the top reference for the top section
  topRootMargin?: string;
  // margin for the bottom reference for the bottom section
  bottomRootMargin?: string;
}

/**
 * custom hook to handle the intersection of two sections on the top of the page and on the bottom
 * it returns the state of the intersection.
 * returns true if the bottom section is visible on the viewport
 * returns false if the top section is visible on the viewport
 *
 * @param topRootMargin - margin for the top reference
 * @param bottomRootMargin - margin for the bottom reference
 **/

export const useTopAndBottomIntersection = ({
  topRootMargin,
  bottomRootMargin,
}: UseTopAndBottomIntersectionProps) => {
  const topRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [intercepted, setIntercepted] = useState(false);

  const topEntry = useIntersection(topRef.current, {
    threshold: 0,
    rootMargin: topRootMargin,
  });

  const bottomEntry = useIntersection(bottomRef.current, {
    threshold: 0,
    rootMargin: bottomRootMargin,
  });

  useEffect(() => {
    if (!topEntry) {
      return;
    }
    if (topEntry && topEntry.isIntersecting && intercepted) {
      setIntercepted(false);
    }
  }, [topEntry, intercepted]);

  useEffect(() => {
    if (!bottomEntry) {
      return;
    }
    if (bottomEntry && bottomEntry.isIntersecting && !intercepted) {
      setIntercepted(true);
    }
  }, [bottomEntry, intercepted]);

  return {
    topRef,
    bottomRef,
    intercepted,
  };
};
