import { ComplexValue, SingleValue } from './types';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  Children,
  FC,
  ReactElement,
  cloneElement,
  useState,
  useEffect,
  useRef,
} from 'react';

import { ListSelectOptionProps } from './ListSelectOption';
import accessibilityStyles from '../../styles/accessibility.css';
import classNames from 'classnames';
import getInitialValue from './getInitialValue';
import includesCastValue from './includesCastValue';
import { getPreferredScrollBehavior, interceptEvent } from '../../util';
import listStyles from './ListSelect.css';

export interface ListSelectProps {
  /** Value or values that should appear as selected. */
  value?: ComplexValue;
  /** The name used to associate this group of checkbox or radio elements. */
  name: string;
  /** The label used for group of elements. Required for accessibility. */
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  multi: boolean;
  children: ReactElement<Omit<ListSelectOptionProps, 'ref'>>[];
  withTwoColumn?: boolean;
  direction?: 'horizontal' | 'vertical';
  className?: string;
}

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/togglebox | ToggleBox} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const ListSelect: FC<ListSelectProps> = ({
  multi = false,
  children,
  label,
  name,
  onChange,
  withTwoColumn,
  className,
  direction = 'vertical',
  ...props
}) => {
  const horizontalDirection = direction === 'horizontal';
  const [internalValue, setInternalValue] = useState(
    getInitialValue(props.value, multi)
  );

  const value: ComplexValue =
    typeof props.value !== 'undefined' ? props.value : internalValue;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const eventValue = e.target.value;

    if (!(multi && Array.isArray(value))) {
      setInternalValue(eventValue);
      return;
    }

    const nextValue = includesCastValue(value, eventValue)
      ? value.filter(lastValue => String(lastValue) !== eventValue)
      : [...value, eventValue];

    setInternalValue(nextValue);
  };

  const getChecked = (childValue: SingleValue): boolean => {
    if (multi && Array.isArray(value)) {
      return includesCastValue(value, childValue);
    }

    return String(value) === String(childValue);
  };

  const fieldsetRef = useRef<HTMLFieldSetElement>(null);
  const selectedOptionRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    // Check that scrollTo exists as jsdom doesn't include it
    if (fieldsetRef.current?.scrollTo && selectedOptionRef.current) {
      const fieldsetBoundingRect = fieldsetRef.current.getBoundingClientRect();
      const selectedOptionBoundingRect =
        selectedOptionRef.current.getBoundingClientRect();
      if (horizontalDirection) {
        const scrollOffset = fieldsetBoundingRect.left;
        const centerOffset =
          fieldsetBoundingRect.width / 2 - selectedOptionBoundingRect.width / 2;
        const left =
          selectedOptionBoundingRect.left - scrollOffset - centerOffset;
        fieldsetRef.current.scrollTo({
          left,
          behavior: getPreferredScrollBehavior(),
        });
      } else {
        const scrollOffset = fieldsetBoundingRect.top;
        const centerOffset =
          fieldsetBoundingRect.height / 2 -
          selectedOptionBoundingRect.height / 2;
        const top =
          selectedOptionBoundingRect.top - scrollOffset - centerOffset;
        fieldsetRef.current.scrollTo({
          top,
          behavior: getPreferredScrollBehavior(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <fieldset
      ref={fieldsetRef}
      className={classNames(listStyles.fieldset, className, {
        [listStyles.twoColumn]: Boolean(withTwoColumn),
        [listStyles.horizontal]: horizontalDirection,
      })}
    >
      <legend className={accessibilityStyles.hidden}>{label || name}</legend>
      {Children.map(
        children,
        (listSelectOption: ReactElement<ListSelectOptionProps>) => {
          if (!(listSelectOption && listSelectOption.props)) {
            return listSelectOption;
          }

          const optionProps = listSelectOption.props;
          const checked = getChecked(listSelectOption.props.value);

          return cloneElement(listSelectOption, {
            name,
            onChange: interceptEvent(
              listSelectOption.props.onChange,
              handleChange,
              onChange
            ),
            labelRef: checked ? selectedOptionRef : undefined,
            checked,
            type: multi ? 'checkbox' : 'radio',
            className: classNames(optionProps.className, {
              [listStyles.badge]: Boolean(optionProps.badge),
            }),
          });
        }
      )}
    </fieldset>
  );
};
