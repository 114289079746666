import { track } from '../track';
import { dismissed_customer_account_log_in_notice } from './eventNames';

/**
 * Send a tracking event for when a user dismissed the customer account log in notice
 */

export function trackDismissedCustomerAccountLogInNotice() {
  track({
    eventName: dismissed_customer_account_log_in_notice,
  });
}
