import { track } from '../track';
import { landing_page_clicked_lunch_and_learn_request } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Request Lunch and Learn" button on the landing page
 */

export function trackLandingPageClickedLunchAndLearnRequest() {
  track({ eventName: landing_page_clicked_lunch_and_learn_request });
}
