import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import { FormFields } from '../../../../components/FormFields';
import styles from './AddOrganization.module.css';
import { useFormik } from 'formik';
import {
  AddOrganizationFormValues,
  addOrganizationInitialFormValues,
  generateOrganizationFields,
} from './constants';
import { addOrganizationValidationSchema } from './utils';
import * as pagePaths from '../../../../constants/pagePaths';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';

interface AddOrganizationResponsiveProps extends AddOrganizationProps {
  isMobile?: boolean;
}

function AddOrganizationResponsive({
  disableSubmit,
  isMobile = false,
  isAddPractice = false,
  onSaveChanges,
}: AddOrganizationResponsiveProps) {
  const fields = generateOrganizationFields({ isPractice: isAddPractice });
  const formik = useFormik({
    initialValues: addOrganizationInitialFormValues,
    validationSchema: addOrganizationValidationSchema,
    onSubmit: values => onSaveChanges(values),
  });
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid flexDirection="column" alignItems="center">
          <Button
            variant="plain-text"
            className={styles.backToSearchButton}
            weight="normal"
            underline
            type="link"
            to={{
              pathname: pagePaths.PATH_PROFILE,
              search: 'update=practice&practice=search',
            }}
            color={showPortalUXOverhaul ? 'kale' : 'carrot'}
          >
            Back to Search
          </Button>
          <Text as="h2" variant={'heading-22'} bold>
            {isAddPractice ? 'Practice Details' : 'Organization Details'}
          </Text>
          <GridItem
            className={classNames(styles.containerFields, styles.splitForms)}
          >
            <FormFields
              // @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023
              formik={formik}
              fieldData={fields}
            />
          </GridItem>

          <Divider
            color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
            spacing={isMobile ? 36 : 48}
            width={1}
          />
          <GridItem flexDirection="row" justifyContent="center">
            <Button
              type="submit"
              aria-disabled={!(formik.isValid && formik.dirty) || disableSubmit}
              variant={
                showPortalUXOverhaul
                  ? 'primary-mini-kale-experimental'
                  : 'primary'
              }
              className={styles.button}
            >
              Save Changes
            </Button>
          </GridItem>
        </Grid>
      </form>
    </>
  );
}
export interface AddOrganizationProps {
  onSaveChanges: (values: AddOrganizationFormValues) => void;
  isAddPractice?: boolean;
  disableSubmit: boolean;
}

export const AddOrganization = (props: AddOrganizationProps) => {
  return (
    <div>
      <div className={styles.mobile}>
        <AddOrganizationResponsive isMobile {...props} />
      </div>
      <div className={styles.desktop}>
        <AddOrganizationResponsive {...props} />
      </div>
    </div>
  );
};
