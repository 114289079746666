import * as React from 'react';

import { BorderLabel, useElementState } from '../BorderLabel';
import { WithRefProps, getHasValue, interceptEvent, withRef } from '../../util';

import classNames from 'classnames';
import { heading } from '../../typography';
import styles from './TextArea.css';

const typography = heading.create({ size: 16 });

type IntrinsicTextAreaProps = JSX.IntrinsicElements['textarea'];
type RefProps = WithRefProps<HTMLTextAreaElement>;
export interface TextAreaProps extends IntrinsicTextAreaProps, RefProps {
  /** Label and placeholder of input */
  label?: string;
  /** Display invalid state */
  invalid?: boolean;
  /** The value of the text area */
  value?: string;
  /** Hide BorderLabel but keep it in the accessibility tree */
  hideLabel?: boolean;
}

export const TextAreaComponent: React.FC<TextAreaProps> = ({
  label,
  className,
  invalid,
  onBlur,
  onChange,
  onFocus,
  forwardedRef,
  hideLabel = false,
  ...props
}) => {
  // autoFocus and disabled should be passed on to native element.
  const [state, handlers] = useElementState({
    autoFocus: props.autoFocus,
    disabled: props.disabled,
    invalid,
  });

  const [value, setValue] = React.useState(
    typeof props.value !== 'undefined' ? props.value : ''
  );

  React.useEffect(() => {
    if (typeof props.value !== 'undefined') {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
    setValue(event.target.value);
  };

  return (
    <BorderLabel
      className={classNames(styles.label, className)}
      in={state === 'focus' || state === 'invalid' || getHasValue(value)}
      text={label || props.name || ''}
      state={state}
      hideLabel={hideLabel}
    >
      <textarea
        ref={forwardedRef}
        className={classNames(styles.textarea, typography.className)}
        onChange={interceptEvent(handleChange, onChange)}
        onFocus={interceptEvent(handlers.onFocus, onFocus)}
        onBlur={interceptEvent(handlers.onBlur, onBlur)}
        aria-invalid={invalid}
        {...props}
        value={value}
        placeholder={undefined}
      />
    </BorderLabel>
  );
};

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/textarea | TextArea} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const TextArea = withRef<HTMLTextAreaElement, TextAreaProps>(
  TextAreaComponent
);
