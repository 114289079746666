// TODO: Rework Typography to not return any
// https://app.clubhouse.io/farmersdog/story/42358/Text-types-no-any-type-from-typography-in-Text
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from 'react';
import * as typography from '../../typography';

import classNames from 'classnames';

export const TextVariants = [
  'heading-72',
  'heading-52',
  'heading-40',
  'heading-28',
  'heading-22',
  'heading-21',
  'heading-16',
  'heading-12',
  'body-21',
  'body-16',
  'body-12',
  'article-21',
  'article-20',
  'article-16',
  'article-12',
] as const;

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  /** The text variant to use (required). */
  variant: (typeof TextVariants)[number];
  /** The HTML element to render. */
  as?: React.ElementType;
  /** Set the text font style. */
  fontStyle?:
    | (typeof typography.ArticleStyles)[number]
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | (typeof typography.BodyStyles)[number]
    | (typeof typography.HeadingStyles)[number];
  /** Use bold text weight. */
  bold?: boolean;
  /** Set the text color. */
  color?:
    | (typeof typography.ArticleColors)[number]
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | (typeof typography.BodyColors)[number]
    | (typeof typography.HeadingColors)[number];
}

/**
 * ## Text
 *
 * The `Text` component renders textual content following the CORGI styles.
 *
 * ### Usage
 *
 * - Use this component to render block of texts.
 * - Follow the designer guidelines to choose the right `variant`.
 * - Make sure you use the proper semantic HTML tags for the `as` property.
 *
 * ### Example
 *
 * ```jsx
 * import { Text } from '@farmersdog/corgi';
 *
 * function () {
 *  return (
 *    <Text color="charcoal-3" as="h1" bold variant="heading-52">
 *      This is a bold title!
 *    </Text>
 *  )
 * }
 * ```
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/text | Text} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const Text: React.FC<TextProps> = ({
  as: HTMLElement = 'span',
  variant = 'heading-52',
  bold,
  fontStyle,
  color,
  className,
  children,
  ...props
}) => {
  const [type, size] = variant.split('-');

  let weight = 'normal';

  if (bold) {
    if (type === 'heading' && Number(size) > 22) {
      weight = 'dynamicBold';
    } else {
      weight = 'bold';
    }
  }

  // @ts-expect-error not sure why this works at runtime, but typescript isn't happy
  const typographyStyles = typography[type as keyof typeof typography].create({
    size,
    weight,
    fontStyle,
    color,
  });

  return (
    <HTMLElement
      className={classNames(className, typographyStyles.className as string)}
      {...props}
    >
      {children}
    </HTMLElement>
  );
};
