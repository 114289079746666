import config from '../../config';

const websiteMeasurementId = config.get('app.ga4.websiteMeasurementId');
const vetPortalMeasurementId = config.get('app.ga4.vetPortalMeasurementId');

export const ga4ScriptContent = ` 
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${websiteMeasurementId}', { send_page_view: false });
gtag('config', '${vetPortalMeasurementId}', { send_page_view: false });
`;

export const ga4WebsiteMeasurementSrc = `https://www.googletagmanager.com/gtag/js?id=${websiteMeasurementId}`;
export const ga4VetPortalMeasurementSrc = `https://www.googletagmanager.com/gtag/js?id=${vetPortalMeasurementId}`;

export function ga4Script() {
  if (!config.get('app.ga4.enabled')) {
    return '';
  }

  return `
  <script async src="${ga4WebsiteMeasurementSrc}"></script>
  <script async src="${ga4VetPortalMeasurementSrc}"></script>
  <script>${ga4ScriptContent}</script>`;
}
