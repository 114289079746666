import { useLazyQuery } from '../../services/apollo';
import { ValidatePracticeQuery, QueryValidatePracticeArgs } from '../types';
import validatePracticeQuery from '../queries/validate-practice.graphql';

export function useValidatePractice() {
  const [validatePractice, { loading }] = useLazyQuery<
    ValidatePracticeQuery,
    QueryValidatePracticeArgs
  >(validatePracticeQuery, {
    fetchPolicy: 'cache-first',
  });

  return {
    validatePractice,
    loading,
  };
}
