import { Text } from '@farmersdog/corgi-x';
import CalendarSVG from './assets/calendar.svg?react';
import styles from './Calendar.module.css';

interface CalendarProps {
  month: string;
  day: string;
}
export const Calendar = ({ day, month }: CalendarProps) => (
  <div className={styles.container}>
    <CalendarSVG />
    <div className={styles.dateContainer}>
      <Text variant="heading-12" bold bottomSpacing="none">
        {month}
      </Text>

      <Text variant="heading-12" bold topSpacing="none">
        {day}
      </Text>
    </div>
  </div>
);
