import { Text, Button } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import styles from './EmailSection.module.css';

export const EmailSection = () => {
  return (
    <div className={styles.footerCard}>
      <Card>
        <Text
          as="h3"
          color="Blueberry3"
          variant="heading-28"
          bold
          bottomSpacing="sm"
          topSpacing="none"
        >
          Prefer email?
        </Text>

        <Text color="charcoal-3" variant="article-16">
          You can always send us a message at{' '}
          <Button
            variant="plain-text"
            type="anchor"
            color="kale"
            weight="bold"
            href={'mailto:vetpros@thefarmersdog.com'}
            underline
          >
            vetpros@thefarmersdog.com
          </Button>
          .
        </Text>
      </Card>
    </div>
  );
};
