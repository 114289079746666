import * as CSS from 'csstype';

import { CSSModule } from '../types';
import classNames from 'classnames';

export interface TypographyProps {
  readonly color?: string;
  readonly size?: number | string;
  readonly weight?: string | number;
  readonly fontStyle?: CSS.Property.FontStyle;
}

export interface TypographyCSSModules {
  base: CSSModule;
  colors: CSSModule;
  sizes: CSSModule;
  weights: CSSModule;
  fontStyles: CSSModule;
}

export class Typography<T extends TypographyProps> {
  props: Partial<T>;
  cssModules: TypographyCSSModules;

  constructor(cssModules: TypographyCSSModules, props: Partial<T>) {
    this.props = props;
    this.cssModules = cssModules;
  }

  get className(): string {
    return this.toString();
  }

  /**
   * Return a new typography object that merges the current and next typographic
   * properties.
   */
  create(props: Partial<T>): Typography<Partial<T>> {
    return new Typography(
      this.cssModules,
      Object.assign({}, this.props, props)
    );
  }

  /**
   * Return a new typography object that merges the current properties with a
   * font color.
   */
  color(nextColor: T['color']): Typography<T> {
    const change = { color: nextColor } as Partial<T>;
    return this.create(change);
  }

  /**
   * Return a new typography object that merges the current properties with a
   * font size.
   */
  size(nextSize: T['size']): Typography<T> {
    const change = { size: nextSize } as Partial<T>;
    return this.create(change);
  }

  /**
   * Return a new typography object that merges the current properties with a
   * font weight.
   */
  weight(nextWeight: T['weight']): Typography<T> {
    const change = { weight: nextWeight } as Partial<T>;
    return this.create(change);
  }

  /**
   * Return a new typography object that merges the current properties with a
   * font style.
   */
  fontStyle(nextStyle: T['fontStyle']): Typography<T> {
    const change = { fontStyle: nextStyle } as Partial<T>;
    return this.create(change);
  }

  /**
   * Return a new typography object that merges the current properties with the
   * font weight of `bold`.
   */
  bold(): Typography<T> {
    return this.weight('bold');
  }

  /**
   * Return a single class name that matches a given typographic property with
   * the class names provided by this object's css modules.
   */
  getClassName(
    prop: keyof TypographyCSSModules,
    className?: string | number
  ): string | undefined {
    if (typeof className === 'undefined') {
      return;
    }

    return this.cssModules[prop][className];
  }

  /**
   * Return a string value of css class names for the appropriate typographic
   * properties that have been applied to this object.
   */
  toString(): string {
    const { color, size, weight, fontStyle } = this.props;

    return classNames(
      ...Object.values(this.cssModules.base),
      this.getClassName('colors', color),
      this.getClassName('sizes', `size-${size ?? 'undefined'}`),
      this.getClassName('weights', weight),
      this.getClassName('fontStyles', fontStyle)
    );
  }
}
