import { Logger } from '@farmersdog/logger';
import config from '../../config';

const log = new Logger('app:ga4');

type sendToLookupType = {
  [index: string]: string;
};

interface getGa4EventConfigArgs {
  sendTo: 'default' | 'vetPortal' | 'website';
}
const sendToLookup = (lookup: string) => {
  const lookupHash: sendToLookupType = {
    default: 'default',
    vetPortal: config.get('app.ga4.vetPortalMeasurementId') || '',
    website: config.get('app.ga4.websiteMeasurementId') || '',
  };

  return lookupHash[lookup];
};

/* allows us to dynamically choose which project to send GA events to. 
Using default will send to both. */

export const getGa4EventConfig = (
  { sendTo = 'default' } = {} as getGa4EventConfigArgs
) => {
  const measurementId = sendToLookup(sendTo);
  if (!measurementId) {
    log.error('Ga4 measurementId is not defined');
  }

  return {
    enabled: config.get('app.ga4.enabled'),
    debugView: config.get('app.ga4.debugView'),
    measurementId: measurementId,
    loadSdk: true,
  };
};
