import React, { ReactNode } from 'react';

import { WithRefProps, interceptEvent, withRef } from '../../util';

import { CheckboxView } from '../Checkbox';
import { RadioView } from '../Radio';
import { SingleValue } from './types';
import { Text } from '../Text';
import accessibilityStyles from '../../styles/accessibility.css';
import classNames from 'classnames';
import styles from './ListSelectOption.css';
import { useElementState } from '../BorderLabel';

type IntrinsicInputProps = JSX.IntrinsicElements['input'];
type RefProps = WithRefProps<HTMLInputElement>;
export interface ListSelectOptionProps extends IntrinsicInputProps, RefProps {
  /** The label used for this element. Required for accessibility. */
  label?: string;
  /** Styled description used for this element. */
  description: string;
  /** The value of this element. */
  value: SingleValue;
  /** Display invalid state */
  invalid: boolean;
  /** Display checkbox */
  withCheckbox: boolean;
  /** Display radio */
  withRadio?: boolean;
  /** Display a badge */
  badge?: string;
  /** Display a recommended badge */
  recommended?: boolean;
  /** A ref forwarded to the option label */
  labelRef?: React.Ref<HTMLLabelElement>;
  children?: ReactNode;
}

export const ListSelectOptionComponent: React.FC<ListSelectOptionProps> = ({
  label,
  invalid,
  forwardedRef,
  onBlur,
  onFocus,
  withCheckbox,
  withRadio,
  badge,
  className,
  labelRef,
  style,
  children,
  ...props
}) => {
  // autoFocus and disabled should be passed on to native element.
  const [state, handlers] = useElementState({
    autoFocus: props.autoFocus,
    disabled: props.disabled,
    invalid,
  });

  return (
    <label
      ref={labelRef}
      className={classNames(styles.label, styles[state], className, {
        [styles.checked]: props.checked,
      })}
      style={style}
    >
      {badge && (
        <div
          className={classNames(styles.badge, {
            [styles.checked]: props.checked,
            [styles.recommended]: props.recommended,
            [styles.disabled]: state === 'disabled',
          })}
        >
          <Text role="status" variant="heading-12" className={styles.badgeText}>
            {badge}
          </Text>
        </div>
      )}
      <div>
        <Text
          as="div"
          variant="heading-22"
          color={state === 'disabled' ? 'charcoal-1' : 'kale-3'}
          bold
        >
          {label || props.name || ''}
        </Text>
        {props.description && (
          <Text
            as="div"
            variant="heading-16"
            color={state === 'disabled' ? 'charcoal-1' : 'charcoal-3'}
          >
            {props.description}
          </Text>
        )}
        {children}
      </div>
      {withCheckbox && (
        <div className={styles.checkbox}>
          <CheckboxView checked={props.checked} state={state} />
        </div>
      )}
      {withRadio && <RadioView checked={props.checked} state={state} />}
      <input
        ref={forwardedRef}
        className={classNames(accessibilityStyles.hidden, styles.input)}
        onBlur={interceptEvent(handlers.onBlur, onBlur)}
        onFocus={interceptEvent(handlers.onFocus, onFocus)}
        data-checked={props.checked}
        {...props}
      />
    </label>
  );
};

export const ListSelectOption = withRef(ListSelectOptionComponent);
