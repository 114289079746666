import { Text } from '@farmersdog/corgi-x';
import { Modal } from '@farmersdog/corgi';
import { LightboxId, useGlobalLightbox } from '../../../../hooks';

export const listItems = [
  'Practice Manager / Office Manager',
  'Veterinary Technician/Nurse',
  'Veterinary Technician/Nurse Student',
  'Veterinarian',
  'Veterinary Student',
  'Other Veterinary Healthcare Team Member',
];

export function EligibleModal() {
  const { rootProps, labelProps } = useGlobalLightbox({
    id: LightboxId.WhoIsEligible,
  });

  return (
    // @ts-expect-error Types are incorrect
    <Modal {...rootProps}>
      <div>
        <Text
          bold
          as="h3"
          {...labelProps}
          vSpacing="none"
          color="blueberry-3"
          variant="heading-40"
        >
          Veterinary Discount Eligible Professions
        </Text>
        <Text as="p" variant="article-16">
          The following veterinary professions are eligible:
        </Text>
        <ul>
          {listItems.map(item => (
            <li key={item}>
              <Text variant="article-16">{item}</Text>
            </li>
          ))}
        </ul>
        <Text as="p" variant="article-16">
          Professionals may be retired.
        </Text>
      </div>
    </Modal>
  );
}
