import { Redirect, useLocation } from 'react-router';

import * as pagePaths from '../../constants/pagePaths';

export const RedirectToNotFound = () => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: pagePaths.PATH_NOT_FOUND,
        state: { from: location },
      }}
    />
  );
};
