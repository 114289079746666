import React, { FC, useContext, useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';
import { Toast } from './Toast';
import { ToastContext } from './ToastContext';
import { ToastDialog } from './ToastDialog';
import { TransitionGroup } from 'react-transition-group';
import { addDOMElementById } from '../Lightbox/dom';
import styles from './ToastRenderer.css';

export const ToastRenderer: FC = () => {
  const root = useRef<HTMLElement>();
  const { activeToasts, activeToastDialogs } = useContext(ToastContext);

  useEffect(() => {
    root.current = addDOMElementById(styles.tfdToast);
  }, []);

  if (!root.current || !activeToastDialogs || !activeToasts) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <TransitionGroup component={null}>
        {activeToastDialogs.map(toastDialogProps => (
          <ToastDialog key={toastDialogProps.id} {...toastDialogProps} />
        ))}
        {activeToasts.map(toastProps => (
          <Toast key={toastProps.id} {...toastProps} />
        ))}
      </TransitionGroup>
    </div>,
    root.current
  );
};
