import * as React from 'react';

import { ElementState } from './useElementState';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import { heading } from '../../typography';
import labelStyles from './BorderLabel.css';
import textStyles from './Text.css';
import { AccessibleContent } from '../AccessibleContent';

const ANIMATION_DURATION = 200;
const typography = heading.create({ size: 16 });

export interface BorderLabelProps {
  /** Optional class name */
  className?: string;
  /** Boolean prop that toggles the animation state of the label text. */
  in: boolean;
  /**
   * The text to render as the label. BorderLabel functions as both an aria
   * compliant label as well as placeholder.
   */
  text: string;
  /**
   * The state of the element. See `useElementState` for additional usage
   * information and possible state values
   */
  state: ElementState;
  /** Hide BorderLabel but keep it in the accessibility tree */
  hideLabel?: boolean;
}

/**
 * An animated label that serves as both a WCAG compliant element label as well
 * as a placholder. The animations occur based on the different states of the
 * element.
 *
 * In adherence of best WCAG practices we put the interface element inside of
 * the label.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/fieldlabel | FieldLabel} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const BorderLabel: React.FC<
  React.PropsWithChildren<BorderLabelProps>
> = ({
  state,
  children,
  text,
  in: show,
  className,
  hideLabel = false,
  ...props
}) => {
  return (
    <Transition in={show} timeout={ANIMATION_DURATION}>
      {(transitionState): React.ReactElement => (
        <div className={classNames(labelStyles.container, className)}>
          <label
            {...props}
            className={classNames(labelStyles.label, labelStyles[state])}
          >
            {children}

            {hideLabel && show ? (
              <AccessibleContent visualContent={''} accessibleText={text} />
            ) : (
              <div
                className={classNames(
                  textStyles.text,
                  textStyles[transitionState],
                  textStyles[state],
                  typography.className
                )}
              >
                {text}
              </div>
            )}
          </label>
        </div>
      )}
    </Transition>
  );
};
