import type stripeJs from '@stripe/stripe-js';

const WOFF =
  'https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3';

export const FONTS: stripeJs.CustomFontSource[] = [
  {
    family: 'neue-haas-grotesk-display',
    src: `url(${WOFF})`,
    display: 'auto',
    style: 'normal',
    weight: '400',
  },
];
