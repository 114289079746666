import { ElementType } from 'react';

import {
  Login,
  Logout,
  Signup,
  SignupConference,
  ForgotPassword,
  ResetPassword,
  Account,
  Profile,
  Resources,
  OurTeam,
  ContactUs,
  TrialDiscounts,
  NotFound,
  RedirectToNotFound,
  EventsAndRecordings,
  RecordedEvent,
  FAQs,
  Home,
} from '../pages';

import * as pagePaths from './pagePaths';

interface routeType {
  path: string;
  component: ElementType;
  isPrivate?: boolean;
  exact?: boolean;
  // Metadata
  title?: string;
  description?: string;
}

interface RouteElementType {
  [key: string]: routeType;
}

export const routes: RouteElementType = {
  index: {
    path: pagePaths.PATH_HOME,
    component: Home,
    exact: true,
    title: 'Vet Team Portal Home',
  },
  login: {
    path: pagePaths.PATH_LOGIN,
    component: Login,
    // Metadata
    title: 'Vet Team Portal Log in',
    description:
      'Welcome Back! We’re glad you’re here. Please log in to continue.',
  },
  signUp: {
    path: pagePaths.PATH_SIGNUP,
    component: Signup,
    exact: true,
    // Metadata
    title: 'Vet Team Portal Sign up',
    description:
      'Vet Portal Registration. Get access to useful info on canine nutrition, downloadable resources for your practice, and discounts on fresh food.',
  },
  signUpConference: {
    path: pagePaths.PATH_SIGNUP_CONFERENCE,
    component: SignupConference,
    title: 'Vet Team Portal Conference Sign up',
    exact: true,
  },
  logOut: {
    path: pagePaths.PATH_LOGOUT,
    component: Logout,
  },
  forgotPassword: {
    path: pagePaths.PATH_FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  resetPassword: {
    path: pagePaths.PATH_RESET_PASSWORD,
    component: ResetPassword,
    title: 'Vet Team Portal Reset Password',
  },
  profile: {
    path: pagePaths.PATH_PROFILE,
    component: Profile,
    isPrivate: true,
    exact: true,
    title: 'My Profile',
  },
  faqs: {
    path: pagePaths.PATH_FAQ,
    component: FAQs,
    isPrivate: true,
    exact: true,
    title: 'Vet Team Portal FAQs',
  },
  dashboard: {
    path: pagePaths.PATH_DASHBOARD,
    component: Account,
    isPrivate: true,
    exact: true,
    title: 'Vet Team Portal Dashboard',
  },
  downloads: {
    path: pagePaths.PATH_DOWNLOADS,
    component: Resources,
    isPrivate: true,
    title: 'Vet Team Portal Resources',
  },
  ourTeam: {
    path: pagePaths.PATH_OUR_TEAM,
    component: OurTeam,
    isPrivate: true,
    title: 'Vet Team Portal Our Team',
  },
  contactUs: {
    path: pagePaths.PATH_CONTACT_US,
    component: ContactUs,
    isPrivate: true,
    title: 'Vet Team Portal Contact Us',
  },
  trialDiscounts: {
    path: pagePaths.PATH_TRIAL_DISCOUNTS,
    component: TrialDiscounts,
    isPrivate: true,
    title: 'Vet Team Portal Trial and Discounts',
  },

  eventsAndRecordings: {
    path: pagePaths.PATH_EVENTS_AND_RECORDINGS,
    component: EventsAndRecordings,
    isPrivate: true,
    title: 'Vet Team Portal Events and Recordings',
    exact: true,
  },
  eventAndRecording: {
    path: pagePaths.PATH_EVENT_AND_RECORDING,
    component: RecordedEvent,
    isPrivate: true,
    title: 'Vet Team Portal Recorded Event',
  },
  notFound: {
    path: pagePaths.PATH_NOT_FOUND,
    component: NotFound,
    title: 'Vet Team Portal Not Found',
  },
  fourOhFour: {
    path: '*',
    component: RedirectToNotFound,
    title: 'Vet Team Portal Redirect to Not Found',
  },
};
