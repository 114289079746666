import * as React from 'react';
import type stripeJs from '@stripe/stripe-js';

/** An initialized instance of the stripe global object */
export type StripeInstance = stripeJs.Stripe;

/** An interface representing the value of `StripeContext` */
export interface StripeContextValue {
  stripe?: StripeInstance;
  stripeLoadError?: Error;
}

const INITIAL_VALUE: StripeContextValue = {};
export const StripeContext = React.createContext(INITIAL_VALUE);
