import { useEffect, useState, useCallback } from 'react';

/** An array of all possible states that an element can have */
export const ElementStates = [
  'invalid',
  'disabled',
  'focus',
  'highlighted',
  'normal',
] as const;
/** An union type of possible states that an element can have */
export type ElementState = (typeof ElementStates)[number];

/**
 * Props consumed by `useElementState` that can trigger changes in the state of
 * the element.
 */
export interface ElementStateProps {
  /** The html autoFocus boolean used to set initial state. */
  autoFocus?: boolean;
  /** The html disabled boolean used to set internal states. */
  disabled?: boolean;
  /** A boolean value used to set internal validation state. */
  invalid?: boolean;
  /** A boolean value used to set highlighted state. */
  highlighted?: boolean;
}

/**
 * An interface representing the handlers returned by `useElementState`. These
 * handlers are used to intercept focus and blur events so that the element
 * state stays in sync.
 */
export interface ReturnHandlers<T> {
  /** The blur handler function created by this hook. */
  onBlur: (event: T) => void;
  /** The focus handler function created by this hook. */
  onFocus: (event: T) => void;
}

/**
 * Return a single string of the highest priority state given an array of
 * currently active states.
 */
function getState(states: ElementState[]): ElementState {
  if (states.includes('disabled')) {
    return 'disabled';
  }

  if (states.includes('invalid')) {
    return 'invalid';
  }

  if (states.includes('focus')) {
    return 'focus';
  }

  if (states.includes('highlighted')) {
    return 'highlighted';
  }

  return 'normal';
}

/**
 * Use interface element states based on props. Return an enum value of the
 * current element state as well as handlers to intercept focus and blur events
 * so that they stay in sync with the element state.
 */
export const useElementState = <T>(
  props: ElementStateProps = {}
): [ElementState, ReturnHandlers<T>] => {
  const invalid = Boolean(props.invalid);
  const disabled = Boolean(props.disabled);
  const autoFocus = Boolean(props.autoFocus);
  const highlighted = Boolean(props.highlighted);
  const [states, setStates] = useState<ElementState[]>([]);

  const addState = useCallback((nextState: ElementState) => {
    setStates(lastStates => [...lastStates, nextState]);
  }, []);

  const removeState = useCallback((nextState: ElementState) => {
    setStates(lastStates => lastStates.filter(state => state !== nextState));
  }, []);

  useEffect(() => {
    if (invalid) {
      addState('invalid');
    } else {
      removeState('invalid');
    }
  }, [addState, removeState, invalid]);

  useEffect(() => {
    if (disabled) {
      addState('disabled');
    } else {
      removeState('disabled');
    }
  }, [addState, removeState, disabled]);

  useEffect(() => {
    if (highlighted) {
      addState('highlighted');
    } else {
      removeState('highlighted');
    }
  }, [addState, removeState, highlighted]);

  useEffect(() => {
    if (autoFocus) {
      addState('focus');
    } else {
      removeState('focus');
    }
  }, [addState, removeState, autoFocus]);

  const handleFocus = (): void => {
    addState('focus');
  };

  const handleBlur = (): void => {
    removeState('focus');
  };

  return [
    getState(states),
    {
      onBlur: handleBlur,
      onFocus: handleFocus,
    },
  ];
};
