import { FC } from 'react';

import { Text } from '@farmersdog/corgi-x';

import styles from './SubTitle.module.css';

export interface SubTitleProps {
  children: string | string[];
}

export const SubTitle: FC<SubTitleProps> = ({ children }) => {
  return (
    <>
      <div className={styles.container}>
        <Text
          as="p"
          variant="article-16"
          color="charcoal-3"
          className={styles.subtitle}
        >
          {children}
        </Text>
      </div>
    </>
  );
};
