/**
 * Safely execute browser only code.
 *
 * @deprecated Use `browserOnly` from `@farmersdog/utils`.
 */
export const browserOnly = <T>(
  fn: (window: Window, document: Document) => T,
  fallback?: () => T
): T | undefined => {
  if (typeof window !== 'undefined') {
    return fn(window, document);
  }

  if (fallback) return fallback();
  return;
};
