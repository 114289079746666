import { Card } from '../../../components';
import { Grid, GridItem } from '@farmersdog/corgi-x';
import { OnDemandHeader } from './OnDemandHeader';
import { OnDemandEvents } from './OnDemandEvents';

interface OnDemandVideosProps {
  isMobile?: boolean;
  showPortalUXOverhaul?: boolean;
}

export const OnDemandVideos = ({
  isMobile,
  showPortalUXOverhaul,
}: OnDemandVideosProps) => {
  if (showPortalUXOverhaul) {
    return (
      <GridItem>
        <Grid rowGap={'lg'} flexDirection="column">
          <GridItem>
            <OnDemandHeader isMobile={isMobile} />
          </GridItem>

          <Card>
            <OnDemandEvents
              isMobile={isMobile}
              showPortalUXOverhaul={showPortalUXOverhaul}
            />
          </Card>
        </Grid>
      </GridItem>
    );
  }

  return (
    <Card>
      <OnDemandHeader isMobile={isMobile} />

      <OnDemandEvents
        isMobile={isMobile}
        showPortalUXOverhaul={showPortalUXOverhaul}
      />
    </Card>
  );
};
