import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function usePdfRedirects() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_PORTAL_PDF_REDIRECTS, {});

  const isPdfRedirectsActive = treatment === OnOffTreatments.on;
  return {
    isPdfRedirectsActive,
  };
}
