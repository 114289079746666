import * as React from 'react';

import { CardField, CardFieldProps } from './CardField';

import { elementTypes } from '../Stripe/elementTypes';

type CardExpirationProps = Omit<CardFieldProps, 'elementType'>;

/**
 * Render a credit card expiration field. Must be inside an `ElementsProvider`
 * and a `StripeProvider`
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/creditcardinput | CreditCardInput} from corgi-x to create card fields. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const CardExpiration: React.FC<CardExpirationProps> = props => {
  return <CardField {...props} elementType={elementTypes.cardExpiry} />;
};
