// TODO - Replace the pdf file with the new one. Keep the file name the same as below.
// So that means we shouldn't need a code change here except to remove this comment. We should only see the diff of the pdf file on github
import vetNutritionGuideFile from '../../../../../static/pdf/TFD_Vet_Nutrition_Guide_2024.pdf';
import feedingTrialAffidavitsFile from '../../../../../static/pdf/Feeding-Trial-Affidavits.pdf';
import decodingCanineDCMFile from '../../../../../static/pdf/DecodingCanineDCM.pdf';
import digestibilityOnesheetFile from '../../../../../static/pdf/DigestibilityOnesheet.pdf';
import urinaryHealthFile from '../../../../../static/pdf/TFD_Understanding-RSS.pdf';
import understandingFatConsumptionFile from '../../../../../static/pdf/TFD_Understanding-Fat-Consumption.pdf';
import beefRecipeSheet from '../../../../../static/pdf/BeefRecipe.pdf';
import chickenRecipeSheet from '../../../../../static/pdf/ChickenRecipe.pdf';
import porkRecipeSheet from '../../../../../static/pdf/PorkRecipe.pdf';
import turkeyRecipeSheet from '../../../../../static/pdf/TurkeyRecipe.pdf';
import chickenAndGrainRecipeSheet from '../../../../../static/pdf/ChickenAndGrainRecipe.pdf';
import porkAndGrainRecipeSheet from '../../../../../static/pdf/PorkAndGrainRecipe.pdf';
import tfdAndWSAVA from '../../../../../static/pdf/TFD_WSAVA_Onesheet.pdf';
import manufacturingandFoodSafety from '../../../../../static/pdf/ManufacturingandFoodSafety.pdf';
import clinicalIndicationsChart from '../../../../../static/pdf/ClinicalIndicationsChart.pdf';
import performEliminationDietTrialsFile from '../../../../../static/pdf/PerformEliminationDietTrials.pdf';

export interface RecipeListData {
  title: string;
  file: string;
}

export const recipeListData: RecipeListData[] = [
  {
    title: 'Beef',
    file: beefRecipeSheet,
  },
  {
    title: 'Chicken',
    file: chickenRecipeSheet,
  },
  {
    title: 'Pork',
    file: porkRecipeSheet,
  },
  {
    title: 'Turkey',
    file: turkeyRecipeSheet,
  },
  {
    title: 'Chicken & Grain',
    file: chickenAndGrainRecipeSheet,
  },
  {
    title: 'Pork & Grain',
    file: porkAndGrainRecipeSheet,
  },
];

export interface DownloadListData {
  title: string;
  description: string;
  file: string;
}

export const nutritionGuide: DownloadListData[] = [
  {
    title: '2024 Nutrition Guide',
    description:
      'A comprehensive review of The Farmer’s Dog nutritional info, safety standards, and feeding instructions. For the use of veterinary professionals.',
    file: vetNutritionGuideFile,
  },
];

export const downloadListData: DownloadListData[] = [
  {
    title: 'Clinical Indications',
    description:
      "Learn more about how The Farmer's Dog can be used therapeutically to support your patients with medical conditions.",
    file: clinicalIndicationsChart,
  },
  {
    title: 'Feeding Study Affidavits',
    description:
      'Affidavits from veterinarians who oversaw our feeding studies.',
    file: feedingTrialAffidavitsFile,
  },
  {
    title: 'The Farmer’s Dog and WSAVA',
    description:
      'Detailed breakdown of How The Farmer’s Dog meets or exceeds all of the WSAVA guidelines for selecting a pet food.',
    file: tfdAndWSAVA,
  },
  {
    title: 'The Farmer’s Dog Manufacturing and Food Safety',
    description:
      'The Farmer’s Dog is raising the bar on every aspect of pet food, with a particular focus on manufacturing and food safety. Learn more about our production and safety methods methods.',
    file: manufacturingandFoodSafety,
  },
  {
    title: 'Digestibility: A Core Nutritional Metric',
    description:
      'A key measure of a pet food’s quality, affecting not just how much stool is produced each day but a dog’s long-term well-being.',
    file: digestibilityOnesheetFile,
  },
  {
    title:
      'Urinary Health: Understanding Urinary Relative Supersaturation (RSS)',
    description:
      'Relative supersaturation (RSS) is considered the gold standard for risk assessment of crystal formation (struvite and oxalate) in urine for dogs.',
    file: urinaryHealthFile,
  },
  {
    title: 'Understanding Fat Consumption by Calculating Grams of Fat Consumed',
    description:
      'See how we are able to use more meat and non-rendered fats while keeping fat consumption at or below a typical veterinarian-recommended kibble.',
    file: understandingFatConsumptionFile,
  },
  {
    title: 'Decoding Canine DCM: The Truth Behind the Headlines',
    description:
      'In order to address the most persistent misunderstandings, we’ve compiled statements from the FDA along with the latest peer-reviewed research, and commentary from board-certified nutritionists.',
    file: decodingCanineDCMFile,
  },
  {
    title:
      "How to Perform Elimination Diet Trials Using The Farmer's Dog Recipes",
    description:
      'Our human-grade manufacturing process avoid the risk of cross-contamination and introduction of unwanted allergens, making our recipes great options for dogs with food sensitivities.',
    file: performEliminationDietTrialsFile,
  },
];
