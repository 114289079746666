import * as React from 'react';

import { CustomSelectorProps, Selector } from '../Selector';

import { Check } from '../../icons';
import { ElementState } from '../BorderLabel';
import { Text } from '../Text';
import classNames from 'classnames';
import { getCheckboxDesign } from '../../designs/getCheckboxDesign';
import { getLineThroughDesign } from '../../designs/getLineThroughDesign';
import { getRadioDesign } from '../../designs/getRadioDesign';
import styles from './RichSelector.css';

export interface RichSelectorProps extends CustomSelectorProps {
  /** Content displayed directly beneath the input button */
  label: React.ReactNode;
  /** Image to display above the input button */
  icon?: React.ReactNode;
  /** Extended content for the selector */
  description?: React.ReactNode;
  /** State of the input from useElementState  */
  state?: ElementState;
  /** Toggles design if selector is first in list of selectors */
  isFirst?: boolean;
  /** Toggles design if selector is last in list of selectors */
  isLast?: boolean;
}

/**
 * Selector component to display very detailed options.  Can display an image
 * and extended description.  Also set up to connect sibling options together
 * via the `lineThroughDesign`
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/richselector | RichSelector} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const RichSelector: React.FC<RichSelectorProps> = ({
  label,
  isFirst,
  isLast,
  icon,
  description,
  type,
  state: stateProp,
  ...props
}) => {
  const isCheckbox = type === 'checkbox';
  const getInputDesign = isCheckbox ? getCheckboxDesign : getRadioDesign;

  return (
    <Selector type={type} {...props}>
      {(state: ElementState): React.ReactNode => {
        const inputDesign = getInputDesign({
          state: stateProp ?? state,
          checked: props.checked,
        });

        const lineThroughDesign = getLineThroughDesign({
          first: isFirst,
          last: isLast,
        });

        return (
          <div className={classNames(styles.container, styles[state])}>
            {icon && <div className={styles.icon}>{icon}</div>}
            <span className={lineThroughDesign}>
              <span
                className={classNames(inputDesign, styles.input, {
                  [styles.largeInput]: !icon,
                })}
              >
                {isCheckbox && <Check fill="white" size={12} />}
              </span>
            </span>
            <Text
              as="span"
              variant="heading-16"
              color="kale-3"
              className={styles.label}
            >
              {label}
            </Text>
            {description}
          </div>
        );
      }}
    </Selector>
  );
};
