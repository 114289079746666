import { positionOptions } from '../../../../../constants';
import { FieldType, FormFieldArray } from '../../../../FormFields';

export enum ReviewInfoFormFields {
  position = 'position',
  acceptedPrivacyPolicy = 'acceptedPrivacyPolicy',
}

export const reviewInfoFormFieldsData: FormFieldArray<ReviewInfoFormFields> = [
  {
    name: ReviewInfoFormFields.position,
    type: FieldType.Select,
    label: 'Role',
    options: positionOptions,
    className: 'inputFullWidth',
  },
];
