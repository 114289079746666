import baseStyles from '../../styles/button.css';
import classNames from 'classnames';
import { heading } from '../../typography';
import styles from './getButtonDesign.css';

/** All of the possible design variants available for the button design */
export const ButtonVariants = ['primary', 'secondary'] as const;

/** The properties of the button design */
export interface ButtonDesignProps {
  /** Change the appearance */
  variant?: (typeof ButtonVariants)[number];
  /** @ignore Toggle active status */
  active?: boolean;
  /** @ignore Toggle hover status */
  hover?: boolean;
  /** Toggle pill design */
  asPill?: boolean;
  /** Toggle mini design */
  asMini?: boolean;
  /** Toggle disabled style */
  disabled?: boolean;
}

const typography = heading.create({ size: 16, weight: 'bold' });

/**
 * Return a class name the applies the button design based on the button design
 * props interface.
 */
export function getButtonDesign({
  variant = 'primary',
  disabled = false,
  active = false,
  hover = false,
  asPill = false,
  asMini = false,
}: ButtonDesignProps): string {
  return classNames(styles.button, baseStyles.button, typography.className, {
    [styles[variant]]: variant,
    [styles.disabled]: disabled,
    [styles.active]: active,
    [styles.hover]: hover,
    [styles.pill]: asPill,
    [styles.mini]: asMini,
  });
}
