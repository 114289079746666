import * as yup from 'yup';
import { ReviewInfoFormFields } from './reviewInfoFormFieldsData';

import { Position } from '../../../../../graphql/types';
import { fieldRequiredErrorMessage } from '../../../../../utils';

export const reviewInfoFormValidationSchema = yup.object({
  [ReviewInfoFormFields.position]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(Object.values(Position)),
  [ReviewInfoFormFields.acceptedPrivacyPolicy]: yup
    .boolean()
    .required(fieldRequiredErrorMessage)
    .oneOf([true], 'You must accept the terms and conditions.'),
});
