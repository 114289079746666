/**
 * Return a number between min and max based on progress. Progress should be a
 * decimal between 0 and 1 that represents how much of the task (usually
 * animation) has been completed.
 *
 * @example
 *
 * const startingScale = 15;
 * const endingScale = 115;
 * const progress = 0.6 // compute this linearly or along a bezier curve
 *
 * const currentX = getScale(progress, startingScale, endingScale)
 */
export function getScale(
  progress: number,
  minScale: number,
  maxScale: number
): number {
  const scaleDistance = maxScale - minScale;
  const scale = minScale + scaleDistance * progress;

  return scale;
}
