import * as React from 'react';
import { Check } from '../../icons';
import { getCheckboxDesign } from '../../designs/getCheckboxDesign';

type IntrinsicSpanProps = JSX.IntrinsicElements['span'];

type CheckboxViewState =
  | 'invalid'
  | 'disabled'
  | 'focus'
  | 'normal'
  | 'highlighted';
export interface CheckboxViewProps extends IntrinsicSpanProps {
  state?: CheckboxViewState;
  checked?: boolean;
}

export const CheckboxView: React.FC<CheckboxViewProps> = ({
  state,
  checked,
}) => {
  const inputDesign = getCheckboxDesign({ state: state ?? 'normal', checked });
  return (
    <span className={inputDesign}>
      {' '}
      <Check fill="white" size={12} />
    </span>
  );
};
