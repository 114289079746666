import { FC } from 'react';

import { useHistory } from 'react-router';
import { useFormError } from '../FormError';
import { useAuthContext } from '../../context';

import { FormFields } from '../FormFields';
import { Button, Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { getErrorMessage } from '../../services/auth/utils/errors';

import * as pagePaths from '../../constants';
import {
  initialFormValues,
  resetPasswordFormFieldsData,
  resetPasswordFormValidationSchema,
} from './constants';

import styles from './ResetPasswordForm.module.css';
import { useFormikWithFocusOnError } from '../../hooks';

export const ResetPasswordForm: FC = () => {
  const history = useHistory();

  const { login, resetPassword } = useAuthContext();

  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const formik = useFormikWithFocusOnError({
    initialValues: initialFormValues,
    validationSchema: resetPasswordFormValidationSchema,
    onSubmit: async ({ code, email, password }, { setSubmitting }) => {
      clearFormErrorMessage();
      try {
        if (!login) {
          throw new Error('No login function provided');
        }
        if (!resetPassword) {
          throw new Error('No reset password function provided');
        }
        await resetPassword({
          code,
          email,
          password,
        });
        await login({ email, password, persistSession: true });
      } catch (error: unknown) {
        const message = getErrorMessage(error, true);
        setFormErrorMessage(message);
        return;
      }
      setSubmitting(false);
      history.push(pagePaths.PATH_DASHBOARD);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={resetPasswordFormFieldsData} formik={formik} />
        </div>
        <div className={styles.messagesContainer}>
          <FormError />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={formik.isSubmitting}
            variant="primary"
            className={styles.submitButton}
            type="submit"
          >
            Set Password
          </Button>
          <Text className={styles.loginButton} as="p" variant="heading-16">
            <Link to={pagePaths.PATH_LOGIN} variant="primary">
              Back to Login
            </Link>
          </Text>
        </div>
      </form>
    </div>
  );
};
