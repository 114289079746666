import * as React from 'react';

import { Arrow } from '../../icons';
import baseStyles from '../../styles/button.css';
import classNames from 'classnames';
import styles from './ArrowButton.css';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];

/** All of the available directions for this arrow to point */
export const ArrowButtonDirections = ['up', 'down', 'left', 'right'] as const;

/** All available sizes for the arrow */
export const ArrowButtonSize = ['sm', 'md', 'lg'];

export interface ArrowButtonProps extends IntrinsicButtonProps {
  /** An enum describing which direction to point the arrow */
  direction: (typeof ArrowButtonDirections)[number];
  /** A required label for this button as it has no text */
  'aria-label': string;
  /** Optional className */
  className?: string;
  /** Change style of Button */
  variant?: 'default' | 'white-bg';
  /** Change the size of Button */
  size?: (typeof ArrowButtonSize)[number];
}

/**
 * A button containing an arrow icon with a configurable direction.
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/iconbutton | IconButton} from corgi-x instead. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const ArrowButton: React.FC<ArrowButtonProps> = ({
  direction,
  className,
  size = 'md',
  variant = 'default',
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(
        baseStyles.button,
        styles.button,
        className,
        styles[variant],
        styles[size]
      )}
      {...props}
    >
      <Arrow
        height={24}
        width={12}
        className={styles[direction]}
        fill="kale-3"
      />
    </button>
  );
};
