import React, { FC, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { CarouselChildProps } from './Carousel';

import styles from './CarouselItem.css';

export interface CarouselItemProps extends CarouselChildProps {
  children: ReactNode;
  className?: string;
}

/**
 * List item for the carousel.  Maintains a ref to the root li
 * to be sent to the click handler.  This is so the event handler
 * always receives the li element instead of a descendant of the li.
 */
export const CarouselItem: FC<CarouselItemProps> = ({
  children,
  onMouseUp,
  fullWidth,
  className,
}) => {
  const self = useRef<HTMLLIElement>(null);

  const handleMouseUp = (): void => {
    if (self.current) {
      onMouseUp(self.current);
    }
  };

  return (
    <li
      ref={self}
      onMouseUp={handleMouseUp}
      className={classNames(
        styles.container,
        { [styles.fullWidth]: fullWidth },
        className
      )}
    >
      {children}
    </li>
  );
};

export default CarouselItem;
