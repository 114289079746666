/**
 * Send a tracking event for when a user updates their profile
 *
 * @param title - Update Profile
 */

import { track } from '../track';
import { profile_updated } from './eventNames';

export interface TrackProfileUpdatedValues {
  id: string;
  firstName?: boolean;
  lastName?: boolean;
  practice?: boolean;
  position?: boolean;
}

export function trackProfileUpdated(values: TrackProfileUpdatedValues) {
  track({
    eventName: profile_updated,
    eventProps: {
      ...values,
    },
  });
}
