import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import styles from './PracticeInformation.module.css';
import { Text, Button } from '@farmersdog/corgi-x';

export interface PracticeInformationProps {
  name?: string;
  address?: string;
  onUpdate: () => void;
}
export function PracticeInformation({
  address,
  name,
  onUpdate,
}: PracticeInformationProps) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text variant="heading-16" bold>
          Your Practice
        </Text>
        <Button variant="plain-text" onClick={onUpdate}>
          <Text
            variant="heading-16"
            color={showPortalUXOverhaul ? 'kale-2' : 'carrot-2'}
            className={styles.link}
            as="span"
          >
            Update
          </Text>
        </Button>
      </div>
      <div className={styles.information}>
        <div>
          <Text variant="heading-16" bold>
            Name
          </Text>
          <Text className={styles.informationItem} variant="heading-16">
            {name}
          </Text>
        </div>
        <div>
          <Text variant="heading-16" bold>
            Address
          </Text>
          <Text className={styles.informationItem} variant="heading-16">
            {address}
          </Text>
        </div>
      </div>
    </div>
  );
}
