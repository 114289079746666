/** No arguments */
export function interceptEvent<E, R>(
  ...handlers: (undefined | (() => R))[]
): (event: E) => R;

/** Single unknown argument */
export function interceptEvent<E, R>(
  ...handlers: (undefined | ((event: E) => R))[]
): (event: E) => R;

/** Multiple unknown arguments */
export function interceptEvent<T extends unknown[], R>(
  ...handlers: (undefined | ((...args: T) => R))[]
): (...args: T) => R;

/**
 * Return an event handler that will run multiple events in the order that they
 * are passed as arguments.
 */
export function interceptEvent<T extends unknown[], R>(
  ...handlers: (undefined | ((...args: T) => R))[]
) {
  return (...args: T): void => {
    handlers.forEach(handler => {
      if (handler) {
        handler(...args);
      }
    });
  };
}
