import { useAuthContext } from '../../context';
import { useFormError } from '../FormError';

import { FormFields } from '../FormFields';
import { Text, Button, Divider } from '@farmersdog/corgi-x';

import {
  getErrorMessage,
  defaultErrorMessage,
} from '../../services/auth/utils/errors';

import * as pagePaths from '../../constants';
import {
  initialFormValues,
  loginFormFieldsData,
  loginFormValidationSchema,
} from './constants';

import styles from './LoginForm.module.css';
import { useRedirection, useFormikWithFocusOnError } from '../../hooks';
import { Logger } from '@farmersdog/logger';
import { handleUnexpectedAuthError } from '../../services/auth/utils/handleUnexpectedAuthError';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export function LoginForm() {
  const { login, logout, isAuthenticated } = useAuthContext();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const push = useRedirection();
  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const { isSubmitting, ...formik } = useFormikWithFocusOnError({
    initialValues: initialFormValues,
    validationSchema: loginFormValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      clearFormErrorMessage();
      try {
        if (!login) {
          throw new Error('No login function provided');
        }
        if (!logout) {
          throw new Error('No logout function provided');
        }
        if (isAuthenticated) {
          await logout();
        }
        await login({
          email: values.email,
          password: values.password,
          persistSession: true,
        });

        push({
          noRedirectPathname: pagePaths.PATH_DASHBOARD,
        });
      } catch (error: unknown) {
        const message = getErrorMessage(error);
        setFormErrorMessage(message);

        // default error message indicates an unexpected error
        if (message === defaultErrorMessage) {
          const logger = new Logger('auth:login');
          handleUnexpectedAuthError({ error, logger });
        }

        return;
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={loginFormFieldsData} formik={formik} />
        </div>
        <div className={styles.messagesContainer}>
          <FormError />
        </div>
        <div
          className={
            showPortalUXOverhaul
              ? styles.buttonContainer
              : styles.buttonContainerLegacy
          }
        >
          <Button
            disabled={isSubmitting}
            variant={
              showPortalUXOverhaul
                ? 'primary-mini-kale-experimental'
                : 'primary'
            }
            className={styles.submitButton}
            type="submit"
          >
            Log In
          </Button>

          <Text
            as="p"
            variant="heading-16"
            className={styles.forgotPasswordButton}
          >
            <Button
              type="link"
              to={pagePaths.PATH_FORGOT_PASSWORD}
              variant="plain-text"
              color={showPortalUXOverhaul ? 'kale' : 'carrot'}
              weight="normal"
            >
              Forgot Password?
            </Button>
          </Text>
        </div>
      </form>

      {!showPortalUXOverhaul && <Divider width={2} color="Charcoal1" />}
    </div>
  );
}
