import { FC } from 'react';
import { PublicPage, SignUpBanner, SignupForm } from '../../components';

import { useSignupPracticeSearchMode } from '../../hooks';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const Signup: FC = () => {
  const { showSignUpBanner, showPortalUXOverhaul } = usePublicPageFeatures();

  useSignupPracticeSearchMode();

  return (
    <>
      <PublicPage blueberryBackground={showPortalUXOverhaul}>
        {showSignUpBanner && <SignUpBanner />}
        <SignupForm />
      </PublicPage>
    </>
  );
};
