import * as React from 'react';
import type stripeJs from '@stripe/stripe-js';

import { useSafeOptions } from '../../hooks/useSafeOptions';

import { useStripe } from './useStripe';
import { ElementsContext } from './ElementsContext';
import { FONTS } from './fonts';

interface ElementsProviderProps {
  /** An array of custom fonts, which elements created can use */
  fonts?: Array<stripeJs.CssFontSource | stripeJs.CustomFontSource>;
}

/**
 * Create and provides a stripe elements object to subscribers.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/StripeElementProvider | StripeElementProvider} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const ElementsProvider: React.FC<
  React.PropsWithChildren<ElementsProviderProps>
> = ({ children, fonts = FONTS, ...props }) => {
  const [elements, setElements] = React.useState<stripeJs.StripeElements>();
  const safeOptions = useSafeOptions({ fonts, ...props });
  const { stripe } = useStripe();

  React.useEffect(() => {
    if (!stripe) {
      // Stripe is a required dependency for this component
      return;
    }

    if (elements) {
      return;
    }

    setElements(stripe.elements(safeOptions));
  }, [stripe, safeOptions, elements]);

  const stripeElementsContextValue = React.useMemo(
    () => ({
      elements,
    }),
    [elements]
  );

  return (
    <ElementsContext.Provider value={stripeElementsContextValue}>
      {children}
    </ElementsContext.Provider>
  );
};
