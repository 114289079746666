import { PropsWithChildren, FC } from 'react';
import { VetPortalSplitProvider } from '../abTesting/VetPortalSplitProvider';
import { PublicPageFeaturesProvider } from '../abTesting/PublicPageFeatures';

export const ClientAppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <VetPortalSplitProvider>
      {/*
          The build would fail if we try to call useFeature in a server-side built page (i.e. any public pages on the vet portal e.g. Signup, SignupConference). 
          It fails because the SplitProvider we are using from the @farmersdog/ab-testing package does not work in a non-browser environment (See https://help.split.io/hc/en-us/articles/360047346131-How-to-use-server-side-rendering-with-React-SDK-).
          The workaround is to make sure we don't call useFeature directly during the build. This is done by replacing the direct calls to useFeature in our public pages with usePublicPageFeatures (which uses React context). The only call to useFeature in public pages now all happen client side only in PublicPageFeaturesProvider.

          TODO - figure out a better way to handle this issue
          */}
      <PublicPageFeaturesProvider>{children}</PublicPageFeaturesProvider>
    </VetPortalSplitProvider>
  );
};
