import { AppBarItemProps, AppBarProps } from './AppBar';
import React, { FC, ReactElement, useRef } from 'react';

import { CallToAction } from './CallToAction';
import { Link, isAnchorLink } from '../Link';
import { Logo } from './Logo';
import { MaxWidth } from './MaxWidth';
import { Text } from '../Text';
import classNames from 'classnames';
import styles from './Large.css';
import { useCallToActionObserver } from './useCallToActionObserver';
import { useScrollingStyles } from './useScrollingStyles';
import { HorizontalNavigation, HorizontalNavigationItem } from 'components';
import { SmallLabel } from './SmallLabel';

interface LargeProps extends AppBarProps {
  content: Element | null;
}

export const Large: FC<LargeProps> = ({
  overlay,
  content,
  leftItems,
  rightItems,
  smallRightLabel,
  persistentItem,
  subNavItems,
  callToAction,
  showCTA,
  onCallToActionClick,
  darkTheme,
  logoTo,
  logoHref,
  hasContentAbove,
}) => {
  const nav = useRef<HTMLElement>(null);
  const { backgroundColor, boxShadow, logoTranslate, linkTheme } =
    useScrollingStyles({
      overlay,
      darkTheme,
      content,
      hasContentAbove,
    });
  const showCallToAction = useCallToActionObserver(showCTA, callToAction);
  const alwaysShowCallToAction = !showCTA && callToAction;

  const logoLinkProps = logoTo ? { to: logoTo } : { href: logoHref };

  return (
    <div
      style={{
        backgroundColor,
        boxShadow,
      }}
    >
      <nav
        role="navigation"
        className={styles.nav}
        style={{
          backgroundColor,
        }}
        ref={nav}
      >
        <MaxWidth>
          <Logo {...logoLinkProps} translate={logoTranslate} />
          <Text
            variant="heading-16"
            bold
            className={classNames(styles.items, styles.left)}
            as="ul"
          >
            {leftItems.map(
              (item: AppBarItemProps): ReactElement => (
                <li key={item.title}>
                  <Link
                    {...item}
                    activeClassName={styles.active}
                    className={classNames(
                      styles.link,
                      item.className as string
                    )}
                    variant="secondary"
                    mode={linkTheme}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            )}
          </Text>
          {smallRightLabel && <SmallLabel title={smallRightLabel}></SmallLabel>}
          {rightItems && (
            <Text
              variant="heading-16"
              bold
              className={classNames(styles.items, styles.right)}
              as="ul"
            >
              {rightItems.map(
                (item: AppBarItemProps): ReactElement => (
                  <li key={item.title}>
                    <Link
                      {...item}
                      activeClassName={styles.active}
                      className={classNames(
                        styles.link,
                        item.className as string
                      )}
                      variant="secondary"
                      mode={linkTheme}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              )}
            </Text>
          )}
          {callToAction && (showCallToAction || alwaysShowCallToAction) && (
            <div className={classNames(styles.items, styles.callToAction)}>
              <CallToAction
                {...callToAction}
                onClick={onCallToActionClick}
                asPill
              />
            </div>
          )}
          {persistentItem && (
            <Link
              {...persistentItem}
              className={classNames(
                styles.link,
                persistentItem.className as string
              )}
              variant={rightItems ? 'primary' : 'secondary'}
              mode={linkTheme}
            >
              <Text variant="heading-16" bold>
                {persistentItem.title}
              </Text>
            </Link>
          )}
        </MaxWidth>
      </nav>
      {subNavItems && (
        <div
          className={styles.horizontalNavContainer}
          aria-label="subnavigation"
        >
          <MaxWidth className={styles.horizontalNavContentRow}>
            <HorizontalNavigation withButtons={false}>
              {subNavItems.map(item => {
                const linkProps = isAnchorLink(item)
                  ? { href: item.href }
                  : { to: item.to };

                return (
                  <HorizontalNavigationItem
                    className={styles.horizontalNavLink}
                    key={item.title}
                    {...linkProps}
                  >
                    {item.title}
                  </HorizontalNavigationItem>
                );
              })}
            </HorizontalNavigation>
          </MaxWidth>
        </div>
      )}
    </div>
  );
};
