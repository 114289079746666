import * as yup from 'yup';
import { EMAIL_REGEXP } from '../../../../constants/validation';
import { PersonalInformationFormFields } from '../constants';
import { Position } from '../../../../graphql/types';
import { noTrailingWhitespace } from '../../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../../../utils';
import { howDidYouHearAboutUsOptions } from '../../../../constants';

const termsAndConditionsErrorMessage =
  'You must accept the terms and conditions.';

export const personalInformationValidationSchema = yup.object({
  [PersonalInformationFormFields.firstName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [PersonalInformationFormFields.lastName]: yup
    .string()
    .required(fieldRequiredErrorMessage),

  [PersonalInformationFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),

  [PersonalInformationFormFields.position]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(Object.values(Position)),

  [PersonalInformationFormFields.acceptedPrivacyPolicy]: yup
    .boolean()
    .required(fieldRequiredErrorMessage)
    .oneOf([true], termsAndConditionsErrorMessage),
  [PersonalInformationFormFields.attestedAtSignup]: yup
    .boolean()
    .required(fieldRequiredErrorMessage)
    .oneOf([true], termsAndConditionsErrorMessage),

  [PersonalInformationFormFields.password]: yup
    .string()
    .min(6)
    .required(fieldRequiredErrorMessage),
  [PersonalInformationFormFields.howDidYouHearAboutUs]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(howDidYouHearAboutUsOptions),
});

export const personalInformationConferenceValidationSchema = yup.object({
  [PersonalInformationFormFields.firstName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [PersonalInformationFormFields.lastName]: yup
    .string()
    .required(fieldRequiredErrorMessage),

  [PersonalInformationFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),

  [PersonalInformationFormFields.position]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(Object.values(Position)),

  [PersonalInformationFormFields.acceptedPrivacyPolicy]: yup
    .boolean()
    .required(fieldRequiredErrorMessage)
    .oneOf([true], termsAndConditionsErrorMessage),
  [PersonalInformationFormFields.attestedAtSignup]: yup
    .boolean()
    .required(fieldRequiredErrorMessage)
    .oneOf([true], termsAndConditionsErrorMessage),
});
