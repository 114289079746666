import { PersonalInformationFormFields } from './personalInformationFormFieldsData';

export interface PersonalInformationConferenceFormValues {
  [PersonalInformationFormFields.firstName]: string;
  [PersonalInformationFormFields.lastName]: string;
  [PersonalInformationFormFields.email]: string;
  [PersonalInformationFormFields.position]: string | undefined;
  [PersonalInformationFormFields.acceptedPrivacyPolicy]: boolean;
  [PersonalInformationFormFields.attestedAtSignup]: boolean;
}

export interface PersonalInformationFormValues
  extends PersonalInformationConferenceFormValues {
  [PersonalInformationFormFields.password]: string;
  [PersonalInformationFormFields.howDidYouHearAboutUs]: string | undefined;
}

export const personalInformationConferenceFormValues: PersonalInformationConferenceFormValues =
  {
    [PersonalInformationFormFields.firstName]: '',
    [PersonalInformationFormFields.lastName]: '',
    [PersonalInformationFormFields.email]: '',
    [PersonalInformationFormFields.position]: undefined,
    [PersonalInformationFormFields.acceptedPrivacyPolicy]: false,
    [PersonalInformationFormFields.attestedAtSignup]: false,
  };

export const personalInformationFormValues: PersonalInformationFormValues = {
  ...personalInformationConferenceFormValues,
  [PersonalInformationFormFields.password]: '',
  [PersonalInformationFormFields.howDidYouHearAboutUs]: undefined,
};
