/**
 * Return true if a child element is more than 50% in view relative to its
 * nearest scrollable parent.
 */
function isVisible(childRect: DOMRect, containerRect: DOMRect): boolean {
  const childCenter = childRect.left + childRect.width / 2;

  return (
    childCenter >= containerRect.left && childCenter <= containerRect.right
  );
}

export default isVisible;
