import cookie from 'js-cookie';

const AJS_USER_ID_COOKIE_NAME = 'ajs_user_id';

export function getMainSiteUserId(): string | undefined {
  const id = cookie.get(AJS_USER_ID_COOKIE_NAME);

  if (!id || !stringIsNumber(id)) {
    return undefined;
  }

  return id;
}

function stringIsNumber(value: string) {
  return !isNaN(Number(value));
}
