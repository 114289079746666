import React, { useId } from 'react';
import classNames from 'classnames';

import { browserOnly, interceptEvent } from '../../util';
import { withTFDProps } from '../withTFDProps';

import styles from './Tooltip.css';

type IntrinsicDivProps = JSX.IntrinsicElements['div'];

export const TooltipPositions = [
  'top',
  'top-left',
  'top-right',
  'right',
  'right-top',
  'right-bottom',
  'bottom',
  'bottom-left',
  'bottom-right',
  'left',
  'left-top',
  'left-bottom',
] as const;

export const TooltipModes = ['light', 'dark'] as const;

export interface TooltipProps<T extends HTMLElement>
  extends Omit<IntrinsicDivProps, 'content'> {
  children: React.ReactElement<React.HTMLAttributes<T>>;
  position: (typeof TooltipPositions)[number];
  mode?: (typeof TooltipModes)[number];
  content?: React.ReactNode;
  id?: string;
}

export function TooltipComponent<T extends HTMLElement>({
  children,
  position,
  content,
  mode = 'light',
  className,
  ...props
}: TooltipProps<T>): JSX.Element {
  const el = React.useRef<HTMLSpanElement>(null);
  const reactId = useId();
  const id = props.id || reactId;

  const [isHidden, setIsHidden] = React.useState(true);

  const hide = (): void => {
    setIsHidden(true);
  };

  const show = (): void => {
    setIsHidden(false);
  };

  // Special case - prevent mouseleave hiding tooltip if element in focus using
  // document.contains because child element could have multiple focusable
  // children
  const handleMouseLeave = (): void => {
    if (el.current && !el.current.contains(document.activeElement)) {
      setIsHidden(true);
    }
  };

  const handleBlur = (): void => {
    browserOnly(window => {
      /** This pushes the hide to the end of the event loop ensuring that on touch
       * devices they can interact with the tooltip content before it hides */
      window.setTimeout(hide, 0);
    });
  };

  React.useEffect(() => {
    return browserOnly((_, document) => {
      const handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === 'Escape') {
          setIsHidden(true);
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return (): void => document.removeEventListener('keydown', handleKeyDown);
    });
  }, []);

  if (!React.isValidElement<React.HTMLAttributes<T>>(children)) {
    throw new Error(
      'Tooltip only accepts a single child as a valid trigger element'
    );
  }

  const triggerElement = React.cloneElement(children, {
    'aria-describedby': id,
    onMouseEnter: interceptEvent(children.props.onMouseEnter, show),
    onMouseLeave: interceptEvent(children.props.onMouseLeave, handleMouseLeave),
    onFocus: interceptEvent(children.props.onFocus, show),
    onBlur: interceptEvent(children.props.onBlur, handleBlur),
    // We need to attach onClick to support iOS Safari
    onClick: interceptEvent(children.props.onClick, show),
  });

  return (
    <span
      className={classNames(styles.overlayWrapper, className)}
      aria-label="Toggle tooltip"
      ref={el}
      {...props}
    >
      {triggerElement}
      <span
        className={classNames(
          styles.tooltipWrapper,
          styles[`tooltip-${position}`]
        )}
        onMouseEnter={show}
        onMouseLeave={hide}
        aria-hidden={content ? isHidden : true}
      >
        <span
          className={classNames(styles.tooltip, styles[mode])}
          role="tooltip"
          id={id}
        >
          {content}
        </span>
      </span>
    </span>
  );
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/tooltip | Tooltip} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Tooltip = withTFDProps(TooltipComponent);
