import { Text } from '@farmersdog/corgi-x';

import madisonJpg from '../../assets/quotes-madison.jpg';
import madisonWebP from '../../assets/quotes-madison.webp';
import cynthiaJpg from '../../assets/quotes-cynthia.jpg';
import cynthiaWebP from '../../assets/quotes-cynthia.webp';
import sarahJpg from '../../assets/quotes-sarah.jpg';
import sarahWebP from '../../assets/quotes-sarah.webp';

const VETS_DATA = [
  {
    name: (
      <Text variant="heading-16">
        <strong>Madison Phillips,</strong> DVM
      </Text>
    ),
    quote:
      "Since switching to The Farmer's Dog, I’ve noticed my dogs' poops look healthy, and they are so excited for their meals! I couldn’t be happier knowing they’re eating nutritious food which gives me peace of mind as a vet and a dog mom.",
    sources: [madisonJpg, madisonWebP],
  },
  {
    name: (
      <Text variant="heading-16">
        <strong>Cynthia Pacelli,</strong> Head Technician
      </Text>
    ),
    quote:
      "I was nervous to try something new, but The Farmer's Dog has made a huge difference in Nero's life and care. He’s happier, excited to eat, and healthier than ever!",
    sources: [cynthiaJpg, cynthiaWebP],
  },
  {
    name: (
      <Text variant="heading-16">
        <strong>Sarah Callahan,</strong> DVM
      </Text>
    ),
    quote:
      'I very much approach health recommendations with a ‘food is medicine’ philosophy and The Farmer’s Dog solved the challenges I was experiencing with home cooked options. It’s made the process so much more convenient!',
    sources: [sarahJpg, sarahWebP],
  },
];

export default VETS_DATA;
