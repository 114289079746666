import 'intersection-observer';
import { useEffect, useState } from 'react';

import { useSafeOptions } from './useSafeOptions';

/**
 * Return an array of decimal points between 0 and 1
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Building_the_array_of_threshold_ratios
 */
export function buildThresholdList(steps: number): number[] {
  return steps > -1
    ? [...(new Array(steps) as null[])].map((_, i) => i / steps)
    : [];
}

/**
 * Hook for creating an intersection observer.
 *
 * @param referenceEl - A target html element to observe.
 * @param root - An element used as the viewport for checking visibility.
 * @param rootMargin - A css margin around the root.
 * @param threshold - Which percentage of visibility to execute the callback.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/useIntersection | useIntersection} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export function useIntersection(
  referenceEl?: Element | null,
  options: IntersectionObserverInit = {}
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  // Having stateful options allows us run our effect based on deep equality
  // rather then shallow object reference.
  const safeOptions = useSafeOptions({
    referenceEl,
    ...options,
  });

  useEffect(() => {
    const { root, rootMargin, threshold, referenceEl } = safeOptions;

    if (!referenceEl) {
      return;
    }

    const handleIntersections: IntersectionObserverCallback = entries => {
      setEntry(entries[0] || {});
    };

    const observer = new IntersectionObserver(handleIntersections, {
      root,
      rootMargin,
      threshold,
    });

    observer.observe(referenceEl);
    return (): void => observer.disconnect();
  }, [safeOptions]);

  return entry;
}
