import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import '@farmersdog/corgi/build/components.css';
import '@farmersdog/corgi-x/build/styles.css';
import 'normalize.css';
import './variables.css';
import './accessibility.css';
/**
 * This App import should be kept as the first import in order to avoid css
 * source ordering issues.
 */
import { Application } from './Application';
import { ClientAppProviders } from './context/ClientAppProviders';

export function ClientApp() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ClientAppProviders>
          <Application />
        </ClientAppProviders>
      </BrowserRouter>
    </HelmetProvider>
  );
}
