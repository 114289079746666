import { Divider, Link, Text } from '@farmersdog/corgi-x';

import { Picture } from '../../../components';

import { Recipe } from '../assets/recipes';
import { trackDownloadableInfo } from '../../../analytics/events';

import styles from './RecipeCard.module.css';

interface RecipeProps {
  index: number;
  recipe: Recipe;
  isLastOne?: boolean;
  isMobile: boolean;
  listOfRecipes: Recipe[];
}

export function RecipeCard({
  index,
  recipe,
  isLastOne,
  isMobile,
  listOfRecipes,
}: RecipeProps) {
  return (
    <>
      <div className={styles.recipe}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <Text
              bold
              as="h4"
              topSpacing="none"
              key={recipe.title}
              bottomSpacing="xs"
              color="charcoal-3"
              variant="heading-22"
            >
              {recipe.title}
            </Text>
            <Link
              target="_blank"
              aria-label={`View ${recipe.title} PDF`}
              href={recipe.pdf}
              onClick={() =>
                trackDownloadableInfo({
                  title: recipe.title,
                })
              }
            >
              <Text className={styles.link} variant="heading-16" color="kale-2">
                Recipe Sheet PDF
              </Text>
            </Link>
          </div>
          <Picture
            className={styles.image}
            sources={recipe.images}
            alt={recipe.alt}
          />
        </div>
        <div className={styles.dateContainer}>
          {recipe.availableDate && (
            <Text
              fontStyle="italic"
              vSpacing="none"
              color="charcoal-3"
              as="p"
              variant="article-16"
            >
              Available {recipe.availableDate}
            </Text>
          )}
        </div>
        <Text
          bold
          as="h5"
          topSpacing="sm"
          bottomSpacing="xs"
          variant="heading-16"
        >
          Diet Indications
        </Text>
        <ul className={styles.list}>
          {recipe.dietIndications.map(indication => (
            <li key={`${recipe.title}-${indication}`}>
              <Text
                as="p"
                topSpacing="xxs"
                color="charcoal-3"
                variant="article-16"
                bottomSpacing="none"
              >
                {indication}
              </Text>
            </li>
          ))}
        </ul>
        <Text bottomSpacing="xs" bold as="h5" variant="heading-16">
          Contraindications
        </Text>
        <ul className={styles.list}>
          {recipe.contraIndications.map(indication => (
            <li key={`${recipe.title}-${indication}`}>
              <Text
                as="p"
                topSpacing="xxs"
                color="charcoal-3"
                variant="article-16"
                bottomSpacing="none"
              >
                {indication}
              </Text>
            </li>
          ))}
        </ul>
      </div>

      {!isLastOne && isMobile && (
        <Divider width={1} color="Kale1" spacing={36} />
      )}

      {!isMobile && (index === 0 || index === 2) && (
        <Divider orientation="vertical" width={1} color="Kale1" spacing={32} />
      )}

      {!isMobile && index === 1 && listOfRecipes.length === 4 && (
        <Divider
          width={1}
          spacing={32}
          color="Kale1"
          orientation="horizontal"
        />
      )}
    </>
  );
}
