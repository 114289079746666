import {
  Button,
  Text,
  Toast,
  ToastProps,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import { AccountHeader } from '../../../components/AccountHeader';
import StethoscopeSVG from '../assets/stethoscope.svg?react';
import styles from './AttestationPrompt.module.css';
import { useFormik } from 'formik';
import {
  AttestationFormFields,
  AttestionFormValues,
  attestationFormValidationSchema,
  initialFormValues,
} from './assets';
import { CheckboxWithText } from '../../../components/CheckboxWithText';
import { useUpdateVet } from '../../../graphql/hooks';
import {
  HospitalInput,
  VetDetailsQuery,
  VetInput,
} from '../../../graphql/types';
import { useState } from 'react';
import { getErrorMessage } from '../../../services/auth/utils/errors';
import omit from 'lodash/omit';

interface AttestationPromptResponsiveProps extends AttestationPromptProps {
  isMobile?: boolean;
}

export function AttestationPromptResponsive({
  isMobile = false,
  vetDetails,
}: AttestationPromptResponsiveProps) {
  const [updateVet] = useUpdateVet();
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const form = useFormik({
    initialValues: initialFormValues,
    validationSchema: attestationFormValidationSchema,
    onSubmit: async ({ attestedAtSignup }: AttestionFormValues) => {
      try {
        await updateVet({
          refetchQueries: ['vetDetails'],
          variables: {
            organizationInput: vetDetails!.organization
              ? omit(vetDetails!.organization, '__typename')
              : null,
            vetInput: {
              ...(omit(
                vetDetails!.vet,
                '__typename',
                'id',
                'discountRequests'
              ) as VetInput),
              attestedAtSignup,
              hospital: vetDetails!.vet!.hospital
                ? omit(vetDetails!.vet!.hospital as HospitalInput, '__typename')
                : null,
            },
          },
        });
      } catch (error) {
        const message = getErrorMessage(error);
        setToast({
          message,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
  });
  return (
    <form onSubmit={form.handleSubmit}>
      <AccountHeader
        discountRequests={[]}
        showAttestationPrompt
        isMobile={isMobile}
      />
      <div className={styles.toast}>
        <Toast
          {...toast}
          autoClose
          onClose={() => setToast({ isOpen: false })}
          timeout={1000}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.svgContainer}>
          <StethoscopeSVG />
        </div>

        <Text
          color="blueberry-3"
          variant={isMobile ? 'heading-22' : 'heading-28'}
          bold
        >
          Please confirm that you’re a vet professional to proceed.
        </Text>
        <div className={styles.attestationMessage}>
          <CheckboxWithText
            maxWidth
            checked={form.values[AttestationFormFields.attestedAtSignup]}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.errors[AttestationFormFields.attestedAtSignup]}
            isTouched={form.touched[AttestationFormFields.attestedAtSignup]}
            fieldName={AttestationFormFields.attestedAtSignup}
            labelContent={
              <>
                <Text variant="heading-16">
                  By clicking here, I attest that the information I have
                  provided to gain access to this site, intended strictly for
                  veterinary professionals, is true and accurate to the best of
                  my knowledge.
                </Text>
                <Text variant="heading-16" as="p">
                  I understand that if this information is found to be untrue,
                  the company reserves the right to change or deny access to the
                  site and any benefits contained therein.
                </Text>
              </>
            }
          />
        </div>

        <Button disabled={!(form.isValid && form.dirty)} type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
}

export interface AttestationPromptProps {
  vetDetails: VetDetailsQuery['vetDetails'];
}

export const AttestationPrompt = ({ vetDetails }: AttestationPromptProps) => {
  return (
    <>
      <div className={styles.mobile}>
        <AttestationPromptResponsive isMobile vetDetails={vetDetails} />
      </div>
      <div className={styles.desktop}>
        <AttestationPromptResponsive vetDetails={vetDetails} />
      </div>
    </>
  );
};
