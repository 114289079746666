import { Picture } from '@farmersdog/corgi-x';
import { obesityPreventionAssets } from '../../assets';

import styles from './ObesityPreventionLogo.module.css';

export const ObesityPreventionLogo = () => {
  return (
    <div>
      <Picture
        className={styles.logo}
        sources={obesityPreventionAssets}
        alt="Association for Pet Obesity"
      />
    </div>
  );
};
