import { Children, ReactElement, isValidElement } from 'react';

import { getHasValue } from '../../util';

type IntrinsicOptionProps = JSX.IntrinsicElements['option'];

/**
 * Used to determine if an option is selected and it isn't an empty option
 */
export const hasValueSelected = (
  children?: ReactElement<IntrinsicOptionProps>[]
): boolean => {
  if (!children) return false;

  const childArray = Children.toArray(
    children
  ) as ReactElement<IntrinsicOptionProps>[];
  const firstChild = childArray[0];
  if (!isValidElement(firstChild)) return false;
  const firstChildValue = firstChild.props.value;

  if (getHasValue(firstChildValue)) {
    return true;
  }

  return childArray.some(option => {
    if (!isValidElement(option)) return false;
    const { selected, value } = option.props;
    return selected && getHasValue(value);
  });
};
