import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { page } from '../';

import config from '../../config';

export function usePageTracker() {
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    page({
      path: pathname,
      baseUrl: config.get('app').portal.url,
      otherProps: { search },
    });
  }, [location]);
}
