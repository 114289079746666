import { track } from '../track';
import { landing_page_clicked_download_nutrition_guide } from './eventNames';

/**
 * Send a tracking event for when a user clicks on download nutrition guide.
 */

export function trackLandingPageClickedDownloadNutritionGuide() {
  track({ eventName: landing_page_clicked_download_nutrition_guide });
}
