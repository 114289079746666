import * as Apollo from '@apollo/client';

import { CreateVetMutationVariables, CreateVetMutation } from '../types';
import createVet from '../mutations/create-vet.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  CreateVetMutation,
  CreateVetMutationVariables
>;

export function useCreateVet(options?: Options) {
  return useMutation<CreateVetMutation, CreateVetMutationVariables>(
    createVet,
    options
  );
}
