import React, { PropsWithChildren, useId } from 'react';
import * as stripeJs from '@stripe/stripe-js';

import { BorderLabel, useElementState } from '../BorderLabel';

import { ELEMENT_PROPS } from './constants';
import { FormControl } from '../FormControl';
import { StripeElement } from '../Stripe';
import { StripeElementProps } from '../Stripe/StripeElement';
import classNames from 'classnames';
import { elementTypes } from '../Stripe/elementTypes';
import { interceptEvent } from '../../util/interceptEvent';
import styles from './CardField.css';

type StripeEvent = stripeJs.StripeElementChangeEvent;

/**
 * Get the current error message giving preference to errors passed in from
 * props and defaulting to any error messages generated by the stripe change
 * event.
 */
function getError(event?: StripeEvent, error?: string): string | undefined {
  if (error) {
    return error;
  }

  return event?.error?.message;
}

export interface CardFieldProps {
  /** Callback when element is ready */
  onReady?: StripeElementProps['onReady'];
  /** Callback when element value changes */
  onChange?: StripeElementProps['onChange'];
  /** Callback when element receives focus */
  onFocus?: StripeElementProps['onFocus'];
  /** Callback when element loses focus */
  onBlur?: StripeElementProps['onBlur'];
  /** Required input type */
  elementType: elementTypes;
  /** Required input label */
  label: string;
  /** Toggle disabled input state */
  disabled?: boolean;
  /** Optional element id */
  id?: string;
  /** Optional error message */
  error?: string;
  /** Optional class name */
  className?: string;
  /** Optional element class name */
  elementClassName?: string;
  /** Hide BorderLabel but keep it in the accessibility tree */
  hideLabel?: boolean;
}

/**
 * Render a stripe card field with the CORGI input style
 */
export const CardField: React.FC<PropsWithChildren<CardFieldProps>> = ({
  label,
  elementType,
  onBlur,
  onFocus,
  onChange,
  error,
  className,
  elementClassName,
  children,
  hideLabel = false,
  ...props
}) => {
  const reactId = useId();
  const id = props.id || reactId;

  const [event, setEvent] = React.useState<StripeEvent>();
  const message = getError(event, error);
  const isEmpty = event?.empty === false;
  const invalid = Boolean(message);

  const [state, handlers] = useElementState({
    invalid,
    disabled: props.disabled,
  });

  const handleChange: StripeElementProps['onChange'] = event => {
    setEvent(event);
  };

  return (
    <FormControl
      className={className}
      aria-live="polite"
      invalid={invalid}
      message={message}
      id={id}
      // Stripe doesn't allow passing of aria-describedby into the iframe. This
      // may be supported in the future
      disableAriaProps
    >
      <BorderLabel
        in={Boolean(state === 'focus' || isEmpty)}
        text={label}
        state={state}
        hideLabel={hideLabel}
      >
        <StripeElement
          elementType={elementType}
          onChange={interceptEvent(handleChange, onChange)}
          onBlur={interceptEvent(handlers.onBlur, onBlur)}
          onFocus={interceptEvent(handlers.onFocus, onFocus)}
          placeholder=""
          className={classNames(styles.input, elementClassName)}
          {...ELEMENT_PROPS}
          {...props}
        />
        {children}
      </BorderLabel>
    </FormControl>
  );
};
