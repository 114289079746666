import * as React from 'react';
import { StripeContext, StripeContextValue } from './StripeContext';

/**
 * Return the stripe context value
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/useStripe | useStripe} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export function useStripe(): StripeContextValue {
  return React.useContext(StripeContext);
}
