import * as React from 'react';

import { CustomSelectorProps, Selector, Types } from '../Selector';

import { Check } from '../../icons';
import { ElementState } from '../BorderLabel';
import { Text } from '../Text';
import classNames from 'classnames';
import { getCheckboxDesign } from '../../designs/getCheckboxDesign';
import { getRadioDesign } from '../../designs/getRadioDesign';
import * as typography from '../../typography';
import { WithRefProps, withRef } from '../../util';

import styles from './LabeledSelector.css';

type RefProps = WithRefProps<HTMLInputElement>;
export interface LabeledSelectorProps extends CustomSelectorProps, RefProps {
  /** A demo prop to override `ElementState`  */
  state?: ElementState;
  /** Color of the label text */
  color?:
    | (typeof typography.ArticleColors)[number]
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | (typeof typography.BodyColors)[number]
    | (typeof typography.HeadingColors)[number];
  type: (typeof Types)[number];
  /** Child node(s) represents the label content */
  children: React.ReactNode;
}

/**
 * Component to attach a label to a design radio or checkbox
 *
 * @deprecated Use corgi-x {@link https://corgi-x.tfd.engineering/components/radiobutton | RadioButton} or {@link https://corgi-x.tfd.engineering/components/checkbox | Checkbox} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const LabeledSelector = withRef<HTMLInputElement, LabeledSelectorProps>(
  ({
    children,
    state: stateProp,
    checked,
    type,
    color = 'kale-3',
    className,
    forwardedRef,
    ...props
  }) => {
    const isCheckbox = type === 'checkbox';
    const getDesign = isCheckbox ? getCheckboxDesign : getRadioDesign;

    return (
      <Selector
        type={type}
        checked={checked}
        className={classNames(className, styles.container)}
        ref={forwardedRef}
        {...props}
      >
        {(state: ElementState): React.ReactNode => {
          const inputDesign = getDesign({
            state: stateProp ?? state,
            checked,
          });

          return (
            <>
              <span
                className={classNames(styles.input, inputDesign, styles[type])}
              >
                {isCheckbox && <Check fill="white" size={12} />}
              </span>
              <Text
                as="span"
                variant="heading-16"
                className={classNames(
                  styles.label,
                  styles[state],
                  styles[type],
                  styles[color]
                )}
              >
                {children}
              </Text>
            </>
          );
        }}
      </Selector>
    );
  }
);
