export enum SectionId {
  ScienceAndSafety = 'science-and-safety',
  WhyFresh = 'why-fresh',
  ArticlesAndStudies = 'articles-and-studies',
  AboutOurTeam = 'about-our-team',
}
export type SectionName =
  | 'Science & Safety'
  | 'About Our Team'
  | 'Why Fresh'
  | 'Articles & Studies';
export interface NavItem {
  name: SectionName;
  hash: SectionId;
}

export const navItems: NavItem[] = [
  {
    name: 'Science & Safety',
    hash: SectionId.ScienceAndSafety,
  },
  {
    name: 'About Our Team',
    hash: SectionId.AboutOurTeam,
  },
  {
    name: 'Why Fresh',
    hash: SectionId.WhyFresh,
  },
  {
    name: 'Articles & Studies',
    hash: SectionId.ArticlesAndStudies,
  },
];
