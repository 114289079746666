import { Grid, Picture, Text } from '@farmersdog/corgi-x';
import styles from './Testimonial.module.css';
import { isValidElement, ReactElement } from 'react';
interface TestimonialProps {
  name: ReactElement;
  sources: string[];
  quote: string;
}

export const Testimonial = ({ name, quote, sources }: TestimonialProps) => {
  return (
    <Grid className={styles.container}>
      <Picture
        sources={sources}
        alt={`${quote} testimonial`}
        loading="lazy"
        className={styles.photo}
      />
      <Grid flexDirection="column" className={styles.testimonialInformation}>
        {isValidElement(name) ? name : <Text variant="heading-16">{name}</Text>}
        <Text variant="article-12" className={styles.quote}>
          “{quote}”
        </Text>
      </Grid>
    </Grid>
  );
};
