import { PersonalDetailsFormFields } from './personalDetailsFormFieldsData';

export interface PersonalInformationFormValues {
  [PersonalDetailsFormFields.firstName]: string;
  [PersonalDetailsFormFields.lastName]: string;
}

export const personalInformationFormValues: PersonalInformationFormValues = {
  [PersonalDetailsFormFields.firstName]: '',
  [PersonalDetailsFormFields.lastName]: '',
};
