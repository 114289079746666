import { AppProviders } from './context';

import { Router } from './components';
import { Head } from './components/Head';
import { ErrorBoundary } from './errors/ErrorBoundary';

export function Application() {
  return (
    <ErrorBoundary>
      <AppProviders>
        <Head />
        <Router />
      </AppProviders>
    </ErrorBoundary>
  );
}
