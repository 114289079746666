/**
 * Given an element and it's parent, where the parent has overflow x,
 * returns true if the element is partially or completely
 * out of view to the left.
 *
 * @param {Node} element
 * @param {Node} parentElement
 * @returns Boolean
 */
function isOverflowingLeft(
  element: HTMLElement,
  parentElement: HTMLElement | null
): boolean {
  if (!parentElement) return false;

  const offsetLeft = element.offsetLeft;
  const scrollLeft = parentElement.scrollLeft;

  const positionInVisibleArea = offsetLeft - scrollLeft;
  return positionInVisibleArea < 0;
}

export default isOverflowingLeft;
