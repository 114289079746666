import { getEventsAndRecordingsData } from '../../../../data/eventsAndRecordingsData';
import { Divider, Grid } from '@farmersdog/corgi-x';
import { Video } from '../Video';
import { joinText } from '../../../../utils/joinText';

export const OnDemandEvents = ({
  isMobile,
  showPortalUXOverhaul,
}: {
  isMobile?: boolean;
  showPortalUXOverhaul?: boolean;
}) => {
  function getSpeakersName(speakers: string[]): string {
    return joinText({
      elements: speakers,
      separator: ', ',
      endSeparator: ' & ',
    });
  }

  return (
    <>
      {getEventsAndRecordingsData().map((event, index) => (
        <Grid key={event.videoId} bottomSpacing="md">
          {index != 0 && (
            <Divider
              width={1}
              color="Blueberry1"
              spacing={isMobile ? 24 : 36}
            />
          )}
          <Video
            isMobile={isMobile}
            type={event.type}
            name={event.videoTitle}
            speaker={getSpeakersName(
              event.speakers.map(speaker => speaker.nameAndTitle)
            )}
            dateAndLocation={event.webinarDate}
            videoId={event.videoId}
            wistiaOptions={event.wistiaOptions}
            showPortalUXOverhaul={showPortalUXOverhaul}
          />
        </Grid>
      ))}
    </>
  );
};
