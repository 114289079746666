import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { Link, LinkLocationProps } from 'components/Link';
import { Text } from 'components/Text';

import styles from './VerticalNavigation.css';

export type VerticalNavigationItemProps = {
  title: string;
} & LinkLocationProps &
  Partial<NavLinkProps>;

export interface VerticalNavigationProps {
  /** Items to appear in the navigation */
  items: VerticalNavigationItemProps[];
}

/**
 *
 * The VerticalNavigation displays information and actions relating to the navigation in a vertical orientation.
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/verticalnavigation | VerticalNavigation} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const VerticalNavigation: FC<VerticalNavigationProps> = props => {
  return (
    <nav role="navigation" className={styles.container}>
      <div className={styles.items}>
        {props.items.map(item => (
          <Link
            {...item}
            key={item.title}
            className={styles.link}
            activeClassName={styles.active}
            variant="secondary"
            mode="dark"
          >
            <Text variant="heading-16" bold>
              {item.title}
            </Text>
          </Link>
        ))}
      </div>
    </nav>
  );
};
