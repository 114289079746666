import { Picture } from '@farmersdog/corgi-x';
import { wsavaLogoAssets } from '../../assets';

import styles from './WsavaLogo.module.css';

export const WsavaLogo = () => {
  return (
    <div>
      <Picture
        className={styles.logo}
        sources={wsavaLogoAssets}
        alt="WSAVA Diamond Partner"
      />
    </div>
  );
};
