/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { forwardRef, createElement, Ref, FC } from 'react';

/**
 * Interface that represents the addition of a forwardedRef prop using the
 * corresponding HOC `withRef`
 */
export interface WithRefProps<T> {
  /** A ref that is forwarded to the component */
  forwardedRef?: Ref<T>;
}

/**
 * Add a prop named `forwardedRef` to the component props. This is useful for
 * exposing the inner HTML element to the consumer of a component and makes it
 * easier to deal with the complications from using `forwardRef` in typescript.
 *
 * @deprecated Don't use this. Use the `forwardRef` function from React and make explicit the ref forwarding explicit in the code.
 */
export const withRef = <T, P extends WithRefProps<T>>(
  Wrapped: FC<P>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<T>
> =>
  forwardRef<T, P>((props, forwardedRef) =>
    createElement(Wrapped, { ...props, forwardedRef })
  );

/**
 * Type that omits the ref prop of `JSX.IntrinsicElements<T>`.
 * Use this type to extend JSX.IntrinsicElements when a component and its parent both uses `withRef` because the `LegacyRef` used by `JSX.IntrinsicElements<T>` ref is incompatible with the `Ref` used by `withRef`.
 */
export type IntrinsicElementOmitRef<T extends keyof JSX.IntrinsicElements> =
  Omit<JSX.IntrinsicElements[T], 'ref'>;
