import React from 'react';

import classNames from 'classnames';

import { AsProp } from '../../types';
import styles from './GridContainer.css';

export const GridContainerBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export const GridContainerHTMLElements = ['div', 'ul'] as const;

export interface GridContainerDesignProps {
  /* toggle full width */
  enabled?: boolean;
  /** Breakpoints to skip, as skipped breakpoint will use the next lowest unskipped breakpoint value */
  skip?: Array<(typeof GridContainerBreakpoints)[number]>;
}

type GridContainerHTMLElement = (typeof GridContainerHTMLElements)[number];

export type GridContainerProps<T extends GridContainerHTMLElement = 'div'> =
  AsProp<T> & GridContainerDesignProps;

export function GridContainer<T extends GridContainerHTMLElement>(
  props: GridContainerProps<T>
): JSX.Element;

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/grid | Grid} from corgi-x to create grid layouts. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export function GridContainer({
  children,
  enabled = true,
  skip = [],
  className,
  as: HTMLElement = 'div',
  ...props
}: GridContainerProps): JSX.Element {
  return (
    <HTMLElement
      className={classNames(
        styles.container,
        { [styles.disabled]: !enabled },
        ...skip.map(s => styles[`skip-${s}`]),
        className
      )}
      {...props}
    >
      {children}
    </HTMLElement>
  );
}
