import { Divider } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { Picture } from '../Picture';

import styles from './MemberCard.module.css';
import { SimpleMemberCardProps } from '.';

export function MemberCard({
  name,
  education,
  sources,
  position,
  text,
  alt,
}: SimpleMemberCardProps) {
  return (
    <article className={styles.card}>
      <Picture
        className={styles.image}
        width="150"
        height="150"
        sources={sources}
        alt={alt}
      />
      <Text
        className={styles.name}
        as="h3"
        bold
        color="blueberry-3"
        fontStyle="normal"
        variant="heading-22"
      >
        {name}
      </Text>
      {education && (
        <Text
          className={styles.education}
          as="h3"
          bold
          color="blueberry-3"
          fontStyle="normal"
          variant="heading-22"
        >
          {education}
        </Text>
      )}
      {!text && (
        <div className={styles.divider}>
          <Divider width={2} color="carrot-2" orientation="horizontal" />
        </div>
      )}
      <Text
        className={styles.position}
        as="p"
        fontStyle={text ? 'italic' : 'normal'}
        variant={text ? 'article-16' : 'article-20'}
      >
        {position}
      </Text>
      {text && (
        <>
          <div className={styles.divider}>
            <Divider width={2} color="carrot-2" orientation="horizontal" />
          </div>

          <Text as="p" color="kale-3" fontStyle="normal" variant="article-16">
            {text}
          </Text>
        </>
      )}
    </article>
  );
}
