import { track } from '../track';
import { webinars_page_clicked_share_button } from './eventNames';

interface TrackWebinarsPageClickedShareButtonArgs {
  title: string;
}

export function trackWebinarsPageClickedShareButton({
  title,
}: TrackWebinarsPageClickedShareButtonArgs) {
  track({
    eventName: webinars_page_clicked_share_button,
    eventProps: {
      title,
    },
  });
}
