import { Grid, GridItem } from '@farmersdog/corgi-x';
import { RecipeCard } from '../RecipeCard';
import { RecipeDisplay } from '../RecipeDisplay';
import { farmersDogMeals, grains } from '../assets/recipes';

interface RecipesProps {
  isMobile: boolean;
}

export function Recipes({ isMobile }: RecipesProps) {
  return (
    <Grid rowGap="md">
      <GridItem>
        <RecipeDisplay title="The Farmer’s Dog Grain-Inclusive Recipes">
          {grains.map((grain, index) => (
            <RecipeCard
              index={index}
              isMobile={isMobile}
              key={grain.title}
              recipe={grains[index]}
              isLastOne={index === grains.length - 1}
              listOfRecipes={grains}
            />
          ))}
        </RecipeDisplay>
      </GridItem>
      <GridItem>
        <RecipeDisplay title="The Farmer’s Dog Meat and Vegetables Recipes">
          {farmersDogMeals.map((meal, index) => (
            <RecipeCard
              index={index}
              isMobile={isMobile}
              key={meal.title}
              recipe={farmersDogMeals[index]}
              isLastOne={index === farmersDogMeals.length - 1}
              listOfRecipes={farmersDogMeals}
            />
          ))}
        </RecipeDisplay>
      </GridItem>
    </Grid>
  );
}
