import { FC } from 'react';

import { PageTitle, PublicPage, ResetPasswordForm } from '../../components';

import styles from './ResetPassword.module.css';

export const ResetPassword: FC = () => {
  return (
    <PublicPage>
      <section className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle
            title="Set New Password"
            subtitle="Enter the code and specified information below to reset your password."
          />
        </div>
        <ResetPasswordForm />
      </section>
    </PublicPage>
  );
};
