import { ReactNode } from 'react';

import { Helmet } from 'react-helmet-async';
import { defaultTitle, titleTemplate } from '../../utils';

export interface PageProps {
  children: ReactNode;
  title?: string;
  description?: string;
}

export function Page({ children, title, description }: PageProps) {
  return (
    <main
      data-name="Page"
      aria-label={title || defaultTitle}
      data-tfd-new-typography
    >
      <Helmet
        defaultTitle={titleTemplate.replace('%s', defaultTitle)}
        titleTemplate={titleTemplate}
      >
        <title>{title}</title>
        <meta
          name="twitter:title"
          content={titleTemplate.replace('%s', title || defaultTitle)}
        />
        <meta
          property="og:title"
          content={titleTemplate.replace('%s', title || defaultTitle)}
        />
      </Helmet>

      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta itemProp="description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
      )}
      {children}
    </main>
  );
}
