import { useFormik } from 'formik';
import { veterinaryPracticeOrOrganizationInformationFormValues } from '../SignUpFormWithNoSelectedPractice/constants';
import { practiceOrOrganizationValidationSchema } from '../SignUpFormWithNoSelectedPractice';
import { getPassword } from '../utils';

import {
  OrganizationInput,
  OrganizationDataSource,
} from '../../../graphql/types';
import { countryManualPractice, OrganizationType } from '../../../constants';
import type { CreateVetWithOrganizationArgs } from '../SignupForm';
import type {
  PersonalInformationConferenceFormValues,
  PersonalInformationFormValues,
} from '../PersonalInformationForm';

interface DataToSubmit {
  source: OrganizationDataSource;
  type: OrganizationType;
}

interface UseOrganizationManualFormArgs {
  personalInfoFormValues:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues;
  dataToSubmit: DataToSubmit;
  isConferenceModeOn: boolean;
  submit: (args: CreateVetWithOrganizationArgs) => Promise<void>;
}

export function useOrganizationOrPracticeManualForm({
  personalInfoFormValues,
  dataToSubmit,
  isConferenceModeOn,
  submit,
}: UseOrganizationManualFormArgs) {
  const { source, type } = dataToSubmit;

  const organizationOrPracticeManualForm = useFormik({
    initialValues: veterinaryPracticeOrOrganizationInformationFormValues,
    validationSchema: practiceOrOrganizationValidationSchema,
    // submit is used when submitting the form for add and none modes
    onSubmit: async formValues => {
      const personalInformationValues = personalInfoFormValues;
      const organization = {
        ...formValues,
        source,
        type,
        country: countryManualPractice,
        state: formValues.state!,
      } satisfies OrganizationInput;

      const password = getPassword({
        personalInformationValues,
        isConferenceModeOn,
      });

      await submit({
        personalInformation: personalInformationValues,
        organization,
        password,
      });
    },
  });

  return { organizationOrPracticeManualForm };
}
