/**
 * Given an element and it's parent, where the parent has overflow x,
 * returns true if the element is partially or completely
 * out of view to the right.
 *
 * @param {Node} element
 * @param {Node} parentElement
 * @returns Boolean
 */
function isOverflowingRight(
  element: HTMLElement,
  parentElement: HTMLElement | null
): boolean {
  if (!parentElement) return false;

  const offsetLeft = element.offsetLeft;
  const elementWidth = element.getBoundingClientRect().width;
  const scrollLeft = parentElement.scrollLeft;
  const scrollSnapWidth = parentElement.getBoundingClientRect().width;

  const positionInVisibleArea = offsetLeft + elementWidth - scrollLeft;
  return positionInVisibleArea > scrollSnapWidth;
}

export default isOverflowingRight;
