import { Helmet } from 'react-helmet-async';
import {
  ga4ScriptContent,
  ga4WebsiteMeasurementSrc,
  ga4VetPortalMeasurementSrc,
} from '../../vendors/ga4';
import { segmentScriptContent } from '../../vendors/segment';
import config from '../../config';

export const Head = () => {
  if (config.get('app.clientAnalytics')) {
    return <HeadAnalyticsForLocalOnly />;
  }
  return null;
};

function HeadAnalyticsForLocalOnly() {
  return (
    <Helmet>
      <script async src={ga4WebsiteMeasurementSrc}></script>
      <script async src={ga4VetPortalMeasurementSrc}></script>
      <script>{ga4ScriptContent}</script>
      <script>{segmentScriptContent}</script>
    </Helmet>
  );
}
