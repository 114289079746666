import { EnterEmailFormFields } from './enterEmailFormFieldsData';
export interface EnterEmailFormValues {
  [EnterEmailFormFields.email]: string;
}
export const initialFormValues: EnterEmailFormValues = {
  [EnterEmailFormFields.email]: '',
};

export const getInitialValues = ({ email }: { email: string }) => {
  return {
    [EnterEmailFormFields.email]: email ?? '',
  };
};
