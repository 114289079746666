import classNames from 'classnames';
import React, { useId } from 'react';

import { SelectorGroup, SelectorGroupProps } from '../SelectorGroup';

import { Description } from './Description';
import { RichSelectorProps } from '../RichSelector';

import styles from './RichSelectorGroup.css';

export interface RichSelectorGroupProps extends SelectorGroupProps {
  /** Current selected value */
  value?: string | number | null;
  /** Enables description to be width of the line */
  fullWidthDescription?: boolean;
  /** The default full width description to fall back on when no option is selected*/
  defaultFullWidthDescription?: string;
  /** RichSelector children */
  children: React.ReactElement<RichSelectorProps>[];
}

/**
 * SelectorGroup component meant to be used with the RichSelector component.
 *
 * Will manage RichSelector `isFirst`, `isLast`, and `description` props.
 *
 * Handles showing the description given the fullWidthDescription and the hover
 * or checked state of the RichSelectors
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/richselector | RichSelector} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const RichSelectorGroup: React.FC<RichSelectorGroupProps> = ({
  value,
  fullWidthDescription,
  defaultFullWidthDescription,
  children,
  ...props
}) => {
  const id = useId();
  const options = React.Children.map(children, child => child);

  const selectedOption = options.find(option => option.props.value === value);
  const hasDescriptions = options.some(option =>
    Boolean(option.props.description)
  );

  const [activeOption, setActiveOption] = React.useState<
    React.ReactElement<RichSelectorProps> | undefined
  >(selectedOption);

  const handleOptionEnter = (value?: string | number): void => {
    const hoveredOption = options.find(option => option.props.value === value);
    setActiveOption(hoveredOption);
  };
  const handleOptionLeave = (): void => {
    setActiveOption(selectedOption);
  };

  const activeOptionId =
    id && activeOption
      ? `${id}-${String(activeOption.props.value)}`
      : undefined;
  const activeOptionHasDescription = Boolean(activeOption?.props.description);
  const hasDefaultFullWithDescription = Boolean(defaultFullWidthDescription);

  const showFullWidthDescription =
    fullWidthDescription &&
    (activeOptionHasDescription || hasDefaultFullWithDescription);

  return (
    <div
      className={classNames(styles.container, {
        [styles.descriptionPadding]: hasDescriptions && fullWidthDescription,
      })}
    >
      <SelectorGroup inputsClassName={styles.inputs} value={value} {...props}>
        {options.map((option, index) => {
          const isActive = activeOption?.props.value === option.props.value;
          const hasDescription = Boolean(option.props.description);
          const descriptionId = id
            ? `${id}-${String(option.props.value)}`
            : undefined;

          return React.cloneElement(option, {
            isFirst: index === 0,
            isLast: index === options.length - 1,
            labelProps: {
              ...option.props.labelProps,
              onMouseEnter: (): void => handleOptionEnter(option.props.value),
              onMouseLeave: handleOptionLeave,
              onFocus: (): void => handleOptionEnter(option.props.value),
              onBlur: handleOptionLeave,
            },
            className: classNames(option.props.className, {
              [styles.descriptionPadding]:
                hasDescriptions && !fullWidthDescription,
            }),
            'aria-describedby': descriptionId,
            description: fullWidthDescription ? undefined : (
              <Description
                id={descriptionId}
                in={isActive && hasDescription}
                className={styles.description}
              >
                {option.props.description}
              </Description>
            ),
          });
        })}
      </SelectorGroup>
      {showFullWidthDescription && (
        <Description
          id={activeOptionId}
          in={activeOptionHasDescription || hasDefaultFullWithDescription}
          className={styles.description}
        >
          {activeOption?.props.description ?? defaultFullWidthDescription}
        </Description>
      )}
    </div>
  );
};
