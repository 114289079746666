import * as React from 'react';

type UseHtmlElementReturn = [
  HTMLElement | null,
  (el: HTMLElement | null) => void,
];

/**
 * Obtain a reference to an underlying HTML element in a stateful manner. This
 * can be preferred to useRef for instances where an html node may not be
 * available on initial render but you would like to receive updates when it is
 * available.
 *
 * @example
 *
 * const [divElement, divRef] = useHtmlElement();
 * return <div ref={divRef} />
 *
 * @deprecated Import {@link https://corgi-x.tfd.engineering/api/functions/useHtmlElement | useHtmlElement} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export function useHtmlElement(): UseHtmlElementReturn {
  const [currentHtmlElement, setCurrentHtmlElement] =
    React.useState<HTMLElement | null>(null);

  const setRef = React.useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setCurrentHtmlElement(node);
    }
  }, []);

  return [currentHtmlElement, setRef];
}
