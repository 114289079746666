import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function usePortalUXOverhaul() {
  const portalUXOverhaul = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_PORTAL_UX_OVERHAUL, {});

  const showPortalUXOverhaul =
    portalUXOverhaul.treatment === OnOffTreatments.on;

  return {
    showPortalUXOverhaul,
  };
}
