import * as React from 'react';

import { Transition } from 'react-transition-group';
import { browserOnly } from '../../util/browserOnly';
import classNames from 'classnames';
import transitions from './transitions';

const { useState, useEffect } = React;

export const ContentRevealAnimations = [
  'springUp',
  'springDown',
  'slideUp',
  'slideDown',
  'fade',
] as const;

export interface ContentRevealProps {
  /** Transition in */
  in: boolean;
  /** Animation when the content is shown */
  inAnimation: (typeof ContentRevealAnimations)[number];
  /** Delay to showing the content */
  inDelay?: number;
  /** Animation when the content is hidden */
  outAnimation?: (typeof ContentRevealAnimations)[number];
  /** Delay to hiding the content */
  outDelay?: number;
  children: React.ReactNode;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/contentreveal | ContentReveal} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const ContentReveal: React.FC<ContentRevealProps> = ({
  inAnimation,
  inDelay = 0,
  outAnimation,
  outDelay = 0,
  children,
  ...props
}) => {
  const [inState, setInState] = useState<boolean>(false);
  const inTransition = transitions[inAnimation];
  const outTransition = outAnimation
    ? transitions[outAnimation]
    : { ...inTransition, transitions: inTransition.defaultOut };

  const stateTransitions = {
    entering: inTransition.transitions.entering,
    exiting: outTransition.transitions.exiting,
    init: transitions.base.transitions.init,
    ...transitions.base.transitions,
  };

  useEffect(() => {
    let delayTimeout: number | undefined;

    if (props.in !== inState) {
      delayTimeout = browserOnly(window =>
        window.setTimeout(
          () => {
            setInState(props.in);
            clearTimeout(delayTimeout);
            delayTimeout = undefined;
          },
          props.in ? inDelay : outDelay
        )
      );
    }

    return (): void => {
      clearTimeout(delayTimeout);
      delayTimeout = undefined;
    };
  }, [inDelay, inState, outDelay, props.in]);

  return (
    <Transition
      timeout={{
        enter: inTransition.duration,
        exit: outTransition.duration,
      }}
      in={inState}
    >
      {(state): React.ReactNode => {
        const child = React.Children.only(children);

        if (!React.isValidElement<HTMLElement>(child)) {
          return child;
        }

        const className = classNames(
          child.props.className,
          stateTransitions.init,
          stateTransitions[state as keyof typeof stateTransitions]
        );
        return React.cloneElement(child, { className });
      }}
    </Transition>
  );
};
