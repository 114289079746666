import React, { FC } from 'react';
import classNames from 'classnames';

import { Link } from '../Link';

import { AppBarItemProps } from './AppBar';
import styles from './CallToAction.css';

type CallToActionProps = {
  asPill?: boolean;
  withPadding?: boolean;
  isMobile?: boolean;
} & AppBarItemProps;

export const CallToAction: FC<CallToActionProps> = ({
  href,
  to,
  title,
  onClick,
  asPill = false,
  withPadding = false,
  isMobile,
}) => (
  <div
    data-name="CallToAction"
    className={classNames(styles.container, { [styles.padding]: withPadding })}
  >
    <Link
      className={classNames(isMobile ? styles.fullWidth : undefined)}
      asButton
      asPill={asPill}
      to={to}
      href={href}
      variant="primary"
      onClick={onClick}
    >
      {title}
    </Link>
  </div>
);
