import { Picture } from '../../../../../components';

import { foodBowlsAssets } from '../../assets';

import styles from './FoodBowls.module.css';

export function FoodBowls() {
  return (
    <div className={styles.container}>
      <Picture
        className={styles.bowlImage}
        sources={foodBowlsAssets}
        alt="Food bowls"
      />
    </div>
  );
}
