import React, { FC } from 'react';

import baseStyles from '../../styles/button.css';
import classNames from 'classnames';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];
type ClickEvent = React.MouseEventHandler<HTMLButtonElement>;

/**
 * The core component to render a button.  Responsible for managing any
 * accessibility patterns and minimal base button styles.
 *
 * This component is "private" to CORGI and should not be exported.
 */
export const ButtonBase: FC<IntrinsicButtonProps> = ({
  type = 'button',
  disabled,
  ...props
}) => {
  if (disabled) {
    props['aria-disabled'] = true;
  }

  const handleClickSuppression: ClickEvent = event => {
    event.preventDefault();
    return;
  };

  return (
    <button
      {...props}
      type={type}
      className={classNames(baseStyles.button, props.className)}
      /* for accessibility purposes, disable click events if disabled or loading; otherwise use props */
      onClick={disabled ? handleClickSuppression : props.onClick}
    >
      {props.children}
    </button>
  );
};
