import * as React from 'react';

import { LiveMessage } from '../LiveMessage';
import { Text } from '../Text';
import classNames from 'classnames';
import styles from './Description.css';

interface DescriptionProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  in?: boolean;
}

export const Description: React.FC<DescriptionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <LiveMessage
      aria-live="polite"
      hidden={!props.in}
      className={styles.position}
      {...props}
    >
      <Text
        as="div"
        variant="article-12"
        color="carrot-2"
        fontStyle="italic"
        className={classNames(styles.container, className)}
      >
        {children}
      </Text>
    </LiveMessage>
  );
};
