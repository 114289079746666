import { copy_button_clicked } from './eventNames';
import { track } from '../track';

/**
 * Send a tracking event for when a user clicks the copy button on the recommend to client screen
 *
 * @param email - User email
 */

interface TrackClickedCopyButtonValues {
  email: string;
}

export function trackClickedCopyButton({
  email,
}: TrackClickedCopyButtonValues) {
  track({
    eventName: copy_button_clicked,
    eventProps: {
      email,
    },
  });
}
