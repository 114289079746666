import { LinkProps } from '@farmersdog/corgi';
import { links, paths } from '@farmersdog/constants';
import { getAbsoluteLink } from '../../../utils/get-absolute-link';
import { Text } from '@farmersdog/corgi-x';
import * as pagesPaths from '../../../constants/pagePaths';
import { LINK_AFFILIATES } from '@farmersdog/constants/links';
interface NavigationLink extends LinkProps {
  label: string | JSX.Element;
}

export const navigationLinks: NavigationLink[] = [
  {
    href: getAbsoluteLink(paths.PATH_REVIEWS),
    label: 'Reviews',
  },
  {
    href: getAbsoluteLink(paths.PATH_ABOUT_US),
    label: 'About Us',
  },
  {
    href: getAbsoluteLink(paths.PATH_FAQ),
    label: 'Subscriber FAQs',
    ['aria-label']: 'Frequently Asked Questions',
  },
  {
    href: getAbsoluteLink(pagesPaths.PATH_LOGIN),
    label: 'Subscription Account Log In',
  },
  {
    href: getAbsoluteLink(pagesPaths.PATH_SIGNUP),
    label: 'Subscribe to Fresh Food',
  },
  {
    href: getAbsoluteLink(`${links.LINK_DIGEST}/`),
    label: 'Digest',
  },
  {
    href: getAbsoluteLink(paths.PATH_DIY),
    label: 'DIY',
  },
  {
    href: getAbsoluteLink(paths.PATH_CAREERS),
    label: (
      <Text variant="heading-16">
        Careers{' '}
        <Text variant="article-12" color="Kale1" fontStyle="italic">
          We’re Hiring!
        </Text>
      </Text>
    ),
  },
  {
    href: LINK_AFFILIATES,
    label: `Affiliates`,
  },
  {
    to: paths.PATH_HOME,
    label: 'For Vet Professionals',
  },
  {
    href: getAbsoluteLink(paths.PATH_BREED),
    label: 'Breeds',
  },
];
export const navigationVetPortalLinks: NavigationLink[] = [
  {
    to: pagesPaths.PATH_DASHBOARD,
    label: 'Home',
  },
  {
    to: pagesPaths.PATH_DOWNLOADS,
    label: 'Resources',
  },
  {
    to: pagesPaths.PATH_EVENTS_AND_RECORDINGS,
    label: 'Events & Recordings',
  },
  {
    to: pagesPaths.PATH_CONTACT_US,
    label: 'Contact',
  },
  {
    to: pagesPaths.PATH_OUR_TEAM,
    label: 'Our Team',
  },
  {
    to: pagesPaths.PATH_PROFILE,
    label: 'Profile',
  },
  {
    to: pagesPaths.PATH_FAQ,
    label: 'Vet FAQs',
  },
];

export const joinUsLinks: NavigationLink[] = [
  {
    to: pagesPaths.PATH_LOGIN,
    label: 'Login',
  },
  {
    to: pagesPaths.PATH_SIGNUP,
    label: 'Register',
  },

  {
    to: pagesPaths.PATH_FAQ,
    label: 'Vet FAQs',
  },
];
