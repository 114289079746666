import { Divider } from '@farmersdog/corgi-x';
import { Card } from '../../../components';
import { TitleSection } from '../TitleSection';
import { getSupportItems } from './supportData';
import { SupportItem } from './SupportItem/SupportItem';
import styles from './Support.module.css';

const supportedItems = getSupportItems();
export const Support = () => {
  const notLastItem = (index: number) => index < supportedItems.length - 1;
  return (
    <>
      <TitleSection title="Get support from our team" />
      <Card className={styles.card}>
        {supportedItems.map((supportItem, index) => (
          <div key={`${supportItem.title}-${supportItem.description}`}>
            <SupportItem key={supportItem.title} {...supportItem} />
            {notLastItem(index) && (
              <Divider width={1} color="Blueberry1" spacing={24} />
            )}
          </div>
        ))}
      </Card>
    </>
  );
};
