import { PREFERS_REDUCED_MOTION_QUERY } from './constants';
import { browserOnly } from '../browserOnly';

export enum Behavior {
  auto = 'auto',
  smooth = 'smooth',
}

export function getPreferredScrollBehavior(): Behavior | undefined {
  return browserOnly(window => {
    const mediaQuery = window.matchMedia(PREFERS_REDUCED_MOTION_QUERY);
    const behavior = mediaQuery.matches ? Behavior.auto : Behavior.smooth;

    return behavior;
  });
}
