import { Banner, Button, Text } from '@farmersdog/corgi-x';
import { BannerConfig } from '../../abTesting/features';

export function PortalBanner({
  link,
  buttonVerbiage,
  textVerbiage,
}: BannerConfig) {
  return (
    <>
      <Banner variant="kale-2">
        <Text variant="heading-16">
          <Button
            variant="plain-text"
            type="anchor"
            href={link}
            target="_blank"
            color="white"
            id="register-webinar-btn"
            underline
          >
            <Text variant="heading-16" bold>
              {buttonVerbiage}
            </Text>
          </Button>{' '}
          {textVerbiage}
        </Text>
      </Banner>
    </>
  );
}
