import { useMachine } from '@xstate/react';
import { usePreviousEffect } from '@farmersdog/utils';
import { DiscountRequestEvent } from './discountRequestEvent';
import { discountRequestMachine } from './discountRequestMachine';
import { DiscountRequestState } from './discountRequestState';
import { useSearchParam } from './useSearchParam';
import { useForwardSteps } from './useForwardSteps';

interface UseDiscountRequestFlowArgs {
  isLegacyHospital: boolean;
}

export function useDiscountRequestFlow({
  isLegacyHospital,
}: UseDiscountRequestFlowArgs) {
  const [state, send] = useMachine(discountRequestMachine, {
    context: {
      isLegacyHospital,
    },
  });
  const { setNextForwardStep, setPreviousForwardStep, forwardSteps } =
    useForwardSteps();
  const isState = (stateInput: DiscountRequestState) => {
    return state.matches(stateInput);
  };

  const { updateSearchParam } = useSearchParam({
    isOnFirstStep: isState(DiscountRequestState.AreYouSubscribed),
  });

  // When length of steps has decreased, that means we want to go to the state that is represented by the last step of current steps
  usePreviousEffect((prevSteps, nextSteps) => {
    if (nextSteps.length < prevSteps.length) {
      const prevStep = nextSteps[nextSteps.length - 1];
      send(DiscountRequestEvent.Previous, {
        prevStep,
      });
      updateSearchParam(prevStep);
    }
  }, forwardSteps);

  function goToNext(event: DiscountRequestEvent) {
    const sentEvent = send(event);
    const nextStep = sentEvent.value as DiscountRequestState;
    setNextForwardStep(nextStep);
    updateSearchParam(nextStep);
  }

  const onAlreadySubscribed = () => {
    goToNext(DiscountRequestEvent.AlreadySubscribed);
  };

  const onNotYetSubscribed = () => {
    goToNext(DiscountRequestEvent.NotYetSubscribed);
  };

  const onHowToGetDiscountContinue = () => {
    goToNext(DiscountRequestEvent.HowToGetDiscountContinue);
  };

  const onEnterEmailContinue = () => {
    goToNext(DiscountRequestEvent.EnterEmailContinue);
  };

  const onUpdatePractice = () => {
    goToNext(DiscountRequestEvent.UpdatePractice);
  };

  const onSelectPractice = () => {
    goToNext(DiscountRequestEvent.SelectPractice);
  };

  const onAddPractice = () => {
    goToNext(DiscountRequestEvent.AddPractice);
  };

  const onSubmit = () => {
    goToNext(DiscountRequestEvent.SubmitRequest);
  };

  const onPrevious = () => {
    setPreviousForwardStep();
  };

  const onClose = () => {
    send(DiscountRequestEvent.Close);
  };

  return {
    isState,
    onAlreadySubscribed,
    onNotYetSubscribed,
    onHowToGetDiscountContinue,
    onEnterEmailContinue,
    onUpdatePractice,
    onSelectPractice,
    onAddPractice,
    onSubmit,
    onClose,
    onPrevious,
    steps: forwardSteps,
  };
}
