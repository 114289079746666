import { track } from '../track';
import { faq_question_clicked } from './eventNames';

/**
 * Send a tracking event for when a user clicks on a closed FAQ question dropdown.
 */

interface TrackClickedFAQQuestionValues {
  question: string;
}

export function trackClickedFAQQuestion({
  question,
}: TrackClickedFAQQuestionValues) {
  track({
    eventName: faq_question_clicked,
    eventProps: {
      question,
    },
  });
}
