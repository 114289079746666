import springDownTransitions from './transitionStyles/spring-down.css';
import springUpTransitions from './transitionStyles/spring-up.css';
import slideDownTransitions from './transitionStyles/slide-down.css';
import slideUpTransitions from './transitionStyles/slide-up.css';
import fadeTransitions from './transitionStyles/fade.css';
import baseTransitions from './transitionStyles/base.css';

export default {
  springDown: {
    transitions: springDownTransitions,
    defaultOut: springUpTransitions,
    duration: 600,
  },
  springUp: {
    transitions: springUpTransitions,
    defaultOut: springDownTransitions,
    duration: 600,
  },
  slideDown: {
    transitions: slideDownTransitions,
    defaultOut: slideUpTransitions,
    duration: 300,
  },
  slideUp: {
    transitions: slideUpTransitions,
    defaultOut: slideDownTransitions,
    duration: 300,
  },
  fade: {
    transitions: fadeTransitions,
    defaultOut: fadeTransitions,
    duration: 300,
  },
  base: {
    transitions: baseTransitions,
  },
};
