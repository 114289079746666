import {
  Button,
  Grid,
  GridItem,
  Link,
  NativeWistiaOptions,
  Text,
  VideoSection,
} from '@farmersdog/corgi-x';
import styles from './Video.module.css';
import * as pagePaths from '../../../../constants/pagePaths';

interface VideoProps {
  type: string;
  name: string;
  speaker: string;
  dateAndLocation: string;
  videoId: string;
  wistiaOptions: NativeWistiaOptions;
  isMobile?: boolean;
  showPortalUXOverhaul?: boolean;
}

export const Video = ({
  dateAndLocation,
  name,
  speaker,
  type,
  videoId,
  wistiaOptions,
  isMobile,
  showPortalUXOverhaul,
}: VideoProps) => {
  const webinarDetails = `${pagePaths.PATH_EVENT_AND_RECORDING}?videoId=${videoId}`;
  return (
    <Grid className={styles.container}>
      {!isMobile && (
        <GridItem className={styles.video}>
          <Link to={webinarDetails}>
            <VideoSection
              className={styles.video}
              wistiaId={videoId}
              hideControls
              blockInteractions
              wistiaOptions={wistiaOptions}
            />
          </Link>
        </GridItem>
      )}
      <GridItem>
        <Grid flexDirection="column" className={styles.information}>
          <Text variant="heading-16" color="kale-2" bold>
            {type}
          </Text>
          <Text variant="heading-16" bold>
            {name}
          </Text>
          <Text variant="heading-16" color="charcoal-2">
            {speaker}
          </Text>
          <Text variant="heading-16" color="charcoal-2">
            {dateAndLocation}
          </Text>
          <Button
            variant="plain-text"
            color={showPortalUXOverhaul ? 'kale' : 'carrot'}
            type="link"
            to={webinarDetails}
            underline
            className={styles.viewDetailsButton}
          >
            Earn CE Credit
          </Button>
        </Grid>
      </GridItem>
    </Grid>
  );
};
