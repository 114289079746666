import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './PageWidth.css';

type IntrinsicDivProps = JSX.IntrinsicElements['div'];

export const PageWidthBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

export interface PageWidthProps extends IntrinsicDivProps {
  /** Toggle max width */
  enabled?: boolean;
  /** Breakpoints to skip, as skipped breakpoint will use the next lowest unskipped breakpoint value */
  skip?: Array<(typeof PageWidthBreakpoints)[number]>;
}

/** @deprecated Use {@link https://corgi-x.tfd.engineering/components/pagewidth | PageWidth} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const PageWidth: FC<PageWidthProps> = ({
  children,
  enabled = true,
  skip = [],
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.disabled]: !enabled },
        ...skip.map(s => styles[`skip-${s}`]),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
