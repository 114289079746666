import { useState } from 'react';

import { FormSuccess as FormSuccessComponent } from '../FormSuccess';

export function useFormSuccess() {
  const [formSuccessMessage, setFormSuccessMessage] = useState<string>('');

  const clearFormSuccessMessage = () => setFormSuccessMessage('');

  const FormSuccess = () => (
    <FormSuccessComponent message={formSuccessMessage} />
  );

  return {
    FormSuccess,
    setFormSuccessMessage,
    clearFormSuccessMessage,
    formSuccessMessage,
  };
}
