import * as yup from 'yup';
import { ResetPasswordFormFields } from './resetPasswordFieldsData';
import { EMAIL_REGEXP } from '../../../constants/validation';
import { noTrailingWhitespace } from '../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../..//utils';

export const resetPasswordFormValidationSchema = yup.object({
  [ResetPasswordFormFields.code]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [ResetPasswordFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),
  [ResetPasswordFormFields.password]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [ResetPasswordFormFields.confirmPassword]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf([yup.ref(ResetPasswordFormFields.password)], 'Passwords must match'),
});
