import { FC, ReactElement, useMemo } from 'react';

import { useAuthContext } from '../../context';

import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import * as pagePaths from '../../constants/pagePaths';
import { AttestedAtSignupRoute } from './AttestedAtSignupRoute';
import { useQueryParameters } from '../../hooks';

export interface AuthorizedRouteProps extends RouteProps {
  children: ReactElement;
}

export const AuthorizedRoute: FC<AuthorizedRouteProps> = ({
  children,
  path,
  exact,
}) => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const { pathname, hash } = useLocation();
  const query = useQueryParameters();
  const resource = query.get('resource');

  const redirect = useMemo(() => {
    const newQuery = new URLSearchParams();
    newQuery.set('next', `${pathname}${hash ?? ''}`);
    if (resource) {
      newQuery.set('resource', resource);
    }
    return newQuery.toString();
  }, [pathname, hash, resource]);

  return (
    <>
      {!isLoading && (
        <Route
          path={path}
          exact={exact}
          render={({ location }) =>
            isAuthenticated ? (
              <AttestedAtSignupRoute>{children}</AttestedAtSignupRoute>
            ) : (
              <Redirect
                to={{
                  pathname: pagePaths.PATH_SIGNUP,
                  search: redirect,
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
