interface JoinTextArgs {
  elements: string[];
  separator: string;
  endSeparator?: string;
}

export const joinText = ({
  elements,
  separator,
  endSeparator,
}: JoinTextArgs) => {
  if (elements.length === 1) {
    return elements[0];
  }
  const result =
    elements.slice(0, -1).join(separator) +
    (endSeparator ?? separator) +
    elements[elements.length - 1];
  return result;
};
