export const KALE_0 = '#E5EFE7';
export const KALE_1 = '#b5d3ba';
export const KALE_2 = '#417C67';
export const KALE_3 = '#173b33';

export const BLUEBERRY_0 = '#E6ECF5';
export const BLUEBERRY_1 = '#B7C6E6';
export const BLUEBERRY_2 = '#385A9D';
export const BLUEBERRY_3 = '#03184E';

export const CARROT_0 = '#FFF4F1';
export const CARROT_1 = '#FFD9CC';
export const CARROT_2 = '#f2674b';
export const CARROT_3 = '#D2563D';

export const CHARCOAL_0 = '#c2c3c5';
export const CHARCOAL_1 = '#969799';
export const CHARCOAL_2 = '#717377';
export const CHARCOAL_3 = '#39383C';

export const CHICKPEA_0 = '#FFFAF3';
export const CHICKPEA_1 = '#fff3e1';
export const CHICKPEA_2 = '#f2e1c8';
export const CHICKPEA_3 = '#D3BFA2';

export const OYSTER_0 = '#F6F5F3';
export const OYSTER_1 = '#EFEDEA';
export const OYSTER_2 = '#DBD6D1';
export const OYSTER_3 = '#C6BFB7';

export const TFD_LOGO = '#F5694D';
export const WHITE = '#ffffff';
export const ERROR_RED = '#f54c5a';
