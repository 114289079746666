import config from '../config';

/**
 * Split configuration settings to be used on the browser
 */
export const splitConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: config.get('app.splitio.authorizationKey'),
    key: 'NO_SEGMENT_ID',
  },
  debug: config.get('app.splitio.debug'),
};
