import { useEffect, useState } from 'react';

interface useDebounceProps<T> {
  delay: number;
  initialValue: T;
  callBackService: (value: T) => void;
}
export const useDebounce = <T,>({
  callBackService,
  delay,
  initialValue,
}: useDebounceProps<T>) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
  useEffect(() => {
    const handler = setTimeout(() => callBackService(debouncedValue), delay);

    return () => clearTimeout(handler);
  }, [callBackService, debouncedValue, delay]);

  const changeValue = (value: T) => {
    setDebouncedValue(value);
  };
  return { changeValue };
};
