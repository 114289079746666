import * as stripeJs from '@stripe/stripe-js';

import { KALE_3, ERROR_RED } from '../../styles/colors';

/**
 * Element style properties. Stripe applies these directly to the input element
 * inside the `iframe` while the css classes are only applied to the root
 * element. Unfortunately we must use this inline approach as the other approach
 * does not work correctly.
 */
export const ELEMENT_PROPS: stripeJs.StripeCardNumberElementOptions = {
  classes: {
    base: 'NOOP',
    complete: 'NOOP',
    empty: 'NOOP',
    focus: 'NOOP',
    invalid: 'NOOP',
  },
  style: {
    base: {
      color: KALE_3,
      fontWeight: '400',
      fontFamily: '"neue-haas-grotesk-display", serif',
      fontSize: '16px',
      letterSpacing: '0.5px',
    },
    invalid: {
      color: ERROR_RED,
    },
  },
};
