import { ApolloClient, from } from '@apollo/client';

import { createAuthMiddleware } from './middleware/createAuthMiddleware';
import { createHttpMiddleware } from './middleware/createHttpMiddleware';
import { createErrorMiddleware } from './middleware/createErrorMiddleware';

import { createInMemoryCache } from './createInMemoryCache';

export function createClient() {
  return new ApolloClient({
    cache: createInMemoryCache(),
    link: from([
      createAuthMiddleware(),
      createErrorMiddleware(),
      createHttpMiddleware(),
    ]),
  });
}
