import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';
import styles from './ResetPasswordSucessMessage.module.css';
import MailboxFilled from './assets/MailboxFilled.svg?react';
import { Button, Text } from '@farmersdog/corgi-x';

interface ResetPasswordSucessMessageProps {
  isMobile?: boolean;
  onReturnProfile: () => void;
}

export const ResetPasswordSucessMessage = ({
  isMobile,
  onReturnProfile,
}: ResetPasswordSucessMessageProps) => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <section className={styles.container}>
      <MailboxFilled />
      <div>
        <Text
          as="h1"
          variant={isMobile ? 'heading-28' : 'heading-40'}
          bold
          color="blueberry-3"
        >
          Password Reset Email Sent!
        </Text>

        <Text as="p" variant="article-16">
          Please follow the instructions in the email to complete resetting your
          password.
        </Text>
      </div>
      <Button
        variant={
          showPortalUXOverhaul ? 'primary-mini-kale-experimental' : 'primary'
        }
        className={styles.registerButton}
        onClick={onReturnProfile}
      >
        Return to Profile
      </Button>
    </section>
  );
};
