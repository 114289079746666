import {
  Fieldset,
  Grid,
  GridItem,
  RadioButton,
  Text,
} from '@farmersdog/corgi-x';
import { AccountPage } from '../../components/AccountPage';

import { ResponsiveProps } from '../../constants';
import { useState } from 'react';
import { ContactUsForm } from '../../components/ContactUsFormNew';
import { Card } from '../../components';
import { ExternalFormLink } from '../../components/ContactUsFormNew/ExternalFormLink';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { EmailSection, EmailSectionLegacy } from './ContactUsEmailSection';

const radioButtonValues = {
  lunchAndLearn: 'lunch_and_learn',
  universityVisit: 'university_visit',
  partnership: 'partnership',
  question: 'question',
  other: 'other',
};

export const contactReasons = {
  lunchAndLearn: 'I would like to request education/lunch & learn for my staff',
  universityVisit:
    'I would like to request education for my university / students',
  partnership: 'I would like to partner with TFD',
  question: 'I have a question about a specific recipe/ingredient',
  other: 'Other',
};

const radioButtons = [
  {
    name: 'Lunch and Learn Button',
    label: contactReasons.lunchAndLearn,
    value: radioButtonValues.lunchAndLearn,
  },
  {
    name: 'University Visit Button',
    label: contactReasons.universityVisit,
    value: radioButtonValues.universityVisit,
  },
  {
    name: 'Partnership Button',
    label: contactReasons.partnership,
    value: radioButtonValues.partnership,
  },
  {
    name: 'Question Button',
    label: contactReasons.question,
    value: radioButtonValues.question,
  },
  {
    name: 'Other Button',
    label: contactReasons.other,
    value: radioButtonValues.other,
  },
];

export const ContactUsResponsive = ({ isMobile }: ResponsiveProps) => {
  const [selectedRadioOption, setSelectedRadioOption] = useState('');
  const [selectedContactReason, setSelectedContactReason] = useState('');
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const cardHeaderContent = showPortalUXOverhaul
    ? 'Select Your Reason for Contact'
    : 'Contact Us';

  const cardSubHeaderContent = showPortalUXOverhaul
    ? 'Then complete the form, and we’ll get back to you shortly!'
    : 'Select your reason for contact, then complete the form, and we’ll get back to you shortly!';

  const handleRadioClick = (value: string, contactReason: string) => {
    setSelectedRadioOption(value);
    setSelectedContactReason(contactReason);
  };

  const renderDynamicForm = () => {
    const showLunchAndLearnRequestFormLink = [
      radioButtonValues.lunchAndLearn,
      radioButtonValues.universityVisit,
    ].some(value => value === selectedRadioOption);

    const showContactUsForm = [
      radioButtonValues.partnership,
      radioButtonValues.question,
      radioButtonValues.other,
    ].some(value => value === selectedRadioOption);

    if (showLunchAndLearnRequestFormLink) {
      return <ExternalFormLink reasonSelection={selectedContactReason} />;
    }

    if (showContactUsForm) {
      return <ContactUsForm reasonSelection={selectedContactReason} />;
    }

    return null;
  };
  return (
    <AccountPage>
      {showPortalUXOverhaul && (
        <Text
          as="h2"
          variant={'heading-40'}
          bold
          color="blueberry-3"
          topSpacing="none"
          bottomSpacing="lg"
        >
          Contact Us
        </Text>
      )}
      <Card>
        <Grid>
          <GridItem>
            <Text
              as="h2"
              variant={
                isMobile || showPortalUXOverhaul ? 'heading-28' : 'heading-40'
              }
              bold
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="none"
            >
              {cardHeaderContent}
            </Text>
            <Text
              as="p"
              variant="article-16"
              color="charcoal-3"
              topSpacing="sm"
              bottomSpacing="sm"
            >
              {cardSubHeaderContent}
            </Text>
          </GridItem>
          <Grid bottomSpacing={'sm'}>
            <GridItem>
              <Fieldset legend={''}>
                {radioButtons.map(({ name, label, value }) => (
                  <RadioButton
                    key={value}
                    name={name}
                    label={label}
                    value={value}
                    onChange={() => handleRadioClick(value, label)}
                    checked={selectedRadioOption === value}
                  />
                ))}
              </Fieldset>
            </GridItem>
          </Grid>
        </Grid>

        {renderDynamicForm()}
      </Card>

      {showPortalUXOverhaul ? <EmailSection /> : <EmailSectionLegacy />}
    </AccountPage>
  );
};
