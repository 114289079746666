import { track } from '../track';
import { terms_changes_notice_dismissed } from './eventNames';

/**
 * Send a tracking event for when a user dismissed the terms changes notice
 */

export function trackTermsChangesNoticeDismissed() {
  track({
    eventName: terms_changes_notice_dismissed,
  });
}
