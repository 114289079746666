import { Button, Grid, GridItem, Text, Nowrap } from '@farmersdog/corgi-x';

import styles from './RegisterBanner.module.css';
import { PATH_SIGNUP } from '../../../constants';
import { trackLandingPageClickedRegisterForVetTeamPortalHero } from '../../../analytics/events';

export const RegisterBanner = () => {
  return (
    <section className={styles.container}>
      <Grid
        gap="sm"
        className={styles.grid}
        justifyContent={{
          xs: 'center',
        }}
      >
        <GridItem lg={7}>
          <Text color="kale-3" variant="article-16">
            Join the The Farmer’s Dog Vet Team Portal to access our nutrition
            guide, discounts, and <Nowrap>nutrition research.</Nowrap>
          </Text>
        </GridItem>
        <GridItem lg={5}>
          <Button
            type="link"
            to={PATH_SIGNUP}
            className={styles.button}
            onClick={() => {
              trackLandingPageClickedRegisterForVetTeamPortalHero();
            }}
          >
            Register for Vet Team Portal
          </Button>
        </GridItem>
      </Grid>
    </section>
  );
};
