import * as Apollo from '@apollo/client';

import { AddContactMutationVariables, AddContactMutation } from '../types';
import addContact from '../mutations/add-contact.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  AddContactMutation,
  AddContactMutationVariables
>;

export function useAddContact(options?: Options) {
  return useMutation<AddContactMutation, AddContactMutationVariables>(
    addContact,
    options
  );
}
