import { Modal } from '@farmersdog/corgi';
import { Text, Grid, GridItem } from '@farmersdog/corgi-x';
import { LightboxId, useGlobalLightbox } from '../../../../hooks';

import styles from './BioModal.module.css';
import { MemberCardProps } from '../..';
import { Picture } from '../../../Picture';

export const BioModal = ({
  name,
  education,
  position,
  sources,
  alt,
  howTheyHelpDogsText,
  howTheyHelpDogsTitle,
  whyTheyLoveTFDTitle,
  whyTheyLoveTFDText,
  meetTheirPetsTitle,
  meetTheirPetsText,
}: MemberCardProps) => {
  const { rootProps } = useGlobalLightbox({
    id: LightboxId.Biography,
  });

  return (
    //@ts-expect-error types are wrong
    <Modal {...rootProps}>
      <Grid className={styles.profileAndTitleSection}>
        <GridItem>
          <Picture
            className={styles.image}
            width="120"
            height="120"
            sources={sources}
            alt={alt}
          />
        </GridItem>
        <GridItem className={styles.titleSection}>
          <Text
            className={styles.name}
            as="p"
            bold
            color="charcoal-3"
            fontStyle="normal"
            variant="heading-16"
            topSpacing="none"
          >
            {name}
          </Text>
          {education && (
            <Text
              className={styles.education}
              as="p"
              bold
              color="charcoal-3"
              fontStyle="normal"
              variant="heading-16"
            >
              {education}
            </Text>
          )}
          <Text
            className={styles.position}
            as="p"
            variant="heading-16"
            color="charcoal-3"
          >
            {position}
          </Text>
        </GridItem>
      </Grid>
      <Grid columnGap="md">
        <GridItem md={6}>
          {howTheyHelpDogsTitle && (
            <Text
              as="p"
              bold
              color="charcoal-3"
              fontStyle="normal"
              variant="heading-16"
              topSpacing="none"
              bottomSpacing="xs"
            >
              {howTheyHelpDogsTitle}
            </Text>
          )}
          {howTheyHelpDogsText && (
            <Text
              as="p"
              color="charcoal-3"
              fontStyle="normal"
              variant="article-16"
              topSpacing="none"
              bottomSpacing="md"
            >
              {howTheyHelpDogsText}
            </Text>
          )}
        </GridItem>
        <GridItem md={6}>
          {whyTheyLoveTFDTitle && (
            <Text
              as="p"
              bold
              color="charcoal-3"
              fontStyle="normal"
              variant="heading-16"
              topSpacing="none"
              bottomSpacing="xs"
            >
              {whyTheyLoveTFDTitle}
            </Text>
          )}
          {whyTheyLoveTFDText && (
            <Text
              as="p"
              color="charcoal-3"
              fontStyle="normal"
              variant="article-16"
              topSpacing="none"
              bottomSpacing="md"
            >
              {whyTheyLoveTFDText}
            </Text>
          )}
          {meetTheirPetsTitle && (
            <Text
              as="p"
              bold
              color="charcoal-3"
              fontStyle="normal"
              variant="heading-16"
              topSpacing="none"
              bottomSpacing="xs"
            >
              {meetTheirPetsTitle}
            </Text>
          )}
          {meetTheirPetsText && (
            <Text
              as="p"
              color="charcoal-3"
              fontStyle="normal"
              variant="article-16"
              topSpacing="none"
              bottomSpacing="md"
            >
              {meetTheirPetsText}
            </Text>
          )}
        </GridItem>
      </Grid>
    </Modal>
  );
};
