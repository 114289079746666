import * as React from 'react';

import { Brand, brandTypes } from './brandTypes';
import { CardField, CardFieldProps } from './CardField';

import classNames from 'classnames';
import { elementTypes } from '../Stripe/elementTypes';
import { interceptEvent } from '../../util';
import styles from './CardNumber.css';

export interface CardNumberProps extends Omit<CardFieldProps, 'elementType'> {
  /** Optional brand */
  brand?: Brand;
}

/**
 * Render a credit card number field. Must be inside an `ElementsProvider` and a
 * `StripeProvider`
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/creditcardinput | CreditCardInput} from corgi-x to create card fields. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const CardNumber: React.FC<CardNumberProps> = ({
  elementClassName,
  onChange,
  label,
  brand: brandProp,
  hideLabel = false,
  ...props
}) => {
  const [eventBrand, setEventBrand] = React.useState<Brand>();
  const brand = brandProp || eventBrand || brandTypes.unknown;

  const handleChange: CardNumberProps['onChange'] = event => {
    if (event.elementType === elementTypes.cardNumber) {
      setEventBrand(event.brand);
    }
  };

  return (
    <CardField
      elementClassName={classNames(styles.element, elementClassName)}
      onChange={interceptEvent(handleChange, onChange)}
      label={label}
      {...props}
      elementType={elementTypes.cardNumber}
      hideLabel={hideLabel}
    >
      <img
        alt={`Credit card by ${brand}`}
        width="32"
        className={classNames(styles.icon, styles[brand])}
      />
    </CardField>
  );
};
