/**
 * Default method for converting a url fragment to a readable title string.
 */
export function stringifyUrl(url: string): string {
  const words = url.split('-');

  return words
    .map((word, index) =>
      index === 0 ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word
    )
    .join(' ');
}
