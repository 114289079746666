export interface FormField<T extends string = string> {
  name: T;
  label: string;
}

export interface OptionType {
  label?: string;
  value: string;
}
export interface InputFormField<T extends string = string>
  extends FormField<T> {
  type: FieldType.Text | FieldType.Password | FieldType.TextArea;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  className?: string;
  disabled?: boolean;
}

export interface SelectFormField<T extends string = string>
  extends FormField<T> {
  type: FieldType.Select;
  className?: string;
  options: OptionType[];
}

export enum FieldType {
  Text = 'text',
  Select = 'select',
  Password = 'password',
  TextArea = 'textarea',
}

export type FormFieldArray<T extends string = string> = (
  | InputFormField<T>
  | SelectFormField<T>
)[];
