import classNames from 'classnames';
import styles from './getLinkDesign.css';

/** All of the possible contrast modes available for the link design */
export const LinkModes = ['light', 'dark'] as const;
/** All of the possible design variants available for the link design */
export const LinkVariants = ['primary', 'secondary', 'tertiary'] as const;

/** The properties of the link design */
export interface LinkDesignProps {
  /** Change the appearance to one of the design variations */
  variant?: (typeof LinkVariants)[number];
  /** Change the color based on the desired contrast mode */
  mode?: (typeof LinkModes)[number];
  /** Enable high contrast colors for font sizes below 16px */
  highContrast?: boolean;
  /** Toggle the disabled state */
  disabled?: boolean;
  /** @ignore Toggle active status (only for demo purpose) */
  active?: boolean;
  /** @ignore Toggle hover status (only for demo purpose) */
  hover?: boolean;
}

/**
 * Return a class name the applies the link design based on the link design
 * props interface.
 */
export function getLinkDesign({
  variant = 'primary',
  mode = 'light',
  highContrast = false,
  disabled,
  active,
  hover,
}: LinkDesignProps): string {
  return classNames(styles.link, styles[variant], styles[mode], {
    [styles.active]: active,
    [styles.hover]: hover,
    [styles.disabled]: disabled,
    [styles.highContrast]: highContrast,
  });
}
