import {
  Grid,
  GridItem,
  Text,
  TextVariants,
  PageWidth,
  Nowrap,
  Button,
} from '@farmersdog/corgi-x';

import styles from './ScienceAndSafetySection.module.css';
import { Benefit } from './components';
import { benefitData } from './benefitData';
import { SectionId } from '../Hero/nav-items';
import * as pagePaths from '../../../constants/pagePaths';
import { trackLandingPageClickedDownloadNutritionGuide } from '../../../analytics/events';

interface ResponsiveScienceAndSafetySection {
  titleVariant: TextVariants;
  description: TextVariants;
}

export function ResponsiveScienceAndSafetySection({
  titleVariant,
  description,
}: ResponsiveScienceAndSafetySection) {
  return (
    <PageWidth>
      <Grid columnGap="md">
        <GridItem lg={5}>
          <div className={styles.stickyTitle}>
            <Text
              bold
              as="h2"
              vSpacing="none"
              variant={titleVariant}
              color="blueberry-3"
            >
              Our Commitment
              <br /> to Science & Safety
            </Text>
            <Text
              as="p"
              color="charcoal-3"
              variant={description}
              className={styles.description}
            >
              Our science-backed, vet-nutritionist-formulated recipes are made
              to the pet-food industry’s highest nutritional and safety
              standards, so you can recommend them{' '}
              <Nowrap>with confidence.</Nowrap>
            </Text>
            <Button
              type="link"
              to={pagePaths.PATH_DOWNLOADS}
              onClick={() => {
                trackLandingPageClickedDownloadNutritionGuide();
              }}
              className={styles.button}
            >
              Download Nutrition Guide
            </Button>
          </div>
        </GridItem>
        <GridItem lg={7} className={styles.benefitsContainer}>
          {benefitData.map(({ title, image, content, ariaLabel }) => (
            <Benefit
              key={`benefit-${title}`}
              image={image}
              title={title}
              content={content}
              ariaLabel={ariaLabel}
            />
          ))}
        </GridItem>
      </Grid>
    </PageWidth>
  );
}

export function ScienceAndSafetySection() {
  return (
    <section className={styles.section} id={SectionId.ScienceAndSafety}>
      <div className={styles.mobile}>
        <ResponsiveScienceAndSafetySection
          titleVariant={'heading-28'}
          description={'article-16'}
        />
      </div>
      <div className={styles.desktop}>
        <ResponsiveScienceAndSafetySection
          titleVariant={'heading-52'}
          description={'article-20'}
        />
      </div>
    </section>
  );
}
