import { useState } from 'react';
import { Modal } from '@farmersdog/corgi';
import { Button, Text } from '@farmersdog/corgi-x';
import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { trackWebinarsPageClickedCopyLinkButton } from '../../../analytics/events';

import styles from './ShareModal.module.css';
import { getEventsAndRecordingsData } from '../../../data/eventsAndRecordingsData';

interface ShareModalLinkProps {
  selectedEvent: SelectedEvent;
}
export type SelectedEvent =
  | ReturnType<typeof getEventsAndRecordingsData>[number]
  | undefined;

export const ShareModal = ({ selectedEvent }: ShareModalLinkProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { rootProps, labelProps } = useGlobalLightbox({
    id: LightboxId.ShareWebinar,
  });

  const shareLink = selectedEvent?.shareLink ?? '';

  const handleClick = async () => {
    await navigator?.clipboard?.writeText(shareLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
    trackWebinarsPageClickedCopyLinkButton({
      title: selectedEvent?.videoTitle ?? '',
    });
  };

  return (
    //@ts-expect-error types are wrong
    <Modal {...rootProps}>
      <Text
        bold
        as="h3"
        {...labelProps}
        topSpacing="none"
        bottomSpacing="md"
        color="blueberry-3"
        variant="heading-40"
      >
        Share
      </Text>
      <Text variant="heading-16" as="p">
        Use the link below to share the {selectedEvent?.videoTitle ?? ''}{' '}
        webinar.
      </Text>
      <Text
        variant="heading-16"
        as="p"
        color="kale-2"
        bottomSpacing={'lg'}
        className={styles.webinarModalShareLink}
      >
        {shareLink}
      </Text>
      <Button onClick={handleClick} aria-controls="copy-link-button">
        <span aria-live="polite" role="status" id="copy-link-button">
          {isCopied ? 'Copied!' : 'Copy Link'}
        </span>
      </Button>
    </Modal>
  );
};
