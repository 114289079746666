import React, {
  useState,
  useMemo,
  useCallback,
  FC,
  useEffect,
  PropsWithChildren,
} from 'react';
import { nanoid } from 'nanoid/non-secure';

import { ANIMATION_DURATION } from './constants';
import { ToastContext } from './ToastContext';
import { ToastProps } from './Toast';
import { ToastDialogProps } from './ToastDialog';

const TOAST_DURATION = 1000 * 5;
const TIMEOUT = TOAST_DURATION + ANIMATION_DURATION;

function useToastDispatch<Props>(
  toastTimeout?: number
): [(props: Props) => void, Props[] | undefined, () => void] {
  const [active, setActive] = useState<Props>();
  const [queued, setQueued] = useState<Props>();

  const close = (): void => setActive(undefined);

  const dispatchToast = useCallback(
    (toastProps: Props): void => {
      const id = nanoid();

      const newToast = {
        ...toastProps,
        id,
      };

      if (active || queued) {
        setQueued(newToast);
      } else {
        setActive(newToast);
      }
    },
    [active, queued]
  );

  useEffect(() => {
    if (!active) return;
    const removeToast = (): void => {
      setActive(undefined);
    };

    const timeout = toastTimeout
      ? setTimeout(removeToast, toastTimeout)
      : undefined;

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
      removeToast();
    };
  }, [active, toastTimeout]);

  useEffect(() => {
    if (!queued) return;

    setActive(undefined);
    const timeout = setTimeout(() => {
      setQueued(currentQueued => {
        setActive(currentQueued);
        return undefined;
      });
    }, ANIMATION_DURATION);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [queued]);

  return [dispatchToast, active ? [active] : [], close];
}

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dispatchToast, activeToasts] = useToastDispatch<ToastProps>(TIMEOUT);
  const [dispatchToastDialog, activeToastDialogs, closeToastDialog] =
    useToastDispatch<ToastDialogProps>();

  const toastContextValue = useMemo(
    () => ({
      dispatchToast,
      activeToasts,
      dispatchToastDialog,
      activeToastDialogs,
      closeToastDialog,
    }),
    [
      dispatchToast,
      activeToasts,
      dispatchToastDialog,
      activeToastDialogs,
      closeToastDialog,
    ]
  );

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
    </ToastContext.Provider>
  );
};
