import { track } from '../track';
import { events_and_recordings_page_clicked_university_visit_request } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Request University Visit" button on the events and recordings page
 */

export function trackEventsAndRecordingsPageClickedUniversityVisitRequest() {
  track({
    eventName: events_and_recordings_page_clicked_university_visit_request,
  });
}
