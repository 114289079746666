import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './SmallLabel.css';
import { Text } from '../Text';

interface AppBarLabelProps {
  title: string;
  className?: string | undefined;
}

export const SmallLabel: FC<AppBarLabelProps> = ({ title, className }) => (
  <div>
    <Text
      color="kale-3"
      variant="heading-21"
      className={classNames(styles.container, styles.padding)}
    >
      <label className={classNames(className, styles.small, styles.active)}>
        {title}
      </label>
    </Text>
  </div>
);
