import {
  fetchAuthSession,
  confirmResetPassword,
  resetPassword as resetPasswordAmplify,
  signIn,
  signOut,
  signUp,
  fetchUserAttributes,
  deleteUser as deleteUserAmplify,
} from 'aws-amplify/auth';

import {
  CognitoAttributes,
  CognitoAttributesSendCode,
  CognitoAttributesResetPassword,
  CognitoAuthenticatedUserResult,
} from '../../context/constants';

import cognitoTokenStorage from '../../utils/cognito-token-storage';

export const getCurrentEmailSession = async (): Promise<string | undefined> => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.payload.email?.toString();
  } catch {
    return undefined;
  }
};

export const getCurrentAuthenticatedUser = async (): Promise<
  string | undefined
> => {
  const { tokens } = await fetchAuthSession({
    forceRefresh: true,
  });
  const token = tokens?.idToken?.toString();
  if (token) {
    cognitoTokenStorage.set(token);
  }
  return token;
};

export const login = async ({
  email,
  password,
}: CognitoAttributes): Promise<CognitoAuthenticatedUserResult | undefined> => {
  const result = await signIn({ username: email, password });

  if (result.isSignedIn) {
    const { tokens } = await fetchAuthSession({
      forceRefresh: true,
    });
    const token = tokens?.idToken?.toString();
    if (token) {
      cognitoTokenStorage.set(token);
      const attributes = await fetchUserAttributes();
      return {
        token,
        email: attributes?.email || '',
        id: attributes.sub || '',
      };
    }
  }
  return undefined;
};

export const signup = async ({ email, password }: CognitoAttributes) => {
  await signUp({
    username: email,
    password,
    options: {
      userAttributes: {
        email,
      },
    },
  });
};

export const signout = async () => {
  await signOut();
  cognitoTokenStorage.remove();
};

export const forgotPassword = async ({ email }: CognitoAttributesSendCode) => {
  await resetPasswordAmplify({ username: email });
};

export const resetPassword = async ({
  code,
  email,
  password,
}: CognitoAttributesResetPassword) => {
  await confirmResetPassword({
    username: email,
    confirmationCode: code,
    newPassword: password,
  });
};

export const deleteUser = async () => {
  await deleteUserAmplify();
};
