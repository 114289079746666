import React, { PropsWithChildren, useId } from 'react';

import { useHtmlElement, useSlideShow } from '../../hooks';

import { ArrowButton } from '../ArrowButton';
import { IndexButton } from '../IndexButton';
import { ScrollSnap } from '../ScrollSnap';
import Slide from './Slide';
import classNames from 'classnames';
import isVisible from './isVisible';
import styles from './SlideShow.css';

export interface SlideShowProps {
  /** The label used for this slide show. Required for accessibility. */
  label: string;
  /** Boolean value to toggle the display of index dots indicators */
  withDots?: boolean;
  /** Boolean value to toggle next/previous buttons */
  withButtons?: boolean;
  /** Optional class name to pass through to the "next" button */
  nextButtonClass?: string;
  /** Optional class name to pass through to the "previous" button */
  prevButtonClass?: string;
  /** @deprecated renamed to `onSlideChange` */
  onCurrentSlide?: (nextIndex: number) => void;
  /** Callback when the current slide changes from a touch gestures */
  onSlideChange?: (nextIndex: number) => void;
  /** @deprecated renamed to `activeIndex` */
  currentSlide?: number;
  /** The currently active index when used in a controlled state pattern */
  activeIndex?: number;
  /** The scroll behavior when using programmatic scroll */
  scrollBehavior?: ScrollBehavior;
  controlsPosition?: 'inside' | 'outside';
}

/**
 * Render a slide show component. This component accepts any number of children
 * that will each be rendered as a slide for the show.
 *
 * !Requires `polyfillSmoothScroll()` on the client for full browser support.
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/slideshow | SlideShow} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const SlideShow: React.FC<PropsWithChildren<SlideShowProps>> = ({
  children,
  label,
  withDots = false,
  withButtons = false,
  nextButtonClass,
  prevButtonClass,
  onCurrentSlide,
  onSlideChange,
  scrollBehavior = 'auto',
  currentSlide,
  controlsPosition = 'outside',
  activeIndex: activeIndexProp,
}) => {
  const id = useId();
  const numberOfSlides = React.Children.count(children);
  const [scrollableEl, scrollableRef] = useHtmlElement();

  const { activeIndex, goToSlide } = useSlideShow({
    scrollableEl,
    scrollBehavior,
    getIsActiveSlide: isVisible,
    onSlideChange: onSlideChange ?? onCurrentSlide,
    activeIndex: activeIndexProp ?? currentSlide,
  });

  const handlePreviousClick = (): void => {
    if (activeIndex === 0) {
      return;
    }

    goToSlide(activeIndex - 1);
  };

  const handleNextClick = (): void => {
    if (activeIndex === numberOfSlides - 1) {
      return;
    }

    goToSlide(activeIndex + 1);
  };

  return (
    <section
      aria-label={label}
      style={{
        position: controlsPosition === 'inside' ? 'relative' : 'static',
      }}
    >
      <div className={styles.container}>
        <ScrollSnap
          as="ul"
          id={id}
          ref={scrollableRef}
          className={styles.horizontalSnap}
        >
          {React.Children.map(children, (child, index) => (
            <Slide aria-label={`${index + 1} of ${numberOfSlides}`}>
              {child}
            </Slide>
          ))}
        </ScrollSnap>
        {withButtons && (
          <>
            <ArrowButton
              direction="left"
              aria-label="previous"
              aria-controls={id}
              className={classNames(
                styles.previous,
                prevButtonClass,
                styles[controlsPosition]
              )}
              size={controlsPosition === 'inside' ? 'lg' : 'md'}
              variant={controlsPosition === 'inside' ? 'white-bg' : 'default'}
              onClick={handlePreviousClick}
            />
            <ArrowButton
              direction="right"
              aria-label="next"
              aria-controls={id}
              className={classNames(
                styles.next,
                nextButtonClass,
                styles[controlsPosition]
              )}
              size={controlsPosition === 'inside' ? 'lg' : 'md'}
              onClick={handleNextClick}
              variant={controlsPosition === 'inside' ? 'white-bg' : 'default'}
            />
          </>
        )}
      </div>
      {withDots && (
        <div className={classNames(styles.dots, styles[controlsPosition])}>
          {React.Children.map(children, (_, childIndex) => {
            return (
              <IndexButton
                aria-controls={id}
                aria-label={`Go to slide ${childIndex + 1}`}
                active={childIndex === activeIndex}
                onClick={(): void => goToSlide(childIndex)}
                mode={controlsPosition === 'inside' ? 'green' : 'dark'}
                className={classNames(
                  childIndex === activeIndex ? styles.active : null
                )}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};
