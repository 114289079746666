import { useState } from 'react';
import { DiscountRequestState } from './discountRequestState';

const stepsHierarchy: { [key in DiscountRequestState]: number } = {
  [DiscountRequestState.AreYouSubscribed]: 1,
  [DiscountRequestState.HowToGetDiscount]: 2,
  [DiscountRequestState.EnterEmail]: 2,
  [DiscountRequestState.PracticeSearch]: 3,
  [DiscountRequestState.ReviewInfo]: 3,
  [DiscountRequestState.ReviewInfoWithUpdatedPractice]: 4,
  [DiscountRequestState.AddPractice]: 5,
  [DiscountRequestState.RequestSuccess]: 6,
  [DiscountRequestState.Close]: 7,
};

function updateSteps(
  prevSteps: DiscountRequestState[],
  nextStep: DiscountRequestState
) {
  const lastStep = prevSteps[prevSteps.length - 1];
  if (
    !prevSteps.includes(nextStep) &&
    stepsHierarchy[lastStep] <= stepsHierarchy[nextStep] // necessary?
  ) {
    return [...prevSteps, nextStep];
  }
  return prevSteps;
}

/**
 * This hook keeps track of a user's Forward Steps.
 * Forward steps include steps that represent a user's "final committed forward  path". For example, a user navigating through step 1 - step 2 - step 3a - step 2 - step 3b should have forward steps of step 1 - step 2 - step 3b.
 * We keep track of the forward steps outside of the state machine such that we can trace the last forward step to go back to.
 */
export function useForwardSteps() {
  const [forwardSteps, setForwardSteps] = useState<DiscountRequestState[]>([
    DiscountRequestState.AreYouSubscribed,
  ]);

  const setNextForwardStep = (nextStep: DiscountRequestState) => {
    setForwardSteps(prevSteps => updateSteps(prevSteps, nextStep));
  };

  const setPreviousForwardStep = () => {
    setForwardSteps(prevSteps => {
      if (prevSteps.length <= 1) {
        return prevSteps;
      }
      return prevSteps.slice(0, prevSteps.length - 1);
    });
  };

  return {
    setNextForwardStep,
    setPreviousForwardStep,
    forwardSteps,
  };
}
