import * as yup from 'yup';
import { ForgotPasswordFormFields } from './forgotPasswordFormFieldsData';
import { EMAIL_REGEXP } from '../../../constants/validation';
import { noTrailingWhitespace } from '../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../../utils';

export const ForgotPasswordFormValidationSchema = yup.object({
  [ForgotPasswordFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),
});
