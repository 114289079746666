import { FieldType } from '../../components/FormFields';
import type { FormFieldArray } from '../../components/FormFields';
import {
  StyledSelect,
  StyledOption,
  Input,
  FormControl,
  TextArea,
} from '@farmersdog/corgi-x';

import { LoginFormValues } from '../LoginForm/constants';
import { ForgotPasswordFormValues } from '../ForgotPasswordForm/constants';

import styles from './FormFields.module.css';

import { FormikProps } from 'formik';
import classNames from 'classnames';
import {
  PersonalInformationConferenceFormValues,
  PersonalInformationFormValues,
} from '../SignupForm/PersonalInformationForm';
import { VeterinaryPracticeOrOrganizationInformationFormValues } from '../SignupForm/SignUpFormWithNoSelectedPractice';
import { fieldRequiredErrorMessage } from '../../utils';

// TODO: find a way to make these tyeps work https://github.com/jaredpalmer/formik/issues/2023 */
type FormikValues =
  | LoginFormValues
  | ForgotPasswordFormValues
  | PersonalInformationFormValues
  | PersonalInformationConferenceFormValues
  | VeterinaryPracticeOrOrganizationInformationFormValues;

export interface FormFieldsProps<T extends FormFieldArray> {
  formik: FormikProps<FormikValues>;
  fieldData: T;
}

export function FormFields<T extends FormFieldArray>({
  formik,
  fieldData,
}: FormFieldsProps<T>) {
  const fields = fieldData.map(field => {
    const fieldName = field.name as keyof FormikValues;
    const fieldHasErrorAndIsTouched = Boolean(
      formik.touched[fieldName] && formik.errors[fieldName]
    );

    // TODO typescript bug? fieldName is of type "never"
    const fieldNameString = fieldName as string;

    const getMessage = () => {
      if (!fieldHasErrorAndIsTouched) return undefined;
      const errorMessage = formik.errors[fieldName] as string | undefined;
      if (formik.errors[fieldName] === fieldRequiredErrorMessage) {
        return `${field.label ?? fieldNameString} ${formik.errors[fieldName] as string | undefined}`;
      }
      return errorMessage;
    };

    return (
      <div
        className={classNames(styles.input, styles[field.className as string])}
        key={`input-${fieldNameString}`}
      >
        <FormControl
          id={`${fieldNameString}-message`}
          aria-live="polite"
          message={getMessage()}
          invalid={fieldHasErrorAndIsTouched}
        >
          {field.type === FieldType.Select ? (
            <StyledSelect
              id={fieldName}
              name={fieldName}
              label={field.label}
              value={formik.values[fieldName] as string}
              onChange={option => {
                void formik.setFieldValue(fieldName, option?.value);
              }}
              onBlur={formik.handleBlur}
            >
              {field.options.map((option, j) => {
                return (
                  <StyledOption
                    key={`${fieldNameString}-option-${j}`}
                    label={option.label ?? option.value}
                    value={option.value}
                  >
                    {option.label ?? option.value}
                  </StyledOption>
                );
              })}
            </StyledSelect>
          ) : field.type === FieldType.TextArea ? (
            <TextArea
              id={fieldName}
              name={fieldName}
              label={field.label}
              value={formik.values[fieldName] as string}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputMode={field.inputMode}
              disabled={field.disabled}
            />
          ) : (
            <Input
              id={fieldName}
              name={fieldName}
              label={field.label}
              type={field.type}
              value={formik.values[fieldName] as string}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              withRevealButton={field.type === FieldType.Password}
              inputMode={field.inputMode}
              disabled={field.disabled}
            />
          )}
        </FormControl>
      </div>
    );
  });

  return <>{fields}</>;
}
