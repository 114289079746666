import { BorderLabel, useElementState } from '../BorderLabel';
import React, {
  ChangeEventHandler,
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { WithRefProps, getHasValue, interceptEvent, withRef } from '../../util';

import { Caret, Chevron } from '../../icons';
import arrowStyles from './Arrow.css';
import classNames from 'classnames';
import { hasValueSelected } from './hasValueSelected';
import { heading, body } from '../../typography';
import selectStyles from './Select.css';

interface SelectNativeOverrides {
  /** WCAG violation */
  autoFocus: boolean;
  /** Multiple select is a separate component */
  multiple: boolean;
  /** Form inputs should be controlled components */
  defaultValue: undefined;
}

/** These are native props that we do not want to use and should protect ourselves */
const nativeOverrides: SelectNativeOverrides = {
  autoFocus: false,
  multiple: false,
  defaultValue: undefined,
};

const headingTypography = heading.create({ size: 16 });
const bodyTypography = body.create({ size: 16, weight: 'bold' });

type IntrinsicSelectProps = JSX.IntrinsicElements['select'];
type RefProps = WithRefProps<HTMLSelectElement>;
type IntrinsicOptionProps = JSX.IntrinsicElements['option'];

export interface SelectProps extends IntrinsicSelectProps, RefProps {
  /** Label and placeholder of select */
  label: string;
  /** Display invalid state */
  invalid?: boolean;
  children?: ReactElement<IntrinsicOptionProps>[];
  /** Display highlighted state */
  highlighted?: boolean;
  /** Hide BorderLabel but keep it in the accessibility tree */
  hideLabel?: boolean;
  /** Dropdown icon style */
  icon?: 'caret' | 'chevron';
  /** Apply bold font weight to selection */
  bold?: boolean;
}

/**
 * Custom select component using native select element.
 * @deprecated Import {@link https://corgi-x.tfd.engineering/components/select | Select} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
const SelectComponent: FC<SelectProps> = ({
  label,
  children,
  className,
  onChange,
  onFocus,
  onBlur,
  forwardedRef,
  invalid,
  highlighted,
  hideLabel = false,
  icon = 'caret',
  bold = false,
  ...props
}) => {
  // autoFocus and disabled should be passed on to native element.
  const [state, handlers] = useElementState({
    autoFocus: props.autoFocus,
    disabled: props.disabled,
    invalid,
    highlighted,
  });

  const [hasValue, setHasValue] = useState<boolean>(
    getHasValue(props.value) || hasValueSelected(children)
  );

  useEffect(() => {
    setHasValue(getHasValue(props.value) || hasValueSelected(children));
  }, [props.value, children]);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
    const eventTarget = event.target;
    setHasValue(getHasValue(eventTarget.value));
  };

  return (
    <BorderLabel
      in={hasValue}
      state={state}
      text={label || props.name || ''}
      className={className}
      hideLabel={hideLabel}
    >
      <select
        ref={forwardedRef}
        className={classNames(
          selectStyles.select,
          bold ? bodyTypography.className : headingTypography.className,
          bold ? selectStyles.bold : selectStyles.regular
        )}
        onChange={interceptEvent(handleChange, onChange)}
        onBlur={interceptEvent(handlers.onBlur, onBlur)}
        onFocus={interceptEvent(handlers.onFocus, onFocus)}
        aria-invalid={invalid}
        {...props}
        // Order is important, we want our native overrides to always work.
        {...nativeOverrides}
      >
        {children}
      </select>
      <span
        className={classNames(
          arrowStyles.container,
          arrowStyles[icon],
          arrowStyles[state]
        )}
      >
        {icon === 'chevron' ? (
          <Chevron fill="kale-3" className={arrowStyles.buttonIcon} size={36} />
        ) : (
          <Caret fill="kale-3" className={arrowStyles.buttonIcon} size={36} />
        )}
      </span>
    </BorderLabel>
  );
};

export const Select = withRef<HTMLSelectElement, SelectProps>(SelectComponent);
