import { Text } from '@farmersdog/corgi-x';
import { ReviewStars } from './ReviewStars';
import { Picture } from '../../../components/Picture';
import { Card } from '../../../components/Card';

import styles from './Testimonials.module.css';

import { testimonialData, TestimonialData } from './testimonialData';

interface ResponsiveTestimonialProps {
  testimonial: TestimonialData;
  isMobile: boolean;
}

function ResponsiveTestimonial({
  testimonial,
  isMobile,
}: ResponsiveTestimonialProps) {
  return (
    <div className={isMobile ? styles.mobile : styles.desktop}>
      <Picture
        sources={testimonial.images}
        alt={`${testimonial.vetName} testimonial`}
        className={styles.image}
      />
      <Text
        as="h3"
        variant={isMobile ? 'heading-16' : 'heading-22'}
        bold
        className={styles.vetName}
      >
        {testimonial.vetName}
      </Text>
      {testimonial.hospitalInfo && (
        <Text
          as="h4"
          fontStyle="italic"
          variant={isMobile ? 'heading-12' : 'heading-16'}
          className={styles.hospitalInfo}
        >
          {testimonial.hospitalInfo}
        </Text>
      )}
      <Text
        as="p"
        variant="article-16"
        className={styles.testimonialText}
        topSpacing="xs"
      >
        {testimonial.testimonialText}
      </Text>
    </div>
  );
}

export function TestimonialsLegacy() {
  return (
    <Card className={styles.container}>
      <ReviewStars starCount={5} />
      <Text
        as="h3"
        variant="heading-28"
        bold
        color="blueberry-3"
        className={styles.title}
      >
        Veterinarian Testimonials
      </Text>
      <div className={styles.testimonialContainer}>
        {testimonialData.map((testimonial, i) => {
          return (
            <div key={`testimoniall-${i}`} className={styles.testimonial}>
              <ResponsiveTestimonial testimonial={testimonial} isMobile />
              <ResponsiveTestimonial
                testimonial={testimonial}
                isMobile={false}
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
}
