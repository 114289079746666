import classNames from 'classnames';
import * as React from 'react';

import { WithRefProps, withRef } from '../../util/withRef';

import styles from './ScrollSnap.css';

type IntrinsicProps = JSX.IntrinsicElements['div'];
type RefProps = WithRefProps<HTMLElement>;

export interface ScrollSnapProps extends IntrinsicProps, RefProps {
  children: React.ReactNode;
  /** HTML tag to render text in e.g. "div" */
  as?: React.ElementType;
  /** Optional class name */
  className?: string;
}

/**
 * Wraps child elements in a scroll-snap parent, ensuring one child
 * is always centered in the parent.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/scrollsnap | ScrollSnap} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const ScrollSnapComponent: React.FC<ScrollSnapProps> = ({
  as = 'div',
  children,
  className,
  forwardedRef,
  ...props
}) => {
  const Component = as;
  return (
    <Component
      ref={forwardedRef}
      className={classNames([styles.container, className])}
      {...props}
    >
      {React.Children.map(children, child => {
        if (!React.isValidElement<HTMLElement>(child)) {
          return child;
        }
        const className = classNames(child.props.className, styles.item);
        return React.cloneElement(child, { className });
      })}
    </Component>
  );
};

export const ScrollSnap = withRef(ScrollSnapComponent);
