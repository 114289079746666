import { useEffect, FC } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { useClient } from '../../services/apollo';

import * as pagePaths from '../../constants/pagePaths';

export const Logout: FC = () => {
  const { logout } = useAuthContext();
  const client = useClient();
  const history = useHistory();

  useEffect(() => {
    const fetchLogout = async () => {
      if (!logout) {
        throw new Error('No logout function provided');
      }
      await logout();
      await client.resetStore();
      history.push(pagePaths.PATH_HOME);
    };

    void fetchLogout();
  });

  return null;
};
