export interface AdditionalListData {
  title: string;
  link: string;
}

export const therapeutic: AdditionalListData[] = [
  {
    title: 'Understanding urinary relative supersaturation',
    link: 'https://www.cliniciansbrief.com/article/understanding-urinary-relative-supersaturation',
  },
  {
    title:
      'Randomized controlled trial demonstrates nutritional management is superior to metronidazole for treatment of acute colitis in dogs',
    link: 'https://avmajournals.avma.org/view/journals/javma/260/S3/javma.22.08.0349.xml',
  },
];

export const weightManagement: AdditionalListData[] = [
  {
    title:
      'Effects of diet restriction on life span and age-related changes in dogs',
    link: 'https://avmajournals.avma.org/view/journals/javma/220/9/javma.2002.220.1315.xml',
  },
  {
    title:
      'Association between life span and body condition in neutered client-owned dogs',
    link: 'https://onlinelibrary.wiley.com/doi/10.1111/jvim.15367',
  },
];

export const petFoodProcessing: AdditionalListData[] = [
  {
    title:
      'The Maillard reaction and pet food processing: effects on nutritive value and pet health',
    link: 'https://www.cambridge.org/core/journals/nutrition-research-reviews/article/maillard-reaction-and-pet-food-processing-effects-on-nutritive-value-and-pet-health/E085D3648D6A209003AB4D0DB72DB8B2',
  },
  {
    title: 'Effects of extrusion processing on nutrients in dry pet food',
    link: 'https://onlinelibrary.wiley.com/doi/10.1002/jsfa.3247',
  },
  {
    title:
      'Effects of drying temperature and time of a canine diet extruded with a 4 or 8 mm die on physical and nutritional quality indicators',
    link: 'https://www.sciencedirect.com/science/article/abs/pii/S0377840111000915',
  },
];
