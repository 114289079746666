import { Card } from '@farmersdog/corgi-x';

import styles from './Step.module.css';

interface StepProps {
  enumerator: number;
  content: JSX.Element | string;
}

export function Step({ enumerator, content }: StepProps) {
  return (
    <Card
      className={styles.step}
      as="section"
      badgeNumber={enumerator}
      variant="bordered"
      subHeading={content}
    />
  );
}
