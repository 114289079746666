import { useEffect } from 'react';
import { useScrollRestore } from '.';
import { useHistory } from 'react-router';

export const useRestoreScrollOnModeChange = () => {
  const { restoreScroll } = useScrollRestore();
  const history = useHistory();
  useEffect(() => {
    restoreScroll();
  }, [history.location.search, restoreScroll]);
};
