import smoothscroll from 'smoothscroll-polyfill';

/**
 * Enable native smooth scrolling on browser not supporting it (e.g. IE, Safari)
 * with the [smoothscroll-polyfill](https://github.com/iamdustan/smoothscroll).
 *
 * Polyfills must be enabled on the browser, for example in the app client's
 * entry file or within an effect.
 */
export function polyfillSmoothScroll(): void {
  smoothscroll.polyfill();
}
