import * as yup from 'yup';
import { EnterEmailFormFields } from './enterEmailFormFieldsData';
import { EMAIL_REGEXP } from '../../../../../constants/validation';
import { noTrailingWhitespace } from '../../../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../../../../utils';

export const enterEmailFormValidationSchema = yup.object({
  [EnterEmailFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Please enter a valid email address'),
});
