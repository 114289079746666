import { Button, Divider, Link, Text } from '@farmersdog/corgi-x';
import styles from './UpdatePersonalDetails.module.css';
import { personalDetailsFormFieldsData } from './constants';
import { useFormik } from 'formik';
import { FormFields } from '../../../components/FormFields';
import { personalInformationValidationSchema } from './utils';
import { EMAIL_VET } from '@farmersdog/constants/emails';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

export interface UpdatePersonalDetailsResponsiveProps
  extends UpdatePersonalDetailsProps {
  isMobile?: boolean;
}

const UpdatePersonalDetailsResponsive = ({
  isMobile,
  onSubmit,
  disableSubmit,
  email,
  ...initialData
}: UpdatePersonalDetailsResponsiveProps) => {
  const form = useFormik({
    initialValues: initialData,
    onSubmit: ({ firstName, lastName }) => {
      onSubmit(firstName, lastName);
    },
    validationSchema: personalInformationValidationSchema,
  });
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <div className={styles.container}>
      <Text
        color="blueberry-3"
        variant={isMobile ? 'heading-28' : 'heading-40'}
        bold
        as="h1"
      >
        Update Profile Details
      </Text>
      <form onSubmit={form.handleSubmit}>
        <Divider
          width={1}
          color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
        />
        <div className={styles.formContent}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={personalDetailsFormFieldsData} formik={form} />
          <section>
            <Text as="h5" variant="heading-16" bold>
              Email Address
            </Text>
            <Text variant={'heading-16'}>{email}</Text>
            <Text as="p" variant={'article-16'}>
              To update your email address, please contact The Farmer’s Dog Vet
              Team at&nbsp;
              <Link to={`mailto:${EMAIL_VET}`}>
                <Text
                  as="span"
                  color="kale-2"
                  variant="article-16"
                  className={styles.email}
                >
                  {EMAIL_VET}
                </Text>
              </Link>
              .
            </Text>
          </section>
        </div>
        <Divider
          width={1}
          color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
        />
        <Button
          type="submit"
          variant={
            showPortalUXOverhaul ? 'primary-mini-kale-experimental' : 'primary'
          }
          aria-disabled={!form.dirty || !form.isValid || disableSubmit}
          className={styles.button}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export interface UpdatePersonalDetailsProps {
  firstName: string;
  lastName: string;
  email: string;
  onSubmit: (firstName: string, lastName: string) => void;
  disableSubmit: boolean;
}

export const UpdatePersonalDetails = (props: UpdatePersonalDetailsProps) => {
  return (
    <>
      <div className={styles.mobile}>
        <UpdatePersonalDetailsResponsive isMobile {...props} />
      </div>
      <div className={styles.desktop}>
        <UpdatePersonalDetailsResponsive {...props} />
      </div>
    </>
  );
};
