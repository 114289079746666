import { track } from '../track';
import { clicked_customer_account_log_in } from './eventNames';

/**
 * Send a tracking event for when a user clicked the customer account log in link
 */

export function trackClickedCustomerAccountLogIn() {
  track({
    eventName: clicked_customer_account_log_in,
  });
}
