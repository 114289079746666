import * as React from 'react';

import { Link, LinkProps, LinkLocationProps } from '../Link';
import { Text, TextProps } from '../Text';

import { LOGO_SIZE } from '../AppBar/constants';
import { PageWidth } from '../PageWidth';
import classNames from 'classnames';
import styles from './Hero.css';

type HeroCtaProps = LinkProps & Partial<LinkLocationProps>;
export interface HeroProps {
  /** The title content */
  title: string;
  /** Props to pass to the title text component */
  titleProps: TextProps;
  /** The subtitle content */
  subtitle: string;
  /** Props to pass to the subtitle text component */
  subtitleProps: TextProps;
  /** Whether to display the cta button */
  withCta: boolean;
  /** The text for the cta button */
  ctaLabel: string;
  /** Props to pass to the cta button */
  ctaProps: HeroCtaProps;
  /** The background for the hero */
  background: React.ReactNode;
  /** CSS className */
  className?: string;
}

/** @deprecated Import {@link https://corgi-x.tfd.engineering/components/hero | Hero} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}. */
export const Hero: React.FC<HeroProps> = ({
  title,
  titleProps,
  subtitle,
  subtitleProps,
  ctaLabel,
  ctaProps,
  withCta = true,
  background,
  className,
  ...props
}) => {
  return (
    <section className={classNames(styles.section, className)} {...props}>
      <div className={styles.background}>{background}</div>
      <PageWidth className={styles.content} style={{ marginTop: LOGO_SIZE }}>
        <Text
          as="h1"
          color="white"
          bold
          className={styles.title}
          {...titleProps}
        >
          {title}
        </Text>
        <Text
          as="h2"
          color="white"
          bold
          className={styles.subtitle}
          {...subtitleProps}
        >
          {subtitle}
        </Text>
        {withCta && (
          <Link asButton variant="primary" className={styles.cta} {...ctaProps}>
            {ctaLabel}
          </Link>
        )}
      </PageWidth>
    </section>
  );
};
