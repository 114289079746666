import React, { useId } from 'react';

import { AriaLiveAttributes, LiveMessage } from '../LiveMessage';
import { ComplexValue, SingleValue } from './types';

import accessibilityStyles from '../../styles/accessibility.css';
import classNames from 'classnames';
import getInitialValue from './getInitialValue';
import { heading } from '../../typography';
import includesCastValue from './includesCastValue';
import { interceptEvent } from '../../util';
import styles from './SelectorGroup.css';

type IntrinsicFieldsetProps = JSX.IntrinsicElements['fieldset'];

export interface SelectorGroupProps
  extends Omit<IntrinsicFieldsetProps, 'onChange'> {
  /** Value or values that should appear as selected. */
  value?: ComplexValue;
  /** The name used to associate this group of checkbox or radio elements. */
  name: string;
  /** Type of the children inputs */
  type: 'radio' | 'checkbox';
  /** The label used for group of elements. Required for accessibility. */
  legend: React.ReactNode;
  /** By default the legend is hidden. Toggle to display legend. */
  showLegend?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** Class name to apply to inputs container */
  inputsClassName?: string;
  /** The message to provide to the user */
  message?: React.ReactNode;
  /** Whether to toggle the invalid state */
  invalid?: boolean;
  /** An optional id. WCAG compliant ids are automatically provided. */
  /**
   * Indicates that an element will be updated, and describes the types of
   * updates the user agents, assistive technologies, and user can expect from
   * the live region.
   */
  'aria-live': (typeof AriaLiveAttributes)[number];
  id?: string;
  children: React.ReactNode;
}

type ContentRevealChildProps = React.HTMLProps<HTMLInputElement>;

const typography = heading.create({ size: 16, color: 'charcoal-1' });

/**
 * Component to manage the state of multiple input children.  This component will
 * manage each child's props for: name, onChange, and checked.
 *
 * This allows the shared props to be set in one spot and automatically passed down.
 *
 * @deprecated Use {@link https://corgi-x.tfd.engineering/components/fieldset | Fieldset} from corgi-x. See the {@link https://corgi-x.tfd.engineering/components/legacy | Legacy components}.
 */
export const SelectorGroup: React.FC<SelectorGroupProps> = ({
  children,
  name,
  type,
  legend,
  showLegend,
  className,
  inputsClassName,
  value: propValue,
  onChange,
  invalid,
  message,
  ...props
}) => {
  const reactId = useId();
  const id = props.id || reactId;

  const messageHidden = !message;
  const multi =
    type === 'checkbox' ||
    Boolean(
      React.Children.toArray(children).find(
        child =>
          React.isValidElement<ContentRevealChildProps>(child) &&
          child.props.type === 'checkbox'
      )
    );

  const [internalValue, setInternalValue] = React.useState(
    getInitialValue(propValue, multi)
  );

  const value: ComplexValue =
    typeof propValue !== 'undefined' ? propValue : internalValue;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = e.target.value;

    if (!(multi && Array.isArray(value))) {
      setInternalValue(eventValue);
      return;
    }

    const nextValue = includesCastValue(value, eventValue)
      ? value.filter(lastValue => String(lastValue) !== eventValue)
      : [...value, eventValue];

    setInternalValue(nextValue);
  };

  const getChecked = (childValue: SingleValue): boolean => {
    if (multi && Array.isArray(value)) {
      return includesCastValue(value, childValue);
    }

    return String(value) === String(childValue);
  };

  return (
    <fieldset className={classNames(styles.fieldset, className)} {...props}>
      <legend
        className={classNames({
          [accessibilityStyles.hidden]: !showLegend,
        })}
      >
        {legend}
      </legend>
      <div className={inputsClassName}>
        {React.Children.map(children, child => {
          if (!React.isValidElement<ContentRevealChildProps>(child))
            return child;

          return React.cloneElement(child, {
            name,
            onChange: interceptEvent(
              child.props.onChange,
              handleChange,
              onChange
            ),
            checked: getChecked(child.props.value as SingleValue),
            type,
          });
        })}
      </div>
      <LiveMessage
        id={id}
        hidden={messageHidden}
        aria-live={props['aria-live']}
        invalid={invalid}
        className={classNames(typography.className, styles.message)}
      >
        {message}
      </LiveMessage>
    </fieldset>
  );
};
