import { useState, ReactNode, useRef, useEffect } from 'react';
import { AnimateHeight, ButtonBase, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import ArrowRight from '../assets/arrow-right.svg?react';

import styles from './Details.module.css';
import { trackClickedFAQQuestion } from '../../../analytics/events';

interface DetailsProps {
  id?: string;
  summary: string;
  itemProp?: string;
  children: ReactNode;
}

export const Details = ({ summary, children, itemProp }: DetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toggleDetails = () => {
    setIsOpen(prevOpen => !prevOpen);
  };

  useEffect(() => {
    if (isOpen) {
      trackClickedFAQQuestion({ question: summary });
    }
  }, [isOpen, summary]);

  const className = classNames(styles.details, { [styles.open]: isOpen });

  return (
    <div className={className}>
      <ButtonBase
        aria-expanded={isOpen}
        className={styles.summary}
        onClick={toggleDetails}
        tabIndex={0}
      >
        <ArrowRight className={styles.arrowRight} />{' '}
        <Text
          as="h3"
          vSpacing="none"
          variant="article-16"
          color="charcoal-3"
          itemProp={itemProp}
          bold={isOpen}
        >
          {summary}
        </Text>
      </ButtonBase>
      <AnimateHeight nodeRef={divRef} animateIn={isOpen}>
        <div ref={divRef} className={styles.content}>
          <Text
            as="p"
            topSpacing="sm"
            bottomSpacing="lg"
            variant="article-16"
            color="charcoal-3"
          >
            {children}
          </Text>
        </div>
      </AnimateHeight>
    </div>
  );
};
