import { AddVetpracticeFormFields } from './addVetpracticeFieldsData';

export interface AddVetPracticeFormValues {
  [AddVetpracticeFormFields.name]: string;
  [AddVetpracticeFormFields.phone]: string;
  [AddVetpracticeFormFields.streetAddress1]: string;
  [AddVetpracticeFormFields.streetAddress2]?: string;
  [AddVetpracticeFormFields.city]: string;
  [AddVetpracticeFormFields.state]: string | undefined;
  [AddVetpracticeFormFields.zip]: string;
  [AddVetpracticeFormFields.position]: string | undefined;
  [AddVetpracticeFormFields.acceptedTerms]: boolean;
}

export const addVetPracticeInitialFormValues: AddVetPracticeFormValues = {
  [AddVetpracticeFormFields.name]: '',
  [AddVetpracticeFormFields.phone]: '',
  [AddVetpracticeFormFields.streetAddress1]: '',
  [AddVetpracticeFormFields.streetAddress2]: '',
  [AddVetpracticeFormFields.city]: '',
  [AddVetpracticeFormFields.state]: undefined,
  [AddVetpracticeFormFields.zip]: '',
  [AddVetpracticeFormFields.position]: undefined,
  [AddVetpracticeFormFields.acceptedTerms]: false,
};
