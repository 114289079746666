const reasonOne = {
  title: 'It’s Not Raw',
  description:
    'Fresh food is cooked to kill potentially dangerous bacteria; it can be handled and stored as you would handle and store any perishable cooked food. Raw food is made of completely uncooked (raw) meat and other ingredients.',
};

const reasonTwo = {
  title: 'It’s Not Trend-Driven',
  description:
    'We don’t follow ingredient-driven food or marketing trends (for example, we offer recipes with and without grains). Our focus instead is on making safe, balanced food that makes good nutrition easy for vets and pet owners.',
};

const reasonThree = {
  title: 'It’s Not “Boutique” or “Exotic”',
  description:
    'We aren’t niche, and neither is our food. Over the past 10 years we have become a trusted, recognized brand in the pet food industry. Our complete and balanced recipes are made from high-quality ingredients plus added nutrients, and formulated by on-staff, board-certified nutritionists to meet the dietary needs of dogs.',
};

const reasonFour = {
  title: 'It’s Not Food for Humans',
  description:
    "Human-grade is simply a guarantee that foods labeled with this term are made according to the same stringent health and safety standards that govern human food. We don't pretend people should eat it—dogs and humans have different nutritional needs. We’ve raised the bar on pet-food industry standards because we believe it contributes to dogs’ health and vet professionals’ peace of mind.",
};

export const desktopReasons = [reasonOne, reasonTwo, reasonThree, reasonFour];

export const mobileReasons = [reasonOne, reasonTwo, reasonThree, reasonFour];
