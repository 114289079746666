/**
 * Split a path into an array of its component pieces.
 */
export function splitPath(path: string): string[] {
  if (path.startsWith('/')) {
    return path.split('/').slice(1);
  }

  return path.split('/');
}
