import { Button, Text } from '@farmersdog/corgi-x';

interface SupportItemProps {
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
}

export const SupportItem = ({
  title,
  description,
  linkText,
  linkUrl,
}: SupportItemProps) => {
  return (
    <>
      <Text
        variant="heading-22"
        bold
        color="Blueberry3"
        as="h3"
        topSpacing="none"
      >
        {title}
      </Text>
      <Text as="p" variant="article-16" topSpacing="xs" bottomSpacing="md">
        {description}
      </Text>
      <Button
        variant="plain-text"
        color="kale"
        type="link"
        to={linkUrl}
        underline
      >
        {linkText}
      </Button>
    </>
  );
};
