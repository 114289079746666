import { track } from '../track';
import { events_and_recordings_page_clicked_lunch_and_learn_request } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Request Lunch and Learn" button on the events & recordings page
 */

export function trackEventsAndRecordingsPageClickedLunchAndLearnRequest() {
  track({
    eventName: events_and_recordings_page_clicked_lunch_and_learn_request,
  });
}
