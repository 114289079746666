import { OrganizationType } from '../constants';
import {
  OrganizationDataSource,
  OrganizationInput,
  ValidatedPractice,
} from '../graphql/types';

export function validatedOrganizationToInput(
  validatedOrganization: ValidatedPractice
): OrganizationInput {
  return {
    id: validatedOrganization?.id,
    source: OrganizationDataSource.Google,
    name: validatedOrganization?.addressComponents?.name || '',
    country: validatedOrganization?.addressComponents?.country || '',
    state: validatedOrganization?.addressComponents?.state || '',
    city: validatedOrganization?.addressComponents?.city || '',
    streetAddress1:
      validatedOrganization?.addressComponents?.streetAddress1 || '',
    streetAddress2: validatedOrganization?.addressComponents?.streetAddress2,
    zip: validatedOrganization?.addressComponents?.zip || '',
    type: OrganizationType.Practice,
  };
}
