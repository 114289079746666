import { track } from '../track';
import { landing_page_clicked_view_clinical_recommendations } from './eventNames';

/**
 * Send a tracking event for when a user clicks on view clinical recommendations
 */

export function trackLandingPageClickedViewClinicalRecommendations() {
  track({ eventName: landing_page_clicked_view_clinical_recommendations });
}
